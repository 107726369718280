
import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
    FETCH_TRAINERS_AVAILABILITY_START,
    FETCH_TRAINERS_AVAILABILITY_USER_START,
    DELETE_TRAINERS_AVAILABILITY_START,
    SAVE_TRAINERS_AVAILABILITY_START
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

import {
    fetchTrainersAvailabilitySuccess,
    fetchTrainersAvailabilityFailure,
    fetchTrainersAvailabilityUserSuccess,
    fetchTrainersAvailabilityUserFailure,
    deleteTrainersAvailabilitySuccess,
    deleteTrainersAvailabilityFailure,
    saveTrainersAvailabilitySuccess,
    saveTrainersAvailabilityFailure,
    fetchTrainersAvailabilityStart,
} from "../actions/AvailabilityAction";

function* fetchTrainersAvailabilityAPI(action) {
  try {
    const response = yield api.postMethod("trainer_availabilities",action.data);

    if (response.data.success) {
      yield put(fetchTrainersAvailabilitySuccess(response.data.data));
    } else {
      yield put(fetchTrainersAvailabilityFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTrainersAvailabilityFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchTrainersAvailabilityUserAPI(action) {
  try {
    const response = yield api.postMethod("trainer_availabilities_for_user", action.data);

    if (response.data.success) {
      yield put(fetchTrainersAvailabilityUserSuccess(response.data.data));
    } else {
      yield put(fetchTrainersAvailabilityUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTrainersAvailabilityUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveTainerAvailabilityAPI(action) {
  try {
    const response = yield api.postMethod("trainer_availabilities_save" , action.data);

    if (response.data.success) {
      yield put(saveTrainersAvailabilitySuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(fetchTrainersAvailabilityStart({date:action.data.date}));
      yield put(createNotification(notificationMessage));
    } else {
      yield put(saveTrainersAvailabilityFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveTrainersAvailabilityFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteTrainersAvailabilityAPI(action) {
  try {
    const response = yield api.postMethod("trainer_availabilities_delete" , action.data);

    if (response.data.success) {
      yield put(deleteTrainersAvailabilitySuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(deleteTrainersAvailabilityFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteTrainersAvailabilityFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_TRAINERS_AVAILABILITY_START, fetchTrainersAvailabilityAPI)]);
  yield all([yield takeLatest(FETCH_TRAINERS_AVAILABILITY_USER_START, fetchTrainersAvailabilityUserAPI)]);
  yield all([yield takeLatest(DELETE_TRAINERS_AVAILABILITY_START, deleteTrainersAvailabilityAPI)]);
  yield all([yield takeLatest(SAVE_TRAINERS_AVAILABILITY_START, saveTainerAvailabilityAPI)]);
}
