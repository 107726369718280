import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import {
    postFileUploadStart,
    savePostStart,
    postFileRemoveStart,
    fetchSinglePostOwnerStart,
} from "../../../store/actions/PostAction";
import { Media, Container, Row, Col, Button, Form, Image, InputGroup, FormControl } from "react-bootstrap";
import Dropzone from 'react-dropzone'

import ReactPlayer from "react-player/lazy";

import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { getErrorNotificationMessage } from "../../helper/NotificationMessage";
import { translate, t } from "react-multi-lang";
// import ContentEditable from 'react-contenteditable'
// import { set } from "date-fns/esm";
import PostEditor from "../postMentions/PostEditor";
import { stateToHTML } from "draft-js-export-html";
import { Multiselect } from "multiselect-react-dropdown";
import ContentCreatorSteps from "../CreatePost/ContentCreatorSteps";
import axios from "axios";
import { isMobile } from "react-device-detect";

const EditPostIndex = (props) => {

    const [inputData, setInputData] = useState({});
    const [postFileData, setPostFileData] = useState([]);
    const [previewImage, setPreviewImage] = useState(false);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);

    const [paidPost, setPaidPost] = useState(false);
    const [videoTitle, setVideoTitle] = useState("");

    const [fileUploadStatus, setFileUploadStatus] = useState(false);

    const [videoThumbnailStatus, setVideoThumbnailStatus] = useState(false);

    const mentionsRef = useRef();

    const [editorContentState, setEditorContentstate] = useState("");

    const [editorHtmlContent, setEditorHtmlContent] = useState("");

    const [disableImage, setDisableImage] = useState(false);

    const [disableVideo, setDisableVideo] = useState(false);

    const [videoThumbnail, setVideoThumbnail] = useState({ videoPreviewImage: "" });

    const [audioTitle, setAudioTitle] = useState("");

    const [audioThumbnail, setAudioThumbnail] = useState(false);


    const [disableAudio, setDisableAudio] = useState(false);

    const [videoPreview, setVideoPreview] = useState({ previewVideo: "" });

    const [selectedValue, setSelectedValue] = useState("");


    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        fetchPostStart();
    }, []);

    const fetchPostStart = () => {
        props.dispatch(
            fetchSinglePostOwnerStart({
                post_unique_id: props.match.params.post_unique_id,
            })
        );
    };

    const onVideoDrop = useCallback(acceptedFiles => {
        // Do something with the files
        let data_array = [];
        let name = 'file[0]';
        data_array[name] = acceptedFiles[0];
        data_array['file_type'] = "video";
        setVideoTitle(acceptedFiles[0].name);
        setPaidPost(true);
        setFileUploadStatus(true);
        setVideoThumbnailStatus(true);
        setDisableImage(true);
        setDisableAudio(true);
        setVideoPreviewUrl(true);
        props.dispatch(postFileUploadStart(data_array));
    }, []);

    const onVideoPreviewImageDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles);
        let reader = new FileReader();
        let file = acceptedFiles[0];
        reader.onloadend = () => {
            setVideoThumbnail({ ...videoThumbnail, videoPreviewImage: reader.result });
        };
        if (file) {
            reader.readAsDataURL(file);
        }
        console.log(file)
        setInputData({
            ...inputData,
            preview_file: file,
        });
        setFileUploadStatus(true);
    }, [inputData]);

    useEffect(() => {
        setPaidPost(true);
        setVideoThumbnailStatus(true);
        setVideoPreviewUrl(true);
        if (props.singlePostOwner.data.post) {
            setPreviewFile(new File([props.singlePostOwner.data.post.postFiles[0]?.preview_file],
                props.singlePostOwner.data.post.postFiles[0].preview_file, { type: "image/jpeg" }));
            setFileUploadStatus(true);
            setEditorHtmlContent(props.singlePostOwner.data.post.content_formatted);
            setSelectedValue(props.singlePostOwner.data.post.is_home_post);
            setInputData({
                ...inputData,
                title: props.singlePostOwner.data.post.title,
                length: props.singlePostOwner.data.post.length,
                amount: props.singlePostOwner.data.post.token,
                content: props.singlePostOwner.data.post.content_formatted,
                post_file_id: props.singlePostOwner.data.post.post_file_id,
            })
        }

    }, [props.singlePostOwner.data.post]);

    console.log(previewFile)


    useEffect(() => {
        if (props.fileUpload.loading === false && props.fileUpload.data.post_file.length > 0) {
            let files = [];
            props.fileUpload.data.post_file.map((value, i) => {
                files.push(value.post_file);
            });
            setPostFileData(files);
            setInputData({
                ...inputData,
                post_file_id: props.fileUpload.data.post_file_id,
            });
        }
    }, [!props.fileUpload.loading]);

    useEffect(() => {
        if (props.fileRemove.loading === false) {
            let files = [];
            if (props.fileRemove.data.post_file.length > 0) {
                props.fileRemove.data.post_file.map((value, i) => {
                    files.push(value);
                });
            } else {
                setFileUploadStatus(false);
                setDisableVideo(false);
                setDisableAudio(false);
                setPaidPost(false);
                setDisableImage(false);
                setPreviewImage(false);
                setVideoPreviewUrl(false);
                setVideoThumbnailStatus(false);
                setAudioThumbnail(false);
                setVideoTitle('');
            }
            setInputData({
                ...inputData,
                post_file_id: props.fileRemove.data.post_file_id,
            });
            setPostFileData(files);
        }
    }, [!props.fileRemove.loading]);


    const handleChangeVideo = (event, fileType) => {

        let data_array = [];
        let name = 'file[0]';
        data_array[name] = event.target.files[0];
        data_array['file_type'] = fileType;
        setVideoTitle(event.target.files[0].name);

        data_array['post_id'] = props.singlePostOwner.data.post.post_id;

        setPaidPost(true);
        setFileUploadStatus(true);
        setVideoThumbnailStatus(true);
        setDisableImage(true);
        setDisableAudio(true);
        setVideoPreviewUrl(true);
        props.dispatch(postFileUploadStart(data_array));
    };



    const handleClose = (event, post_file) => {
        event.preventDefault();
        if (props.fileUpload.loadingButtonContent !== null) {
            const notificationMessage = getErrorNotificationMessage(
                t("file_is_uploaded_please_wait")
            );
            props.dispatch(createNotification(notificationMessage));
        } else {
            setPostFileData([]);
            props.dispatch(
                postFileRemoveStart({
                    file: post_file,
                    // file_type: props.fileUpload.data.post_file.file_type,
                    // blur_file: props.fileUpload.data.post_file.blur_file,
                    post_file_id: inputData.post_file_id,
                })
            );
        }
    };

    const handleRemove = (event, post_file) => {
        event.preventDefault();
        if (props.fileUpload.loadingButtonContent !== null) {
            const notificationMessage = getErrorNotificationMessage(
                t("file_is_being_uploaded_please_wait")
            );
            props.dispatch(createNotification(notificationMessage));
        } else {
            if (window.confirm(t("remove_post_file_confirmation"))) {

                setPostFileData([]);
                props.dispatch(
                    postFileRemoveStart({
                        post_file_id: post_file.post_file_id,
                    })
                );
                fetchPostStart();
            }
        }
    };

    const handleSubmit = (event) => {
        console.log(previewFile)
        event.preventDefault();
        if (fileUploadStatus) {
            props.dispatch(
                savePostStart({
                    title: inputData.title,
                    content: inputData.content,
                    length: inputData.length,
                    is_home_post: selectedValue,
                    amount: inputData.amount ? inputData.amount : "",
                    post_file_id: inputData.post_file_id ? inputData.post_file_id : "",
                    preview_file: inputData.preview_file ? inputData.preview_file : "",
                    post_category_ids: inputData.post_category_ids
                        ? inputData.post_category_ids
                        : [],
                    post_id: props.singlePostOwner.data.post.post_id,
                    video_preview_file: inputData.video_preview_file ? inputData.video_preview_file : "",
                })
            );
        } else {
            props.dispatch(
                savePostStart({
                    is_home_post: selectedValue,
                    title: inputData.title,
                    content: inputData.content,
                    length: inputData.length,
                    post_id: props.singlePostOwner.data.post.post_id,
                    amount: inputData.amount ? inputData.amount : "",
                    post_category_ids: inputData.post_category_ids
                        ? inputData.post_category_ids
                        : [],
                })
            );
        }
    };

    const handleVideopreviewImage = (event) => {
        if (event.currentTarget.type === "file") {
            setFileUploadStatus(true);
            let reader = new FileReader();
            let file = event.currentTarget.files[0];
            reader.onloadend = () => {
                setVideoThumbnail({ ...videoThumbnail, videoPreviewImage: reader.result });
            };

            if (file) {
                reader.readAsDataURL(file);
            }

            setInputData({
                ...inputData,
                preview_file: file,
            });
        }
    };

    const handleVideoPreview = (event) => {
        if (event.currentTarget.type === "file") {
            let reader = new FileReader();
            let file = event.currentTarget.files[0];
            reader.onloadend = () => {
                setVideoPreview({ ...videoPreview, previewVideo: reader.result });
            };

            if (file) {
                reader.readAsDataURL(file);
            }

            setInputData({
                ...inputData,
                video_preview_file: file,
            });
        }
    };

    return (
        <div className="notification-page create-post" id="tabs">
            <Container>
                <ul className="nav" role="tablist">
                    <Media
                        as="li"
                        role="presentation"
                        className={props.activeSec === "settings-card" ? "active" : ""}
                    >
                        <Link
                            to={isMobile ? "/home" : "/w/home"}
                            className="bookmarkes-list"
                            onClick={() => props.history.goBack()}
                        >
                            <Image
                                src={window.location.origin + "/assets/images/icons/back.svg"}
                                className="svg-clone"
                            />
                        </Link>
                    </Media>
                </ul>
                <h3 className="head-title">Edit Workout Posts</h3>

                {props.singlePostOwner.loading ? '' :
                    localStorage.getItem("is_content_creator") == 2 ? (
                        <div className="create-post-new-box">
                            <Form className="create-post-new-form" onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <div className="create-post-new-left-sec">
                                            <InputGroup className="mb-5">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="basic-addon1">Workout Name</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                    placeholder="(Maximum 6 Words)"
                                                    value={inputData.title}
                                                    onChange={e => setInputData({
                                                        ...inputData,
                                                        title: e.target.value,
                                                    })}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-5 display-home">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="basic-addon">
                                                        {t("add_post_to_public")}:
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Group className="form-posts">
                                                    <label className="radio-label">
                                                        <input
                                                            className="form-check-inputs btn"
                                                            type="radio"
                                                            value="1"
                                                            checked={selectedValue == '1'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        {t("yes")}
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value="0"
                                                            className="form-check-inputs btn"
                                                            checked={selectedValue == '0'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        {t("no")}
                                                    </label>
                                                </Form.Group>
                                                <p className="create-home-post">{t("when_you_add_your_workout_post_para")}</p>
                                            </InputGroup>
                                            <InputGroup className="mb-5 textarea-input">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="basic-addon1">Workout Description</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                    as="textarea"
                                                    aria-label="With textarea"
                                                    value={inputData.content}
                                                    onChange={e => setInputData({
                                                        ...inputData,
                                                        content: e.target.value,
                                                    })} />
                                            </InputGroup>
                                            <InputGroup className="mb-5">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="basic-addon1">Workout Length</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                    placeholder="(In Minutes)"
                                                    value={inputData.length}
                                                    onChange={e => setInputData({
                                                        ...inputData,
                                                        length: e.target.value,
                                                    })}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-0">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="basic-addon1">Price for Media</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    type="number"
                                                    placeholder={t("price_placeholder")}
                                                    name="amount"
                                                    pattern="[0-9]*"
                                                    min="1"
                                                    inputmode="numeric"
                                                    value={inputData.amount}
                                                    onChange={(event) =>
                                                        setInputData({
                                                            ...inputData,
                                                            amount: event.currentTarget.value,
                                                        })
                                                    }
                                                />
                                            </InputGroup>
                                        </div>
                                        {props.singlePostOwner.data.post.postFiles ? (
                                            <Row>
                                                {props.singlePostOwner.data.post.postFiles.map((postFile, index) => (
                                                    <>
                                                        <Col sm={12} md={12}>
                                                            <div key={postFile.post_file_id} className="post-img-preview-sec my-3 my-lg-4">

                                                                <ReactPlayer
                                                                    // light={postFile.preview_file}
                                                                    url={postFile.post_file}
                                                                    controls={true}
                                                                    width="400"
                                                                    height="200"
                                                                    playing={true}
                                                                    muted={true}
                                                                    autoplay={true}
                                                                    className="post-video-size"
                                                                />
                                                                <Link to="#" onClick={(event) => handleRemove(event, postFile)} className="close-video">
                                                                    <i className="far fa-window-close"></i>
                                                                </Link>
                                                            </div>
                                                        </Col>
                                                    </>
                                                ))}
                                            </Row>
                                        ) : null}
                                    </Col>
                                    <Col md={6}>
                                        <div className="create-post-new-right-sec">
                                            {videoPreviewUrl && postFileData ? (
                                                <Row>
                                                    {videoTitle !== "" ? (
                                                        <div className="post-title-content create-post-video-title mb-2">
                                                            <h4>{videoTitle}</h4>
                                                        </div>
                                                    ) : null}
                                                    {postFileData.map((video, index) => (
                                                        <>
                                                            <h4 className="mt-4">Note: Vimeo video upload in progress. It will take few minutes to load.</h4>
                                                            <Col sm={12} md={12}>
                                                                <div key={index} className="post-img-preview-sec my-3 my-lg-4">

                                                                    <iframe src={video} width="400" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen controlsList="nodownload"></iframe>

                                                                    <Link to="#" onClick={(event) => handleClose(event, video)} className="close-video">
                                                                        <i className="far fa-window-close"></i>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    ))}
                                                </Row>
                                            ) :
                                                ''
                                            }

                                            <Dropzone
                                                onDrop={onVideoDrop}
                                                accept={{ 'video/*': ['.mp4', '.webm',] }}
                                                multiple={false}
                                                disabled={props.fileUpload.buttonDisable}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="create-post-new-uploader-sec">
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <div className="create-post-new-uploader-card">
                                                                <Image
                                                                    className="create-post-new-uploader-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/fitness/icons/upload-video-icon.svg"
                                                                    }
                                                                />
                                                                {props.fileUpload.loadingButtonContent ?
                                                                    <h4>{props.fileUpload.loadingButtonContent}</h4>
                                                                    : <>
                                                                        <h4>Maximum Video  size  20 Mb</h4>
                                                                        <p>Please Compress If its Larger than 20 Mb</p>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>

                                            {videoThumbnailStatus === true
                                                ? <Dropzone
                                                    onDrop={onVideoPreviewImageDrop}
                                                    accept={{ 'image/*': ['.gif', '.jpg', '.jpeg', '.gif', '.png', '.jpg', '.jpeg', '.png'] }}
                                                    multiple={false}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="create-post-new-uploader-thumbnail-sec">
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <div className="create-post-new-uploader-thumbnail-card">
                                                                    <Image
                                                                        className="create-post-new-uploader-thumbnail-icon"
                                                                        src={
                                                                            window.location.origin + "/assets/images/fitness/icons/upload-video-thumbnails.svg"
                                                                        }
                                                                    />
                                                                    <p>Upload Thumbnail Image</p>
                                                                </div>
                                                                <div className="image-format-sec">
                                                                    <h4>
                                                                        Supported Format -{" "}
                                                                        <span>jpg, jpeg, png, gif - 1500px * 1000px</span>
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                : null
                                            }
                                            {videoThumbnail.videoPreviewImage !== "" ? (
                                                <Row>
                                                    <Col sm={12} md={6} className="mb-3 mb-lg-4">
                                                        <div className="post-img-preview-sec m-0">
                                                            <Image
                                                                alt="#"
                                                                src={videoThumbnail.videoPreviewImage}
                                                                className="post-video-preview"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ) : null}

                                            {videoThumbnail.videoPreviewImage === "" && props.singlePostOwner.data.post.postFiles[0] ? (
                                                <Row>
                                                    <Col sm={12} md={6} className="mb-3 mb-lg-4">
                                                        <div className="post-img-preview-sec m-0">
                                                            <Image
                                                                alt="#"
                                                                src={props.singlePostOwner.data.post.postFiles[0].preview_file}
                                                                className="post-video-preview"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ) : null}


                                            <div className="create-post-new-btn-sec">
                                                {localStorage.getItem("is_content_creator") == 2 ? (
                                                    <Button
                                                        type="submit"
                                                        className="btn gradient-btn postBtn gradientcolor text-uppercase mt-0 mt-md-3"
                                                        onClick={handleSubmit}
                                                        disabled={
                                                            props.fileUpload.buttonDisable ||
                                                            props.savePost.buttonDisable
                                                        }
                                                    >
                                                        {props.fileUpload.loadingButtonContent !== null
                                                            ? props.fileUpload.loadingButtonContent
                                                            : props.savePost.loadingButtonContent !== null
                                                                ? props.savePost.loadingButtonContent
                                                                : t("save_changes")}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="submit"
                                                        className="btn gradient-btn postBtn gradientcolor text-uppercase mt-0 mt-md-3"
                                                        disabled="true"
                                                    >
                                                        {t("post")}
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    ) : (
                        ""
                    )
                }
            </Container>
            {localStorage.getItem("is_content_creator") != 2 && (
                <ContentCreatorSteps />
            )}
        </div>
    );
};

const mapStateToPros = (state) => ({
    savePost: state.post.savePost,
    fileUpload: state.post.fileUpload,
    fileRemove: state.post.fileRemove,
    searchUser: state.home.searchUser,
    postCategories: state.post.postCategories,
    singlePostOwner: state.post.singlePostOwner,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(EditPostIndex));