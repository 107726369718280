import React, { useState } from "react";
import { Dropdown, Image, Media, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import PaymentModal from "./PaymentModal";
import configuration from "react-global-configuration";
import { liveVideoStartCallStart } from "../../store/actions/LiveVideoAction";

const LiveDataCardNew = (props) => {
    const { video } = props;
    const [paymentModal, setPaymentModal] = useState(false);
    const openPaymentModal = (event) => {
        event.preventDefault();
        setPaymentModal(true);
    };

    const closePaymentModal = () => {
        setPaymentModal(false);
    };

    const handleLiveStart = (event, live_video_id) => {
        event.preventDefault();
        props.dispatch(
            liveVideoStartCallStart({
                live_video_id: live_video_id,
            })
        );
    };

    return (
        <>
            <div className="web-livestreaming-schedule-card">
                <div className="web-livestreaming-schedule-top-sec">
                    <Image
                        className="web-livestreaming-schedule-top-bg-img"
                        src={video.snapshot}
                    />
                    <div className="web-livestreaming-schedule-user-img-sec">
                        <Image
                            className="web-livestreaming-schedule-user-img"
                            src={video.user_picture}
                        />
                    </div>
                    {video.amount > 0 ? (
                        <div className="web-livestreaming-schedule-video-token">
                            <h3>{video.amount_formatted}</h3>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="web-livestreaming-schedule-video-timezone">
                        <h3>{video.formatted_schedule_time}</h3>
                    </div>
                </div>
                <div className="web-livestreaming-schedule-bottom-sec">
                    <div className="web-livestreaming-schesule-info-sec">
                        <h4>{video.title}</h4>
                        <p>@{video.user_displayname}</p>
                    </div>
                    <div className="start-now-btn-sec">
                        {video.streaming_type == 2 &&
                            video.user_id == localStorage.getItem("userId") ? (
                            <Button
                                className="start-now-btn"
                                onClick={(event) =>
                                    handleLiveStart(event, video.live_video_id)
                                }
                            >
                                {t("start_now")}
                            </Button>
                        ) : video.is_streaming &&
                            video.user_id == localStorage.getItem("userId") ? (
                            <Link
                                className="start-now-btn"
                                to={`/join/${video.live_video_unique_id}`}
                            >
                                {t("join_now")}
                            </Link>
                        ) : (
                            ""
                        )}

                        {video.is_user_needs_to_pay == 1 ? (
                            <Button
                                className="live-btn-blue"
                                type="submit"
                                disabled={video.buttonDisable}
                                onClick={(event) => openPaymentModal(event)}
                            >
                                {t("pay_and_join")}
                            </Button>
                        ) : (
                            ""
                        )}

                        {video.is_user_needs_to_pay == 0 &&
                            video.user_id != localStorage.getItem("userId") ? (
                            <Link
                                to={`/join/${video.live_video_unique_id}`}
                                target="_blank"
                                className="live-btn-blue"
                                type="button"
                            >
                                {" "}
                                {t("join_now")}
                            </Link>
                        ) : (
                            ""
                        )}

                        <PaymentModal
                            paymentModal={paymentModal}
                            closePaymentModal={closePaymentModal}
                            liveVideo={video}
                        />

                    </div>
                </div>
            </div>

        </>
    );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(LiveDataCardNew));
