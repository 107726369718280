import React, { Component } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import AuthLayout from "../layouts/AuthLayout";
import EditProfile from "../Accounts/Profile/EditProfile";
import ProfileIndex from "../Accounts/Profile/ProfileIndex";
import NotFoundIndex from "../NotFound/NotFoundIndex";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import LandingPageLoader from "../Loader/LandingPageLoader";
import EmptyLayout from "../layouts/EmptyLayout";
import LandingPageIndex from "../LandingPageIndex/LandingPageIndex";
import HomePageIndex from "../Home/HomePageIndex";
import MessageIndex from "../Messages/MessageIndex";
import BookmarksIndex from "../Bookmarks/BookmarksIndex";
import BookmarkPhoto from "../Bookmarks/BookmarkPhoto";
import BookmarkVideo from "../Bookmarks/BookmarkVideo";
import BookmarkAudio from "../Bookmarks/BookmarkAudio";
import ModelViewProfile from "../Model/ModelViewProfile";
import FollowingIndex from "../Accounts/FansFollowing/Following/FollowingIndex";
import ListIndex from "../Accounts/List/ListIndex";
import NotificationIndex from "../Notification/NotificationIndex";
import CreatePostIndex from "../Post/CreatePost/CreatePostIndex";
import FavoritesIndex from "../Accounts/Favorites/FavoritesIndex";
import PaymentsIndex from "../Accounts/Payments/PaymentsIndex";
import BankingIndex from "../Accounts/Payments/BankingIndex";
import CardsIndex from "../Accounts/Payments/CardsIndex";
import AddBankIndex from "../Accounts/Payments/AddBankIndex";
import Logout from "../Accounts/Logout";
import Wallet from "../Wallet/Wallet";
import BillingAccountIndex from "../Accounts/Payments/BillingAccountIndex";
import DocumentUploadIndex from "../DocumentUpload/DocumentUploadIndex";
import StaticPage from "../StaticPage/StaticPage";
import StaticPageSupport from "../StaticPage/StaticPageSupport";
import FanIndex from "../Accounts/FansFollowing/Fans/FanIndex";
import PostView from "../Post/PostView";
import CategoryUsers from "../Categories/CategoryUsers";
import ResetPassword from "../LandingPageIndex/ResetPassword";
import UploadProfilePicture from "../Accounts/Profile/UploadProfilePicture";
import UserChatIndex from "../UserChat/UserChatIndex";
import UserChatMobileRoom from "../UserChat/UserChatMobileRoom";
import LiveStreamingPost from "../LiveStreaming/LiveStreamingPost";
import CategoryListingIndex from "../CategoryListing/CategoryListingIndex";
import SingleProfile from "../Accounts/Profile/SingleProfile";
import Explore from "../Post/Explore/Explore";
import DashboardContentCreator from "../DashboardContentCreator/DashboardContentCreator";
import BecomeAContentCreatorIndex from "../BecomeAContentCreator/BecomeAContentCreatorIndex";
import EcomIndex from "../Ecom/EcomIndex";
import EcomCartIndex from "../Ecom/EcomCartIndex";
import EcomPaymentIndex from "../Ecom/EcomPaymentIndex";
import EcomPaymentMethod from "../Ecom/EcomPaymentMethod";
import EcomCategoryIndex from "../Ecom/EcomCategoryIndex";
import SingleProduct from "../Ecom/Product/SingleProduct";
import OrderList from "../Ecom/Orders/OrderList";
import OrderView from "../Ecom/Orders/OrderView";
import OrderTransaction from "../Ecom/Orders/OrderTransaction";
import AddProduct from "../Ecom/Product/AddProduct";
import EditProduct from "../Ecom/Product/EditProduct";
import ProductList from "../Ecom/Product/ProductList";
import ExploreIndex from "../Post/Explore/ExploreIndex";
import StoriesIndex from "../Home/Stories/StoriesIndex";
import VerificationIndex from "../Verification/VerificationIndex";
import RegisterVerifyIndex from "../Verification/RegisterVerifyIndex";

import UserLiveVideosIndex from "../LiveVideos/UserLiveVideosIndex";
import UserWebLiveVideosIndex from "../LiveVideos/UserWebLiveVideosIndex";
import UserScheduledLiveVideosIndex from "../LiveVideos/ScheduledLiveVideosIndex";
import LiveVideosIndex from "../LiveVideos/LiveVideosIndex";
import SingleLiveVideosIndex from "../LiveVideos/SingleLiveVideosIndex";
import EditLiveVideos from "../LiveVideos/EditLiveVideos";
import JoinLiveVideoIndex from "../LiveVideos/JoinLiveVideoIndex";
import VideoCallRequestSentIndex from "../OneToOneStreaming/VideoCallRequestList/VideoCallRequestSentIndex";
import VideoCallHistoryIndex from "../OneToOneStreaming/VideoCallRequestList/VideoCallHistoryIndex";
import AudioCallHistoryIndex from "../OneToOneStreaming/AudioCallRequestList/AudioCallHistoryIndex";
import VideoCallRequestReceivedIndex from "../OneToOneStreaming/VideoCallRequestList/VideoCallRequestReceivedIndex";
import VideoCallIndex from "../OneToOneStreaming/VideoCallIndex";
import AudioCallIndex from "../OneToOneStreaming/AudioCallRequestList/AudioCallIndex";
import ReferralsIndex from "../Referrals/ReferralsIndex";
import BlockedUserIndex from "../Accounts/BlockedUser/BlockedUserIndex";

// Coach New Page

import CoachLayout from "../layouts/CoachLayout";
import EmptyLayoutNew from "../layouts/EmptyLayoutNew";
import CoachSingleProfileIndex from "../Fitness/CoachSingleProfileIndex";
import NewHomePageIndex from "../Home/NewHomePageIndex";
import LiveStreamingOnetoOne from "../LiveStreaming/LiveStreamingOnetoOne";
import LiveStreamingOnetoMany from "../LiveStreaming/LiveStreamingOnetoMany";
import RegisterIndex from "../Auth/RegisterIndex";
import LoginIndex from "../Auth/LoginIndex";
import ForgotPasswordIndex from "../Auth/ForgotPasswordIndex";
import TrainerRegisterIndex from "../Auth/TrainerRegisterIndex";
import TrainerLoginIndex from "../Auth/TrainerLoginIndex";
import TrainerForgotPasswordIndex from "../Auth/TrainerForgotPasswordIndex";
import NewSingleProfile from "../SingleProfile/CoachSingleProfileIndex";
import PostsIndex from "../SingleProfile/PostsIndex";
import EditPostIndex from "../Post/EditPost/EditPostIndex";

import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
import VideoCallList from "../VideoCalls/VideoCallList";
import StoriesSliderModal from "../Home/StoriesSliderModal";
import ScrollToTop from "../helper/ScrollToTop";
import SingleProductOrders from "../Ecom/Product/SingleProductOrders";
import ProductGallery from "../Ecom/Product/ProductGallery";
import NewNotificationIndex from "../Fitness/NewNotificationIndex";
import TransactionIndex from "../Transaction/TransacionIndex";
import AudioCallRequestSentIndex from "../OneToOneStreaming/AudioCallRequestList/AudioCallRequestSentIndex";
import AudioCallRequestReceivedIndex from "../OneToOneStreaming/AudioCallRequestList/AudioCallRequestReceivedIndex";
import SendNotificationIndex from "../BulkNotification/SendNotificationIndex";
import NotificationSettingsIndex from "../BulkNotification/NotificationSettingsIndex";
import BecomeAContentCreatorMobile from "../BecomeAContentCreator/NewBecomeAContentCreator/BecomeAContentCreatorMobile";
import LiveVideoHistoryIndex from "../LiveVideos/LiveVideoHistoryIndex";
import ScheduleAvailabilityIndex from "../Accounts/Profile/ScheduleAvailability/ScheduleAvailabilityIndex";
import CreatePostNewIndex from "../Post/CreatePost/CreatePostNewIndex";
import LoginModalContainer from "../Auth/LoginModalContainer";
import queryString from 'query-string';
import HomeProfileListIndex from "../Home/HomeProfileListIndex";
import WebLiveStreamingIndex from "../LiveVideos/WebLiveStreamingIndex";
import NewVideoCallIndex from "../LiveStreaming/NewVideoCallIndex";
import NewAudioCallIndex from "../LiveStreaming/NewAudioCallIndex";
import NewSingleProfileIndex from "../SingleProfile/NewSingleProfileIndex";
import NewLandingPage from "../LandingPageIndex/NewLandingPage";

import MarcIndex from "../marc/MarcIndex";
import SingleVideo from "../marc/SingleVideo";

setTranslations({ en, es });

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => {
  if (!authentication)
    localStorage.setItem("is_client", queryString.parse(window.location.search).is_client);
  localStorage.setItem("redirect", window.location.href);
  return <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : 
      !localStorage.getItem("userId") ?
        <Redirect to={{ pathname: "/login", state: { from: props.location }, search: queryString.stringify({ is_client: queryString.parse(window.location.search).is_client }) }} />
      :
      history.push("/")
    }
  />
};

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    this.state = {
      loading: true,
      configLoading: true,
      authentication: userId && accessToken ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");

      accessToken = localStorage.getItem("accessToken");

      this.setState({
        loading: true,
        authentication: userId && accessToken ? true : false,
      });

      document.body.scrollTop = 0;
    });
  }

  componentDidMount() {
    this.fetchConfig();
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    console.log(userLanguage);
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }

  async fetchConfig() {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      // console.log(configValue.data);
      this.setState({ configLoading: false });
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      this.setState({ configLoading: false });
    }

    $("#google_analytics").html(
      configuration.get("configData.google_analytics")
    );

    $("#header_scripts").html(configuration.get("configData.header_scripts"));

    $("#body_scripts").html(configuration.get("configData.body_scripts"));
  }

  render() {
    const isLoading = this.state.configLoading;

    if (isLoading) {
      return (
        // Place content loadder here
        <div>{/* <HomeLoader></HomeLoader> */}</div>
      );
    }
    return (
      <>
        <Helmet>
          <title>{configuration.get("configData.site_name")}</title>
          <link
            rel="icon"
            type="image/png"
            href={configuration.get("configData.site_icon")}
          // sizes="16x16"
          />
        </Helmet>
        <ScrollToTop />
        <Switch>
        <AppRoute
            authentication={this.state.authentication}
            path={"/"}
            exact component={MarcIndex}
            layout={CoachLayout}
          />
          
          <AppRoute
            path={"/new-home"}
            component={NewLandingPage}
            layout={EmptyLayoutNew}
          />

          <AppRoute
            path={"/login"}
            component={LoginIndex}
            layout={EmptyLayoutNew}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/home-old"}
            component={HomePageIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/inbox"}
            component={UserChatIndex}
            layout={EmptyLayoutNew}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/add-post-old"}
            component={CreatePostIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/add-post"}
            component={CreatePostNewIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-post/:post_unique_id"}
            component={EditPostIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/post/:post_unique_id"}
            component={PostView}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/bookmark-photo"}
            component={BookmarkPhoto}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/bookmark-video"}
            component={BookmarkVideo}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/bookmark-audio"}
            component={BookmarkAudio}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-profile"}
            component={EditProfile}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/send-notification"}
            component={SendNotificationIndex}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/notification-settings"}
            component={NotificationSettingsIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile"}
            component={ProfileIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/notification"}
            component={NotificationIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/payments"}
            component={PaymentsIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/billing-accounts"}
            component={BillingAccountIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/add-bank"}
            component={AddBankIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/banking"}
            component={BankingIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/document-upload"}
            component={DocumentUploadIndex}
            layout={CoachLayout}
          />

          <AppRoute
            path={"/page/:title"}
            component={StaticPage}
            layout={EmptyLayoutNew}
          />

          <AppRoute
            path={"/support"}
            component={StaticPageSupport}
            layout={EmptyLayoutNew}
          />

          <AppRoute
            path={"/reset-password/:token"}
            component={ResetPassword}
            layout={EmptyLayoutNew}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/upload-profile-picture"}
            component={UploadProfilePicture}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/user-chat"}
            component={UserChatIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/user-chat-room/:from_user_id/:to_user_id"}
            component={UserChatMobileRoom}
            layout={EmptyLayoutNew}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/video-calls-sent"}
            component={VideoCallRequestSentIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/audio-calls-sent"}
            component={AudioCallRequestSentIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/live-streaming-post"}
            component={LiveStreamingPost}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/video-calls-history"}
            component={VideoCallHistoryIndex}
            layout={CoachLayout}
          />

          {/* <PrivateRoute
            authentication={this.state.authentication}
            path={"/audio-calls-history"}
            component={AudioCallHistoryIndex}
            layout={CoachLayout}
          /> */}

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/explore"}
            component={ExploreIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/stories"}
            component={StoriesIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/video-calls-received"}
            component={VideoCallRequestReceivedIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/audio-calls-received"}
            component={AudioCallRequestReceivedIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/category-listing"}
            component={CategoryListingIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/private-call/:video_call_request_unique_id"}
            component={VideoCallIndex}
            layout={EmptyLayoutNew}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/private-audio-call/:audio_call_request_unique_id"}
            component={AudioCallIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/single-profile"}
            component={SingleProfile}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/live-videos-history"}
            component={LiveVideosIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/live-video/:live_video_unique_id"}
            component={SingleLiveVideosIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-live-video/:live_video_unique_id"}
            component={EditLiveVideos}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/live-videos"}
            component={UserLiveVideosIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/w/live-videos"}
            component={UserWebLiveVideosIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/scheduled-live-videos"}
            component={UserScheduledLiveVideosIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/join/:live_video_unique_id"}
            component={JoinLiveVideoIndex}
            layout={EmptyLayoutNew}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/referrals"}
            component={ReferralsIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/logout"}
            component={Logout}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/category/:u_category_unique_id"}
            component={CategoryUsers}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/dashboard"}
            component={DashboardContentCreator}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/become-a-coach"}
            component={BecomeAContentCreatorIndex}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/ecom"}
            component={EcomIndex}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/ecom-cart"}
            component={EcomCartIndex}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/ecom-payment"}
            component={EcomPaymentIndex}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/ecom-payment-method"}
            component={EcomPaymentMethod}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/ecom-category/:search_key"}
            component={EcomCategoryIndex}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/single-product/:product_unique_id"}
            component={SingleProduct}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/add-product"}
            component={AddProduct}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-product/:user_product_id"}
            component={EditProduct}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/product-list"}
            component={ProductList}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/order-list"}
            component={OrderList}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/order-view/:id"}
            component={OrderView}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/order-transaction"}
            component={OrderTransaction}
            layout={CoachLayout}
          />
          <AppRoute
            path={"/verification"}
            component={VerificationIndex}
            layout={AuthLayout}
          />

          <AppRoute
            path={"/register/verify"}
            component={RegisterVerifyIndex}
            layout={AuthLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/view-order/:u_id/:id"}
            component={SingleProductOrders}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/product-gallery/:u_id/:id"}
            component={ProductGallery}
            layout={CoachLayout}
          />

          <AppRoute
            path={"/coach-single-profile"}
            component={CoachSingleProfileIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/home"}
            component={NewHomePageIndex}
            layout={CoachLayout}
          />

          <AppRoute
            path={"/one-to-one"}
            component={LiveStreamingOnetoOne}
            layout={EmptyLayoutNew}
          />

          <AppRoute
            path={"/one-to-many"}
            component={LiveStreamingOnetoMany}
            layout={EmptyLayoutNew}
          />
          <AppRoute
            path={"/register"}
            component={RegisterIndex}
            layout={EmptyLayoutNew}
          />
          <AppRoute
            path={"/login"}
            component={LoginIndex}
            layout={EmptyLayoutNew}
          />

          <AppRoute
            path={"/login-redirect"}
            component={LoginModalContainer}
            layout={EmptyLayoutNew}
          />

          <AppRoute
            path={"/forgot-password"}
            component={ForgotPasswordIndex}
            layout={EmptyLayoutNew}
          />

          <AppRoute
            path={"/trainer-register"}
            component={TrainerRegisterIndex}
            layout={EmptyLayoutNew}
          />

          <AppRoute
            path={"/trainer-login"}
            component={TrainerLoginIndex}
            layout={EmptyLayoutNew}
          />

          <AppRoute
            path={"/trainer-forgot-password"}
            component={TrainerForgotPasswordIndex}
            layout={EmptyLayoutNew}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/cards"}
            component={CardsIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/wallet"}
            component={Wallet}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/bookmarks"}
            component={BookmarksIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/list"}
            component={ListIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/clients"}
            component={FanIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/following"}
            component={FollowingIndex}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/blocked-users"}
            component={BlockedUserIndex}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/favorites"}
            component={FavoritesIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/video-calls-history"}
            component={VideoCallHistoryIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/audio-calls-history"}
            component={AudioCallHistoryIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/new-notification"}
            component={NewNotificationIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/transaction/:username?"}
            component={TransactionIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/posts"}
            component={PostsIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/content-creator"}
            component={BecomeAContentCreatorMobile}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/live-history"}
            component={LiveVideoHistoryIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/schedule-availability"}
            component={ScheduleAvailabilityIndex}
            layout={CoachLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/w/home"}
            component={HomeProfileListIndex}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/w/scheduled-live-videos"}
            component={WebLiveStreamingIndex}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/video-call/:video_call_request_unique_id"}
            component={NewVideoCallIndex}
            layout={CoachLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/audio-call/:audio_call_request_unique_id"}
            component={NewAudioCallIndex}
            layout={CoachLayout}
          />
          {/* Mobile route */}
          <AppRoute
            authentication={this.state.authentication}
            path={"/w/:username"}
            component={NewSingleProfile}
            layout={CoachLayout}
          />

    
           <AppRoute
            authentication={this.state.authentication}
            path={"/single-video/:post_unique_id"}
            component={SingleVideo}
            layout={CoachLayout}
          />

          {/* Dont move this route to top */}
          <AppRoute
            authentication={this.state.authentication}
            path={"/:username"}
            component={NewSingleProfileIndex}
            layout={CoachLayout}
          />
          <Route path="*" component={NotFoundIndex} />
        </Switch>
      </>
    );
  }
}

export default App;
