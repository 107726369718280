import React, { useState, useEffect } from "react";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import PaymentModal from "../LiveVideos/PaymentModal";
import { Link } from "react-router-dom";
import { Image, Media } from "react-bootstrap";
import { useHistory } from "react-router";

const ModelLiveStreamingCard = (props) => {
  const { video } = props;

  const history = useHistory();

  const [showContents, setShowContents] = React.useState(false);

  const redirectToLogin = (
    event,
    is_user_needs_to_pay,
    live_video_unique_id,
    streaming_type
  ) => {
    if (!localStorage.getItem("userId")) {
      history.push(`/login-redirect?is_client=${localStorage.getItem("is_client")}`);
    } else if (is_user_needs_to_pay) {
      openPaymentModal(event);
    } else if (streaming_type == 2) {
      history.push(`/live-video/${live_video_unique_id}`);
    } else {
      history.push(`/join/${live_video_unique_id}`);
    }
  };

  const [paymentModal, setPaymentModal] = useState(false);
  const openPaymentModal = (event) => {
    event.preventDefault();
    setPaymentModal(true);
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  return (
    <>
      <div className="coach-single-profile-subscribers-sessions">
        <div className="coach-single-profile-program-card">
          <Link
            to="#"
            onClick={(event) =>
              redirectToLogin(
                event,
                video.is_user_needs_to_pay,
                video.live_video_unique_id,
                video.streaming_type
              )
            }
          >
            <div className="new-coach-single-header">
              <h3>{video.title}</h3>
            </div>
            <div className="coach-single-profile-program-video-sec">
              <Image
                className="coach-single-profile-program-video"
                src={
                  video.snapshot
                    ? video.snapshot
                    : window.location.origin +
                    "/assets/images/fitness/video-1.jpg"
                }
              />
            </div>
            {video.is_streaming == 1 ? (
              <div className="coach-single-profile-on-demand">Live</div>
            ) : (
              ""
            )}
            {video.amount > 0 && video.is_user_needs_to_pay ? (
              <div className="coach-single-profile-on-scheduled">
                {t("book_now")}
              </div>
            ) : (
              ""
            )}
            {/* <div className="coach-single-profile-fav-icon">
										<i className="far fa-bookmark"></i>
									</div> */}
            <div className="new-live-streaming-time">
              <h4>Going Live {video.formatted_schedule_time}</h4>
            </div>

            <div className="coach-single-profile-program-info">
              <div>
                <div className="new-video-time">
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/icons/new/video-camera.svg"
                    }
                    className="new-video-icon"
                  />
                  <span>{video.no_of_minutes} Minutes</span>{" "}
                </div>
                {/* <h4>{video.title}</h4> */}
                {/* <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> */}
              </div>
              {video.amount > 0 && video.is_user_needs_to_pay ? (
                <div className="coach-single-profile-program-time-count">
                  <p>
                    <Image
                      className="token-icon"
                      src={
                        window.location.origin +
                        "/assets/images/fitness/token/token4.svg"
                      }
                    />
                    {video.amount_formatted}
                  </p>
                </div>
              ) : null}
            </div>
          </Link>
          <div className="coach-single-profile-program-toggle">
            <Link to="#" onClick={() => setShowContents(!showContents)}>
              {/* <i className="fas fa-chevron-down"></i> */}
              <Image
                src={
                  window.location.origin +
                  "/assets/images/icons/new/arrow-down.svg"
                }
                className="card-down-arrow"
              />
            </Link>
          </div>
        </div>
        {showContents && (
          <div className="coach-single-profile-program-desc">
            <p className="coach-bio-sec">{video.description}</p>
          </div>
        )}

        <PaymentModal
          paymentModal={paymentModal}
          closePaymentModal={closePaymentModal}
          liveVideo={video}
        />
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ModelLiveStreamingCard));
