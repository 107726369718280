import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import AgoraRTC from "agora-rtc-sdk-ng";
import { Form, Button, Container, Row, Col, Table, Media, Image } from "react-bootstrap";
import {
	endVideoCallStart,
	fetchSingleVideoCallStart,
	joinVideoCallStart,
} from "../../store/actions/PrivateCallAction";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";

const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
const $ = window.$;
AgoraRTC.setLogLevel(4);

const AgoraOnetoOne = (props) => {

	var rtc = {
		// For the local client.
		client: null,
		// For the local audio and video tracks.
		localAudioTrack: null,
		localVideoTrack: null,
	};


	var localTracks = {
		videoTrack: null,
		audioTrack: null
	};

	var localTrackState = {
		videoTrackEnabled: true,
		audioTrackEnabled: true
	}

	var options = {
		// Pass your app ID here.
		appId: configuration.get("configData.agora_app_id"),
		// Set the channel name.
		channel: props.videoCallData.virtual_id,
		// Pass a token if your project enables the App Certificate.
		token: props.videoCallData.agora_token ? props.videoCallData.agora_token : null,
		uid: null,
	};

	var remoteUsers = {};

	async function startBasicCall() {

		rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

		rtc.client.on("user-published", handleUserPublished);
		rtc.client.on("user-unpublished", handleUserUnpublished);

		[options.uid, localTracks.audioTrack, localTracks.videoTrack] = await Promise.all([
			// join the channel
			await rtc.client.join(options.appId, options.channel, options.token, null),
			// create local tracks, using microphone and camera
			rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack(),
			rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack()
		]);


		localTracks.videoTrack.play("local-player");

		await rtc.client.publish(Object.values(localTracks));

		// Publish the local audio and video tracks to the channel.
		// await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);

		console.log("publish success!");

		props.dispatch(
			joinVideoCallStart({
				video_call_request_id: props.videoCallData.video_call_request_id,
			})
		);


	}

	async function subscribe(user, mediaType) {
		const uid = user.uid;
		// subscribe to a remote user
		await rtc.client.subscribe(user, mediaType);
		console.log("subscribe success");
		if (mediaType === 'video') {

			const remoteVideoTrack = user.videoTrack;
			remoteVideoTrack.play("remote-playerlist");
		}
		if (mediaType === 'audio') {
			user.audioTrack.play();
		}
	}

	function handleUserPublished(user, mediaType) {
		const id = user.uid;
		remoteUsers[id] = user;
		subscribe(user, mediaType);
		$("#remote-playerlist").show();
    $("#remote-player-placeholder").hide();
	}

	function handleUserUnpublished(user) {
		$("#remote-playerlist").hide();
    $("#remote-player-placeholder").show();
		// const id = user.uid;
		// delete remoteUsers[id];
		// $(`#player-wrapper-${id}`).remove();
	}


	startBasicCall();

	async function leaveCall(is_owner) {
		if (is_owner) {
			// Destroy the local audio and video tracks.
			rtc.localAudioTrack.close();
			rtc.localVideoTrack.close();

			// Traverse all remote users.
			rtc.client.remoteUsers.forEach(user => {
				// Destroy the dynamically created DIV container.
				const playerContainer = document.getElementById(user.uid);
				playerContainer && playerContainer.remove();
			});
			props.dispatch(
				endVideoCallStart({
					video_call_request_id: props.videoCallData.video_call_request_id,
				})
			);
			window.location.assign("/video-calls-received");
		} else {
			// Leave the channel.
			await rtc.client.leave();
			window.location.assign("/video-calls-sent");
		}

		
	}

	async function muteAudio() {
		if (!rtc.localAudioTrack) return;
		if (localTrackState.audioTrackEnabled == true) {
			await rtc.localAudioTrack.setEnabled(false);
			localTrackState.audioTrackEnabled = false;
			$("#mute-audio").hide();
			$("#unmute-audio").show();
		} else {
			await rtc.localAudioTrack.setEnabled(true);
			localTrackState.audioTrackEnabled = true;
			$("#mute-audio").show();
			$("#unmute-audio").hide();
		}
	}

	async function muteVideo() {
		if (!rtc.localVideoTrack) return;
		if (localTrackState.videoTrackEnabled == true) {
			await rtc.localVideoTrack.setEnabled(false);
			localTrackState.videoTrackEnabled = false;
			$("#mute-video").hide();
			$("#unmute-video").show();
			$("#local-player").hide();
			$("#local-player-placeholder").show();
		} else {
			await rtc.localVideoTrack.setEnabled(true);
			localTrackState.videoTrackEnabled = true;
			$("#mute-video").show();
			$("#unmute-video").hide();
			$("#local-player").show();
			$("#local-player-placeholder").hide();
		}
	}
	console.log(props.isOwner);
	return (
		<>
			<div className="live-streaming-one-to-one-sec">
				<div className="live-streaming-video-sec">
					<div className="live-streaming-video-bg-sec">
						{/* <Image
							className="live-streaming-video-bg"
							src={
								window.location.origin + "/assets/images/fitness/videocall_bg.jpg"
							}
						/> */}
						<div id="remote-playerlist" className="one-to-one-video-bg" />
						<div id="remote-player-placeholder" className="reciver-video-placeholder" style={{ display: "none" }}>
							<Image
                className="live-streaming-video-bg"
                src={props.videoCallData ? props.videoCallData.is_model ?  
									props.videoCallData.user_picture : 
									props.videoCallData.model_picture :
                    window.location.origin + "/assets/images/fitness/videocall_bg.jpg"
                }
              />
						</div>
					</div>
					<div className="live-streaming-video-append-sec">
						{/* <Draggable
							nodeRef={nodeRef}
							onDrag={(e, data) => trackPos2(data)}
							onStart={handleStart2}
							onStop={handleEnd2}
							scale={2}
						> */}
						{/* <Image
								className="live-streaming-video-append-img"
								ref={nodeRef}
								style={{ opacity: Opacity2 ? "0.6" : "1" }}
								src={
									window.location.origin + "/assets/images/fitness/videocall-1.jpg"
								}
							/> */}
						<div id="local-player" className="one-to-one-own-video" />
						<div id="local-player-placeholder" className="one-to-one-own-video local-player-placeholder" style={{ display: "none" }}>
							<Image
								className="live-streaming-video-append-img"
								src={
									props.videoCallData ? props.videoCallData.is_model ?  
									props.videoCallData.model_picture : 
									props.videoCallData.user_picture :
									window.location.origin + "/assets/images/fitness/videocall-1.jpg"
								}
							/>
						</div>

						{/* </Draggable> */}
					</div>
					<div className="live-streaming-video-action-btn-sec">
						<ul className="list-unstyled live-streaming-video-action-btn">
							{/* {props.isOwner ? ( */}
								<>
									<Media as="li"
										onClick={() =>
											muteAudio()
										}
									>
										<Link to="#">
											<i id="mute-audio" className="fa fa-microphone"></i>
											<i id="unmute-audio" className="fa fa-microphone-slash" style={{ display: "none" }}></i>
										</Link>
									</Media>
									<Media as="li" className="call-animation-li"
										onClick={() => {
											if (window.confirm(t("leave_call_confirmation"))) {
												leaveCall(props.isOwner)
											};
										}
										}
									>
										<Link to="#" className="call-animation">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
										</Link>
									</Media>
									<Media as="li"
										onClick={() =>
											muteVideo()
										}
									>
										<Link to="#">
											<i id="mute-video" className="fa fa-video"></i>
											<i id="unmute-video" className="fa fa-video-slash" style={{ display: "none" }}></i>
										</Link>
									</Media>
								</>
							
						</ul>
					</div>
				</div>
			</div>

			{/* <div className="agora-card">
				<div>
					<Col sm={12} xs={12} md={3}>
						<div id="local-player" style={{ width: "280px", height: "400px" }} />
					</Col>
					<Col sm={12} xs={12} md={9}>
						<div id="remote-playerlist" style={{ width: "825px", height: "400px" }} />
					</Col>
				</div>
				<div className="button-group mt-3 mb-3">
					<Col sm={12} xs={12} md={3}>

						{props.isOwner ?
							<>
								<div className="live-action-icon-sec">
									<ul className="list-unstyled live-action-flex">
										<Media as="li">
											<Link to="#"
												onClick={() => {
													if (window.confirm(t("leave_call_confirmation"))) {
														leaveCall(props.isOwner)
													};
												}
												}>
												<Image
													src={
														window.location.origin + "/assets/images/icons/end-stream.png"
													}
													className="action-live-icon"
												/>
											</Link>
										</Media>
										<Media as="li" id="mute-audio">
											<Link to="#"
												onClick={() =>
													muteAudio()
												}>
												<Image
													src={
														window.location.origin + "/assets/images/icons/audio.png"
													}
													className="action-live-icon"
												/>
											</Link>
										</Media>
										<Media as="li" id="unmute-audio" style={{ display: "none" }}>
											<Link to="#"
												onClick={() =>
													muteAudio()
												}>
												<Image
													src={
														window.location.origin + "/assets/images/icons/no-audio.png"
													}
													className="action-live-icon"
												/>
											</Link>
										</Media>
										<Media as="li" id="mute-video">
											<Link to="#"
												onClick={() =>
													muteVideo()
												}>
												<Image
													src={
														window.location.origin + "/assets/images/icons/video.png"
													}
													className="action-live-icon"
												/>
											</Link>
										</Media>
										<Media as="li" id="unmute-video" style={{ display: "none" }}>
											<Link to="#"
												onClick={() =>
													muteVideo()
												}>
												<Image
													src={
														window.location.origin + "/assets/images/icons/mute-video.png"
													}
													className="action-live-icon"
												/>
											</Link>
										</Media>
									</ul>
								</div>
							</>
							:
							<div className="live-action-icon-sec">
								<ul className="list-unstyled live-action-flex">
									<Media as="li">
										<Link to="#"
											onClick={() => {
												if (window.confirm(t("leave_call_confirmation"))) {
													leaveCall(props.isOwner)
												};
											}
											}>
											<Image
												src={
													window.location.origin + "/assets/images/icons/end-stream.png"
												}
												className="action-live-icon"
											/>
										</Link>
									</Media>
									<Media as="li" id="mute-audio">
										<Link to="#"
											onClick={() =>
												muteAudio()
											}>
											<Image
												src={
													window.location.origin + "/assets/images/icons/audio.png"
												}
												className="action-live-icon"
											/>
										</Link>
									</Media>
									<Media as="li" id="unmute-audio" style={{ display: "none" }}>
										<Link to="#"
											onClick={() =>
												muteAudio()
											}>
											<Image
												src={
													window.location.origin + "/assets/images/icons/no-audio.png"
												}
												className="action-live-icon"
											/>
										</Link>
									</Media>
									<Media as="li" id="mute-video">
										<Link to="#"
											onClick={() =>
												muteVideo()
											}>
											<Image
												src={
													window.location.origin + "/assets/images/icons/video.png"
												}
												className="action-live-icon"
											/>
										</Link>
									</Media>
									<Media as="li" id="unmute-video" style={{ display: "none" }}>
										<Link to="#"
											onClick={() =>
												muteVideo()
											}>
											<Image
												src={
													window.location.origin + "/assets/images/icons/mute-video.png"
												}
												className="action-live-icon"
											/>
										</Link>
									</Media>
								</ul>
							</div>
						}

					</Col>
				</div> */}

				{/* <div className="button-group">

		        <Button 
		            id="mute-audio"  
		            type="button" 
		            className="save-btn"
		            onClick={() =>
		                muteAudio()
		            }
		        >
		            Mute Audio
		        </Button>
		        <Button 
		            id="mute-video" 
		            type="button" 
		            onClick={() =>
		                muteVideo()
		            }
		            className="save-btn"
		        >
		            Mute Video
		        </Button>

		        <Button
		            className="save-btn"
		            type="submit"
		            onClick={() =>
		                leaveCall(props.isOwner)
		            }
		        >
		            {props.isOwner ? "End Streaming" : "Leave" }
		        </Button>


		    </div> */}
			{/* </div> */}
		</>
	);
};


const mapStateToPros = (state) => ({

});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(AgoraOnetoOne));