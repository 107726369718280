import React, { useState, useEffect } from 'react';
import SingleTrainerTransactions from './SingleTrainerTransactions';
import TransactionsList from './TransactionsList';

const TransactionIndex = (props) => {

    const [selectedTrainer, setSelectedTrainer] = useState(props.match.params.username);

    useEffect(() => {
        setSelectedTrainer(props.match.params.username);
    }, [props.match.params.username]);

    return (
        <>
            {selectedTrainer != null ?
                <SingleTrainerTransactions trainerId={selectedTrainer} />
                : <TransactionsList />
            }
        </>
    );

}

export default TransactionIndex;