import React, { useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const NewLandingPage = (props) => {

    return (
        <>
            <div className="new-landing-page-sec">
                <Container>
                    <div className="new-landing-header-sec">
                        <Link to="#" className="new-landing-header-btn">
                            <Image
                                className="new-landing-header-logo"
                                src={
                                    window.location.origin + "/assets/images/fitness/coach-logo-white.png"
                                }
                            />
                        </Link>
                    </div>
                    <div className="new-landing-page-body-sec">
                        <Row className="justify-content-md-center align-items-center">
                            <Col md={5} xl={4} lg={4} className="resp-marg-btm-xs">
                                <Link to="/trainer-login">
                                    <div className="continue-as-card">
                                        <h3>Continue as a Coach</h3>
                                        <Image
                                            className="continue-as-icon"
                                            src={
                                                window.location.origin + "/assets/images/arrow-right.svg"
                                            }
                                        />
                                    </div>
                                </Link>
                            </Col>
                            <div className="resp-border-bottom"></div>
                            <Col md={1} className="resp-hide">
                                <div className="text-center">
                                    <div className="sepator-line-sec"></div>
                                </div>
                            </Col>
                            <Col md={5} xl={4} lg={4}>
                                <Link to="/home">
                                    <div className="continue-as-card">
                                        <h3>Continue as a Client</h3>
                                        <Image
                                            className="continue-as-icon"
                                            src={
                                                window.location.origin + "/assets/images/arrow-right.svg"
                                            }
                                        />
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                    <div className="new-landing-page-footer-sec">
                        <p>All Rights Reserved @ 2023</p>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default NewLandingPage;
