import React from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { translate, t } from "react-multi-lang";

const VideoTrainingProceedModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center video-training-modal"
        size="md"
        centered
        show={props.videoTrainingProceed}
        onHide={props.closeVideoTrainingProceedModal}
      >
        <Form>
          <Modal.Header>
            <Modal.Title>{t("video_training_proceed")}</Modal.Title>
            <button
              type="button"
              className="close"
              onClick={props.closeVideoTrainingProceedModal}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("close")}</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Form className="video-training-proceed-form">
              <Form.Group className="mb-3">
                <Form.Label>{t("date_and_time")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Select the Date and Time"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <select
                  aria-label="Default select example"
                  className="form-select form-control select-arrow"
                >
                  <option>{t("personal_traning")}</option>
                  <option value="1">{t("pilotes_instructor")}</option>
                  <option value="2">{t("hitt_coach")}</option>
                  <option value="3">{t("nutrition_coach")}</option>
                </select>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              onClick={props.closeVideoTrainingProceedModal}
            >
              {t("cancel")}
            </Button>
            <Button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
            >
              {t("proceed")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default translate(VideoTrainingProceedModal);
