import React, { useState } from "react";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { useEffect } from "react";
import {
    Button,
    Modal,
} from "react-bootstrap";
import {
    saveFeatureStoryStart,
} from "../../../store/actions/UserAction";
const $ = window.$;

const FeatureStoryModal = (props) => {

  const handleFileUpload = (event) => {
    event.preventDefault();
    props.dispatch(
        saveFeatureStoryStart({
          file:props.fileData.file,
        })
    );
    props.closeModal();
  }

  return (
    <>
        <Modal
            centered
            size="lg"
            className="modal-dialog-center sent-tip-modal"
            show={props.featureStory}
            onHide={props.closeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("upload_featured_story")}</Modal.Title>
            </Modal.Header>
            {props.featureStory === true ? 
            <Modal.Body className="subscription-tip-ppv-tab">
          
                <div className="story-upload-file">
                    {props.fileData.previewVideo != "" ? props.fileData.file_type == "image" ? (
                        <img src={props.fileData.previewVideo} alt="" />
                    ) : (
                    <video
                        autoplay
                        controls
                        id="myVideo"
                        className="user-profile1 w-100"
                    >
                        <source src={props.fileData.previewVideo} type="video/mp4" />
                    </video>
                    ): null}
                </div>
            </Modal.Body>
            : "" }
            <Modal.Footer>
                <Button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={props.closeModal}
                >
                    {t("cancel")}
                </Button>
                <Button
                    type="button"
                    className="btn btn-success"
                    data-dismiss="modal"
                    onClick={handleFileUpload}
                >
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  // storyUpload: state.userStories.storyUpload,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(FeatureStoryModal));
