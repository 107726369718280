import {
  FETCH_NOTIFICATIONS_START,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  SEND_PUSH_NOTIFICATION_START,
  SEND_PUSH_NOTIFICATION_SUCCESS,
  SEND_PUSH_NOTIFICATION_FAILURE,
  SEND_EMAIL_NOTIFICATION_START,
  SEND_EMAIL_NOTIFICATION_SUCCESS,
  SEND_EMAIL_NOTIFICATION_FAILURE,
  SEND_NOTIFICATION_START,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILURE,
} from "./ActionConstant";

export function fetchNotificationsStart(data) {
  return {
    type: FETCH_NOTIFICATIONS_START,
    data,
  };
}

export function fetchNotificationsSuccess(data) {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    data,
  };
}

export function fetchNotificationsFailure(error) {
  return {
    type: FETCH_NOTIFICATIONS_FAILURE,
    error,
  };
}

export function sendPushNotificationStart(data) {
  return {
    type: SEND_PUSH_NOTIFICATION_START,
    data,
  }
}

export function sendPushNotificationSuccess(data) {
  return {
    type: SEND_PUSH_NOTIFICATION_SUCCESS,
    data,
  }
}

export function sendPushNotificationFailure(error) {
  return {
    type: SEND_PUSH_NOTIFICATION_FAILURE,
    error,
  }
}

export function sendEmailNotificationStart(data) {
  return {
    type: SEND_EMAIL_NOTIFICATION_START,
    data,
  }
}

export function sendEmailNotificationSuccess(data) {
  return {
    type: SEND_EMAIL_NOTIFICATION_SUCCESS,
    data,
  }
}

export function sendEmailNotificationFailure(error) {
  return {
    type: SEND_EMAIL_NOTIFICATION_FAILURE,
    error,
  }
}

export function sendNotificationStart(data) {
  return {
    type: SEND_NOTIFICATION_START,
    data,
  };
}

export function sendNotificationSuccess(data) {
  return {
    type: SEND_NOTIFICATION_SUCCESS,
    data,
  };
}

export function sendNotificationFailure(error) {
  return {
    type: SEND_NOTIFICATION_FAILURE,
    error,
  };
}
