import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col, Button, Media, Image, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import Background from './header-bg.png';
import Slider from "react-slick";
import NewSingleProfileDetailsModal from "./NewSingleProfileDetailsModal";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import ProfileLoader from "../Loader/ProfileLoader";
import VideoTrainingModal from "./VideoTrainingModal";
import AudioTrainingModal from "./AudioTrainingModal";
import {
  fetchSingleUserProfileStart,
  fetchSingleUserPostsStart,
} from "../../store/actions/OtherUserAction";
import { fetchOtherModelProductListStart } from "../../store/actions/ProductsAction";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import {
  saveBlockUserStart,
  fetchtTrainerServiceListForOthersStart,
} from "../../store/actions/UserAction";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  RedditShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  RedditIcon,
  TelegramIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import configuration from "react-global-configuration";
import AddWalletTokenModal from "../helper/AddWalletTokenModal";
import SendTipModal from "../helper/SendTipModal";
import ModelVideoSec from "./ModelVideoSec";
import ModelLiveStreamingSec from "./ModelLiveStreamingSec";
import ModelProductSec from "./ModelProductSec";
import { saveChatUserStart } from "../../store/actions/ChatAction";
import { isMobile } from "react-device-detect";

const NewSingleProfileIndex = (props) => {

  const toggleVisibility = () => { };

  const { userDetails } = props;

  const params = useParams();

  const history = useHistory();

  const [skipFirst, setSkipFirst] = useState(true);

  const [newSingleProfileDetails, setNewSingleProfileDetails] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [addWalletAmountModal, setAddWalletAmountModal] = useState(false);

  const [videoTraining, setVideoTraining] = useState(false);

  const [audioTraining, setAudioTraining] = useState(false);

  const [sendTip, setSendTip] = useState(false);

  const [activeSec, setActiveSec] = useState("video");

  const closeNewSingleProfileDetailsModal = () => {
    setNewSingleProfileDetails(false);
  };

  useEffect(() => {
    fetchUserProfile();
    props.dispatch(
      fetchtTrainerServiceListForOthersStart({
        trainer_unique_code: params.username,
      })
    );
    if (localStorage.getItem("userId")) {
      props.dispatch(
        fetchWalletDetailsStart({ trainer_unique_code: params.username })
      );
    } else {
      localStorage.setItem("coach_username", params.username);
    }
    window.addEventListener("scroll", toggleVisibility);
  }, [params.username]);

  useEffect(() => {
    if (isMobile) {
      window.location.assign("/w/"+params.username);
    }
    if (!skipFirst) {
      if (
        !props.addMoneyInput.loading &&
        Object.keys(props.addMoneyInput.data).length > 0
      ) {
        fetchUserProfile();
      }
    }
    setSkipFirst(false);
  }, [props.addMoneyInput]);

  const fetchUserProfile = () => {
    props.dispatch(
      fetchSingleUserProfileStart({
        user_unique_id: params.username,
      })
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    loop: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    adptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setActiveSection = (event, key) => {
    setActiveSec(key);
    if (key === "store")
      props.dispatch(
        fetchOtherModelProductListStart({
          user_unique_id: params.username,
        })
      );
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("profile_link_copied")
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const redirectToLogin = () => {
    if (!localStorage.getItem("userId")) {
      localStorage.setItem("is_client", 1);
      history.push(`/login-redirect?is_client=${localStorage.getItem("is_client")}`);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleChatUser = (event, user_id) => {
    event.preventDefault();
    if (!localStorage.getItem("userId")) {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );
      props.dispatch(createNotification(notificationMessage));
    } else {
      props.dispatch(
        saveChatUserStart({
          from_user_id: localStorage.getItem("userId"),
          to_user_id: user_id,
        })
      );
    }
  };

  const closeVideoTrainingModal = () => {
    setVideoTraining(false);
  };

  const closeAudioTrainingModal = () => {
    setAudioTraining(false);
  };

  const closeAddWalletAmountModal = () => {
    setAddWalletAmountModal(false);
  };

  const closeSendTipModal = () => {
    setSendTip(false);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  return (
    <>
      {userDetails.loading ? (
        <ProfileLoader></ProfileLoader>
      ) : (
        <>
          <div className="new-single-profile-sec">
            <>
              <div className="new-single-profile-header-sec" style={{ background: `url(${userDetails.data.user.cover})`, }}>
                <Container>
                  <div className="new-single-profile-header-card">
                    <div className="new-single-profile-img-sec">
                      <Image
                        className="new-single-profile-img"
                        src={userDetails.data.user.picture}
                        alt={userDetails.data.user.name}
                      />
                    </div>
                    <div className="new-single-profile-info-sec">
                      <h2>{userDetails.data.user.name}</h2>
                      <p>
                        {userDetails.data.user.about_formatted ? (
                          <>{userDetails.data.user.about_short_formatted}  <Button className="read-more-btn" onClick={() => setNewSingleProfileDetails(true)}> Read More</Button></>
                        ) : (
                          t("no_about_information")
                        )}</p>

                      <div className="new-single-profile-info-action-btn-sec">
                        <div className="new-single-profile-info-action-left-btn">
                          <ul className="list-unstyled new-single-profile-info-action-left-btn-list">
                            <li>
                              <Button
                                className="buy-token-btn"
                                onClick={() => {
                                  setAddWalletAmountModal(true);
                                  redirectToLogin();
                                }}
                              >
                                {t("buy_tokens")}
                              </Button>
                            </li>
                            <li>
                              {!props.wallet.loading ? (
                                <Link
                                  className="coach-profile-token-count-sec"
                                  to={`/transaction/${params.username}`}
                                >
                                  <span>
                                    {
                                      props.wallet.data.user_wallet
                                        .remaining_formatted
                                    }
                                  </span>
                                </Link>
                              ) : (
                                ""
                              )}
                            </li>
                            <li>
                              <Button
                                className="buy-token-btn"
                                onClick={() => {
                                  setSendTip(true);
                                  redirectToLogin();
                                }}
                              >
                                <span>{t("send_tip")}</span>
                              </Button>
                            </li>
                          </ul>
                        </div>
                        <div className="new-single-profile-info-action-right-btn">
                          <ul className="list-unstyled new-single-profile-info-action-right-btn-list">
                            <li>
                              <Link to="/ecom-cart">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#000"
                                    d="M18 6h-2c0-2.21-1.79-4-4-4S8 3.79 8 6H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-8 4c0 .55-.45 1-1 1s-1-.45-1-1V8h2v2zm2-6c1.1 0 2 .9 2 2h-4c0-1.1.9-2 2-2zm4 6c0 .55-.45 1-1 1s-1-.45-1-1V8h2v2z"
                                  ></path>
                                </svg>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" onClick={handleShareClick}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#000"
                                    d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7 0-.24-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92 0-1.61-1.31-2.92-2.92-2.92z"
                                  ></path>
                                </svg>
                              </Link>
                              <div className="share-popover-sec">
                                <Popover
                                  id={popoverId}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Typography>
                                    <div className="social-share-sec m-3">
                                      <div className="text-center social-link">
                                        <div className="Demo__some-network">
                                          <EmailShareButton
                                            url={userDetails.data.user.share_link}
                                            subject={configuration.get("configData.site_name")}
                                            body={userDetails.data.user.share_message}
                                            className="Demo__some-network__share-button"
                                          >
                                            <EmailIcon size={32} round />
                                          </EmailShareButton>
                                        </div>
                                        {/* <h6 className="social-desc">{t("email")}</h6> */}
                                      </div>
                                      <div className="text-center social-link">
                                        <WhatsappShareButton
                                          url={userDetails.data.user.share_link}
                                          title={userDetails.data.user.share_message}
                                          separator=":: "
                                          className="Demo__some-network__share-button"
                                        >
                                          <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                        {/* <h6 className="social-desc">{t("whatsapp")}</h6> */}
                                      </div>
                                      <div className="text-center social-link">
                                        <FacebookShareButton
                                          url={userDetails.data.user.share_link}
                                          quote={userDetails.data.user.share_message}
                                          className="Demo__some-network__share-button"
                                        >
                                          <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                        {/* <h6 className="social-desc">{t("facebook")}</h6> */}
                                      </div>
                                      <div className="text-center social-link">
                                        <TwitterShareButton
                                          url={userDetails.data.user.share_link}
                                          title={userDetails.data.user.share_message}
                                          className="Demo__some-network__share-button"
                                        >
                                          <TwitterIcon size={32} round />
                                        </TwitterShareButton>
                                        {/* <h6 className="social-desc">{t("twitter")}</h6> */}
                                      </div>
                                      <div className="text-center social-link">
                                        <RedditShareButton
                                          url={userDetails.data.user.share_link}
                                          title={userDetails.data.user.share_message}
                                          windowWidth={660}
                                          windowHeight={460}
                                          className="Demo__some-network__share-button"
                                        >
                                          <RedditIcon size={32} round />
                                        </RedditShareButton>
                                        {/* <h6 className="social-desc">{t("reddit")}</h6> */}
                                      </div>
                                      <div className="text-center social-link">
                                        <CopyToClipboard
                                          onCopy={onCopy}
                                          text={userDetails.data.user.share_link}
                                          windowWidth={660}
                                          windowHeight={460}
                                          className="Demo__some-network__share-button"
                                        >
                                          <button className="react-share__ShareButton Demo__some-network__share-button">
                                            <i className="fas fa-copy"></i>
                                          </button>
                                        </CopyToClipboard>
                                      </div>
                                    </div>
                                  </Typography>
                                </Popover>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
              {!props.trainerServicesListForOthers.loading &&
                props.trainerServicesListForOthers.data.trainer_services &&
                props.trainerServicesListForOthers.data.trainer_services.length >
                0 && (
                  <Container>
                    <div className="new-single-profile-category-slider-sec">
                      <Row>
                        <Col md={12}>
                          <Slider {...settings}>
                            {props.trainerServicesListForOthers.data.trainer_services.map(
                              (service, index) => (
                                <div key={index}>
                                  <div className="new-single-profile-category-card">
                                    <Image
                                      className="new-single-profile-category-bg-img"
                                      src={service.service_detail.picture}
                                    />
                                    <div className="new-single-profile-category-info">
                                      <h4>{service.service}</h4>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </Slider>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                )}
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="new-single-profile-social-link-sec">
                      <ul className="list-unstyled new-single-profile-social-link">
                        <li>
                          <a
                            href={userDetails.data.user.facebook_link}
                            target="_blank"
                            title="facebook"
                          >
                            <Image
                              className="new-single-profile-social-icon"
                              src={
                                window.location.origin + "/assets/images/new-changes/single-profile/facebook.svg"
                              }
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href={userDetails.data.user.instagram_link}
                            target="_blank"
                            title="instagram"
                          >
                            <Image
                              className="new-single-profile-social-icon"
                              src={
                                window.location.origin + "/assets/images/new-changes/single-profile/instagram.svg"
                              }
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href={userDetails.data.user.website}
                            target="_blank"
                            title="website"
                          >
                            <Image
                              className="new-single-profile-social-icon"
                              src={
                                window.location.origin + "/assets/images/new-changes/single-profile/website.svg"
                              }
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href={userDetails.data.user.tiktok_link}
                            target="_blank"
                            title="tiktok"
                          >
                            <Image
                              className="new-single-profile-social-icon"
                              src={
                                window.location.origin + "/assets/images/new-changes/single-profile/tik-tok.svg"
                              }
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href={userDetails.data.user.snapchat_link}
                            target="_blank"
                            title="snapchat"
                          >
                            <Image
                              className="new-single-profile-social-icon"
                              src={
                                window.location.origin + "/assets/images/new-changes/single-profile/snapchat.svg"
                              }
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href={userDetails.data.user.youtube_link}
                            target="_blank"
                            title="youtube"
                          >
                            <Image
                              className="new-single-profile-social-icon"
                              src={
                                window.location.origin + "/assets/images/new-changes/single-profile/youtube.svg"
                              }
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
              <div className="new-single-profile-tab-sec">
                <Container>
                  <Row>
                    <Col md={12}>
                      <Tab.Container id="left-tabs-example" defaultActiveKey="workouts">
                        <Row>
                          <Col sm={12}>
                            <Nav variant="pills">
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="video"
                                  onClick={(event) =>
                                    setActiveSection(event, "video")
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="#001B3A"
                                    viewBox="0 0 64 64"
                                  >
                                    <g>
                                      <path d="M58.11 24.101V39.9c1.53-.32 3.14-1.526 3.14-2.802V26.903c0-1.276-1.61-2.481-3.14-2.802zM2.75 26.903v10.194c0 1.276 1.61 2.481 3.14 2.802V24.1c-1.53.32-3.14 1.526-3.14 2.802zM14.729 18.248h-4.092a3.25 3.25 0 00-3.246 3.247v21.01a3.25 3.25 0 003.246 3.247h4.092a3.25 3.25 0 003.247-3.247v-21.01a3.25 3.25 0 00-3.247-3.247zM53.363 18.248H49.27a3.25 3.25 0 00-3.247 3.247v21.01a3.25 3.25 0 003.247 3.247h4.092a3.25 3.25 0 003.246-3.247v-21.01a3.25 3.25 0 00-3.246-3.247zM19.476 29.125h25.049v5.751H19.476z"></path>
                                    </g>
                                  </svg>
                                  {t("workouts")}
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="virtual-training"
                                  onClick={(event) =>
                                    setActiveSection(event, "virtual-training")
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="#001B3A"
                                    viewBox="0 0 152 152"
                                  >
                                    <g>
                                      <path d="M11 47.9v42.6c0 12.1 9.8 21.9 21.9 21.9h63.6c4.5 0 8.2-3.7 8.2-8.2V61.5c0-12.1-9.8-21.9-21.9-21.9H19.2c-4.5 0-8.2 3.6-8.2 8zM112 95.3l17.6 15.4c2.8 2.5 7.2 2.2 9.7-.6 1.1-1.3 1.7-2.9 1.7-4.5V46.4c0-3.8-3.1-6.8-6.8-6.9-1.7 0-3.3.6-4.5 1.7L112 56.7c-1.5 1.3-2.3 3.2-2.3 5.2v28.3c0 1.9.9 3.8 2.3 5.1z"></path>
                                    </g>
                                  </svg>
                                  {t("virtual_training")}
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="subscribers"
                                  onClick={(event) =>
                                    setActiveSection(event, "subscribers")
                                  }>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="#001B3A"
                                      d="M15.54 16.54a1 1 0 01-.711-1.7 4.022 4.022 0 000-5.674 1 1 0 011.422-1.406 6.021 6.021 0 010 8.486 1 1 0 01-.711.294zm-6.377-.289a1 1 0 00.008-1.414 4.022 4.022 0 010-5.674 1 1 0 10-1.422-1.406 6.021 6.021 0 000 8.486 1 1 0 001.414.008zm10.269 2.42a9.949 9.949 0 000-13.342 1 1 0 00-1.483 1.342 7.949 7.949 0 010 10.658 1 1 0 001.483 1.342zm-13.452.07a1 1 0 00.071-1.412 7.949 7.949 0 010-10.658 1 1 0 00-1.483-1.342 9.949 9.949 0 000 13.342 1 1 0 001.412.07zM12 10.5a1.5 1.5 0 101.5 1.5 1.5 1.5 0 00-1.5-1.5z"
                                    ></path>
                                  </svg>
                                  {t("live_streaming")}
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="merchandiser"
                                  onClick={(event) =>
                                    setActiveSection(event, "store")
                                  }>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="#001B3A"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="#000"
                                      fillRule="evenodd"
                                      d="M14.47 3.47a.75.75 0 011.06 0l3.78 3.78H22a.75.75 0 010 1.5h-.544c-.227.856-.52 1.843-.86 2.991l-1.025 3.456c-.407 1.373-.657 2.215-1.094 2.9a5.75 5.75 0 01-3.255 2.429c-.78.224-1.658.224-3.09.224h-.263c-1.432 0-2.31 0-3.091-.224a5.75 5.75 0 01-3.255-2.43c-.437-.684-.687-1.526-1.094-2.9l-1.025-3.454c-.34-1.149-.633-2.136-.86-2.992H2a.75.75 0 010-1.5h2.932l3.78-3.78a.75.75 0 111.061 1.06l-2.72 2.72H17.19l-2.72-2.72a.75.75 0 010-1.06zM10.75 12a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0zm4 0a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0z"
                                      clipRule="evenodd"
                                    ></path>
                                  </svg>
                                  {t("products")}
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={12} className="mt-5 mb-5">
                            {
                              activeSec === "video" && (
                                // <Tab.Pane eventKey="programs">
                                <ModelVideoSec
                                  activeSec={activeSec}
                                  setActiveSec={setActiveSec}
                                  userPosts={props.userPosts}
                                  scrollToTop={scrollToTop}
                                  otherUserUniquId={params.username}
                                  wallet={props.wallet}
                                />
                              )
                              // </Tab.Pane>
                            }
                            {
                              activeSec === "subscribers" && (
                                // <Tab.Pane eventKey="subscribers">
                                <ModelLiveStreamingSec
                                  activeSec={activeSec}
                                  setActiveSec={setActiveSec}
                                  userDetails={userDetails.data.user}
                                  scrollToTop={scrollToTop}
                                  otherUserUniquId={params.username}
                                />
                              )
                              // </Tab.Pane>
                            }
                            {
                              activeSec === "virtual-training" && (
                                // <Tab.Pane eventKey="virtual-training">
                                <>
                                  <div className="coach-single-profile-live-streaming-sec">
                                    <Container>
                                      <Row>
                                        <Col md={12}>
                                          <ul className="list-unstyled coach-single-profile-live-streaming">
                                            <Media
                                              as="li"
                                              onClick={() => {
                                                setVideoTraining(true);
                                                redirectToLogin();
                                              }}
                                            >
                                              <Link to="#">
                                                <Image
                                                  className="coach-single-live-streaming-icon"
                                                  src={
                                                    window.location.origin +
                                                    "/assets/images/fitness/coach-single-video-white.svg"
                                                  }
                                                />
                                                {t("video")}
                                              </Link>
                                            </Media>
                                            <Media
                                              as="li"
                                              onClick={() => {
                                                setAudioTraining(true);
                                                redirectToLogin();
                                              }}
                                            >
                                              <Link to="#">
                                                <Image
                                                  className="coach-single-live-streaming-icon"
                                                  src={
                                                    window.location.origin +
                                                    "/assets/images/fitness/coach-single-audio-white.svg"
                                                  }
                                                />
                                                {t("call")}
                                              </Link>
                                            </Media>
                                            <Media as="li">
                                              <Link
                                                to="#"
                                                onClick={(event) => {
                                                  handleChatUser(
                                                    event,
                                                    userDetails.data.user.user_id
                                                  );
                                                  redirectToLogin();
                                                }
                                                }
                                              >
                                                <Image
                                                  className="coach-single-live-streaming-icon"
                                                  src={
                                                    window.location.origin +
                                                    "/assets/images/fitness/coach-single-chat-white.svg"
                                                  }
                                                />
                                                {t("message")}
                                              </Link>
                                            </Media>
                                          </ul>
                                        </Col>
                                      </Row>
                                    </Container>
                                  </div>
                                  <div className="coach-single-profile-program-sec">

                                  </div>
                                </>
                              )
                              // </Tab.Pane>
                            }
                            {
                              activeSec === "store" && (
                                // <Tab.Pane eventKey="merchandiser">
                                <ModelProductSec
                                  activeSec={activeSec}
                                  setActiveSec={setActiveSec}
                                  // products={props.products}
                                  otherUserUniquId={params.username}
                                />
                              )
                              // </Tab.Pane>
                            }
                          </Col>
                        </Row>
                      </Tab.Container>
                    </Col>
                  </Row>
                </Container>
              </div>
            </>
          </div>
          <NewSingleProfileDetailsModal
            newSingleProfileDetails={newSingleProfileDetails}
            closeNewSingleProfileDetailsModal={closeNewSingleProfileDetailsModal}
            user={userDetails.data.user}
          />
        </>
      )}
      {localStorage.getItem("userId") ? (
        <>
          {videoTraining && props.profile.data.user_id && (
            <VideoTrainingModal
              videoTraining={videoTraining}
              closeVideoTrainingModal={closeVideoTrainingModal}
              userDetails={userDetails.data.user}
              trainerServices={props.trainerServicesListForOthers}
              profile={props.profile}
            />
          )}

          {audioTraining && props.profile.data.user_id && (
            <AudioTrainingModal
              audioTraining={audioTraining}
              closeAudioTrainingModal={closeAudioTrainingModal}
              userDetails={userDetails.data.user}
              trainerServices={props.trainerServicesListForOthers}
              profile={props.profile}
            />
          )}

          {addWalletAmountModal && (
            <AddWalletTokenModal
              addWalletAmountModal={addWalletAmountModal}
              closeAddWalletAmountModal={closeAddWalletAmountModal}
              trainerId={userDetails.data.user.user_id}
              wallet={props.wallet}
              payments={props.wallet}
            />
          )}
          {sendTip && (
            <SendTipModal
              sendTip={sendTip}
              closeSendTipModal={closeSendTipModal}
              username={userDetails.username}
              userPicture={userDetails.data.user.picture}
              name={userDetails.data.user.usernamee}
              post_id={null}
              user_id={userDetails.data.user.user_id}
              wallet={props.wallet}
            />
          )}
        </>
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  userDetails: state.otherUser.userDetails,
  userPosts: state.otherUser.userPosts,
  products: state.userProducts.otherModelProducts,
  addMoneyInput: state.wallet.addMoneyInput,
  trainerServicesListForOthers: state.users.trainerServicesListForOthers,
  ppvPayStripe: state.post.ppvPayStripe,
  wallet: state.wallet.walletData,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(NewSingleProfileIndex));