import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Image } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { fetchLiveVideosStart } from "../../store/actions/LiveVideoAction";
import { Link } from "react-router-dom";
import NoDataFound from "../NoDataFound/NoDataFound";
import BillingAccountLoader from "../Loader/BillingAccountLoader";
import { translate, t } from "react-multi-lang";
import GoLiveModal from "../helper/GoLiveModal";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import LiveDataCard from "./LiveDataCard";
import UserLiveVideoLoader from "../Loader/UserLiveVideoLoader";
import LiveDataCardNew from "./LiveDataCardNew";
import PaymentModal from "./PaymentModal";

const UserWebLiveVideosIndex = (props) => {
    const [goLive, setGoLive] = useState(false);

    const [paymentModal, setPaymentModal] = useState(false);
    const openPaymentModal = (event) => {
        event.preventDefault();
        setPaymentModal(true);
    };

    const closePaymentModal = () => {
        setPaymentModal(false);
    };

    useEffect(() => {
        if (props.userDetails.loading) props.dispatch(fetchUserDetailsStart());
        props.dispatch(fetchLiveVideosStart());
    }, []);

    const closeGoLiveModal = () => {
        setGoLive(false);
    };
    console.log(props.liveVideos);
    return (
        <>
            <div className="web-live-streaming-sec">
                <Container>

                    {props.liveVideos.loading ? '' :
                        props.liveVideos.data.ongoing_live ? (
                            <>
                                <h2>Ongoing Live</h2>
                                <div className="web-live-streaming-header-sec">
                                    <div className="web-live-streaming-header-card">
                                        <Image
                                            className="web-live-streaming-header-img"
                                            src={props.liveVideos.data.ongoing_live.snapshot}
                                        />
                                        <div className="web-live-streaming-details-sec">
                                            <div className="web-live-streaming-details-info">
                                                <h4>{props.liveVideos.data.ongoing_live.title}</h4>
                                                <p>@{props.liveVideos.data.ongoing_live.user_displayname}</p>
                                            </div>
                                            <div className="play-btn">


                                                {props.liveVideos.data.ongoing_live.is_user_needs_to_pay == 1 ? (
                                                    <Button
                                                        className="live-btn-blue"
                                                        type="submit"
                                                        disabled={props.liveVideos.data.ongoing_live.buttonDisable}
                                                        onClick={(event) => openPaymentModal(event)}
                                                    >
                                                        {t("pay_and_join")}
                                                    </Button>
                                                ) : (
                                                    ""
                                                )}

                                                {props.liveVideos.data.ongoing_live.is_user_needs_to_pay == 0 &&
                                                    props.liveVideos.data.ongoing_live.user_id != localStorage.getItem("userId") ? (
                                                    <Link
                                                        to={`/join/${props.liveVideos.data.ongoing_live.live_video_unique_id}`}
                                                        target="_blank"
                                                        className="live-btn-blue"
                                                        type="button"
                                                    >
                                                        {" "}
                                                        {t("join_now")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}

                                                <PaymentModal
                                                    paymentModal={paymentModal}
                                                    closePaymentModal={closePaymentModal}
                                                    liveVideo={props.liveVideos.data.ongoing_live}
                                                />

                                            </div>

                                            {/* <Button className="play-btn">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                                fill="#fff"
                                                enableBackground="new 0 0 512 512"
                                                version="1.1"
                                                viewBox="0 0 512 512"
                                                xmlSpace="preserve"
                                            >
                                                <path d="M256 0C114.617 0 0 114.615 0 256s114.617 256 256 256 256-114.615 256-256S397.383 0 256 0zm88.48 269.57l-128 80a16.008 16.008 0 01-16.238.422A15.994 15.994 0 01192 336V176c0-5.82 3.156-11.172 8.242-13.992a15.957 15.957 0 0116.238.422l128 80c4.676 2.93 7.52 8.055 7.52 13.57s-2.844 10.641-7.52 13.57z"></path>
                                            </svg>
                                        </Button> */}
                                        </div>
                                    </div>

                                </div>
                            </>
                        ) : ''}
                    <h2 className="mt-5">{t('live_streams')}</h2>
                    <Row>
                        <Col md={12}>

                            {props.liveVideos.loading ? (
                                <UserLiveVideoLoader />
                            ) : props.liveVideos.data.live_videos.filter(liveVideo => liveVideo.user_id != localStorage.getItem("userId")).length > 0 ? (
                                <div className="web-livestreaming-schedule-box">
                                    {props.liveVideos.data.live_videos.filter(liveVideo => localStorage.getItem("userId") != String(liveVideo.user_id)).map((video) => (
                                        <LiveDataCardNew video={video} key={video.live_video_id} />
                                    ))}

                                </div>
                            ) : (
                                <NoDataFound />
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    );
};

const mapStateToPros = (state) => ({
    liveVideos: state.liveVideo.liveVideos,
    userDetails: state.users.profile,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(UserWebLiveVideosIndex));
