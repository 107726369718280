import {
  VIDEO_CALL_BROADCAST_START,
  VIDEO_CALL_BROADCAST_SUCCESS,
  VIDEO_CALL_BROADCAST_FAILURE,
  FETCH_LIVE_VIDEOS_START,
  FETCH_LIVE_VIDEOS_SUCCESS,
  FETCH_LIVE_VIDEOS_FAILURE,
  FETCH_LIVE_VIDEOS_HISTORY_START,
  FETCH_LIVE_VIDEOS_HISTORY_SUCCESS,
  FETCH_LIVE_VIDEOS_HISTORY_FAILURE,
  JOIN_LIVE_VIDEOS_START,
  JOIN_LIVE_VIDEOS_SUCCESS,
  JOIN_LIVE_VIDEOS_FAILURE,
  FETCH_SINGLE_LIVE_VIDEOS_START,
  FETCH_SINGLE_LIVE_VIDEOS_SUCCESS,
  FETCH_SINGLE_LIVE_VIDEOS_FAILURE,
  LIVE_VIDEOS_PAYMENT_BY_STRIPE_START,
  LIVE_VIDEOS_PAYMENT_BY_STRIPE_SUCCESS,
  LIVE_VIDEOS_PAYMENT_BY_STRIPE_FAILURE,
  LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START,
  LIVE_VIDEOS_PAYMENT_BY_PAYPAL_SUCCESS,
  LIVE_VIDEOS_PAYMENT_BY_PAYPAL_FAILURE,
  LIVE_VIDEOS_VIEWER_UPDATE_START,
  LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS,
  LIVE_VIDEOS_VIEWER_UPDATE_FAILURE,
  LIVE_VIDEOS_END_START,
  LIVE_VIDEOS_END_SUCCESS,
  LIVE_VIDEOS_END_FAILURE,
  LIVE_VIDEOS_PAYMENT_BY_WALLET_START,
  LIVE_VIDEOS_PAYMENT_BY_WALLET_SUCCESS,
  LIVE_VIDEOS_PAYMENT_BY_WALLET_FAILURE,
  FETCH_MORE_LIVE_VIDEOS_HISTORY_START,
  FETCH_SCHEDULED_LIVE_VIDEOS_START,
  FETCH_SCHEDULED_LIVE_VIDEOS_SUCCESS,
  FETCH_SCHEDULED_LIVE_VIDEOS_FAILURE,
  LIVE_VIDEOS_START_CALL_START,
  LIVE_VIDEOS_START_CALL_SUCCESS,
  LIVE_VIDEOS_START_CALL_FAILURE,
  FETCH_USER_SCHEDULED_LIVE_VIDEOS_START,
  FETCH_USER_SCHEDULED_LIVE_VIDEOS_SUCCESS,
  FETCH_USER_SCHEDULED_LIVE_VIDEOS_FAILURE,
  DELETE_LIVE_VIDEOS_START,
  DELETE_LIVE_VIDEOS_SUCCESS,
  DELETE_LIVE_VIDEOS_FAILURE,
  LIVE_VIDEOS_SAVE_START,
  LIVE_VIDEOS_SAVE_SUCCESS,
  LIVE_VIDEOS_SAVE_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  saveLiveVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  joinliveVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  singleLiveVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  liveVideos: {
    data: {
      videos: [],
    },
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  liveVideosHistory: {
    data: {
      videos: [],
      total: 0,
    },
    loading: true,
    error: false,
    errorCount: 0,
  },
  liveWallet: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  liveStripe: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  livePaypal: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  liveViewerUpdate: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  liveEnd: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  scheduledLiveVideos: {
    data: {
      videos: [],
    },
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  liveStart: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  userScheduledVideos: {
    data: {
      videos: [],
    },
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  deleteLiveVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
};

const LiveVideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_CALL_BROADCAST_START:
      return {
        ...state,
        saveLiveVideo: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case VIDEO_CALL_BROADCAST_SUCCESS:
      return {
        ...state,
        saveLiveVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case VIDEO_CALL_BROADCAST_FAILURE:
      return {
        ...state,
        saveLiveVideo: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_LIVE_VIDEOS_START:
      return {
        ...state,
        liveVideos: {
          data: {},
          loading: true,
          error: false,
          // skip: state.liveVideos.skip,
          // length: state.liveVideos.length,
        },
      };
    case FETCH_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        liveVideos: {
          data: action.data,
          // data: {
          //   videos: [...state.liveVideos.data.videos, ...action.data.live_videos],
          // },
          loading: false,
          error: false,
          // skip: action.data.live_videos.length + state.liveVideos.skip,
          // length: action.data.live_videos.length,
        },
      };
    case FETCH_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        liveVideos: {
          data: {},
          loading: false,
          error: action.error,
          // skip: state.liveVideos.skip,
          // length: state.liveVideos.length,
        },
      };
    case FETCH_LIVE_VIDEOS_HISTORY_START:
      return {
        ...state,
        liveVideosHistory: {
          data: {
            videos: [],
            total: 0,
          },
          loading: true,
          error: false,
          errorCount: 0,
        },
      };
    case FETCH_MORE_LIVE_VIDEOS_HISTORY_START:
      return state;
    case FETCH_LIVE_VIDEOS_HISTORY_SUCCESS:
      return {
        ...state,
        liveVideosHistory: {
          data: {
            videos: [...state.liveVideosHistory.data.videos, ...action.data.live_videos],
            total: action.data.total,
          },
          loading: false,
          error: false,
          errorCount: 0,
        },
      };
    case FETCH_LIVE_VIDEOS_HISTORY_FAILURE:
      return {
        ...state,
        liveVideosHistory: {
          data: state.liveVideosHistory.data,
          loading: false,
          error: action.error,
          errorCount: state.liveVideosHistory.errorCount + 1,
        },
      };

    case FETCH_SINGLE_LIVE_VIDEOS_START:
      return {
        ...state,
        singleLiveVideo: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait",
          buttonDisable: true,
        },
      };
    case FETCH_SINGLE_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        singleLiveVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_SINGLE_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        singleLiveVideo: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case JOIN_LIVE_VIDEOS_START:
      return {
        ...state,
        joinliveVideo: {
          inputData: action.data,
          loading: true,
          error: false,
          data: {},
          buttonDisable: true,
          loadingButtonContent: "Loading... Please wait",
        },
      };
    case JOIN_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        joinliveVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case JOIN_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        joinliveVideo: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case LIVE_VIDEOS_PAYMENT_BY_STRIPE_START:
      return {
        ...state,
        liveStripe: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case LIVE_VIDEOS_PAYMENT_BY_STRIPE_SUCCESS:
      return {
        ...state,
        liveStripe: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_PAYMENT_BY_STRIPE_FAILURE:
      return {
        ...state,
        liveStripe: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START:
      return {
        ...state,
        livePaypal: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case LIVE_VIDEOS_PAYMENT_BY_PAYPAL_SUCCESS:
      return {
        ...state,
        livePaypal: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_PAYMENT_BY_PAYPAL_FAILURE:
      return {
        ...state,
        livePaypal: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_VIEWER_UPDATE_START:
      return {
        ...state,
        liveViewerUpdate: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS:
      return {
        ...state,
        liveViewerUpdate: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_VIEWER_UPDATE_FAILURE:
      return {
        ...state,
        liveViewerUpdate: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_END_START:
      return {
        ...state,
        liveEnd: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case LIVE_VIDEOS_END_SUCCESS:
      return {
        ...state,
        liveEnd: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_END_FAILURE:
      return {
        ...state,
        liveEnd: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_PAYMENT_BY_WALLET_START:
      return {
        ...state,
        liveWallet: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case LIVE_VIDEOS_PAYMENT_BY_WALLET_SUCCESS:
      return {
        ...state,
        liveWallet: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_PAYMENT_BY_WALLET_FAILURE:
      return {
        ...state,
        liveWallet: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_SCHEDULED_LIVE_VIDEOS_START:
      return {
        ...state,
        scheduledLiveVideos: {
          data: {},
          loading: true,
          error: false,
          // skip: state.liveVideos.skip,
          // length: state.liveVideos.length,
        },
      };
    case FETCH_SCHEDULED_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        scheduledLiveVideos: {
          data: action.data,
          // data: {
          //   videos: [...state.liveVideos.data.videos, ...action.data.live_videos],
          // },
          loading: false,
          error: false,
          // skip: action.data.live_videos.length + state.liveVideos.skip,
          // length: action.data.live_videos.length,
        },
      };
    case FETCH_SCHEDULED_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        scheduledLiveVideos: {
          data: {},
          loading: false,
          error: action.error,
          // skip: state.liveVideos.skip,
          // length: state.liveVideos.length,
        },
      };
    case LIVE_VIDEOS_START_CALL_START:
      return {
        ...state,
        liveStart: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case LIVE_VIDEOS_START_CALL_SUCCESS:
      return {
        ...state,
        liveStart: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_START_CALL_FAILURE:
      return {
        ...state,
        liveStart: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_USER_SCHEDULED_LIVE_VIDEOS_START:
      return {
        ...state,
        scheduledLiveVideos: {
          data: {},
          loading: true,
          error: false,
          // skip: state.liveVideos.skip,
          // length: state.liveVideos.length,
        },
      };
    case FETCH_USER_SCHEDULED_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        scheduledLiveVideos: {
          data: action.data,
          // data: {
          //   videos: [...state.liveVideos.data.videos, ...action.data.live_videos],
          // },
          loading: false,
          error: false,
          // skip: action.data.live_videos.length + state.liveVideos.skip,
          // length: action.data.live_videos.length,
        },
      };
    case FETCH_USER_SCHEDULED_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        scheduledLiveVideos: {
          data: {},
          loading: false,
          error: action.error,
          // skip: state.liveVideos.skip,
          // length: state.liveVideos.length,
        },
      };
    case DELETE_LIVE_VIDEOS_START:
      return {
        ...state,
        deleteLiveVideo: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case DELETE_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        deleteLiveVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case DELETE_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        deleteLiveVideo: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_SAVE_START:
      return {
        ...state,
        saveLiveVideo: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case LIVE_VIDEOS_SAVE_SUCCESS:
      return {
        ...state,
        saveLiveVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_SAVE_FAILURE:
      return {
        ...state,
        saveLiveVideo: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    default:
      return state;
  }
};

export default LiveVideoReducer;
