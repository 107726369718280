import React from "react";
import {Form, Button, Image, Modal} from "react-bootstrap";
import { translate, t } from "react-multi-lang";

const MarcDescModal = (props) => {

  const {postDescription}= props;

  return (
    <>
      <Modal
        className="modal-dialog-center new-single-profile-modal"
        size="md"
        centered
        show={props.marcDescModal}
        onHide={props.closeMarcDescModal}
      >
          <Modal.Header closeButton>
            <Modal.Title>{t("workout_description")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="marc-desc-card">
              <p>{postDescription}</p>
            </div>
          </Modal.Body>
      </Modal>
    </>
  );
};

export default translate(MarcDescModal);