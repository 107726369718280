import React, { useEffect, useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Media,
  Image,
} from "react-bootstrap";
import "./Orders.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { moreOrdersListrForOwnerStart, ordersListForOthersStart, ordersListrForOwnerStart } from "../../../store/actions/ProductsAction";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../../NoDataFound/NoDataFound";
import ProductHeader from "../Product/ProductHeader";
import CommonCenterLoader from "../../Loader/CommonCenterLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile } from "react-device-detect";

const OrderList = (props) => {
  useEffect(() => {
    props.dispatch(ordersListrForOwnerStart());
  }, []);

  const fetchMoreOrders = () => {
    props.dispatch(moreOrdersListrForOwnerStart({
      skip: props.ordersListForOthers.data.orders.length,
      take: 12,
    }))
  }

  return (
    <>
      <div className="order-list-sec">
        <Container>
          <h2>{t("orders_list")}</h2>
          <div className="ecom-navbar">
            <ProductHeader />
          </div>
          {props.ordersListForOthers.loading ? (
            "Loading..."
          ) : (
            props.ordersListForOthers.data.orders.length > 0 ? (
              <InfiniteScroll
                dataLength={props.ordersListForOthers.data.orders.length}
                next={fetchMoreOrders}
                hasMore={props.ordersListForOthers.data.orders.length < props.ordersListForOthers.data.total &&
                  props.ordersListForOthers.errorCount < 2}
                loader={<CommonCenterLoader />}
              >
                <Row>
                  <Col md={12}>
                    <div className="table-wrap">

                      <Table responsive="md" responsive="sm">
                        <thead className="thead-primary">
                          <tr>
                            <th>{t("products")}</th>
                            <th>{t("trainer")}</th>
                            <th>{t("order_id")}</th>
                            <th>{t("shipping_address")}</th>
                            <th>{t("phone_number")}</th>
                            <th>{t("amount")}</th>
                            <th className="text-centre">{t("action")}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {props.ordersListForOthers.data.orders.map(
                            (order, index) => (
                              <>
                                <tr className="alert" role="alert" key={index}>
                                  <td>
                                    <div className="order-list-flex">
                                      <div className="orderer-product">
                                        {order.order_product.map(
                                          (product, index) => (
                                            <>
                                              {product.user_product_details ? (
                                                <div
                                                  className={`order-list-product ${order.total_products > 1
                                                    ? "mb-3"
                                                    : ""
                                                    }`}
                                                  key={index}
                                                >
                                                  <div
                                                    className="img"
                                                    style={{
                                                      backgroundImage: `url(${product.user_product_details.picture})`,
                                                    }}
                                                  ></div>
                                                  <div className="email">
                                                    <span>
                                                      {
                                                        product
                                                          .user_product_details
                                                          .name
                                                      }{" "}
                                                    </span>
                                                    <span>
                                                      {t("quantity")} :{" "}
                                                      {product.quantity}
                                                    </span>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  {order.user_details ?
                                    <td className="quantity">
                                      <Link to={isMobile ? `/w/` + order.user_details.unique_id : `/` +  order.user_details.unique_id}>
                                        {order.user_details.name}
                                      </Link>
                                    </td>
                                    : ''}
                                  <td className="quantity">{order.unique_id}</td>
                                  {order.delivery_address ?
                                    <>
                                      <td className="address">
                                        {order.delivery_address.landmark}, <br />
                                        {order.delivery_address.address}-
                                        {order.delivery_address.pincode} <br />
                                      </td>
                                      <td>{order.delivery_address.contact_number}</td>
                                    </>
                                    : <><td className="address"></td><td></td></>
                                  }
                                  <td className="quantity">
                                    {order.total_formatted}
                                  </td>
                                  <td className="text-right">
                                    <Link
                                      className="order-view-btn"
                                      to={`/order-view/${order.unique_id}`}
                                    >
                                      {t("view")}
                                    </Link>
                                  </td>
                                </tr>
                              </>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </InfiniteScroll>
            ) : (
              <div>
                <NoDataFound></NoDataFound>
              </div>
            )

          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  ordersListForOthers: state.userProducts.ownerOrdersList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(OrderList));
