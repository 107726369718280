import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Tab,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  PPVPaymentStripeStart,
  PPVPaymentWalletStart,
  PPVPaymentPaypalStart,
  PPVPaymentCCBillStart,
  PPVPaymentCoinPaymentStart,
} from "../../store/actions/PostAction";
import { fetchCardDetailsStart } from "../../store/actions/CardsAction";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

const PPVPaymentModal = (props) => {
  const [amount, setAmount] = useState(0);
  const [paymentType, setPaymentType] = useState(
    localStorage.getItem("default_payment_method")
  );

  const [isOnlyWalletPayment, setIsOnlyWalletPayment] = useState(
    configuration.get("configData.is_only_wallet_payment")
  );

  const [showPayPal, payPal] = useState(false);

  useEffect(() => {
    if (props.PPVPayment === true) {
      props.dispatch(fetchCardDetailsStart());
      props.dispatch(fetchWalletDetailsStart({ trainer_id: props.user_id }));
    }
  }, [props.PPVPayment]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (paymentType === "PAYPAL") showPayPal(true);

    if (paymentType === "CARD")
      props.dispatch(
        PPVPaymentStripeStart({
          post_id:
            props.post_id != undefined || props.post_id != null
              ? props.post_id
              : "",
          amount: amount,
          user_id: props.user_id,
        })
      );
    if (paymentType === "WALLET")
      props.dispatch(
        PPVPaymentWalletStart({
          post_id:
            props.post_id != undefined || props.post_id != null
              ? props.post_id
              : "",
          trainer_id: props.user_id,
        })
      );
    if (paymentType === "CCBILL")
      props.dispatch(
        PPVPaymentCCBillStart({
          post_id:
            props.post_id != undefined || props.post_id != null
              ? props.post_id
              : "",
          amount: props.amount,
          user_id: props.user_id,
        })
      );
    if (paymentType === "coinpayment")
      props.dispatch(
        PPVPaymentCoinPaymentStart({
          post_id:
            props.post_id != undefined || props.post_id != null
              ? props.post_id
              : "",
          amount: props.amount,
          user_id: props.user_id,
        })
      );

    if (paymentType === "PAYPAL") props.closePPVPaymentModal();
  };

  const paypalOnSuccess = (payment) => {
    console.log(payment);
    setTimeout(() => {
      props.dispatch(
        PPVPaymentPaypalStart({
          payment_id: payment.paymentID,
          post_id:
            props.post_id != undefined || props.post_id != null
              ? props.post_id
              : "",
          amount: props.amount,
          user_id: props.user_id,
        })
      );
    }, 1000);
  };

  const paypalOnError = (err) => {
    const notificationMessage = getErrorNotificationMessage(err);
    this.props.dispatch(createNotification(notificationMessage));
  };

  const paypalOnCancel = (data) => {
    const notificationMessage = getErrorNotificationMessage(
      t("payment_cancelled_note")
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  const choosePaymentOption = (event) => {
    console.log(amount);
    setPaymentType(event);
  };

  let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
  let currency = "USD"; // or you can set this value from your props or state

  const client = {
    sandbox: configuration.get("configData.PAYPAL_ID"),
    production: configuration.get("configData.PAYPAL_ID"),
  };

  return (
    <>
      <Modal
        show={props.PPVPayment}
        onHide={props.closePPVPaymentModal}
        className="modal-dialog-center"
        size="md"
        centered
      >
        {props.PPVPayment === true ? (
          <>
            <Modal.Header className="wallet-modal-header" closeButton>
              <Modal.Title>{t("pay_and_see_the_Post")}</Modal.Title>
            </Modal.Header>
            <div className="payment-tokens">
              <Modal.Body className="wallet-token-body">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey={paymentType}
                >
                  <Row>
                    <Col sm={12}>
                      <div className="wallet-balance-box">
                        <Form>
                          <div className="wallet-box-card mb-4">
                            <h4>{props.post.amount_formatted}</h4>
                          </div>

                          <Tab.Content>
                            {configuration.get(
                              "configData.is_wallet_payment_enabled"
                            ) == 1 ? (
                              <Tab.Pane eventKey="WALLET">
                                {props.wallet.loading ? (
                                  ""
                                ) : (
                                  <div className="card-stripe-box">
                                    <div className="wallet-box-card mb-2">
                                      <h4>{t("available")}</h4>
                                      <h3>
                                        {
                                          props.wallet.data.user_wallet
                                            .remaining_formatted
                                        }
                                      </h3>
                                    </div>
                                    {props.amount >
                                      props.wallet.data.user_wallet.remaining ? (
                                      <div className="">
                                        <p className="conv-desc desc">
                                          {t(
                                            "low_wallet_balance_tips_payment_para"
                                          )}
                                        </p>
                                        <div className="d-flex">
                                          <Link
                                            to="/wallet"
                                            className="withdraw-money-btn"
                                          >
                                            {t("add_wallet_amount")}
                                          </Link>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </Tab.Pane>
                            ) : null}
                          </Tab.Content>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </Tab.Container>
              </Modal.Body>
              <Modal.Footer className="wallet-modal-footer payment-post-wallet">
                <div className="wallet-modal-btn">
                  <Button
                    type="button"
                    className="btn btn-cancel"
                    data-dismiss="modal"
                    onClick={props.closePPVPaymentModal}
                  >
                    {t("cancel")}
                  </Button>

                  <Button
                    type="button"
                    className="btn pay-btn"
                    data-dismiss="modal"
                    onClick={handleSubmit}
                    disabled={props.ppvPayStripe.buttonDisable}
                  >
                    {props.ppvPayStripe.loadingButtonContent !== null
                      ? props.ppvPayStripe.loadingButtonContent
                      : t("pay_now")}
                  </Button>
                </div>
              </Modal.Footer>
            </div>
          </>
        ) : null}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  ppvPayStripe: state.post.ppvPayStripe,
  wallet: state.wallet.walletData,
  cards: state.cards.cardDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(PPVPaymentModal));
