import React, { useState, useEffect } from "react";
import { Form, Button, Image, Modal, Tab, Nav, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchCardDetailsStart,
} from "../../store/actions/CardsAction";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";
import {
  payByPayPalStart,
  payByStripeStart,
  videoCallPayByWalletStart,
} from "../../store/actions/PrivateCallAction";
import { Link } from "react-router-dom";

const VideoCallMakePaymentModel = (props) => {
  const [paymentType, setPaymentType] = useState(localStorage.getItem("default_payment_method"));

  const [showPayPal, payPal] = useState(false);

  const [isOnlyWalletPayment, setIsOnlyWalletPayment] = useState(
    configuration.get("configData.is_only_wallet_payment")
  );

  useEffect(() => {
    if (props.videoCallPayment === true) {
      props.dispatch(fetchCardDetailsStart());
      props.dispatch(fetchWalletDetailsStart({ trainer_id: props.model_id ?? 0 }));
    }
  }, [props.videoCallPayment]);

  let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
  let currency = "USD"; // or you can set this value from your props or state

  const client = {
    sandbox: configuration.get("configData.PAYPAL_ID"),
    production: configuration.get("configData.PAYPAL_ID"),
  };

  const choosePaymentOption = (event) => {
    setPaymentType(event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (paymentType === "CARD")
      props.dispatch(
        payByStripeStart({
          video_call_request_id: props.video_call_request_id,
        })
      );
    if (paymentType === "PAYPAL") showPayPal(true);

    if (paymentType === "WALLET")
      props.dispatch(
        videoCallPayByWalletStart({
          video_call_request_id: props.video_call_request_id,
        })
      );

    // props.closePaymentModal();
  };

  const paypalOnSuccess = (payment) => {
    console.log(payment);
    setTimeout(() => {
      props.dispatch(
        payByPayPalStart({
          payment_id: payment.paymentID,
          video_call_request_id: props.video_call_request_id,
        })
      );
    }, 1000);
  };

  const paypalOnError = (err) => {
    const notificationMessage = getErrorNotificationMessage(err);
    this.props.dispatch(createNotification(notificationMessage));
  };

  const paypalOnCancel = (data) => {
    const notificationMessage = getErrorNotificationMessage(
      "Payment cancelled please try again.."
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  return (
    <>
      <Modal
        show={props.videoCallPayment}
        onHide={props.closePaymentModal}
        centered
        size="md"
        className="modal-dialog-center "
      >
        {props.videoCallPayment === true ? (
          <>
            <Modal.Header closeButton className="wallet-modal-header">
              <Modal.Title className="add-wallet-header">
                {t("video_call_payment")}
              </Modal.Title>
            </Modal.Header>
            <div className="payment-tokens">
              <Modal.Body className="wallet-token-body">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey={paymentType}
                >
                  <Row>
                    <Col sm={12}>
                      <div className="wallet-balance-box">
                        <Form>
                          <div className="wallet-box-card mb-4">
                            <h4>{props.callDetails.amount_formatted}</h4>
                          </div>

                          <Tab.Content>
                            {configuration.get(
                              "configData.is_wallet_payment_enabled"
                            ) == 1 ? (
                              <Tab.Pane eventKey="WALLET">
                                {props.wallet.loading ? (
                                  ""
                                ) : (
                                  <div className="card-stripe-box">
                                    <div className="wallet-box-card mb-2">
                                      <h4>{t("available")}</h4>
                                      <h3>
                                        {
                                          props.wallet.data.user_wallet
                                            .remaining_formatted
                                        }
                                      </h3>
                                    </div>
                                    {props.amount >
                                      props.wallet.data.user_wallet.remaining ? (
                                      <div className="">
                                        <p className="conv-desc desc">
                                          {t(
                                            "low_wallet_balance_tips_payment_para"
                                          )}
                                        </p>
                                        <div className="d-flex">
                                          <Link
                                            to="/wallet"
                                            className="withdraw-money-btn"
                                          >
                                            {t("add_wallet_amount")}
                                          </Link>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </Tab.Pane>
                            ) : null}
                          </Tab.Content>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </Tab.Container>
              </Modal.Body>
              <Modal.Footer className="wallet-modal-footer payment-post-wallet">
                <div className="wallet-modal-btn">
                  {paymentType === "PAYPAL" && props.callDetails.amount != 0 ? (
                    <PaypalExpressBtn
                      env={env}
                      client={client}
                      currency={currency}
                      total={props.callDetails.amount}
                      onError={paypalOnError}
                      onSuccess={paypalOnSuccess}
                      onCancel={paypalOnCancel}
                    />
                  ) : null}
                  <Button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={props.closePaymentModal}
                  >
                    {t("cancel")}
                  </Button>
                  {paymentType !== "PAYPAL" ? (
                    <Button
                      type="button"
                      className="btn btn-success"
                      data-dismiss="modal"
                      onClick={handleSubmit}
                      disabled={props.subPayStripe.buttonDisable}
                    >
                      {props.subPayStripe.loadingButtonContent !== null
                        ? props.subPayStripe.loadingButtonContent
                        : props.callDetails.amount > 0 ? t("pay_now") : t("confirm_now")}
                    </Button>
                  ) : null}
                </div>
              </Modal.Footer>
            </div>
          </>
        ) : null}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  subPayStripe: state.subscriptions.subPayStripe,
  wallet: state.wallet.walletData,
  cards: state.cards.cardDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(VideoCallMakePaymentModel));
