import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const VideoCallLoader = () => {
  return (
    <>
      <Skeleton
        className="video-loader" />
      <Skeleton
        containerClassName="video-mini-loader-container"
        className="video-mini-loader" />
      <div className="live-streaming-video-action-btn-sec">
        <div className="d-flex justify-content-center live-streaming-video-action-btn">
          <Skeleton className="video-media-loader" />
          <Skeleton className="video-end-loader" />
          <Skeleton className="video-media-loader" />
        </div>
      </div>
    </>
  )

}

export default VideoCallLoader;