import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Tab,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import configuration from "react-global-configuration";

import { Link, useHistory } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import {
  forgotPasswordStart,
  userLoginStart,
  userRegisterStart,
  usernameValidationStart,
  referralValidationStart,
} from "../../store/actions/UserAction";
import {
  isAndroid,
  isIOS,
  isWindows,
  isMacOs,
  mobileModel,
  browserName,
  osName,
  mobileVendor,
  browserVersion
} from "react-device-detect";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
} from 'reactjs-social-login';

const REDIRECT_URI = window.location.href;

const LoginModal = (props) => {
  const history = useHistory();
  const [loginInputData, setLoginInputData] = useState({});
  const [show, setShow] = useState("login");
  useEffect(() => {

    var device_type = "";
    var device_model = "";
    var browser_type = browserName;

    if (isAndroid == true) {
      device_type = "android";
      device_model = mobileModel;
    } else if (isIOS == true) {
      device_type = "ios";
      device_model = mobileModel;
    } else {
      device_type = "web";
      device_model = browserName + ' ' + browserVersion;
    }

    setLoginInputData({
      ...loginInputData,
      email: configuration.get("configData.demo_user_email"),
      password: configuration.get("configData.demo_user_password"),
      device_type: device_type,
      device_model: device_model,
      browser_type: browser_type,
      isReload: 1,
    })
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    props.dispatch(userLoginStart(loginInputData));
  };

  const handleFacebookLogin = (response) => {
    console.log("handleFacebookLogin", response);

    const emailAddress = response.email === undefined || response.email === null ? response.id + "@facebook.com" : response.email;

    props.dispatch(
      userRegisterStart({
        name: response.name,
        first_name: response.first_name ? response.first_name : "",
        last_name: response.last_name ? response.last_name : "",
        email: emailAddress,
        social_unique_id: response.userID,
        picture: response.picture ? response.picture.data.url : '',
        login_by: "facebook",
        isReload: 1,
      })
    );
  };

  const handleGoogleLogin = (response) => {
    console.log("handleGoogleLogin", response);
    props.dispatch(
      userRegisterStart({
        name: response.name,
        email: response.email,
        first_name: response.given_name ? response.given_name : "",
        last_name: response.family_name ? response.family_name : "",
        social_unique_id: response.sub,
        picture: response.picture,
        login_by: "google",
        isReload: 1,
      })
    );
  };



  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  return (
    <>
      <Modal
        centered
        size="md"
        className="modal-dialog-center sent-tip-modal"
        show={props.loginModal}
        onHide={props.closeLoginModal}
      >
        {props.loginModal === true ?
          <>
            <Modal.Header closeButton>
              <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="signin-modal form-section">
                <Link to="/" className="sign-in-logo">
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/fitness/coach-logo.png"
                    }
                    width="237"
                    className="modal-logo"
                  />
                </Link>
                {/* <p className="login-tagline">
                  {configuration.get("configData.tag_name")}
                </p> */}
                {localStorage.getItem("is_client") == 1 ?
                  <>
                    {configuration.get("configData.GOOGLE_CLIENT_ID") && (
                      <LoginSocialGoogle
                        client_id={configuration.get("configData.social_logins.GOOGLE_CLIENT_ID")}
                        redirect_uri={REDIRECT_URI}
                        scope="openid profile email"
                        discoveryDocs="claims_supported"
                        access_type="offline"
                        onResolve={({ provider, data }) => {
                          handleGoogleLogin(data);
                        }}
                        className="social-button"
                        onReject={err => {
                          console.log(err);
                        }}
                      >
                        <span>
                          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM16 4C9.3832 4 4 9.3832 4 16C4 22.6168 9.3832 28 16 28C22.6168 28 28 22.6168 28 16V13.7143H16V18.2857H23.0688C22.1026 21.2671 19.2991 23.4286 16 23.4286C11.9039 23.4286 8.57143 20.0961 8.57143 16C8.57143 11.9039 11.9039 8.57143 16 8.57143C17.6545 8.57143 19.2197 9.10309 20.5262 10.1088L23.3147 6.4864C21.2017 4.85977 18.6723 4 16 4Z" fill="#D9D9D9" />
                          </svg>
                          {t("signup")} / {t("login_with_google")}
                        </span>
                      </LoginSocialGoogle>
                    )}

                    {configuration.get("configData.FB_CLIENT_ID") && (
                      <LoginSocialFacebook
                        appId={configuration.get("configData.social_logins.FB_CLIENT_ID")}
                        fieldsProfile={
                          'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                        }
                        redirect_uri={REDIRECT_URI}
                        onResolve={({ provider, data }) => {
                          handleFacebookLogin(data);
                        }}
                        className="social-button"
                        onReject={err => {
                          console.log(err);
                        }}
                      >
                        <span>
                          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9489 0.485352C18.8335 0.518268 21.4394 1.22721 23.7667 2.61218C26.0663 3.9695 27.9791 5.89407 29.3224 8.20185C30.6989 10.5432 31.4036 13.1649 31.4364 16.067C31.3547 20.0379 30.1022 23.4293 27.6791 26.2415C25.256 29.0536 22.1526 30.7933 18.9399 31.4603V20.3273H21.9772L22.6641 15.9523H18.0649V13.0868C18.0394 12.4927 18.2272 11.9092 18.5945 11.4416C18.9623 10.9727 19.61 10.7263 20.5376 10.7023H23.3149V6.86989C23.2751 6.85707 22.8969 6.80637 22.1805 6.7178C21.3681 6.62273 20.551 6.57196 19.733 6.5657C17.8817 6.57424 16.4175 7.09647 15.3405 8.13241C14.2634 9.16805 13.7133 10.6664 13.6899 12.6275V15.9523H10.1899V20.3273H13.6899V31.4603C9.74526 30.7933 6.64181 29.0536 4.21869 26.2415C1.79556 23.4293 0.543188 20.0378 0.461426 16.067C0.494138 13.1647 1.1988 10.543 2.5754 8.20185C3.9187 5.89407 5.83158 3.96951 8.13113 2.61218C10.4584 1.22748 13.0643 0.518535 15.9489 0.485352Z" fill="#D9D9D9" />
                          </svg>
                          {t("signup")} / {t("login_with_facebook")}
                        </span>
                      </LoginSocialFacebook>
                    )}

                    {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                      configuration.get("configData.FB_CLIENT_ID") ? (
                      <div className="coach-style-line mb-2">
                        <span>Or Continue with</span>
                      </div>
                    ) : null}
                  </>
                  :
                  null
                      }
              

                <Form
                  onSubmit={handleLogin}
                  method="post"
                  autoComplete="off"
                >
                  <Form.Group controlId="loginemail">
                    <Form.Control
                      type="text"
                      controlId="loginemail"
                      placeholder="E-mail"
                      required
                      value={loginInputData.email}
                      name="email"
                      autoComplete="nope"
                      onChange={(event) =>
                        setLoginInputData({
                          ...loginInputData,
                          email: event.currentTarget.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      controlId="loginpassword"
                      placeholder="Password"
                      required
                      autocomplete="off"
                      value={loginInputData.password}
                      name="password"
                      onChange={(event) =>
                        setLoginInputData({
                          ...loginInputData,
                          password: event.currentTarget.value,
                        })
                      }
                    />
                  </Form.Group>
                  <div className="forget-password d-flex justify-content-center">
                    <Link
                      to="#"
                      type="button"
                      className="forgot-link mb-5"
                      onClick={(event) => {
                        event.preventDefault();
                        props.openForgotPasswordModal();
                      }}
                    >
                      {" "}
                      {t("forgot_password")}{" "}
                    </Link>
                  </div>
                  <div className="">
                    <Button
                      id="login"
                      type="submit"
                      onClick={handleLogin}
                      className="btn gradient-btn gradientcolor"
                      disabled={props.login.buttonDisable}
                    >
                      {props.login.loadingButtonContent !== null
                        ? props.login.loadingButtonContent
                        : "Login"}
                    </Button>
                  </div>
                  <p id="two">{t("do_not_have_an_account")}</p>
                  <p>
                    <Link
                      className="signup"
                      to="#"
                      id="signup"
                      onClick={(event) => {
                        event.preventDefault();
                        props.openSignupModal();
                      }}
                    >
                      {" "}
                      {t("signup_for")}{" "}
                      {configuration.get("configData.site_name")}
                    </Link>
                  </p>
                </Form>
              </div>
            </Modal.Body>
          </>
          : null}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LoginModal));
