import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
    fetchSingleAudioCallStart,
} from "../../store/actions/PrivateCallAction";
import { translate, t } from "react-multi-lang";
import AgoraAudioCall from "../Sample/AgoraAudioCall";
import { connect } from "react-redux";

const NewAudioCallIndex = (props) => {

    useEffect(() => {
        props.dispatch(
            fetchSingleAudioCallStart({
                audio_call_request_unique_id: props.match.params.audio_call_request_unique_id,
            })
        );
    }, []);

    return (
        <>
            <div className="new-video-call-sec">
                <Container>
                    <Row>
                        <Col md={12}>
                            {props.singleAudioCall.loading ? (
                                t("loading")
                            ) : props.singleAudioCall.data.audio_call_request ? (
                                <>
                                    <div className="new-video-call-header-sec">
                                        <Link
                                            to={localStorage.getItem("is_content_creator") == 2 ? "/audio-calls-received" : "/audio-calls-sent"}
                                        >
                                            <div className="new-back-btn-icon-sec">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    fill="#fff"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"></path>
                                                </svg>
                                            </div>
                                        </Link>
                                        <div className="new-video-call-title-sec">
                                            <h2>{props.singleAudioCall.data.audio_call_request.model_displayname} - {props.singleAudioCall.data.audio_call_request.user_displayname}</h2>
                                        </div>
                                    </div>

                                    <AgoraAudioCall
                                        audioCallData={props.singleAudioCall.data.audio_call_request}
                                        isOwner={props.singleAudioCall.data.audio_call_request.is_owner}
                                    />
                                </>
                            ) : (
                                ""
                            )}
                            {/* <div className="new-video-call-header-sec">
                        <div className="new-back-btn-icon-sec">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#fff"
                                viewBox="0 0 24 24"
                                >
                                <path d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"></path>
                            </svg>
                        </div>
                        <div className="new-video-call-title-sec">
                            <h2>Design Critique - Nickelfox Website</h2>
                        </div>
                    </div> */}
                            {/* <div className="new-video-call-recoder-timer-sec">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#FF0000"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            >
                            <path d="M12 9a3 3 0 103 3 3 3 0 00-3-3zm0 4a1 1 0 111-1 1 1 0 01-1 1zm0-13a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 17a5 5 0 115-5 5.006 5.006 0 01-5 5z"></path>
                        </svg>
                        <span>REC  00:12:36</span>
                    </div> */}
                            {/* <div className="new-video-call-box">
                                <div className="new-video-call-card">
                                    <div className="new-audio-call-img">
                                        <div className="new-aduio-call-user-name-bg">
                                            S
                                        </div>
                                    </div>
                                    <div className="new-video-call-user-card">
                                       
                                        <div className="new-aduio-call-participate-name-bg">
                                            M
                                        </div>
                                        <div className="new-video-call-user-mute-option-bg">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                fill="none"
                                                viewBox="0 0 9 10"
                                            >
                                                <path
                                                    fill="#fff"
                                                    d="M5.999 5.517a1.42 1.42 0 00.149-.621V3.122a1.42 1.42 0 00-2.81-.266l2.66 2.66zm1.213-.621a.355.355 0 10-.71 0c0 .31-.085.614-.244.88l.521.539c.286-.418.437-.913.433-1.42zM4.728 6.315h.057L3.31 4.835v.06a1.42 1.42 0 001.42 1.42zm3.09 1.522L2.143 2.16a.356.356 0 10-.504.504L7.315 8.34a.355.355 0 00.581-.116.355.355 0 00-.077-.388z"
                                                ></path>
                                                <path
                                                    fill="#fff"
                                                    d="M5.792 8.09h-.71V7.35c.202-.028.398-.08.586-.156l-.567-.568a1.621 1.621 0 01-.373.043 1.774 1.774 0 01-1.774-1.774.355.355 0 10-.71 0 2.484 2.484 0 002.13 2.455v.738h-.71a.355.355 0 000 .71h2.128a.355.355 0 000-.71z"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="new-video-call-action-btn-sec">
                                        <ul className="list-unstyled new-video-call-action-btn-list">
                                            <li>
                                                <a href="#">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        x="0"
                                                        y="0"
                                                        width="20"
                                                        height="20"
                                                        fill="#fff"
                                                        enableBackground="new 0 0 467.968 467.968"
                                                        version="1.1"
                                                        viewBox="0 0 467.968 467.968"
                                                        xmlSpace="preserve"
                                                    >
                                                        <path d="M264.704 96.512H51.2c-28.16 0-51.2 23.04-51.2 51.2v172.544c0 28.16 23.04 51.2 51.2 51.2h213.504c28.16 0 51.2-23.04 51.2-51.2V147.712c0-28.672-23.04-51.2-51.2-51.2zM430.08 124.672c-3.072.512-6.144 2.048-8.704 3.584l-79.872 46.08V293.12l80.384 46.08c14.848 8.704 33.28 3.584 41.984-11.264 2.56-4.608 4.096-9.728 4.096-15.36V154.368c0-18.944-17.92-34.304-37.888-29.696z"></path>
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="end-call-bg">
                                                    <svg
                                                        xlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        height="40"
                                                        fill="fff"
                                                        viewBox="0 0 40 40"
                                                    >
                                                        <g clipPath="url(#clip0_8_390)">
                                                            <path
                                                                fill="#fff"
                                                                d="M32.085 27.433l-.287 1.316c-.268 1.234-1.449 2.036-2.757 1.875l-2.607-.32c-1.134-.141-2.083-.975-2.345-2.062l-.726-3.028c-1.18-.52-2.49-.778-3.927-.774a10.344 10.344 0 00-4.032.797l-.572 2.803c-.217 1.06-1.12 1.834-2.236 1.916l-2.604.194c-1.3.096-2.52-.761-2.856-2.005l-.36-1.333c-.359-1.328.028-2.705 1.015-3.615 2.332-2.15 6.15-3.157 11.456-3.026 5.314.132 9.195 1.336 11.637 3.61 1.028.957 1.485 2.346 1.2 3.652z"
                                                            ></path>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_8_390">
                                                                <path
                                                                    fill="#fff"
                                                                    d="M0 0H28.61V28.61H0z"
                                                                    transform="rotate(120 15.358 18.704)"
                                                                ></path>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        x="0"
                                                        y="0"
                                                        width="20"
                                                        height="20"
                                                        fill="#fff"
                                                        enableBackground="new 0 0 467.968 467.968"
                                                        version="1.1"
                                                        viewBox="0 0 467.968 467.968"
                                                        xmlSpace="preserve"
                                                    >
                                                        <path d="M264.704 96.512H51.2c-28.16 0-51.2 23.04-51.2 51.2v172.544c0 28.16 23.04 51.2 51.2 51.2h213.504c28.16 0 51.2-23.04 51.2-51.2V147.712c0-28.672-23.04-51.2-51.2-51.2zM430.08 124.672c-3.072.512-6.144 2.048-8.704 3.584l-79.872 46.08V293.12l80.384 46.08c14.848 8.704 33.28 3.584 41.984-11.264 2.56-4.608 4.096-9.728 4.096-15.36V154.368c0-18.944-17.92-34.304-37.888-29.696z"></path>
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    singleAudioCall: state.privateCall.singleAudioCall,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(NewAudioCallIndex));
