import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const PostLoader = () => {

  return (
    <div className="coach-single-profile-program-box">
      {[...Array(6)].map(() =>
        <Skeleton height={300}
        />
      )}
    </div>
  );
}

export default PostLoader;