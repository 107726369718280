import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Tab,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  sendTipStripeStart,
  sendTipWalletStart,
  sendTipPaypalStart,
  sendTipCCBillStart,
  sendTipCoinPaymentStart,
} from "../../store/actions/SendTipAction";
import configuration from "react-global-configuration";

import PaypalExpressBtn from "react-paypal-express-checkout";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import { fetchCardDetailsStart } from "../../store/actions/CardsAction";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

const SendTipModal = (props) => {
  const [amount, setAmount] = useState(1);
  const [paymentType, setPaymentType] = useState("WALLET");
  const [message, setMessage] = useState("");
  const [showPayPal, payPal] = useState(false);
  const [isOnlyWalletPayment, setIsOnlyWalletPayment] = useState(
    configuration.get("configData.is_only_wallet_payment")
  );

  useEffect(() => {
    if (props.sendTip === true) {
      // props.dispatch(fetchCardDetailsStart());
    }
  }, [props.sendTip]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (paymentType === "CARD")
      props.dispatch(
        sendTipStripeStart({
          post_id:
            props.post_id != undefined || props.post_id != null
              ? props.post_id
              : "",
          amount: amount,
          message: message,
          user_id: props.user_id,
        })
      );
    if (paymentType === "WALLET")
      props.dispatch(
        sendTipWalletStart({
          post_id:
            props.post_id != undefined || props.post_id != null
              ? props.post_id
              : "",
          amount: amount,
          message: message,
          user_id: props.user_id,
        })
      );
    if (paymentType === "CCBILL")
      props.dispatch(
        sendTipCCBillStart({
          post_id:
            props.post_id != undefined || props.post_id != null
              ? props.post_id
              : "",
          amount: amount,
          user_id: props.user_id,
        })
      );
    if (paymentType === "coinpayment")
      props.dispatch(
        sendTipCoinPaymentStart({
          post_id:
            props.post_id != undefined || props.post_id != null
              ? props.post_id
              : "",
          amount: amount,
          user_id: props.user_id,
        })
      );
    props.closeSendTipModal();
  };

  return (
    <>
      <Modal
        centered
        size="md"
        className="modal-dialog-center"
        show={props.sendTip}
        onHide={props.closeSendTipModal}
      >
        {props.sendTip === true ? (
          <Form className="payment-tokens">
            <Modal.Header closeButton className="wallet-modal-header">
              <Modal.Title className="add-wallet-header">
                {t("send_tip")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="wallet-token-body add-new-wallet">
              <div className="wallet-token-body-input">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="number"
                    placeholder={
                      isOnlyWalletPayment == 1
                        ? t("tip_token")
                        : t("tip_amount")
                    }
                    // value={amount}
                    min="1"
                    step="any"
                    defaultValue={amount}
                    onChange={(event) => {
                      setAmount(event.currentTarget.value);
                    }}
                  />
                </Form.Group>

                {/* {isOnlyWalletPayment == 1 ? (
                  <div className="floating-label">
                  <h5 className="label-default-1">{t("amount")} ({configuration.get("configData.token_amount")} * {amount}) = {configuration.get("configData.token_amount") * amount} {" "}{configuration.get("configData.currency")}
                  </h5>
                  </div>
                ) : (
                  ""
                )} */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={t("message_optional")}
                    className="height-auto"
                    onChange={(event) => {
                      setMessage(event.currentTarget.value);
                    }}
                  />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer className="wallet-modal-footer payment-post-wallet">
              <div className="wallet-modal-btn">

                <Button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={props.closeSendTipModal}
                >
                  {t("cancel")}
                </Button>
                <Button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={handleSubmit}
                  disabled={props.tipStripe.buttonDisable}
                >
                  {props.tipStripe.loadingButtonContent !== null
                    ? props.tipStripe.loadingButtonContent
                    : t("send_tip")}
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        ) : null}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  tipStripe: state.tip.tipStripe,
  cards: state.cards.cardDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(SendTipModal));
