import React, { useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const CoachTransactionProfileCard = (props) => {
    const { user } = props;

    return (
        <>
            {user.otherUser ?
                <Link to={`/transaction/${user.otherUser.user_unique_id}`}>
                    <div className="home-profile-card">
                        <div className="home-profile-card-bg-sec">
                            <Image
                                className="home-profile-card-bg"
                                src={user.otherUser.cover}
                            />
                        </div>
                        <div className="home-profile-card-content">
                            <div className="home-profile-user-img-sec">
                                <Image
                                    className="home-profile-user-img"
                                    src={user.otherUser.picture}
                                />
                            </div>
                            <h4>{user.otherUser.name}</h4>
                            <p className="trainer-tokens">
                            <Image
                                            className="token-icon"
                                            src={
                                                window.location.origin + "/assets/images/fitness/token/token4.svg"
                                            }
                                        />
                                {user.wallet_balance_formatted}
                            </p>
                        </div>
                    </div>
                </Link>
                : ''}
        </>
    );
};

export default CoachTransactionProfileCard;
