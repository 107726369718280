import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import {
    addMoneyViaCardStart,
    addMoneyViaPaypalStart,
    addMoneyViaPayfastStart,
} from "../../store/actions/WalletAction";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { createNotification } from "react-redux-notify";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import { translate, t } from "react-multi-lang";

const AddWalletAmountModal = (props) => {

    const [paymentType, setPaymentType] = useState("paypal");

    const [amount, setAmount] = useState(1);

    const handleChangeAmount = (amount) => {
        setAmount(amount);
    };

    const [inputData, setInputData] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        // if (paymentType === "card")
        //     props.dispatch(
        //         addMoneyViaCardStart({
        //             amount: amount,
        //         })
        //     );
        // props.closeAddWalletAmountModal();
        if (paymentType === "payfast")
            props.dispatch(
                addMoneyViaPayfastStart({
                    trainer_id: props.trainerId,
                    amount: amount,
                })
            );
        props.closeAddWalletAmountModal();
        setAmount(0);

    };

    const paypalOnSuccess = (payment) => {
        setTimeout(() => {
            props.dispatch(
                addMoneyViaPaypalStart({
                    payment_id: payment.paymentID,
                    trainer_id: props.trainerId,
                    amount: amount,
                })
            );
        }, 1000);
        props.closeAddWalletAmountModal();
    };

    const paypalOnError = (err) => {
        const notificationMessage = getErrorNotificationMessage(err);
        this.props.dispatch(createNotification(notificationMessage));
    };

    const paypalOnCancel = (data) => {
        const notificationMessage = getErrorNotificationMessage(
            t("payment_cancelled_note")
        );
        this.props.dispatch(createNotification(notificationMessage));
    };

    const choosePaymentOption = (event) => {
        setPaymentType(event);
    };

    let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
    let currency = "USD"; // or you can set this value from your props or state

    const client = {
        sandbox: configuration.get("configData.PAYPAL_ID"),
        production: configuration.get("configData.PAYPAL_ID"),
    };

    return (
        <>

            <Modal
                className="modal-dialog-center withdraw-modal"
                size="md"
                centered
                show={props.addWalletAmountModal}
                onHide={props.closeAddWalletAmountModal}
            >
                {props.addWalletAmountModal === true ?
                    <Form onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>{configuration.get("configData.is_only_wallet_payment") ? t("buy_tokens") : t("add_wallet_amount")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {props.payments.loading ? (
                                t("loading")
                            ) : (
                                <Row>
                                    <Col md="12">
                                        <div className="mb-5">
                                            <h4 className="text-muted">
                                                {t("wallet_balance")}:{" "}
                                                <span className="text-muted">
                                                    {props.payments.data.user_wallet
                                                        ? props.payments.data.user_wallet.remaining_formatted
                                                        : ""}
                                                </span>
                                            </h4>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <div className="floating-form">
                                <div className="floating-label">
                                    <input
                                        className="floating-input"
                                        type="number"
                                        placeholder={configuration.get("configData.is_only_wallet_payment") ? t("tokens") : t("amount")}
                                        min="1"
                                        step="any"
                                        value={amount}
                                        onChange={(event) => handleChangeAmount(event.currentTarget.value)}
                                    />
                                    <span className="highlight"></span>
                                    <label className="default-label">{configuration.get("configData.is_only_wallet_payment") ? t("enter_tokens") : t("enter_amount")}</label>
                                </div>
                                {configuration.get("configData.is_only_wallet_payment") ?
                                    <div className="floating-label">
                                        <label className="label-default-1">{t("token_amount")} ({configuration.get("configData.token_amount")} * {amount}) = {configuration.get("configData.token_amount") * amount} {" "}{configuration.get("configData.currency")}
                                        </label>

                                    </div>
                                    : ""}
                                <div className="floating-label">
                                    <label className="label-default-1">{t("currency_amount")} ({props.payments.data.currency_value} * {amount}) = {props.payments.data.currency_value * amount} {" "}{props.payments.data.currency_symbol}
                                    </label>
                                </div>
                                <div className="floating-label">
                                    <label className="label-default-1">{t("currency_value")} {props.payments.data
                                        ? props.payments.data.currency_value_formatted
                                        : ""}
                                    </label>
                                </div>
                                <div className="floating-label">
                                    <label className="label-default-1">{t("payment_type")}</label>
                                    <Form className="mt-4">
                                        {["radio"].map((type) => (
                                            <div key={`custom-inline-${type}`} className="mb-3">
                                                {/* <Form.Check
                                                    custom
                                                    inline
                                                    label="Card"
                                                    type={type}
                                                    id="card"
                                                    value="card"
                                                    name="payment_type"
                                                    defaultChecked={true}
                                                    onChange={() => setPaymentType("card")}
                                                /> */}
                                                {configuration.get("configData.payfast_id") &&
                                                    configuration.get("configData.payfast_key") &&
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label={t("payfast")}
                                                        type={type}
                                                        id="payfast"
                                                        value="payfast"
                                                        name="payment_type"
                                                        // defaultChecked={true}
                                                        onChange={() => setPaymentType("payfast")}
                                                    />
                                                }
                                                {configuration.get("configData.is_paypal_enabled") ==
                                                    1 ? (
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label={t("paypal")}
                                                        type={type}
                                                        id="paypal"
                                                        value="paypal"
                                                        name="payment_type"
                                                        defaultChecked={paymentType == 'paypal' ? true : false}
                                                        onChange={() => setPaymentType("paypal")}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        ))}
                                    </Form>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>

                            {paymentType === "paypal" && amount != 0 ? (
                                <PaypalExpressBtn
                                    env={env}
                                    client={client}
                                    currency={currency}
                                    total={configuration.get("configData.is_only_wallet_payment") ? configuration.get("configData.token_amount") * amount : amount}
                                    onError={paypalOnError}
                                    onSuccess={paypalOnSuccess}
                                    onCancel={paypalOnCancel}
                                />
                            ) : null}

                            <Button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={props.closeAddWalletAmountModal}
                            >
                                {t("cancel")}
                            </Button>
                            {paymentType !== "paypal" ? (
                                <Button
                                    type="button"
                                    className="btn btn-success"
                                    data-dismiss="modal"
                                    onClick={handleSubmit}
                                >
                                    {props.addAmount.loadingButtonContent != null
                                        ? props.addAmount.loadingButtonContent
                                        : t("submit")}
                                </Button>
                            ) : ''}

                        </Modal.Footer>
                    </Form>
                    : null}
            </Modal>

        </>
    );
};

const mapStateToPros = (state) => ({
    //payments: state.wallet.walletData,
    addAmount: state.wallet.addMoneyInput,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(AddWalletAmountModal));
