import React, { useEffect, useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Media,
  Image,
  Nav,
  Tab,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import VideoTrainingModal from "./VideoTrainingModal";
import AudioTrainingModal from "./AudioTrainingModal";
import {
  fetchSingleUserProfileStart,
  fetchSingleUserPostsStart,
} from "../../store/actions/OtherUserAction";
import { fetchOtherModelProductListStart } from "../../store/actions/ProductsAction";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";

import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import ProfileLoader from "../Loader/ProfileLoader";
import { subscriptionPaymentStripeStart } from "../../store/actions/SubscriptionAction";
import { unFollowUserStart } from "../../store/actions/FollowAction";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import {
  saveBlockUserStart,
  fetchtTrainerServiceListForOthersStart,
} from "../../store/actions/UserAction";
import ModelVideoSec from "./ModelVideoSec";
import ModelLiveStreamingSec from "./ModelLiveStreamingSec";
import { saveChatUserStart } from "../../store/actions/ChatAction";
import NewSingleProfileDetailsModal from "./NewSingleProfileDetailsModal";
import ModelProductSec from "./ModelProductSec";
import AddWalletTokenModal from "../helper/AddWalletTokenModal";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import SendTipModal from "../helper/SendTipModal";
import Slider from "react-slick";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  RedditShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  RedditIcon,
  TelegramIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import configuration from "react-global-configuration";
import { isMobile } from "react-device-detect";

const CoachSingleProfileIndex = (props) => {
  const toggleVisibility = () => { };

  const [newSingleProfileDetails, setNewSingleProfileDetails] = useState(false);

  const closeNewSingleProfileDetailsModal = () => {
    setNewSingleProfileDetails(false);
  };

  const [show, toggleShow] = React.useState(false);

  const [showBio, setShowBio] = React.useState(false);

  const [showBioStatus, setShowBioStatus] = React.useState(0);

  const [showDesc, setShowDesc] = React.useState(false);

  const [videoTraining, setVideoTraining] = useState(false);

  const [audioTraining, setAudioTraining] = useState(false);

  const [addWalletAmountModal, setAddWalletAmountModal] = useState(false);

  const [showUnfollow, setShowUnfollow] = useState(false);

  const [blockUserStatus, setBlockUserStatus] = useState("");

  const [activeSec, setActiveSec] = useState("video");

  const [sendTip, setSendTip] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const closeSendTipModal = () => {
    setSendTip(false);
  };

  const params = useParams();

  const history = useHistory();

  const closeVideoTrainingModal = () => {
    setVideoTraining(false);
  };

  const closeAudioTrainingModal = () => {
    setAudioTraining(false);
  };

  const closeAddWalletAmountModal = () => {
    setAddWalletAmountModal(false);
  };

  const handleUnfollowModalClose = () => setShowUnfollow(false);
  const handleUnfollowModalShow = () => setShowUnfollow(true);

  const handleUnfollow = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserStart({
        user_id: user_id,
      })
    );
  };

  const handleBlockUser = (event, status, user_id) => {
    event.preventDefault();
    setBlockUserStatus(status);
    props.dispatch(
      saveBlockUserStart({
        user_id: user_id,
        is_other_profile: 1,
      })
    );
  };
  const handleBioClick = () => {
    setShowBio(!showBio);
    if (showBio != true) {
      setShowBioStatus(1);
    }
  };

  useEffect(() => {
    if (showBioStatus) {
      setShowBioStatus(0);
      const timeId = setTimeout(() => {
        setShowBio(!showBio);
      }, 10000);
    }
  }, [showBioStatus]);

  // useEffect(() => {
  //   const timeId = setTimeout(() => {
  //     setShowBio(true);
  //   }, 5000);

  //   return () => {
  //     clearTimeout(timeId);
  //   };
  // }, []);

  // useEffect(() => {
  //   const timerId = setTimeout(() => {
  //     setShowBio(false);
  //   }, 10000);

  //   return () => {
  //     clearTimeout(timerId);
  //   };
  // }, []);

  const handleChatUser = (event, user_id) => {
    event.preventDefault();
    if (!localStorage.getItem("userId")) {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );
      props.dispatch(createNotification(notificationMessage));
    } else {
      props.dispatch(
        saveChatUserStart({
          from_user_id: localStorage.getItem("userId"),
          to_user_id: user_id,
        })
      );
    }
  };

  useEffect(() => {
    if (!isMobile) {
      window.location.assign("/" + params.username);
    }
    fetchUserProfile();
    props.dispatch(
      fetchtTrainerServiceListForOthersStart({
        trainer_unique_code: params.username,
      })
    );
    if (localStorage.getItem("userId")) {
      props.dispatch(
        fetchWalletDetailsStart({ trainer_unique_code: params.username })
      );
    } else {
      localStorage.setItem("coach_username", params.username);
    }
    // props.dispatch(
    // 	fetchSingleUserPostsStart({
    // 		user_unique_id: params.username,
    // 		type: "video",
    // 	})
    // );
    window.addEventListener("scroll", toggleVisibility);
  }, [params.username]);

  // useEffect(() => {
  // 	if (!props.ppvPayStripe.loading && Object.keys(props.ppvPayStripe.data).length > 0) {
  // 		fetchUserProfile();
  // 		props.dispatch(
  // 			fetchSingleUserPostsStart({
  // 				user_unique_id: params.username,
  // 				type: "video",
  // 			})
  // 		);
  // 	}
  // }, [props.ppvPayStripe]);

  const [skipFirst, setSkipFirst] = useState(true);

  useEffect(() => {
    if (!skipFirst) {
      if (
        !props.addMoneyInput.loading &&
        Object.keys(props.addMoneyInput.data).length > 0
      ) {
        fetchUserProfile();
      }
    }
    setSkipFirst(false);
  }, [props.addMoneyInput]);

  const fetchUserProfile = () => {
    props.dispatch(
      fetchSingleUserProfileStart({
        user_unique_id: params.username,
      })
    );
  };

  const setActiveSection = (event, key) => {
    setActiveSec(key);
    // if (key === "video")
    // 	props.dispatch(
    // 		fetchSingleUserPostsStart({
    // 			user_unique_id: params.username,
    // 			type: "video",
    // 		})
    // 	);
    // else
    if (key === "store")
      props.dispatch(
        fetchOtherModelProductListStart({
          user_unique_id: params.username,
        })
      );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const redirectToLogin = () => {
    if (!localStorage.getItem("userId")) {
      localStorage.setItem("is_client", 1);
      history.push(`/login-redirect?is_client=${localStorage.getItem("is_client")}`);
    }
  };

  const { userDetails } = props;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("profile_link_copied")
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="coach-single-profile-sec">
        {userDetails.loading ? (
          <ProfileLoader></ProfileLoader>
        ) : (
          <>
            <div
              className="new-single-profile-header-sec"
              style={{ backgroundImage: `url(${userDetails.data.user.cover})` }}
            >
              <Container>
                <div className="new-single-profile-header-card">
                  <div className="new-single-profile-img-sec">
                    <Image
                      className="new-single-profile-img"
                      src={userDetails.data.user.picture}
                      alt={userDetails.data.user.name}
                    />
                  </div>
                  <div className="new-single-profile-info-sec">
                    <h2>
                      {userDetails.data.user.name}
                      <span>
                        {userDetails.data.user.is_verified_badge == 1 && (
                          <Image
                            className="coach-home-profile-verified-icon"
                            src={
                              window.location.origin +
                              "/assets/images/fitness/user-verified-icon.png"
                            }
                          />
                        )}
                      </span>
                    </h2>
                    <p>
                        {userDetails.data.user.about_formatted ? (
                          <>{userDetails.data.user.about_short_formatted}  <Button className="read-more-btn" onClick={() => setNewSingleProfileDetails(true)}> Read More</Button></>
                        ) : (
                          t("no_about_information")
                        )}</p>

                    <div className="new-single-profile-info-action-btn-sec">
                      <div className="new-single-profile-info-action-left-btn">
                        <ul className="list-unstyled new-single-profile-info-action-left-btn-list">
                          <li>
                            <Button
                              className="buy-token-btn"
                              onClick={() => {
                                setAddWalletAmountModal(true);
                                redirectToLogin();
                              }}
                            >
                              {t("buy_tokens")}
                            </Button>
                          </li>
                          <li>
                            {!props.wallet.loading ? (
                              <Link
                                className="coach-profile-token-count-sec"
                                to={`/transaction/${params.username}`}
                              >
                                <span>
                                  {
                                    props.wallet.data.user_wallet
                                      .remaining_formatted
                                  }
                                </span>
                              </Link>
                            ) : (
                              <Link
                                className="coach-profile-token-count-sec"
                                to={'#'}
                              >
                                <span>
                                  0 Tokens
                                </span>
                              </Link>
                            )}
                          </li>
                          <li>
                            <Button
                              className="buy-token-btn"
                              onClick={() => {
                                setSendTip(true);
                                redirectToLogin();
                              }}
                            >
                              <span>{t("send_tip")}</span>
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className="new-single-profile-info-action-right-btn">
                        <ul className="list-unstyled new-single-profile-info-action-right-btn-list">
                          <li>
                            <Link
                              to="/ecom-cart"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="#000" d="M18 6h-2c0-2.21-1.79-4-4-4S8 3.79 8 6H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-8 4c0 .55-.45 1-1 1s-1-.45-1-1V8h2v2zm2-6c1.1 0 2 .9 2 2h-4c0-1.1.9-2 2-2zm4 6c0 .55-.45 1-1 1s-1-.45-1-1V8h2v2z"></path></svg>
                            </Link>
                            {userDetails.data.user.is_user_live == 1 && (
                              <div className="coach-single-live-status">
                                <Link
                                  to={`/live-video/${userDetails.data.user.live_video_unique_id}`}
                                >
                                  Live
                                </Link>
                              </div>
                            )}
                          </li>
                          <li>
                            <Link to="#" onClick={handleShareClick}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="#000" d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7 0-.24-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92 0-1.61-1.31-2.92-2.92-2.92z"></path></svg>
                            </Link>
                            <div className="share-popover-sec">
                              <Popover
                                id={popoverId}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <Typography>
                                  <div className="social-share-sec m-3">
                                    <div className="text-center social-link">
                                      <div className="Demo__some-network">
                                        <EmailShareButton
                                          url={userDetails.data.user.share_link}
                                          subject={configuration.get("configData.site_name")}
                                          body={userDetails.data.user.share_message}
                                          className="Demo__some-network__share-button"
                                        >
                                          <EmailIcon size={32} round />
                                        </EmailShareButton>
                                      </div>
                                      {/* <h6 className="social-desc">{t("email")}</h6> */}
                                    </div>
                                    <div className="text-center social-link">
                                      <WhatsappShareButton
                                        url={userDetails.data.user.share_link}
                                        title={userDetails.data.user.share_message}
                                        separator=":: "
                                        className="Demo__some-network__share-button"
                                      >
                                        <WhatsappIcon size={32} round />
                                      </WhatsappShareButton>
                                      {/* <h6 className="social-desc">{t("whatsapp")}</h6> */}
                                    </div>
                                    <div className="text-center social-link">
                                      <FacebookShareButton
                                        url={userDetails.data.user.share_link}
                                        quote={userDetails.data.user.share_message}
                                        className="Demo__some-network__share-button"
                                      >
                                        <FacebookIcon size={32} round />
                                      </FacebookShareButton>
                                      {/* <h6 className="social-desc">{t("facebook")}</h6> */}
                                    </div>
                                    <div className="text-center social-link">
                                      <TwitterShareButton
                                        url={userDetails.data.user.share_link}
                                        title={userDetails.data.user.share_message}
                                        className="Demo__some-network__share-button"
                                      >
                                        <TwitterIcon size={32} round />
                                      </TwitterShareButton>
                                      {/* <h6 className="social-desc">{t("twitter")}</h6> */}
                                    </div>
                                    <div className="text-center social-link">
                                      <RedditShareButton
                                        url={userDetails.data.user.share_link}
                                        title={userDetails.data.user.share_message}
                                        windowWidth={660}
                                        windowHeight={460}
                                        className="Demo__some-network__share-button"
                                      >
                                        <RedditIcon size={32} round />
                                      </RedditShareButton>
                                      {/* <h6 className="social-desc">{t("reddit")}</h6> */}
                                    </div>
                                    <div className="text-center social-link">
                                      <CopyToClipboard
                                        onCopy={onCopy}
                                        text={userDetails.data.user.share_link}
                                        windowWidth={660}
                                        windowHeight={460}
                                        className="Demo__some-network__share-button"
                                      >
                                        <button className="react-share__ShareButton Demo__some-network__share-button">
                                          <i className="fas fa-copy"></i>
                                        </button>
                                      </CopyToClipboard>
                                    </div>
                                  </div>
                                </Typography>
                              </Popover>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            {!props.trainerServicesListForOthers.loading &&
              props.trainerServicesListForOthers.data.trainer_services &&
              props.trainerServicesListForOthers.data.trainer_services.length >
              0 && (
                <div className="coach-single-profile-category-sec">
                  <Container>
                    <Row>
                      <Col md={12}>
                        {/* <div className="coach-single-profile-category-box">
                          {props.trainerServicesListForOthers.data.trainer_services.map(
                            (service, index) => (
                              <div
                                key={index}
                                className="coach-single-profile-category-card"
                              >
                                {service.service}
                              </div>
                            )
                          )}
                        </div> */}
                        <Slider {...settings}>
                          {props.trainerServicesListForOthers.data.trainer_services.map(
                            (service, index) => (
                              <div
                                key={index}
                                className="coach-single-profile-category-card"
                              >
                                {service.service}
                              </div>
                            )
                          )}
                        </Slider>
                      </Col>
                    </Row>
                  </Container>
                </div>
              )}

            <div className="coach-single-profile-social-link-sec">
              <ul className="coach-single-profile-social-link">
                <Media as="li">
                  <a
                    href={userDetails.data.user.instagram_link}
                    target="_blank"
                    title="instagram"
                  >
                    <Image
                      className="coach-single-profile-socia-icon"
                      src={
                        window.location.origin +
                        "/assets/images/fitness/social-link/insta.svg"
                      }
                    />
                  </a>
                </Media>

                <Media as="li">
                  <a
                    href={userDetails.data.user.facebook_link}
                    target="_blank"
                    title="facebook"
                  >
                    <Image
                      className="coach-single-profile-socia-icon"
                      src={
                        window.location.origin +
                        "/assets/images/fitness/social-link/fb.svg"
                      }
                    />
                  </a>
                </Media>

                <Media as="li">
                  <a
                    href={userDetails.data.user.tiktok_link}
                    target="_blank"
                    title="tiktok"
                  >
                    <Image
                      className="coach-single-profile-socia-icon"
                      src={
                        window.location.origin +
                        "/assets/images/fitness/social-link/tiktok.svg"
                      }
                    />
                  </a>
                </Media>

                <Media as="li">
                  <a
                    href={userDetails.data.user.youtube_link}
                    target="_blank"
                    title="youtube"
                  >
                    <Image
                      className="coach-single-profile-socia-icon"
                      src={
                        window.location.origin +
                        "/assets/images/fitness/social-link/youtube.svg"
                      }
                    />
                  </a>
                </Media>

                <Media as="li">
                  <a
                    href={userDetails.data.user.snapchat_link}
                    target="_blank"
                    title="snapchat"
                  >
                    <Image
                      className="coach-single-profile-socia-icon"
                      src={
                        window.location.origin +
                        "/assets/images/fitness/social-link/snapchat.svg"
                      }
                    />
                  </a>
                </Media>

                <Media as="li">
                  <a
                    href={userDetails.data.user.website}
                    target="_blank"
                    title="website"
                  >
                    <Image
                      className="coach-single-profile-socia-icon"
                      src={
                        window.location.origin +
                        "/assets/images/fitness/social-link/web.svg"
                      }
                    />
                  </a>
                </Media>
              </ul>
            </div>
            <div className="about-bio-card-sec">
              {showBio && (
                <div className="coach-bio-sec">
                  {userDetails.data.user.about_formatted ? (
                    <p>{userDetails.data.user.about}</p>
                  ) : (
                    t("no_about_information")
                  )}
                </div>
              )}
            </div>
            <NewSingleProfileDetailsModal
            newSingleProfileDetails={newSingleProfileDetails}
            closeNewSingleProfileDetailsModal={closeNewSingleProfileDetailsModal}
            user={userDetails.data.user}
          />
          </>
        )}
        {/* <div className="coach-single-profile-colapse-bio-sec">
                    <Link to="#" className="about-me-btn" onClick={() => setShowBio(!showBio)}>
                        About Me <i className="fas fa-chevron-down"></i>
                    </Link>
                    {showBio &&
                    <div className="bio-card">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                    }
                </div> */}

        <div className="coach-single-profile-tab-sec">
          <Container>
            <Row>
              <Col md={12}>
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="programs"
                >
                  <Row>
                    <Col sm={12} className="border-bottom-separtion">
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="programs"
                            onClick={(event) =>
                              setActiveSection(event, "video")
                            }
                          >
                            {t("workouts")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="virtual-training"
                            onClick={(event) =>
                              setActiveSection(event, "virtual-training")
                            }
                          >
                            {t("virtual_training")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="subscribers"
                            onClick={(event) =>
                              setActiveSection(event, "subscribers")
                            }
                          >
                            {t("live_streaming")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="merchandiser"
                            onClick={(event) =>
                              setActiveSection(event, "store")
                            }
                          >
                            {t("products")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={12} className="mt-5 mb-5">
                      {
                        activeSec === "video" && (
                          // <Tab.Pane eventKey="programs">
                          <ModelVideoSec
                            activeSec={activeSec}
                            setActiveSec={setActiveSec}
                            userPosts={props.userPosts}
                            scrollToTop={scrollToTop}
                            otherUserUniquId={params.username}
                            wallet={props.wallet}
                          />
                        )
                        // </Tab.Pane>
                      }
                      {
                        activeSec === "subscribers" && (
                          // <Tab.Pane eventKey="subscribers">
                          <ModelLiveStreamingSec
                            activeSec={activeSec}
                            setActiveSec={setActiveSec}
                            userDetails={userDetails.data.user}
                            scrollToTop={scrollToTop}
                            otherUserUniquId={params.username}
                          />
                        )
                        // </Tab.Pane>
                      }
                      {
                        activeSec === "virtual-training" && (
                          // <Tab.Pane eventKey="virtual-training">
                          <>
                            <div className="coach-single-profile-live-streaming-sec">
                              <Container>
                                <Row>
                                  <Col md={12}>
                                    <ul className="list-unstyled coach-single-profile-live-streaming">
                                      <Media
                                        as="li"
                                        onClick={() => {
                                          setVideoTraining(true);
                                          redirectToLogin();
                                        }}
                                      >
                                        <Link to="#">
                                          <Image
                                            className="coach-single-live-streaming-icon"
                                            src={
                                              window.location.origin +
                                              "/assets/images/fitness/coach-single-video-white.svg"
                                            }
                                          />
                                          {t("video")}
                                        </Link>
                                      </Media>
                                      <Media
                                        as="li"
                                        onClick={() => {
                                          setAudioTraining(true);
                                          redirectToLogin();
                                        }}
                                      >
                                        <Link to="#">
                                          <Image
                                            className="coach-single-live-streaming-icon"
                                            src={
                                              window.location.origin +
                                              "/assets/images/fitness/coach-single-audio-white.svg"
                                            }
                                          />
                                          {t("call")}
                                        </Link>
                                      </Media>
                                      <Media as="li">
                                        <Link
                                          to="#"
                                          onClick={(event) => {
                                            handleChatUser(
                                              event,
                                              userDetails.data.user.user_id
                                            );
                                            redirectToLogin();
                                          }
                                          }
                                        >
                                          <Image
                                            className="coach-single-live-streaming-icon"
                                            src={
                                              window.location.origin +
                                              "/assets/images/fitness/coach-single-chat-white.svg"
                                            }
                                          />
                                          {t("message")}
                                        </Link>
                                      </Media>
                                    </ul>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                            <div className="coach-single-profile-program-sec">

                            </div>
                          </>
                        )
                        // </Tab.Pane>
                      }
                      {
                        activeSec === "store" && (
                          // <Tab.Pane eventKey="merchandiser">
                          <ModelProductSec
                            activeSec={activeSec}
                            setActiveSec={setActiveSec}
                            // products={props.products}
                            otherUserUniquId={params.username}
                          />
                        )
                        // </Tab.Pane>
                      }
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {localStorage.getItem("userId") ? (
        <>
          {videoTraining && props.profile.data.user_id && (
            <VideoTrainingModal
              videoTraining={videoTraining}
              closeVideoTrainingModal={closeVideoTrainingModal}
              userDetails={userDetails.data.user}
              trainerServices={props.trainerServicesListForOthers}
              profile={props.profile}
            />
          )}

          {audioTraining && props.profile.data.user_id && (
            <AudioTrainingModal
              audioTraining={audioTraining}
              closeAudioTrainingModal={closeAudioTrainingModal}
              userDetails={userDetails.data.user}
              trainerServices={props.trainerServicesListForOthers}
              profile={props.profile}
            />
          )}

          {addWalletAmountModal && (
            <AddWalletTokenModal
              addWalletAmountModal={addWalletAmountModal}
              closeAddWalletAmountModal={closeAddWalletAmountModal}
              trainerId={userDetails.data.user.user_id}
              wallet={props.wallet}
              payments={props.wallet}
            />
          )}
          {sendTip && (
            <SendTipModal
              sendTip={sendTip}
              closeSendTipModal={closeSendTipModal}
              username={userDetails.username}
              userPicture={userDetails.data.user.picture}
              name={userDetails.data.user.usernamee}
              post_id={null}
              user_id={userDetails.data.user.user_id}
              wallet={props.wallet}
            />
          )}
        </>
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  userDetails: state.otherUser.userDetails,
  userPosts: state.otherUser.userPosts,
  products: state.userProducts.otherModelProducts,
  addMoneyInput: state.wallet.addMoneyInput,
  trainerServicesListForOthers: state.users.trainerServicesListForOthers,
  ppvPayStripe: state.post.ppvPayStripe,
  wallet: state.wallet.walletData,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(CoachSingleProfileIndex));
