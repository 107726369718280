import React, { useState, useEffect } from "react";
import { Form, Button, Image, Modal, Tab, Nav, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
  chatAssetPaymentStripeStart,
  chatAssetPaymentPaypalStart,
  chatAssetPaymentWalletStart,
} from "../../store/actions/ChatAssetAction";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";
import { Link } from "react-router-dom";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";

const AssetPaymentModal = (props) => {
  const [paymentType, setPaymentType] = useState(localStorage.getItem("default_payment_method"));

  const [showPayPal, payPal] = useState(false);

  useEffect(() => {
    if (props.paymentData) {
      props.dispatch(fetchWalletDetailsStart({ trainer_id: props.userDetails.user_id ?? 0 }));
    }
  }, [props.paymentData]);

  let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
  let currency = "USD"; // or you can set this value from your props or state

  const client = {
    sandbox: configuration.get("configData.PAYPAL_ID"),
    production: configuration.get("configData.PAYPAL_ID"),
  };

  const choosePaymentOption = (event) => {
    setPaymentType(event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (paymentType === "CARD")
      props.dispatch(
        chatAssetPaymentStripeStart({
          chat_message_id: props.paymentData.chat_message_id,
        })
      );
    if (paymentType === "WALLET")
      props.dispatch(
        chatAssetPaymentWalletStart({
          chat_message_id: props.paymentData.chat_message_id,
        })
      );
    if (paymentType === "PAYPAL") showPayPal(true);

    props.closePaymentModal();
  };

  const paypalOnSuccess = (payment) => {
    console.log(payment);
    setTimeout(() => {
      props.dispatch(
        chatAssetPaymentPaypalStart({
          payment_id: payment.paymentID,
          chat_message_id: props.paymentData.chat_message_id,
        })
      );
    }, 1000);
  };

  const paypalOnError = (err) => {
    const notificationMessage = getErrorNotificationMessage(err);
    this.props.dispatch(createNotification(notificationMessage));
  };

  const paypalOnCancel = (data) => {
    const notificationMessage = getErrorNotificationMessage(
      t("payment_cancelled_note")
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  console.log(props.userDetails);
  return (
    <>
      <Modal
        className="modal-dialog-center "
        size="md"
        centered
        show={props.chatPayment}
        onHide={props.closePaymentModal}
      >
        {props.chatPayment === true ? (
          <>
            <Modal.Header closeButton className="wallet-modal-header">
              <Modal.Title className="add-wallet-header">
                {t("chat_message")}
              </Modal.Title>
            </Modal.Header>
            <div className="payment-tokens">
              <Form onSubmit={handleSubmit}>
                <Modal.Body className="wallet-token-body">

                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey={paymentType}
                  >
                    <Row>
                      <Col sm={12}>
                        <div className="wallet-balance-box">
                          <Form>
                            <div className="wallet-box-card mb-4">
                              <h4>{props.paymentData.amount}</h4>
                            </div>

                            <Tab.Content>
                              {configuration.get(
                                "configData.is_wallet_payment_enabled"
                              ) == 1 ? (
                                <Tab.Pane eventKey="WALLET">
                                  {props.wallet.loading ? (
                                    ""
                                  ) : (
                                    <div className="card-stripe-box">
                                      <div className="wallet-box-card mb-2">
                                        <h4>{t("available")}</h4>
                                        <h3>
                                          {
                                            props.wallet.data
                                              .total_wallet_balance_formatted
                                          }
                                        </h3>
                                      </div>
                                      {props.amount >
                                        props.wallet.data.user_wallet.remaining ? (
                                        <div className="">
                                          <p className="conv-desc desc">
                                            {t(
                                              "low_wallet_balance_tips_payment_para"
                                            )}
                                          </p>
                                          <div className="d-flex">
                                            <Link
                                              to="/wallet"
                                              className="withdraw-money-btn"
                                            >
                                              {t("add_wallet_amount")}
                                            </Link>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  )}
                                </Tab.Pane>
                              ) : null}
                            </Tab.Content>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Modal.Body>
                <Modal.Footer className="wallet-modal-footer payment-post-wallet">
                  <div className="wallet-modal-btn">
                    {paymentType === "PAYPAL" &&
                      props.paymentData.amount_formatted != 0 ? (
                      <PaypalExpressBtn
                        env={env}
                        client={client}
                        currency={currency}
                        total={props.paymentData.amount_formatted}
                        onError={paypalOnError}
                        onSuccess={paypalOnSuccess}
                        onCancel={paypalOnCancel}
                      />
                    ) : null}

                    <Button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={props.closePaymentModal}
                    >
                      {t("cancel")}
                    </Button>
                    {paymentType !== "PAYPAL" ? (
                      <Button
                        type="button"
                        className="btn btn-success"
                        data-dismiss="modal"
                        onClick={handleSubmit}
                        disabled={props.payStripe.buttonDisable}
                      >
                        {props.payStripe.loadingButtonContent !== null
                          ? props.payStripe.loadingButtonContent
                          : t("pay_now")}
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Modal.Footer>
              </Form>
            </div>
          </>
        ) : null}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  payStripe: state.chatAsset.chatAssetPayStripe,
  wallet: state.wallet.walletData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(AssetPaymentModal));
