import {
  FETCH_NOTIFICATIONS_START,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  SEND_PUSH_NOTIFICATION_START,
  SEND_PUSH_NOTIFICATION_SUCCESS,
  SEND_PUSH_NOTIFICATION_FAILURE,
  SEND_EMAIL_NOTIFICATION_START,
  SEND_EMAIL_NOTIFICATION_SUCCESS,
  SEND_EMAIL_NOTIFICATION_FAILURE,
  SEND_NOTIFICATION_START,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  notification: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  notifications: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_START:
      return {
        ...state,
        notification: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notification: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notification: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case SEND_PUSH_NOTIFICATION_START:
      return {
        ...state,
        notification: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case SEND_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: {
          data: action.data,
          loading: false,
          error: false,
        }
      };
    case SEND_PUSH_NOTIFICATION_FAILURE:
      return {
        ...state,
        notification: {
          data: {},
          loading: true,
          error: action.error,
        }
      };
    case SEND_EMAIL_NOTIFICATION_START:
      return {
        ...state,
        notification: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case SEND_EMAIL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: {
          data: action.data,
          loading: false,
          error: false,
        }
      };
    case SEND_EMAIL_NOTIFICATION_FAILURE:
      return {
        ...state,
        notification: {
          data: {},
          loading: true,
          error: action.error
        }
      }
      case SEND_NOTIFICATION_START:
        return {
          ...state,
          notification: {
            data: {},
            loading: true,
            error: false,
          },
        };
      case SEND_NOTIFICATION_SUCCESS:
        return {
          ...state,
          notification: {
            data: action.data,
            loading: false,
            error: false,
          }
        };
      case SEND_NOTIFICATION_FAILURE:
        return {
          ...state,
          notification: {
            data: {},
            loading: true,
            error: action.error,
          }
        };
    default:
      return state;
  }
};

export default NotificationReducer;
