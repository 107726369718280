import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { Link } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Media,
  Image,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  liveViewerUpdateStart,
  liveVideoEndStart,
} from "../../store/actions/LiveVideoAction";
import configuration from "react-global-configuration";
import axios from "axios";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/NotificationMessage";
import { isMobile } from "react-device-detect";

const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
const $ = window.$;
AgoraRTC.setLogLevel(4);

const AgoraLive = (props) => {
  var rtc = {
    // For the local client.
    client: null,
    // For the local audio and video tracks.
    localAudioTrack: null,
    localVideoTrack: null,
  };

  const localTrackState = {
    videoTrackEnabled: true,
    audioTrackEnabled: true,
  };

  var options = {
    // Pass your app ID here.
    appId: configuration.get("configData.agora_app_id"),
    // set UID
    uid: props.isOwner ? 1 : 0,
    // Set the channel name.
    channel: props.liveVideo.data.virtual_id,
    // Pass a token if your project enables the App Certificate.
    token: props.liveVideo.data.agora_token
      ? props.liveVideo.data.agora_token
      : null,
    // Set the user role in the channel. // "audience"
    role: props.isOwner ? "host" : "audience",
  };

  var remoteUsers = {};
  const record = {
    resourceId: "",
    sId: "",
    status: 0,
  };

  async function startBasicCall() {
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    client.setClientRole(options.role);

    rtc.client.on("user-unpublished", async (user, mediaType) => {
      if (mediaType === "video") {
        $("#agora_local").hide();
        $("#agora_profile_placeholder").show();
      }
    });

    rtc.client.on("user-published", async (user, mediaType) => {
      // Subscribe to a remote user.
      await rtc.client.subscribe(user, mediaType);
      console.log("subscribe success");

      // If the subscribed track is video.
      if (mediaType === "video") {
        $("#agora_local").show();
        $("#agora_profile_placeholder").hide();
        // Get `RemoteVideoTrack` in the `user` object.
        const remoteVideoTrack = user.videoTrack;

        remoteVideoTrack.play("agora_local");
        // Or just pass the ID of the DIV container.
        // remoteVideoTrack.play(playerContainer.id);
      }

      props.dispatch(
        liveViewerUpdateStart({
          live_video_id: props.liveVideo.data.live_video_id,
        })
      );

      // If the subscribed track is audio.
      if (mediaType === "audio") {
        // Get `RemoteAudioTrack` in the `user` object.
        const remoteAudioTrack = user.audioTrack;
        // Play the audio track. No need to pass any DOM element.
        remoteAudioTrack.play();
      }
    });

    const uid = await rtc.client.join(
      options.appId,
      options.channel,
      options.token || null,
      options.uid || null
    );

    if (options.role === "host") {
      // Create an audio track from the audio sampled by a microphone.
      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // Create a video track from the video captured by a camera.
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      // Publish the local audio and video tracks to the channel.
      rtc.localVideoTrack.play("agora_local");

      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
    }
  }

  async function leaveCall() {
    if (options.role === "host") {
      // Destroy the local audio and video tracks.
      rtc.localAudioTrack.close();
      rtc.localVideoTrack.close();

      // Traverse all remote users.
      rtc.client.remoteUsers.forEach((user) => {
        // Destroy the dynamically created DIV container.
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });

      let s3_file = "";
      if (record.status == 1) {
        $("#live-video-downloading").show();
        // Customer ID
        var customerKey = configuration.get("configData.agora_customer_key");
        // Customer secret
        var customerSecret = configuration.get(
          "configData.agora_customer_secret"
        );

        // Concatenate customer key and customer secret and use base64 to encode the concatenated string
        let plainCredentials = customerKey + ":" + customerSecret;
        let base64Credentials =
          Buffer.from(plainCredentials).toString("base64");
        // Create authorization header
        let authorizationHeader = "Basic " + base64Credentials;

        var stop_url =
          "https://api.agora.io/v1/apps/" +
          configuration.get("configData.agora_app_id") +
          "/cloud_recording/resourceid/" +
          record.resourceId +
          "/sid/" +
          record.sId +
          "/mode/mix/stop";

        var stop_data = JSON.stringify({
          cname: props.liveVideo.data.virtual_id,
          uid: "12232323",
          clientRequest: {},
        });

        var stop_config = {
          method: "post",
          url: stop_url,
          headers: {
            "Content-Type": "application/json",
            Authorization: authorizationHeader,
          },
          data: stop_data,
        };
        await axios(stop_config)
          .then(function (response) {
            console.log(response.data.serverResponse.fileList[0].fileName);
            s3_file = response.data.serverResponse.fileList[0].fileName;
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      props.dispatch(
        liveVideoEndStart({
          live_video_id: props.liveVideo.data.live_video_id,
          s3_file: s3_file,
        })
      );
    } else {
      // Leave the channel.
      await rtc.client.leave();
      window.location.assign(isMobile ? "/live-videos" : "/w/live-videos");
    }
  }

  async function muteAudio() {
    if (!rtc.localAudioTrack) return;
    if (localTrackState.audioTrackEnabled == true) {
      await rtc.localAudioTrack.setEnabled(false);
      localTrackState.audioTrackEnabled = false;
      $("#mute-audio").hide();
      $("#unmute-audio").show();
    } else {
      await rtc.localAudioTrack.setEnabled(true);
      localTrackState.audioTrackEnabled = true;
      $("#mute-audio").show();
      $("#unmute-audio").hide();
    }
  }

  async function muteVideo() {
    if (!rtc.localVideoTrack) return;
    if (localTrackState.videoTrackEnabled == true) {
      await rtc.localVideoTrack.setEnabled(false);
      localTrackState.videoTrackEnabled = false;
      $("#mute-video").hide();
      $("#unmute-video").show();
      $("#agora_local").hide();
      $("#agora_profile_placeholder").show();
    } else {
      await rtc.localVideoTrack.setEnabled(true);
      localTrackState.videoTrackEnabled = true;
      $("#mute-video").show();
      $("#unmute-video").hide();
      $("#agora_local").show();
      $("#agora_profile_placeholder").hide();
    }
  }

  async function startRecording() {
    var data = JSON.stringify({
      cname: props.liveVideo.data.virtual_id,
      uid: "12232323",
      clientRequest: {
        resourceExpiredHour: 24,
        scene: 0,
      },
    });

    // Customer ID
    var customerKey = configuration.get("configData.agora_customer_key");
    // Customer secret
    var customerSecret = configuration.get("configData.agora_customer_secret");

    // Concatenate customer key and customer secret and use base64 to encode the concatenated string
    let plainCredentials = customerKey + ":" + customerSecret;
    let base64Credentials = Buffer.from(plainCredentials).toString("base64");
    // Create authorization header
    let authorizationHeader = "Basic " + base64Credentials;

    var config = {
      method: "post",
      url:
        "https://api.agora.io/v1/apps/" +
        configuration.get("configData.agora_app_id") +
        "/cloud_recording/acquire",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorizationHeader,
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        record.resourceId = response.data.resourceId;
        console.log(record.resourceId);
      })
      .catch(function (error) {
        console.log(error);
        const notificationMessage = getErrorNotificationMessage(
          "Recording configuration failed!!"
        );
        props.dispatch(createNotification(notificationMessage));
      });

    var start_url =
      "https://api.agora.io/v1/apps/" +
      configuration.get("configData.agora_app_id") +
      "/cloud_recording/resourceid/" +
      record.resourceId +
      "/mode/mix/start";
    var start_data = JSON.stringify({
      cname: props.liveVideo.data.virtual_id,
      uid: "12232323",
      clientRequest: {
        token: props.liveVideo.data.agora_token,
        recordingConfig: {
          maxIdleTime: 30,
          streamTypes: 2,
          audioProfile: 1,
          channelType: 0,
          videoStreamType: 0,
          transcodingConfig: {
            height: 640,
            width: 360,
            bitrate: 500,
            fps: 15,
            mixedVideoLayout: 1,
            backgroundColor: "#FF0000",
          },
          // "subscribeVideoUids":["1"],
          // "subscribeAudioUids":["1"],
          subscribeUidGroup: 0,
        },
        recordingFileConfig: {
          avFileType: ["hls", "mp4"],
        },
        storageConfig: {
          vendor: 1,
          region: parseInt(configuration.get("configData.agora_s3_region")),
          bucket: configuration.get("configData.agora_s3_bucket"),
          accessKey: configuration.get("configData.agora_s3_access_key"),
          secretKey: configuration.get("configData.agora_s3_secret_key"),
          fileNamePrefix: ["directory"],
        },
      },
    });

    var start_config = {
      method: "post",
      url: start_url,
      headers: {
        "Content-Type": "application/json",
        Authorization: authorizationHeader,
      },
      data: start_data,
    };

    await axios(start_config)
      .then(function (response) {
        record.resourceId = response.data.resourceId;
        record.sId = response.data.sid;
        record.status = 1;
        const notificationMessage = getSuccessNotificationMessage(
          "Recording Started!!"
        );
        props.dispatch(createNotification(notificationMessage));
        $("#record-video").hide();
        $("#stoprecord-video").show();
      })
      .catch(function (error) {
        console.log(error);
        const notificationMessage = getErrorNotificationMessage(
          "Recording configuration failed!!"
        );
        props.dispatch(createNotification(notificationMessage));
      });

    // var query_url = "https://api.agora.io/v1/apps/"+configuration.get("configData.agora_app_id")+"/cloud_recording/resourceid/"+record.resourceId+"/sid/"+record.sId+"/mode/mix/query";

    // var query_config = {
    //   method: 'get',
    //   url: query_url,
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': authorizationHeader
    //   },
    // };

    // await axios(query_config)
    // .then(function (response) {

    //   console.log(response);
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
  }

  startBasicCall();

  return (
    <>
      <div className="live-streaming-one-to-one-sec">
        <div className="live-streaming-video-sec">
          <div className="live-streaming-video-bg-sec">
            <div id="agora_local" className="agora-local" />
            <div
              id="agora_profile_placeholder"
              style={{ display: "none" }}
              className="live-video-placeholder"
            >
              <Image
                className="live-streaming-video-bg"
                src={
                  props.liveVideoDetails
                    ? props.liveVideoDetails.user_picture
                    : window.location.origin +
                      "/assets/images/fitness/videocall_bg.jpg"
                }
              />
            </div>
          </div>
          {/* <div className="live-streaming-video-append-sec">
            <div className="live-streaming-video-view-count-sec">
              <i className="far fa-eye"></i>
              <span>{props.liveVideoDetails.viewer_cnt}</span>
            </div>
          </div> */}
          <div className="live-streaming-video-action-btn-sec">
            <ul className="list-unstyled live-streaming-video-action-btn">
              {props.isOwner ? (
                <>
                  <OverlayTrigger
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-mute`}>Mute / Unmute</Tooltip>
                    }
                  >
                    <Media as="li" onClick={() => muteAudio()}>
                      <Link to="#">
                        <i id="mute-audio" className="fa fa-microphone"></i>
                        <i
                          id="unmute-audio"
                          className="fa fa-microphone-slash"
                          style={{ display: "none" }}
                        ></i>
                      </Link>
                    </Media>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement={"top"}
                    overlay={<Tooltip id={`tooltip-mute`}>Call end</Tooltip>}
                  >
                    <Media
                      as="li"
                      className="call-animation-li"
                      onClick={() => leaveCall()}
                    >
                      <Link to="#" className="call-animation">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-phone"
                        >
                          <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                        </svg>
                      </Link>
                    </Media>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-mute`}>Camera On / Off</Tooltip>
                    }
                  >
                    <Media as="li" onClick={() => muteVideo()}>
                      <Link to="#">
                        <i id="mute-video" className="fa fa-video"></i>
                        <i
                          id="unmute-video"
                          className="fa fa-video-slash"
                          style={{ display: "none" }}
                        ></i>
                      </Link>
                    </Media>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-mute`}>Start / Stop</Tooltip>
                    }
                  >
                    <Media as="li" onClick={() => startRecording()}>
                      <Link to="#">
                        <i id="record-video" class="fa fa-stop-circle"></i>
                        <i
                          id="stoprecord-video"
                          className="fa fa-stop-circle record-end"
                          style={{ display: "none" }}
                        ></i>
                      </Link>
                    </Media>
                  </OverlayTrigger>
                </>
              ) : (
                <Media
                  as="li"
                  className="call-animation-li"
                  onClick={() => leaveCall()}
                >
                  <Link to="#" className="call-animation">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-phone"
                    >
                      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                    </svg>
                  </Link>
                </Media>
              )}
            </ul>
          </div>
          <div
            id="live-video-downloading"
            className="live-video-downloading-sec"
            style={{ display: "none" }}
          >
            <Image
              className="live-video-downloading"
              src={
                window.location.origin +
                "/assets/images/fitness/live-video-download.gif"
              }
            />
          </div>
        </div>
      </div>

      {/* <div className="agora-card">
            <div id="agora_local" style={{ width: "100%", height: "500px" }} />
            <div className="button-group mt-3 mb-3">
              
              {props.isOwner ? 
              <>
                <div className="live-action-icon-sec">
                  <ul className="list-unstyled live-action-flex">
                    <Media as="li">
                      <Link to="#" 
                        onClick={() =>
                          leaveCall()
                      }>
                        <Image
                          src={
                            window.location.origin + "/assets/images/icons/end-stream.png"
                          }
                          className="action-live-icon"
                        />
                      </Link>
                    </Media>
                    <Media as="li" id="mute-audio">
                      <Link to="#"
                      onClick={() =>
                        muteAudio()
                      }>
                        <Image
                          src={
                            window.location.origin + "/assets/images/icons/audio.png"
                          }
                          className="action-live-icon"
                        />
                      </Link>
                    </Media>
                    <Media as="li" id="unmute-audio" style={{display: "none"}}>
                      <Link to="#"
                      onClick={() =>
                        muteAudio()
                      }>
                        <Image
                          src={
                            window.location.origin + "/assets/images/icons/no-audio.png"
                          }
                          className="action-live-icon"
                        />
                      </Link>
                    </Media>
                    <Media as="li" id="mute-video">
                      <Link to="#"
                      onClick={() =>
                        muteVideo()
                      }>
                        <Image
                          src={
                            window.location.origin + "/assets/images/icons/video.png"
                          }
                          className="action-live-icon"
                        />
                      </Link>
                    </Media>
                    <Media as="li" id="unmute-video" style={{display: "none"}}>
                      <Link to="#"
                      onClick={() =>
                        muteVideo()
                      }>
                        <Image
                          src={
                            window.location.origin + "/assets/images/icons/mute-video.png"
                          }
                          className="action-live-icon"
                        />
                      </Link>
                    </Media>
                  </ul>
                </div>
              </>
                :
                <div className="live-action-icon-sec">
                  <ul className="list-unstyled live-action-flex">
                    <Media as="li">
                      <Link to="#" 
                        onClick={() =>
                          leaveCall()
                      }>
                        <Image
                          src={
                            window.location.origin + "/assets/images/icons/end-stream.png"
                          }
                          className="action-live-icon"
                        />
                      </Link>
                    </Media>
                  </ul>
                </div>
              }
              
              
            </div>
        </div> */}
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideo: state.liveVideo.singleLiveVideo,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(AgoraLive));
