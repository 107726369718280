import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { getBankAccountStart } from "../../store/actions/BankAccountAction";
import { sendWithDrawRequestStart } from "../../store/actions/WithDrawAction";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";

const WithdrawModel = (props) => {
  useEffect(() => {
    props.dispatch(getBankAccountStart());
    props.dispatch(fetchUserDetailsStart());
  }, []);

  const [inputData, setInputData] = useState({ select_paypal_email: 0 });

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(sendWithDrawRequestStart(inputData));
    props.closeWithdrawModal();
    setInputData({})
  };

  const closeWithdrawModal = (event) => {
    event.preventDefault();
    props.closeWithdrawModal();
    setInputData({})
  };


  return (
    <>
      <Modal
        className="modal-dialog-center"
        size="md"
        centered
        show={props.withdrawModal}
        onHide={props.closeWithdrawModal}
      >
        {props.withdrawModal === true ?
          <>
            <Modal.Header closeButton className="wallet-modal-header">
              <Modal.Title className="add-wallet-header">
                {t("send_request_to_admin")}
              </Modal.Title>
            </Modal.Header>
            <div className="payment-tokens">
              <Form onSubmit={handleSubmit}>
                <Modal.Body className="wallet-token-body add-new-wallet">
                  <div className="wallet-token-body-input">
                    {props.payments.loading ? (
                      t("loading")
                    ) : (
                      <Row>
                        <Col md="12">
                          <div className="mb-5">
                            {configuration.get("configData.is_only_wallet_payment") == 1 ?
                              <h4 className="mb-2">
                                {t("min_token_required")}:{" "}
                                <span>
                                  {
                                    props.payments.data
                                      .user_withdrawals_min_amount_formatted
                                  }
                                </span>
                              </h4>
                              :
                              <h4 className="mb-2">
                                {t("min_amount_required")}:{" "}
                                <span>
                                  {
                                    props.payments.data
                                      .user_withdrawals_min_amount_formatted
                                  }
                                </span>
                              </h4>
                            }
                            <h4>
                              {t("wallet_balance")}:{" "}
                              <span>
                                {props.payments.data.total_wallet_balance_formatted}
                              </span>
                            </h4>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <div className="floating-form">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="number"
                          placeholder="Enter Tokens"
                          min="1"
                          step="any"
                          value={inputData.requested_amount}
                          onChange={(event) =>
                            setInputData({
                              ...inputData,
                              requested_amount: event.currentTarget.value,
                            })
                          }
                        />
                      </Form.Group>

                      {props.profile.loading ? '' :
                        <div className="floating-form mt-3 mb-2 ">
                          <Form.Check
                            inline
                            type="checkbox"
                            id="customControlAutosizing"
                            custom
                            name="select_paypal_email"
                            required="required"
                            checked={inputData.select_paypal_email}
                            className="check-terms"
                            onChange={(event) =>
                              setInputData({
                                ...inputData,
                                select_paypal_email: !inputData.select_paypal_email,
                              })
                            }
                          />
                          <Form.Label>
                            {t("paypal_email")} - {props.profile.data.paypal_email}
                          </Form.Label>
                        </div>
                      }
                      <h6>Note: Kindly confirm your PayPal ID before placing the withdrawal request. This change cannot be undone.</h6>
                      {/* <div className="floating-label">
                <label className="label-default-1">{t("choose_bank_account")}</label>
                  <Form>
                    {["radio"].map((type) => (
                      <div key={`custom-inline-${type}`}>
                        {props.bankAccount.loading ? (
                          t("loading")
                        ) : props.bankAccount.data.billing_accounts.length > 0 ? (
                          props.bankAccount.data.billing_accounts.map((account) => (
                              <Form.Check
                                custom
                                inline
                                label={account.first_name}
                                type={type}
                                id={account.user_billing_account_id}
                                value={account.user_billing_account_id}
                                name="user_billing_account_id"
                                onChange={(event) =>
                                  setInputData({
                                    ...inputData,
                                    user_billing_account_id:
                                      account.user_billing_account_id,
                                  })
                                }
                              />
                          ))
                        ) : (
                          <h4>
                            {t("no_bank_accounts_added")}. {t("to_add_account")}{" "}
                            <Link className="text-sm" to={`/add-bank`}>
                              {t("click_here")}
                            </Link>
                          </h4>
                        )}
                      </div>
                    ))}
                </Form>
              </div> */}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="wallet-modal-footer payment-post-wallet">
                  <div className="wallet-modal-btn">
                    <Button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={closeWithdrawModal}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-success"
                      data-dismiss="modal"
                      onClick={handleSubmit}
                      disabled={props.sendWithDraw.buttonDisable ||
                        !inputData.select_paypal_email}
                    >
                      {props.sendWithDraw.loadingButtonContent !== null
                        ? props.sendWithDraw.loadingButtonContent
                        : t("send_request")}
                    </Button>
                  </div>
                </Modal.Footer>

              </Form>
            </div>
          </>
          : null}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  sendWithDraw: state.withDraw.sendWithDraw,
  bankAccount: state.bankAccount.bankAccount,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(WithdrawModel));
