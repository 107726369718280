import React, { useState, useEffect } from "react";
import { Modal, Form, InputGroup, Image, Button } from "react-bootstrap";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import {
  addMoneyViaCardStart,
  addMoneyViaPaypalStart,
  addMoneyViaPayfastStart,
} from "../../store/actions/WalletAction";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import { translate, t } from "react-multi-lang";

const AddWalletTokenModal = (props) => {
  const [paymentType, setPaymentType] = useState("paypal");

  const [amount, setAmount] = useState(1);

  const handleChangeAmount = (amount) => {
    setAmount(amount);
  };

  const [inputData, setInputData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    if (paymentType === "payfast")
      props.dispatch(
        addMoneyViaPayfastStart({
          trainer_id: props.trainerId,
          amount: amount,
        })
      );
    props.closeAddWalletAmountModal();
    setAmount(0);
  };

  const paypalOnSuccess = (payment) => {
    setTimeout(() => {
      props.dispatch(
        addMoneyViaPaypalStart({
          payment_id: payment.paymentID,
          trainer_id: props.trainerId,
          amount: amount,
        })
      );
    }, 1000);
    props.closeAddWalletAmountModal();
  };

  const paypalOnError = (err) => {
    const notificationMessage = getErrorNotificationMessage(err);
    this.props.dispatch(createNotification(notificationMessage));
  };

  const paypalOnCancel = (data) => {
    const notificationMessage = getErrorNotificationMessage(
      t("payment_cancelled_note")
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  const choosePaymentOption = (event) => {
    setPaymentType(event);
  };

  let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
  let currency = "USD"; // or you can set this value from your props or state

  const client = {
    sandbox: configuration.get("configData.PAYPAL_ID"),
    production: configuration.get("configData.PAYPAL_ID"),
  };

  return (
    <>
      <Modal
        className="modal-dialog-center "
        size="md"
        centered
        show={props.addWalletAmountModal}
        onHide={props.closeAddWalletAmountModal}
      >
        {props.addWalletAmountModal === true ? (
          <>
            <Modal.Header closeButton className="wallet-modal-header">
              <Modal.Title className="add-wallet-header">
                <span>
                  <div className="wallet-add-icon">+</div>
                </span>
                {t("add_wallet_token")}
              </Modal.Title>
            </Modal.Header>
            <Form className="payment-tokens">
              <Modal.Body className="wallet-token-body add-new-wallet">
                <div className="wallet-token-body-input">
                  {props.payments.loading ? (
                    t("loading")
                  ) : (
                    <h4>
                      {t("wallet_balance")}:{" "}
                      <span>
                        {props.payments.data.user_wallet
                          ? props.payments.data.user_wallet.remaining_formatted
                          : ""}
                      </span>
                    </h4>
                  )}
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="number"
                      placeholder="Enter Tokens"
                      min="1"
                      step="any"
                      value={amount}
                      onChange={(event) =>
                        handleChangeAmount(event.currentTarget.value)
                      }
                    />
                  </Form.Group>
                  {configuration.get("configData.is_only_wallet_payment") ? (
                    <div className="floating-label">
                      <h5>Min Purchase Token {configuration.get("configData.min_purchase_token")}</h5>
                      <h4 className="label-default-1">
                        {t("token_amount")} (
                        {configuration.get("configData.token_amount")} *{" "}
                        {amount}) = {/* <span> */}
                        {configuration.get("configData.token_amount") *
                          amount}{" "}
                        {configuration.get("configData.currency")}
                        {/* </span> */}
                      </h4>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className="floating-label">
                    <h4 className="label-default-1">
                      {t("currency_amount")}(
                      {props.payments.data.currency_value} * {amount}) =
                      <span>
                        {props.payments.data.currency_value * amount}{" "}
                        {props.payments.data.currency_symbol}
                      </span>
                    </h4>
                  </div> */}

                  <div className="floating-label">
                    <h4 className="label-default-1">
                      {t("currency_value")} ={" "}
                      <span>
                        {props.payments.data.currency_symbol}{" "}
                        {(configuration.get("configData.token_amount") *
                          amount *
                          props.payments.data.currency_value).toFixed(2)}
                      </span>
                    </h4>
                  </div>
                  <div className="payment-method">
                    <h4>Payment Type</h4>
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label={t("paypal")}
                      id="paypal"
                      value="paypal"
                      name="payment_type"
                      defaultChecked={paymentType == "paypal" ? true : false}
                      onChange={() => setPaymentType("paypal")}
                    />
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer className="wallet-modal-footer payment-post-wallet">
                <div className="wallet-modal-btn">
                  {/* <Button
                  type="button"
                  className="btn checkout-btn"
                  data-dismiss="modal"
                >
                  <span>
                    <Image
                      src={window.location.origin + "/assets/images/paypal.svg"}
                    />
                  </span>
                  checkout
                </Button> */}
                  {paymentType === "paypal" && amount >= parseInt(configuration.get("configData.min_purchase_token")) ? (
                    <PaypalExpressBtn
                      env={env}
                      client={client}
                      currency={currency}
                      total={
                        configuration.get("configData.is_only_wallet_payment")
                          ? configuration.get("configData.token_amount") *
                          amount
                          : amount
                      }
                      onError={paypalOnError}
                      className="btn checkout-btn"
                      onSuccess={paypalOnSuccess}
                      onCancel={paypalOnCancel}
                    />
                  ) : null}

                  <Button
                    type="button"
                    className="btn btn-cancel"
                    data-dismiss="modal"
                    onClick={props.closeAddWalletAmountModal}
                  >
                    {t("cancel")}
                  </Button>
                  {paymentType !== "paypal" ? (
                    <Button
                      type="button"
                      className="btn btn-success"
                      data-dismiss="modal"
                      onClick={handleSubmit}
                    >
                      {props.addAmount.loadingButtonContent != null
                        ? props.addAmount.loadingButtonContent
                        : t("submit")}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </Modal.Footer>
            </Form>
          </>
        ) : null}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  //payments: state.wallet.walletData,
  addAmount: state.wallet.addMoneyInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(AddWalletTokenModal));
