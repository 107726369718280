import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Image, Media } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../NoDataFound/NoDataFound";
import { connect } from "react-redux";

import {
  fetchMoreSingleUserPostsStart,
  fetchSingleUserPostsStart,
} from "../../store/actions/OtherUserAction";
import InfiniteScroll from "react-infinite-scroll-component";
import PostLoader from "../Loader/PostLoader";
import { useHistory } from "react-router";
import ModelVideoCard from './ModelVideoCard';

const ModelVideoSec = (props) => {

  const [take, setTake] = useState(12);
  const history = useHistory();

  useEffect(() => {
    props.dispatch(
      fetchSingleUserPostsStart({
        user_unique_id: props.otherUserUniquId,
        type: "video",
        skip: 0,
        take: take,
      })
    );
  }, []);

  const fetchMoreUserPosts = () => {
    console.log("Called from my function");
    props.dispatch(fetchMoreSingleUserPostsStart({
      user_unique_id: props.otherUserUniquId,
      type: "video",
      skip: props.userPosts.data.posts.length,
      take: take,
    }))
  }




  return (
    <>
      <div className="coach-single-profile-program-sec">
        {props.userPosts.loading ? (
          <PostLoader />
        ) : props.userPosts.data.posts.length > 0 ? (
          <InfiniteScroll
            dataLength={props.userPosts.data.posts.length}
            next={fetchMoreUserPosts}
            hasMore={props.userPosts.data.posts.length < props.userPosts.data.total &&
              props.userPosts.errorCount < 2}
            loader={<PostLoader />}
          >
            <div className="coach-single-profile-subscribers-box" id={props.enableEditOption}>
              {props.userPosts.data.posts.map((post, mainIndex) =>
                post.postFiles.length > 0 ?
                  post.postFiles.map((p_file,index) => (
                    <ModelVideoCard 
                      post={post} 
                      key={index} 
                      userDetails={props.userDetails} 
                      p_file={p_file} 
                      wallet={props.wallet}
                      enableEditOption={props.enableEditOption}
                    />
                  ))
                  : "")
              }
          
            </div>
          </InfiniteScroll>
        ) : (
          <NoDataFound />
        )}
        {/* {props.noMoreData !== true ? (
            <>{props.isFetching && "Fetching more list items..."}</>
          ) : (
            t("no_more_data")
          )} */}
      </div>


    </>
  );
};

const mapStateToPros = (state) => ({
  userDetails: state.otherUser.userDetails,
  userPosts: state.otherUser.userPosts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ModelVideoSec));

