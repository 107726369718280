import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import {
    Form,
    Button,
    Image,
    Modal,
    Tab,
    Nav,
    Row,
    Col,
} from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import {
    saveTrainerServicesStart,
    fetchtTrainerServiceListStart
} from '../../store/actions/UserAction';

const AddServiceModal = (props) => {

    const serviceRef = useRef(null);

    const [service, setService] = useState({
        service: "",
        serviceMsg: "",
    });

    useEffect(() => {
        setService({ service: props.selectedService ? props.selectedService.service : "" });
    }, [props.selectedService]);


    const [skip, setSkip] = useState(true);

    const saveService = e => {
        e.preventDefault();
        if (service.service && service.service.trim() === service.service) {
            let data = { service: service.service };
            if (props.selectedService) {
                data = {
                    ...data,
                    trainer_service_id: props.selectedService.trainer_service_id
                }
            }
            props.dispatch(saveTrainerServicesStart(data))
        } else {
            setService({
                ...service,
                serviceMsg: t("please_enter_the_service_name")
            })
        }
    }

    useEffect(() => {
        if (!skip) {
            if (!props.saveTrainerServices.loading && Object.keys(props.saveTrainerServices.data).length > 0) {
                setSkip(true);
                setService({});
                props.dispatch(fetchtTrainerServiceListStart());
                props.closeServiceModal();
            }
        } else {
            setSkip(false);
        }
    }, [props.saveTrainerServices]);

    useEffect(() => {
        if (props.showModal) {
            serviceRef.current.focus();
        }
    }, [props.showModal])

    return (
        <>
            <Modal
                centered
                size="md"
                className="modal-dialog-center sent-tip-modal"
                show={props.showModal}
                onHide={props.closeServiceModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.selectedService ? t("edit_services") : t("add_services")}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={saveService} >
                    <Modal.Body className="subscription-tip-ppv-tab">
                        <div className="floating-form">
                            <div className="floating-label">
                                <input
                                    ref={serviceRef}
                                    className="floating-input"
                                    type="text"
                                    placeholder={t("enter_the_service")}
                                    value={service.service}
                                    onChange={(event) => setService({ service: event.currentTarget.value })}
                                />
                                <span className="highlight"></span>
                                <label className="default-label">{t("service")}</label>
                            </div>
                            <div className="float-right text-danger">{service.serviceMsg}</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type="button"
                            className="btn btn-danger"
                            data-dismiss="modal"
                            onClick={props.closeServiceModal}
                        >
                            {t("cancel")}
                        </Button>
                        <Button
                            type="submit"
                            className="btn btn-success"
                            data-dismiss="modal"
                            disabled={props.saveTrainerServices.buttonDisable}
                        >
                            {props.saveTrainerServices.loadingButtonContent != null
                                ? props.saveTrainerServices.loadingButtonContent
                                : t("save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

const mapStateToPros = (state) => ({
    saveTrainerServices: state.users.saveTrainerServices,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(AddServiceModal));