import React, { useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image, InputGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Auth.css";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import {
    forgotPasswordStart,
} from "../../store/actions/UserAction";

const TrainerForgotPasswordIndex = (props) => {

    const [forgotPasswordInputData, setForgotPasswordInputData] = useState({});

    const handleForgotPassword = (event) => {
        event.preventDefault();
        props.dispatch(forgotPasswordStart(forgotPasswordInputData));
    };

    return (
        <>
            <div className="login-43">
                <Container>
                    <Row>
                        <Col lg={5} md={12} className="bg-img none-992">
                                {/* <div className="social-list">
                                    <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                                    <Link to="#"><i className="fab fa-google-plus-g"></i></Link>
                                </div> */}
                        </Col>
                        <Col lg={7} md={12} className="bg-color-8 align-self-center">
                            <div className="info">
                                <div className="logo clearfix">
                                    <Link to="#" className="coach-logo">
                                        <Image
                                            src={
                                                window.location.origin + "/assets/images/fitness/coach-logo-white.png"
                                            }
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="form-section">
                                <h3 className="trainer-head-title">Forgot Password</h3>
                                <div className="login-inner-form">
                                    <div className="trainer-form">
                                        <Form className="trainer-forgot-password-form" onSubmit={handleForgotPassword}>
                                            <InputGroup className="mb-3">
                                                <FormControl
                                                    placeholder="Email Id"
                                                    aria-label="Email Id"
                                                    aria-describedby="basic-addon2"
                                                    value={forgotPasswordInputData.email}
                                                    name="email"
                                                    onChange={(event) =>
                                                        setForgotPasswordInputData({
                                                            ...forgotPasswordInputData,
                                                            email: event.currentTarget.value,
                                                        })
                                                    }
                                                />
                                                <InputGroup.Text id="basic-addon2"><i className="fas fa-envelope"></i></InputGroup.Text>
                                            </InputGroup>
                                            <Form.Group>
                                                <p className="text mb-5">Return to?<Link to="/trainer-login" className="ml-2">Log in</Link></p>
                                            </Form.Group>
                                            <Form.Group>
                                                <Button
                                                    type="submit"
                                                    className="btn-md btn-theme w-100"
                                                    disabled={props.forgotPassword.buttonDisable}>{props.forgotPassword.loadingButtonContent !==
                                                        null
                                                        ? props.forgotPassword.loadingButtonContent
                                                        : "Reset"}</Button>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(TrainerForgotPasswordIndex));
