import React from 'react';
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { Container, Col, Form, Media, Image, Row } from "react-bootstrap";
import NoDataFound from "../NoDataFound/NoDataFound";
import CoachTransactionProfileCard from "../Home/CoachTransactionProfileCard";
import { fetchMoreFollowingStart } from '../../store/actions/FollowAction';
import InfiniteScroll from 'react-infinite-scroll-component';
import HomeLoader from '../Loader/HomeLoader';

const TransactionList = (props) => {

    const fetchMoreFollowings = () => {
        props.dispatch(fetchMoreFollowingStart({
            skip: props.following.data.followers.length,
            take: 12,
        }));
    }

    return (<div className="home-screen home-sec">
        <Container>
            <h3 className='search-button'>{t("token_transactions")}</h3>
            <div className="coach-home-profile-card-sec">

                {props.following.loading ? (
                    <div className="home-profile-grid">
                        <HomeLoader />
                    </div>
                ) : props.following.data.followers.length > 0 ? (
                    <InfiniteScroll
                        dataLength={props.following.data.followers.length}
                        next={fetchMoreFollowings}
                        hasMore={props.following.data.followers.length < props.following.data.total &&
                            props.following.errorCount < 2}
                        loader={<div className="home-profile-grid mt-5">
                            <HomeLoader />
                        </div>}
                    >
                        <div className="home-profile-grid">
                            {props.following.data.followers.map((follower, index) =>
                                <CoachTransactionProfileCard
                                    user={follower}
                                    key={index}
                                    index={index}
                                />
                            )}
                        </div>
                    </InfiniteScroll>
                ) : (
                    <NoDataFound />
                )}
            </div >
        </Container >
    </div >)
}

const mapStateToPros = (state) => ({
    following: state.follow.following,
})

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(TransactionList));