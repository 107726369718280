import {
    FETCH_TRAINERS_AVAILABILITY_START,
    FETCH_TRAINERS_AVAILABILITY_SUCCESS,
    FETCH_TRAINERS_AVAILABILITY_FAILURE,
    FETCH_TRAINERS_AVAILABILITY_USER_START,
    FETCH_TRAINERS_AVAILABILITY_USER_SUCCESS,
    FETCH_TRAINERS_AVAILABILITY_USER_FAILURE,
    DELETE_TRAINERS_AVAILABILITY_START,
    DELETE_TRAINERS_AVAILABILITY_SUCCESS,
    DELETE_TRAINERS_AVAILABILITY_FAILURE,
    SAVE_TRAINERS_AVAILABILITY_START,
    SAVE_TRAINERS_AVAILABILITY_SUCCESS,
    SAVE_TRAINERS_AVAILABILITY_FAILURE,
} from '../actions/ActionConstant'

const initialState = {
    availability: {
        data: {},
        loading: true,
        error: false,
    },
    trainerAvailability: {
        data: {},
        loading: true,
        error: false,
    },
    saveAvailability: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false
    },
    availabilityDelete: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false
    },
}

const AvailabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TRAINERS_AVAILABILITY_START:
            return {
                ...state,
                availability: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_TRAINERS_AVAILABILITY_SUCCESS:
            return {
                ...state,
                availability: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_TRAINERS_AVAILABILITY_FAILURE:
            return {
                ...state,
                availability: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case FETCH_TRAINERS_AVAILABILITY_USER_START:
            return {
                ...state,
                trainerAvailability: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_TRAINERS_AVAILABILITY_USER_SUCCESS:
            return {
                ...state,
                trainerAvailability: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_TRAINERS_AVAILABILITY_USER_FAILURE:
            return {
                ...state,
                trainerAvailability: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case SAVE_TRAINERS_AVAILABILITY_START:
            return {
                ...state,
                saveAvailability: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };
        case SAVE_TRAINERS_AVAILABILITY_SUCCESS:
            return {
                ...state,
                saveAvailability: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case SAVE_TRAINERS_AVAILABILITY_FAILURE:
            return {
                ...state,
                saveAvailability: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case DELETE_TRAINERS_AVAILABILITY_START:
            return {
                ...state,
                availabilityDelete: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };
        case DELETE_TRAINERS_AVAILABILITY_SUCCESS:
            return {
                ...state,
                availabilityDelete: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case DELETE_TRAINERS_AVAILABILITY_FAILURE:
            return {
                ...state,
                availabilityDelete: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        
        default:
            return state;
    }
}

export default AvailabilityReducer;