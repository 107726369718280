import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Table, Badge } from "react-bootstrap";
import "../Wallet/Wallet.css";
import {
  fetchLiveVideosHistoryStart,
  fetchMoreLiveVideosHistoryStart,
  deleteLiveVideosStart,
} from "../../store/actions/LiveVideoAction";
import { Link } from "react-router-dom";
import NoDataFound from "../NoDataFound/NoDataFound";
import BillingAccountLoader from "../Loader/BillingAccountLoader";
import { translate, t } from "react-multi-lang";
import GoLiveModal from "../helper/GoLiveModal";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile } from "react-device-detect";

const LiveVideosIndex = (props) => {
  const [goLive, setGoLive] = useState(false);

  useEffect(() => {
    if (props.userDetails.loading) props.dispatch(fetchUserDetailsStart());
    props.dispatch(
      fetchLiveVideosHistoryStart({
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMore = () => {
    props.dispatch(
      fetchMoreLiveVideosHistoryStart({
        skip: props.liveVideos.data.videos.length,
        take: 12,
      })
    );
  };
  console.log(props.liveVideos);

  const closeGoLiveModal = () => {
    setGoLive(false);
  };

  const handleDelete = (event, live_video_id) => {
    event.preventDefault();
    props.dispatch(deleteLiveVideosStart({ live_video_id: live_video_id }));
  };

  return (
    <>
      <div className="wallet-sec live-video-list-header-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div className="wallet-header-sec">
                <Row>
                  <Col sm={12} md={12} xl={8}>
                    <Link
                      className="bookmarkes-list notify-title back-button"
                      onClick={() => props.history.goBack()}
                    >
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/icons/back-white.svg"
                        }
                        className="svg-clone"
                      />
                      <h3 className="ml-2 mb-0">{t("live_history")}</h3>
                    </Link>
                    <h3></h3>
                  </Col>
                  <Col sm={12} md={12} xl={4} className="align-right">
                    <div className="resp-schedule-btn-sec">

                     
                      <Link
                        className="go-live-btn mr-3"
                        to={isMobile ? "/scheduled-live-videos" : "/w/scheduled-live-videos"}
                      >
                        {t("scheduled_videos")}
                      </Link>
                      <Link
                        className="live-history-btn mr-2 mb-2"
                        onClick={() => setGoLive(true)}
                      >
                        {t("schedule_live")}
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="trans-table-sec">
        <Container>
          {props.liveVideos.loading ? (
            <BillingAccountLoader />
          ) : props.liveVideos.data.videos &&
            props.liveVideos.data.videos.length > 0 ? (
            <InfiniteScroll
              dataLength={props.liveVideos.data.videos.length}
              next={fetchMore}
              hasMore={
                props.liveVideos.data.videos.length <
                  props.liveVideos.data.total && props.liveVideos.errorCount < 2
              }
              loader={<BillingAccountLoader />}
            >
              <Row>
                <Col sm={12} md={12}>
                  <div className="trans-table">
                    <Table borderedless responsive>
                      <thead>
                        <tr className="bg-white text-muted text-center">
                          <th>{t("action")}</th>
                          <th>{t("scheduled_at")}</th>
                          <th>{t("title")}</th>
                          <th>{t("revenue")}</th>
                          <th>{t("amount")}</th>
                          <th>{t("status")}</th>
                          {/* <th>{t("view_count")}</th>                           */}
                        </tr>
                      </thead>
                      <tbody>
                        {props.liveVideos.data.videos.map((videos) => (
                          <tr key={videos.live_video_unique_id}>
                            <td>
                              {videos.status == 2 ? (
                                <>
                                <div className="live-video-btn-action-sec">
                                    <Link
                                      to={`/edit-live-video/${videos.live_video_unique_id}`}
                                      className="live-end-btn-blue"
                                    >
                                      {t("edit")}
                                    </Link>
                                    <Button
                                      onClick={(event) => {
                                        if (
                                          window.confirm(
                                            t("live_delete_confirmation")
                                          )
                                        ) {
                                          handleDelete(
                                            event,
                                            videos.live_video_id
                                          );
                                        }
                                      }}
                                      className="live-end-btn-blue"
                                    >
                                      {t("delete")}
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>{videos.formatted_schedule_time}</td>
                            <td>{videos.title}</td>
                            <td>{videos.user_amount_formatted}</td>
                            <td>{videos.amount_formatted}</td>
                            <td>{videos.status_formatted}</td>
                            {/* <td>{videos.viewer_cnt}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </InfiniteScroll>
          ) : (
            <NoDataFound />
          )}
        </Container>
        {props.userDetails.loading ? (
          t("loading")
        ) : goLive ? (
          <GoLiveModal
            goLive={goLive}
            closeGoLiveModal={closeGoLiveModal}
            // username={props.userDetails.data.username}
            // userPicture={props.userDetails.data.picture}
            // name={props.userDetails.data.name}
            // user_id={props.userDetails.data.user_id}
            timezone={props.userDetails.data.timezone}
          />
        ) : null}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideos: state.liveVideo.liveVideosHistory,
  userDetails: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LiveVideosIndex));
