import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Image, Media } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../NoDataFound/NoDataFound";
import "../Ecom/Ecom.css";
import SingleDisplayCard from "../Ecom/Product/SingleDisplayCard";
import {
  fetchOtherModelProductListStart,
  fetchMoreOtherModelProductListStart,
} from "../../store/actions/ProductsAction";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import ProductLoader from "../Loader/ProductLoader";

const ModelProductSec = (props) => {

  const [take, setTake] = useState(12);

  useEffect(() => {
    props.dispatch(
      fetchOtherModelProductListStart({
        user_unique_id: props.otherUserUniquId,
        skip: 0,
        take: take,
      })
    );
  }, []);

  const fetchMoreProducts = () => {
    props.dispatch(
      fetchMoreOtherModelProductListStart({
        user_unique_id: props.otherUserUniquId,
        skip: props.products.data.user_products.length,
        take: take,
      })
    );
  }

  return (
    <>
      {props.products && props.products.loading ? (
        <ProductLoader />
      ) : props.products.data.user_products && props.products.data.user_products.length > 0 ? (
        <InfiniteScroll
          dataLength={props.products.data.user_products.length}
          next={fetchMoreProducts}
          hasMore={props.products.data.user_products.length < props.products.data.total &&
            props.products.errorCount < 2}
          loader={<ProductLoader />}
        >
          <div className="ecom-featured-box ecom-features-box-1">
            {props.products.data.user_products.map((product, index) =>
              <SingleDisplayCard key={index} product={product} type="userProfile" otherUserUniquId={props.otherUserUniquId}></SingleDisplayCard>
            )}
          </div>
        </InfiniteScroll>
      ) : (
        <NoDataFound />
      )}
      {/* <div className="merchandiser-card">
                <div className="merchandiser-img-sec">
                    <Link to="#">
                        <Image
                            className="merchandiser-img"
                            src={
                                window.location.origin + "/assets/images/fitness/store-3.jpg"
                            }
                        />
                    </Link>
                </div>
                <div className="merchandiser-info">
                    <Link to="#" className="product-title">
                        <h3>Stretch Band</h3>
                    </Link>
                    <div className="rating-star-card">
                        <ul className="rating-star-sec">
                            <Media as="li">
                                <i className="fas fa-star"></i>
                            </Media>
                            <Media as="li">
                                <i className="fas fa-star"></i>
                            </Media>
                            <Media as="li">
                                <i className="fas fa-star"></i>
                            </Media>
                            <Media as="li">
                                <i className="fas fa-star"></i>
                            </Media>
                            <Media as="li">
                                <i className="fas fa-star"></i>
                            </Media>
                        </ul>
                    </div>
                    <div className="merchandiser-price-sec">
                        <ins><span>$15.00</span></ins>
                    </div>
                </div>
            </div> */}
    </>
  );
};

const mapStateToPros = (state) => ({
  products: state.userProducts.otherModelProducts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ModelProductSec);

