import React, { Component, useState } from "react";
import { Modal, Container, Row, Col, Media, Image, Nav, Tab, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import VideoTrainingModal from "./VideoTrainingModal";
import { translate, t } from "react-multi-lang";

const CoachSingleProfileIndex = (props) => {

    const [show, toggleShow] = React.useState(false);

    const [showBio, setShowBio] = React.useState(false);

    const [showContents, setShowContents] = React.useState(false);


    const [showDesc, setShowDesc] = React.useState(false);

    const [videoTraining, setVideoTraining] = useState(false);

    const closeVideoTrainingModal = () => {
        setVideoTraining(false);
    };


    return (
        <>
            <div className="coach-single-profile-sec">
                <div className="coach-single-cover-bg" style={{ backgroundImage: `url("/assets/images/fitness/single-profile-banner-img.jpg")` }}>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="coach-single-logo-status">
                                    <div className="coach-single-logo-sec">
                                        <Link to="#">
                                            <Image
                                                className="coach-single-logo"
                                                src={
                                                    window.location.origin + "/assets/images/fitness/coach-logo-white.png"
                                                }
                                            />
                                        </Link>
                                    </div>
                                    <div className="coach-single-status">
                                        <div className="coach-single-live-status">
                                            {t("live")}
                                        </div>
                                        {/* <div className="coach-single-offline-status">
                                            Offline
                                        </div> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="coach-single-profile-info-header-sec">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="coach-single-profile-info-header">
                                    <div className="coach-profile-verified-icon-sec">
                                        <Image
                                            className="coach-profile-verified-icon"
                                            src={
                                                window.location.origin + "/assets/images/fitness/user-verified-icon.png"
                                            }
                                        />
                                    </div>
                                    <div className="coach-profile-img-sec">
                                        <Image
                                            className="coach-profile-img"
                                            src={
                                                window.location.origin + "/assets/images/fitness/profile-img.jpg"
                                            }
                                        />
                                    </div>
                                    <div className="coach-profile-token-count-sec">
                                       {t("token")} -
                                        <span>686</span>
                                    </div>
                                </div>
                                <div className="buy-token-sec">
                                    <Button className="buy-token-btn">{t("buy_token")}</Button>
                                    <Button className="follow-token-btn">{t("follow")}</Button>
                                </div>
                                <Link to="#" className="about-me-btn" onClick={() => setShowBio(!showBio)}>
                                   {t("about_me")} <i className="fas fa-chevron-down"></i>
                                </Link>
                                {showBio &&
                                <div className="coach-bio-sec">
                                    <p>{t("demo_text")}</p>
                                </div>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="coach-single-profile-category-sec">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="coach-single-profile-category-box">
                                    <div className="coach-single-profile-category-card">
                                       {t("personal_traning")}
                                    </div>
                                    <div className="coach-single-profile-category-card">
                                       {t("pilotes_instructor")}
                                    </div>
                                    <div className="coach-single-profile-category-card">
                                       {t("hitt_coach")}
                                    </div>
                                    <div className="coach-single-profile-category-card">
                                        {t("nutrition_coach")}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="coach-single-profile-social-link-sec">
                    <ul className="coach-single-profile-social-link">
                        <Media as="li">
                            <Link to="#">
                                <Image
                                    className="coach-single-profile-socia-icon"
                                    src={
                                        window.location.origin + "/assets/images/fitness/instagram.svg"
                                    }
                                />
                            </Link>
                        </Media>
                        <Media as="li">
                            <Link to="#">
                                <Image
                                    className="coach-single-profile-socia-icon"
                                    src={
                                        window.location.origin + "/assets/images/fitness/facebook.svg"
                                    }
                                />
                            </Link>
                        </Media>
                        <Media as="li">
                            <Link to="#">
                                <Image
                                    className="coach-single-profile-socia-icon"
                                    src={
                                        window.location.origin + "/assets/images/fitness/twitter.svg"
                                    }
                                />
                            </Link>
                        </Media>
                        <Media as="li">
                            <Link to="#">
                                <Image
                                    className="coach-single-profile-socia-icon"
                                    src={
                                        window.location.origin + "/assets/images/fitness/youtube.svg"
                                    }
                                />
                            </Link>
                        </Media>
                        <Media as="li">
                            <Link to="#">
                                <Image
                                    className="coach-single-profile-socia-icon"
                                    src={
                                        window.location.origin + "/assets/images/fitness/snapchat.svg"
                                    }
                                />
                            </Link>
                        </Media>
                        <Media as="li">
                            <Link to="#">
                                <Image
                                    className="coach-single-profile-socia-icon"
                                    src={
                                        window.location.origin + "/assets/images/fitness/globe.svg"
                                    }
                                />
                            </Link>
                        </Media>
                    </ul>
                </div>
                {/* <div className="coach-single-profile-colapse-bio-sec">
                    <Link to="#" className="about-me-btn" onClick={() => setShowBio(!showBio)}>
                        About Me <i className="fas fa-chevron-down"></i>
                    </Link>
                    {showBio &&
                    <div className="bio-card">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                    }
                </div> */}
                <div className="coach-single-profile-tab-sec">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="programs">
                                    <Row>
                                        <Col sm={12} className="border-bottom-separtion">
                                            <Nav variant="pills">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="programs">{t("workouts")}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="virtual-training">{t("virtual_training")}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="subscribers">{t("live_streaming")}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="merchandiser">{t("products")}</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={12}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="programs">
                                                    <div className="coach-single-profile-program-sec">
                                                        <div className="coach-single-profile-program-box">
                                                            <div className="coach-single-profile-subscribers-sessions">
                                                                <Link to="#">
                                                                    <div className="coach-single-profile-program-card">
                                                                        <div className="coach-single-profile-program-video-sec">
                                                                            <Image
                                                                                className="coach-single-profile-program-video"
                                                                                src={
                                                                                    window.location.origin + "/assets/images/fitness/video-1.jpg"
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="coach-single-profile-on-demand">
                                                                           {t("on_demand")}
                                                                        </div>
                                                                        <div className="coach-single-profile-fav-icon">
                                                                            <i className="far fa-bookmark"></i>
                                                                        </div>
                                                                        <div className="coach-single-profile-program-info">
                                                                            <div>
                                                                                <h4>{t("30minute_core_crusher_with_chris")}</h4>
                                                                                {/* <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> */}
                                                                            </div>
                                                                            <div className="coach-single-profile-program-time-count">
                                                                                <p>
                                                                                    <Image
                                                                                        className="token-icon"
                                                                                        src={
                                                                                            window.location.origin + "/assets/images/fitness/token.png"
                                                                                        }
                                                                                    />
                                                                                    3.30
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="coach-single-profile-program-toggle">
                                                                            <Link to="#" onClick={() => setShowDesc(!showDesc)}>
                                                                                <i class="fas fa-chevron-down"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    {showDesc && <div className="coach-single-profile-program-desc">
                                                                        <p>{t("demo_text")}</p>
                                                                    </div>}
                                                                </Link>
                                                            </div>
                                                            <div className="coach-single-profile-subscribers-sessions">
                                                                <Link to="#">
                                                                    <div className="coach-single-profile-program-card">
                                                                        <div className="coach-single-profile-program-video-sec">
                                                                            <Image
                                                                                className="coach-single-profile-program-video"
                                                                                src={
                                                                                    window.location.origin + "/assets/images/fitness/video-2.jpg"
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="coach-single-profile-on-demand">
                                                                         {t("on_demand")}
                                                                        </div>
                                                                        <div className="coach-single-profile-fav-icon">
                                                                            <i className="far fa-bookmark"></i>
                                                                        </div>
                                                                        <div className="coach-single-profile-program-info">
                                                                            <div>
                                                                                <h4>{t("30minute_core_crusher_with_chris")}</h4>
                                                                                {/* <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> */}
                                                                            </div>
                                                                            <div className="coach-single-profile-program-time-count">
                                                                                <p>
                                                                                    <Image
                                                                                        className="token-icon"
                                                                                        src={
                                                                                            window.location.origin + "/assets/images/fitness/token.png"
                                                                                        }
                                                                                    />
                                                                                    3.30
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="coach-single-profile-program-toggle">
                                                                            <Link to="#" onClick={() => setShowDesc(!showDesc)}>
                                                                                <i class="fas fa-chevron-down"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    {showDesc && <div className="coach-single-profile-program-desc">
                                                                        <p>{t("demo_text")}</p>
                                                                    </div>}
                                                                </Link>
                                                            </div>
                                                            <div className="coach-single-profile-subscribers-sessions">
                                                                <Link to="#">
                                                                    <div className="coach-single-profile-program-card">
                                                                        <div className="coach-single-profile-program-video-sec">
                                                                            <Image
                                                                                className="coach-single-profile-program-video"
                                                                                src={
                                                                                    window.location.origin + "/assets/images/fitness/video-3.jpg"
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="coach-single-profile-on-demand">
                                                                          {t("on_demand")}
                                                                        </div>
                                                                        <div className="coach-single-profile-fav-icon">
                                                                            <i className="far fa-bookmark"></i>
                                                                        </div>
                                                                        <div className="coach-single-profile-program-info">
                                                                            <div>
                                                                                <h4>{t("30minute_core_crusher_with_chris")}</h4>
                                                                                {/* <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> */}
                                                                            </div>
                                                                            <div className="coach-single-profile-program-time-count">
                                                                                <p>
                                                                                    <Image
                                                                                        className="token-icon"
                                                                                        src={
                                                                                            window.location.origin + "/assets/images/fitness/token.png"
                                                                                        }
                                                                                    />
                                                                                    3.30
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="coach-single-profile-program-toggle">
                                                                            <Link to="#" onClick={() => setShowDesc(!showDesc)}>
                                                                                <i class="fas fa-chevron-down"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    {showDesc && <div className="coach-single-profile-program-desc">
                                                                        <p>{t("demo_text")}</p>
                                                                    </div>}
                                                                </Link>
                                                            </div>
                                                            <div className="coach-single-profile-subscribers-sessions">
                                                                <Link to="#">
                                                                    <div className="coach-single-profile-program-card">
                                                                        <div className="coach-single-profile-program-video-sec">
                                                                            <Image
                                                                                className="coach-single-profile-program-video"
                                                                                src={
                                                                                    window.location.origin + "/assets/images/fitness/video-4.jpg"
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="coach-single-profile-on-demand">
                                                                           {t("on_demand")}
                                                                        </div>
                                                                        <div className="coach-single-profile-fav-icon">
                                                                            <i className="far fa-bookmark"></i>
                                                                        </div>
                                                                        <div className="coach-single-profile-program-info">
                                                                            <div>
                                                                                <h4>{t("30minute_core_crusher_with_chris")}</h4>
                                                                                {/* <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> */}
                                                                            </div>
                                                                            <div className="coach-single-profile-program-time-count">
                                                                                <p>
                                                                                    <Image
                                                                                        className="token-icon"
                                                                                        src={
                                                                                            window.location.origin + "/assets/images/fitness/token.png"
                                                                                        }
                                                                                    />
                                                                                    3.30
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="coach-single-profile-program-toggle">
                                                                            <Link to="#" onClick={() => setShowDesc(!showDesc)}>
                                                                                <i class="fas fa-chevron-down"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    {showDesc && <div className="coach-single-profile-program-desc">
                                                                        <p>{t("demo_text")}</p>
                                                                    </div>}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="subscribers">
                                                    {/* <h5>Sessions</h5> */}
                                                    <div className="coach-single-profile-subscribers-box">
                                                        <div className="coach-single-profile-subscribers-sessions">
                                                            <Link to="/one-to-one">
                                                                <div className="coach-single-profile-program-card">
                                                                    <div className="coach-single-profile-program-video-sec">
                                                                        <Image
                                                                            className="coach-single-profile-program-video"
                                                                            src={
                                                                                window.location.origin + "/assets/images/fitness/video-1.jpg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="coach-single-profile-on-demand">
                                                                        25 02 2022 8:30 PM
                                                                    </div>
                                                                    <div className="coach-single-profile-fav-icon">
                                                                        <i className="far fa-bookmark"></i>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-info">
                                                                        <div>
                                                                            <h4>{t("one_to_one")}</h4>
                                                                            {/* <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> */}
                                                                        </div>
                                                                        <div className="coach-single-profile-program-time-count">
                                                                            <p>
                                                                                <Image
                                                                                    className="token-icon"
                                                                                    src={
                                                                                        window.location.origin + "/assets/images/fitness/token.png"
                                                                                    }
                                                                                />
                                                                                3.30
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-toggle">
                                                                        <Link to="#" onClick={() => setShowContents(!showContents)}>
                                                                            <i class="fas fa-chevron-down"></i>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                {showContents && <div className="coach-single-profile-program-desc">
                                                                    <p>{t("demo_text")}</p>
                                                                </div>}
                                                            </Link>
                                                        </div>
                                                        <div className="coach-single-profile-subscribers-sessions">
                                                            <Link to="/one-to-many">
                                                                <div className="coach-single-profile-program-card">
                                                                    <div className="coach-single-profile-program-video-sec">
                                                                        <Image
                                                                            className="coach-single-profile-program-video"
                                                                            src={
                                                                                window.location.origin + "/assets/images/fitness/video-4.jpg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="coach-single-profile-on-demand">
                                                                        {t("subscription")}
                                                                    </div>
                                                                    <div className="coach-single-profile-fav-icon">
                                                                        <i className="far fa-bookmark"></i>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-info">
                                                                        <div>
                                                                            <h4>{t("one_to_many")}</h4>
                                                                            {/* <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> */}
                                                                        </div>
                                                                        <div className="coach-single-profile-program-time-count">
                                                                            <p>
                                                                                <Image
                                                                                    className="token-icon"
                                                                                    src={
                                                                                        window.location.origin + "/assets/images/fitness/token.png"
                                                                                    }
                                                                                />
                                                                                3.30
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-toggle">
                                                                        <Link to="#" onClick={() => setShowDesc(!showDesc)}>
                                                                            <i class="fas fa-chevron-down"></i>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                {showDesc && <div className="coach-single-profile-program-desc">
                                                                    <p>{t("demo_text")}</p>
                                                                </div>}
                                                            </Link>
                                                        </div>
                                                        <div className="coach-single-profile-subscribers-sessions">
                                                            <Link to="#">
                                                                <div className="coach-single-profile-program-card">
                                                                    <div className="coach-single-profile-program-video-sec">
                                                                        <Image
                                                                            className="coach-single-profile-program-video"
                                                                            src={
                                                                                window.location.origin + "/assets/images/fitness/video-2.jpg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="coach-single-profile-on-demand">
                                                                        {t("subscription")}
                                                                    </div>
                                                                    <div className="coach-single-profile-fav-icon">
                                                                        <i className="far fa-bookmark"></i>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-info">
                                                                        <div>
                                                                            <h4>{t("30minute_core_crusher_with_chris")}</h4>
                                                                            {/* <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> */}
                                                                        </div>
                                                                        <div className="coach-single-profile-program-time-count">
                                                                            <p>
                                                                                <Image
                                                                                    className="token-icon"
                                                                                    src={
                                                                                        window.location.origin + "/assets/images/fitness/token.png"
                                                                                    }
                                                                                />
                                                                                3.30
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-toggle">
                                                                        <Link to="#" onClick={() => setShowContents(!showContents)}>
                                                                            <i class="fas fa-chevron-down"></i>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                {showContents && <div className="coach-single-profile-program-desc">
                                                                    <p>{t("demo_text")}</p>
                                                                </div>}
                                                            </Link>
                                                        </div>
                                                        <div className="coach-single-profile-subscribers-sessions">
                                                            <Link to="#">
                                                                <div className="coach-single-profile-program-card">
                                                                    <div className="coach-single-profile-program-video-sec">
                                                                        <Image
                                                                            className="coach-single-profile-program-video"
                                                                            src={
                                                                                window.location.origin + "/assets/images/fitness/video-3.jpg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="coach-single-profile-on-demand">
                                                                         {t("subscription")}
                                                                    </div>
                                                                    <div className="coach-single-profile-fav-icon">
                                                                        <i className="far fa-bookmark"></i>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-info">
                                                                        <div>
                                                                            <h4>{t("30minute_core_crusher_with_chris")}</h4>
                                                                            {/* <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> */}
                                                                        </div>
                                                                        <div className="coach-single-profile-program-time-count">
                                                                            <p>
                                                                                <Image
                                                                                    className="token-icon"
                                                                                    src={
                                                                                        window.location.origin + "/assets/images/fitness/token.png"
                                                                                    }
                                                                                />
                                                                                3.30
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-toggle">
                                                                        <Link to="#" onClick={() => setShowContents(!showContents)}>
                                                                            <i class="fas fa-chevron-down"></i>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                {showContents && <div className="coach-single-profile-program-desc">
                                                                    <p>{t("demo_text")}</p>
                                                                </div>}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="virtual-training">
                                                    <div className="coach-single-profile-live-streaming-sec">
                                                        <Container>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <ul className="list-unstyled coach-single-profile-live-streaming">
                                                                        <Media as="li"  onClick={() => setVideoTraining(true)}>
                                                                            <Link to="#">
                                                                                <Image
                                                                                    className="coach-single-live-streaming-icon"
                                                                                    src={
                                                                                        window.location.origin + "/assets/images/fitness/coach-single-video-white.svg"
                                                                                    }
                                                                                />
                                                                              {t("video")}
                                                                            </Link>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <Link to="#">
                                                                                <Image
                                                                                    className="coach-single-live-streaming-icon"
                                                                                    src={
                                                                                        window.location.origin + "/assets/images/fitness/coach-single-audio-white.svg"
                                                                                    }
                                                                                />
                                                                               {t("call")}
                                                                            </Link>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <Link to="/inbox">
                                                                                <Image
                                                                                    className="coach-single-live-streaming-icon"
                                                                                    src={
                                                                                        window.location.origin + "/assets/images/fitness/coach-single-chat-white.svg"
                                                                                    }
                                                                                />
                                                                               {t("message")}
                                                                            </Link>
                                                                        </Media>
                                                                    </ul>
                                                                </Col>
                                                            </Row>

                                                        </Container>
                                                    </div>
                                                    <div className="coach-single-profile-program-sec">
                                                        <div className="coach-single-profile-program-not-sceduled-sec">
                                                            <div className="bio-card">
                                                                <p>{t("bio_card_note")}</p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="coach-single-profile-program-box">
                                                            <div className="coach-single-profile-subscribers-sessions">
                                                                <div className="coach-single-profile-program-card">
                                                                    <div className="coach-single-profile-program-video-sec">
                                                                        <Image
                                                                            className="coach-single-profile-program-video"
                                                                            src={
                                                                                window.location.origin + "/assets/images/fitness/video-1.jpg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="coach-single-profile-on-demand">
                                                                        Confirmed Session
                                                                    </div>
                                                                    <div className="coach-single-profile-fav-icon">
                                                                        <i className="far fa-bookmark"></i>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-info">
                                                                        <div>
                                                                            <h4>30 Minute Core Crusher with Chris</h4>
                                                                            <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> 
                                                                        </div>
                                                                        <div className="coach-single-profile-program-time-count">
                                                                            <p>
                                                                                <Image
                                                                                    className="token-icon"
                                                                                    src={
                                                                                        window.location.origin + "/assets/images/fitness/token.png"
                                                                                    }
                                                                                />
                                                                                3.30
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-toggle">
                                                                        <Link to="#" onClick={() => setShowDesc(!showDesc)}>
                                                                            <i class="fas fa-chevron-down"></i>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                {showDesc && <div className="coach-single-profile-program-desc">
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                                </div>}
                                                            </div>
                                                            <div className="coach-single-profile-subscribers-sessions">
                                                                <div className="coach-single-profile-program-card">
                                                                    <div className="coach-single-profile-program-video-sec">
                                                                        <Image
                                                                            className="coach-single-profile-program-video"
                                                                            src={
                                                                                window.location.origin + "/assets/images/fitness/video-1.jpg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="coach-single-profile-on-demand">
                                                                        Pending for Approval
                                                                    </div>
                                                                    <div className="coach-single-profile-fav-icon">
                                                                        <i className="far fa-bookmark"></i>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-info">
                                                                        <div>
                                                                            <h4>30 Minute Core Crusher with Chris</h4>
                                                                             <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> 
                                                                        </div>
                                                                        <div className="coach-single-profile-program-time-count">
                                                                            <p>
                                                                                <Image
                                                                                    className="token-icon"
                                                                                    src={
                                                                                        window.location.origin + "/assets/images/fitness/token.png"
                                                                                    }
                                                                                />
                                                                                3.30
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-toggle">
                                                                        <Link to="#" onClick={() => setShowDesc(!showDesc)}>
                                                                            <i class="fas fa-chevron-down"></i>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                {showDesc && <div className="coach-single-profile-program-desc">
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                                </div>}
                                                            </div>
                                                            <div className="coach-single-profile-subscribers-sessions">
                                                                <div className="coach-single-profile-program-card">
                                                                    <div className="coach-single-profile-program-video-sec">
                                                                        <Image
                                                                            className="coach-single-profile-program-video"
                                                                            src={
                                                                                window.location.origin + "/assets/images/fitness/video-1.jpg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="coach-single-profile-on-demand">
                                                                        Confirmed Session
                                                                    </div>
                                                                    <div className="coach-single-profile-fav-icon">
                                                                        <i className="far fa-bookmark"></i>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-info">
                                                                        <div>
                                                                            <h4>30 Minute Core Crusher with Chris</h4>
                                                                             <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6>
                                                                        </div>
                                                                        <div className="coach-single-profile-program-time-count">
                                                                            <p>
                                                                                <Image
                                                                                    className="token-icon"
                                                                                    src={
                                                                                        window.location.origin + "/assets/images/fitness/token.png"
                                                                                    }
                                                                                />
                                                                                3.30
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-toggle">
                                                                        <Link to="#" onClick={() => setShowDesc(!showDesc)}>
                                                                            <i class="fas fa-chevron-down"></i>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                {showDesc && <div className="coach-single-profile-program-desc">
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                                </div>}
                                                            </div>
                                                            <div className="coach-single-profile-subscribers-sessions">
                                                                <div className="coach-single-profile-program-card">
                                                                    <div className="coach-single-profile-program-video-sec">
                                                                        <Image
                                                                            className="coach-single-profile-program-video"
                                                                            src={
                                                                                window.location.origin + "/assets/images/fitness/video-1.jpg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="coach-single-profile-on-demand">
                                                                        Confirmed Session
                                                                    </div>
                                                                    <div className="coach-single-profile-fav-icon">
                                                                        <i className="far fa-bookmark"></i>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-info">
                                                                        <div>
                                                                            <h4>30 Minute Core Crusher with Chris</h4>
                                                                            <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> 
                                                                        </div>
                                                                        <div className="coach-single-profile-program-time-count">
                                                                            <p>
                                                                                <Image
                                                                                    className="token-icon"
                                                                                    src={
                                                                                        window.location.origin + "/assets/images/fitness/token.png"
                                                                                    }
                                                                                />
                                                                                3.30
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="coach-single-profile-program-toggle">
                                                                        <Link to="#" onClick={() => setShowDesc(!showDesc)}>
                                                                            <i class="fas fa-chevron-down"></i>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                {showDesc && <div className="coach-single-profile-program-desc">
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                                </div>}
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="merchandiser">
                                                    <div className="merchandiser-box">
                                                        <div className="merchandiser-card">
                                                            <div className="merchandiser-img-sec">
                                                                <Link to="#">
                                                                    <Image
                                                                        className="merchandiser-img"
                                                                        src={
                                                                            window.location.origin + "/assets/images/fitness/store.jpg"
                                                                        }
                                                                    />
                                                                </Link>
                                                            </div>
                                                            <div className="merchandiser-info">
                                                                <Link to="#" className="product-title">
                                                                    <h3>{t("stretch_band")}</h3>
                                                                </Link>
                                                                <div className="rating-star-card">
                                                                    <ul className="rating-star-sec">
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                    </ul>
                                                                </div>
                                                                <div className="merchandiser-price-sec">
                                                                    <ins><span>$15.00</span></ins>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="merchandiser-card">
                                                            <div className="merchandiser-img-sec">
                                                                <Link to="#">
                                                                    <Image
                                                                        className="merchandiser-img"
                                                                        src={
                                                                            window.location.origin + "/assets/images/fitness/store-1.jpg"
                                                                        }
                                                                    />
                                                                </Link>
                                                            </div>
                                                            <div className="merchandiser-info">
                                                                <Link to="#" className="product-title">
                                                                    <h3>{t("stretch_band")}</h3>
                                                                </Link>
                                                                <div className="rating-star-card">
                                                                    <ul className="rating-star-sec">
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                    </ul>
                                                                </div>
                                                                <div className="merchandiser-price-sec">
                                                                    <ins><span>$15.00</span></ins>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="merchandiser-card">
                                                            <div className="merchandiser-img-sec">
                                                                <Link to="#">
                                                                    <Image
                                                                        className="merchandiser-img"
                                                                        src={
                                                                            window.location.origin + "/assets/images/fitness/store-2.jpg"
                                                                        }
                                                                    />
                                                                </Link>
                                                            </div>
                                                            <div className="merchandiser-info">
                                                                <Link to="#" className="product-title">
                                                                    <h3>{t("stretch_band")}</h3>
                                                                </Link>
                                                                <div className="rating-star-card">
                                                                    <ul className="rating-star-sec">
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                    </ul>
                                                                </div>
                                                                <div className="merchandiser-price-sec">
                                                                    <ins><span>$15.00</span></ins>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="merchandiser-card">
                                                            <div className="merchandiser-img-sec">
                                                                <Link to="#">
                                                                    <Image
                                                                        className="merchandiser-img"
                                                                        src={
                                                                            window.location.origin + "/assets/images/fitness/store-3.jpg"
                                                                        }
                                                                    />
                                                                </Link>
                                                            </div>
                                                            <div className="merchandiser-info">
                                                                <Link to="#" className="product-title">
                                                                    <h3>{t("stretch_band")}</h3>
                                                                </Link>
                                                                <div className="rating-star-card">
                                                                    <ul className="rating-star-sec">
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                        <Media as="li">
                                                                            <i className="fas fa-star"></i>
                                                                        </Media>
                                                                    </ul>
                                                                </div>
                                                                <div className="merchandiser-price-sec">
                                                                    <ins><span>$15.00</span></ins>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {videoTraining && (
                <VideoTrainingModal videoTraining={videoTraining} closeVideoTrainingModal={closeVideoTrainingModal} />
            )}
            
        </>
    );
};

export default (translate(CoachSingleProfileIndex));
