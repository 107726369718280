import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Tab,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import PaypalExpressBtn from "react-paypal-express-checkout";
import AddWalletAmountModal from "../helper/AddWalletAmountModal";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  livePaymentPaypalStart,
  livePaymentStripeStart,
  livePaymentWalletStart,
} from "../../store/actions/LiveVideoAction";
import { fetchCardDetailsStart } from "../../store/actions/CardsAction";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import { Link } from "react-router-dom";
const $ = window.$;

const PaymentModal = (props) => {
  const [addWalletAmountModal, setAddWalletAmountModal] = useState(false);

  const closeAddWalletAmountModal = () => {
    setAddWalletAmountModal(false);
  };

  const [paymentType, setPaymentType] = useState(
    localStorage.getItem("default_payment_method")
  );

  const [isOnlyWalletPayment, setIsOnlyWalletPayment] = useState(
    configuration.get("configData.is_only_wallet_payment")
  );

  useEffect(() => {
    if (props.paymentModal === true) {
      props.dispatch(fetchCardDetailsStart());
      props.dispatch(
        fetchWalletDetailsStart({ trainer_id: props.liveVideo.user_id })
      );
    }
  }, [props.paymentModal]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (paymentType === "CARD")
      props.dispatch(
        livePaymentStripeStart({
          live_video_id: props.liveVideo.live_video_id,
        })
      );
    if (paymentType === "wallet") alert(paymentType);
    props.dispatch(
      livePaymentWalletStart({
        live_video_id: props.liveVideo.live_video_id,
      })
    );
    props.closePaymentModal();
  };

  const paypalOnSuccess = (payment) => {
    setTimeout(() => {
      props.dispatch(
        livePaymentPaypalStart({
          live_video_id: props.liveVideo.live_video_id,
          payment_id: payment.paymentID,
        })
      );
    }, 1000);
    props.closePaymentModal();
  };

  const paypalOnError = (err) => {
    const notificationMessage = getErrorNotificationMessage(err);
    this.props.dispatch(createNotification(notificationMessage));
  };

  const paypalOnCancel = (data) => {
    const notificationMessage = getErrorNotificationMessage(
      t("payment_cancelled_note")
    );
    this.props.dispatch(createNotification(notificationMessage));
  };
  let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
  let currency = "USD"; // or you can set this value from your props or state

  const client = {
    sandbox: configuration.get("configData.PAYPAL_ID"),
    production: configuration.get("configData.PAYPAL_ID"),
  };

  return (
    <Modal
      centered
      size="md"
      className="modal-dialog-center"
      show={props.paymentModal}
      onHide={props.closePaymentModal}
    >
      {props.paymentModal === true ? (
        <>
          <Modal.Header className="wallet-modal-header" closeButton>
            <Modal.Title>{t("live_video_payment")}</Modal.Title>
          </Modal.Header>
          <div className="payment-tokens">
            <Modal.Body className="wallet-token-body modal-body">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={paymentType}
              >
                <Row>
                  <Col sm={12}>
                    <div className="wallet-balance-box">
                      <Form>
                        <div className="wallet-box-card mb-4">
                          <h4>{props.liveVideo.amount_formatted}</h4>
                        </div>

                        <Tab.Content>
                          {configuration.get(
                            "configData.is_wallet_payment_enabled"
                          ) == 1 ? (
                            <Tab.Pane eventKey="WALLET">
                              {props.wallet.loading ? (
                                ""
                              ) : (
                                <div className="card-stripe-box">
                                  <div className="wallet-box-card mb-2">
                                    <h4>{t("available")}</h4>
                                    <h3>
                                      {
                                        props.wallet.data.user_wallet
                                          .remaining_formatted
                                      }
                                    </h3>
                                  </div>
                                  {props.amount >
                                    props.wallet.data.user_wallet.remaining ? (
                                    <div className="">
                                      <p className="conv-desc desc">
                                        {t(
                                          "low_wallet_balance_tips_payment_para"
                                        )}
                                      </p>
                                      <div className="d-flex">
                                        <Link
                                          to="/wallet"
                                          className="withdraw-money-btn"
                                        >
                                          {t("add_wallet_amount")}
                                        </Link>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </Tab.Pane>
                          ) : null}
                        </Tab.Content>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Tab.Container>
            </Modal.Body>
            <Modal.Footer className="wallet-modal-footer payment-post-wallet">
              <div className="wallet-modal-btn">
                <Button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={props.closePaymentModal}
                >
                  {t("cancel")}
                </Button>
                {paymentType !== "PAYPAL" ? (
                  <Button
                    type="button"
                    className="btn btn-success"
                    data-dismiss="modal"
                    onClick={handleSubmit}
                    disabled={props.liveVideoDetails.buttonDisable}
                  >
                    {props.liveVideoDetails.loadingButtonContent !== null
                      ? props.liveVideoDetails.loadingButtonContent
                      : t("pay")}
                  </Button>
                ) : null}
              </div>
            </Modal.Footer>
          </div>
        </>
      ) : null}
    </Modal>
  );
};

const mapStateToPros = (state) => ({
  liveVideoDetails: state.liveVideo.singleLiveVideo,
  wallet: state.wallet.walletData,
  cards: state.cards.cardDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(PaymentModal));
