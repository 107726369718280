import React, { useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
} from "react-bootstrap";
import "./LiveVideoHistory.css";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

const LiveVideoHistoryIndex = (props) => {
  return (
    <>
      <div className="live-video-history-sec">
        <div className="live-video-history-header-bg-img-sec">
          <Image
            className="live-video-history-header-bg-img"
            src={
              window.location.origin +
              "/assets/images/live-history/fitness-bg.jpg"
            }
          />
          <div className="live-video-history-on-live-btn-sec">
            <Button className="on-live-btn">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                <path d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z" />
              </svg>{" "}
              ON LIVE
            </Button>
          </div>
        </div>
        <Container>
          <Row>
            <Col md={12}>
              <div className="live-video-history-box">
                <div className="live-video-history-card">
                  <div className="live-video-history-img-sec">
                    <Image
                      className="live-video-history-img"
                      src={
                        window.location.origin +
                        "/assets/images/live-history/fitness-bg.jpg"
                      }
                    />
                  </div>
                  <div className="live-video-history-info">
                    <div className="live-video-history-user-info">
                      <div className="live-video-history-user-img-sec">
                        <Image
                          className="live-video-history-user-img"
                          src={
                            window.location.origin +
                            "/assets/images/live-history/user-img.jpg"
                          }
                        />
                      </div>
                      <div className="live-video-history-user-details">
                        <h4>Abdominal Crushed</h4>
                        <Link to="#" className="live-video-history-user-link">
                          @Jaco De Bruyn
                        </Link>
                        <h6>Best abb workout, join us at 5pm</h6>
                        <p>2022-07-06 03:13 PM</p>
                      </div>
                    </div>
                    <div className="live-video-history-user-btn-sec">
                      <Button className="onlive-video-history-user-btn">
                        JOIN NOW
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LiveVideoHistoryIndex;
