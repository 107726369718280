import React from "react"
import ContentLoader from "react-content-loader"
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { Modal, Container, Row, Col, Media, Image, Nav, Tab, Button } from "react-bootstrap";


const ProfileLoader = (props) => (
  <>
    {/* <ContentLoader
      speed={2}
      width={1340}
      height={1300}
      viewBox="0 0 1200 1300"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="31" y="130" rx="0" ry="0" width="0" height="9" />
      <rect x="8" y="1" rx="5" ry="5" width="1195" height="129" />
      <circle cx="600" cy="124" r="51" />
      <rect x="300" y="194" rx="5" ry="5" width="600" height="106" />
      <rect x="310" y="320" rx="20" ry="20" width="280" height="37" />
      <rect x="605" y="318" rx="20" ry="20" width="280" height="37" />
      <rect x="355" y="376" rx="15" ry="15" width="80" height="31" />
      <rect x="455" y="376" rx="15" ry="15" width="80" height="31" />
      <rect x="555" y="376" rx="15" ry="15" width="80" height="31" />
      <rect x="655" y="376" rx="15" ry="15" width="80" height="31" />
      <rect x="755" y="376" rx="15" ry="15" width="80" height="31" />
      <rect x="300" y="440" rx="0" ry="0" width="600" height="4" />
      <rect x="500" y="419" rx="0" ry="0" width="52" height="16" />
      <rect x="575" y="419" rx="0" ry="0" width="52" height="16" />
      <rect x="650" y="419" rx="0" ry="0" width="52" height="16" />
      <rect x="320" y="461" rx="0" ry="0" width="64" height="6" />
      <circle cx="340" cy="494" r="21" />
      <rect x="375" y="481" rx="0" ry="0" width="72" height="7" />
      <rect x="375" y="493" rx="0" ry="0" width="79" height="10" />
      <rect x="300" y="533" rx="10" ry="10" width="600" height="189" />
      <rect x="840" y="475" rx="0" ry="0" width="27" height="8" />
      <rect x="880" y="475" rx="0" ry="0" width="20" height="6" />
      <rect x="882" y="497" rx="0" ry="0" width="17" height="14" />
      <rect x="320" y="736" rx="5" ry="5" width="30" height="19" />
      <rect x="360" y="736" rx="5" ry="5" width="30" height="19" />
      <rect x="400" y="736" rx="5" ry="5" width="30" height="19" />
      <rect x="320" y="768" rx="0" ry="0" width="86" height="10" />
      <rect x="320" y="789" rx="0" ry="0" width="86" height="10" />
      <rect x="850" y="736" rx="5" ry="5" width="30" height="19" />
      <rect x="300" y="810" rx="5" ry="5" width="600" height="34" />
      <rect x="300" y="866" rx="0" ry="0" width="397" height="4" />
      <circle cx="320" cy="914" r="21" />
      <rect x="360" y="901" rx="0" ry="0" width="72" height="7" />
      <rect x="360" y="913" rx="0" ry="0" width="79" height="10" />
      <rect x="300" y="953" rx="10" ry="10" width="600" height="189" />
      <rect x="840" y="895" rx="0" ry="0" width="27" height="8" />
      <rect x="880" y="895" rx="0" ry="0" width="20" height="6" />
      <rect x="882" y="917" rx="0" ry="0" width="17" height="14" />
      <rect x="320" y="1156" rx="5" ry="5" width="30" height="19" />
      <rect x="360" y="1156" rx="5" ry="5" width="30" height="19" />
      <rect x="400" y="1156" rx="5" ry="5" width="30" height="19" />
      <rect x="320" y="1188" rx="0" ry="0" width="86" height="10" />
      <rect x="320" y="1209" rx="0" ry="0" width="86" height="10" />
      <rect x="850" y="1156" rx="5" ry="5" width="30" height="19" />
      <rect x="300" y="1230" rx="5" ry="5" width="600" height="34" />
    </ContentLoader> */}

    <Skeleton className="profile-cover-loader" />
    <Skeleton
      className="profile-pic-loader"
      width={150}
      height={150} />
    <Container>
      <div className="new-single-profile-category-slider-sec">
        <Row>
          <Col md={12}>
            <div className="row search-result-grid-box">
              {[...Array(6)].map(() =>
                <Skeleton
                  height={200}
                  width={175} />)}
            </div>
          </Col>
          <Col md={12}>
            <div className="new-single-profile-social-link-sec">
              <div className="new-single-profile-social-link-sec">
                <ul className="list-unstyled new-single-profile-social-link">
                  {[...Array(6)].map(() =>
                    <li>
                      <Skeleton
                        height={43}
                        width={43} />
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="row justify-content-center nav-item-box mt-3">
              {[...Array(4)].map(() =>
                <Skeleton
                width={150}
                  height={65}
                   />)}
            </div>
          </Col>
        </Row>
      </div>
    </Container>


    <div className="coach-single-profile-social-link-sec row justify-content-between p-5">
      {[...Array(3)].map(() => <Skeleton height={20} containerClassName="col-3" />)}
    </div>
    <div className="row justify-content-between p-5">
      {[...Array(6)].map(() => <Skeleton width={50} height={50} className="profile-social-loading" />)}
    </div>
  </>
)

export default ProfileLoader;