import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Badge,
  Image,
} from "react-bootstrap";
import "../../Wallet/Wallet.css";
import NoDataFound from "../../NoDataFound/NoDataFound";
import BillingAccountLoader from "../../Loader/BillingAccountLoader";
import { translate, t } from "react-multi-lang";
import {
  callHistoryUserStart,
} from "../../../store/actions/PrivateCallAction";
import { Link } from "react-router-dom";
import VideoCallHistoryTable from "./VideoCallHistoryTable";

const VideoCallHistoryIndex = (props) => {
  useEffect(() => {
    props.dispatch(callHistoryUserStart());
  }, []);

  return (
    <>
      <div className="wallet-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div className="wallet-header-sec">
                <Row>
                  <Col sm={12} md={12} xl={9}>
                    <Link
                      className="bookmarkes-list notify-title back-button"
                      onClick={() => props.history.goBack()}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/back-white.svg"
                        }
                        className="svg-clone"
                      />
                      {t("video_call_history")}
                    </Link>
                    <p className="text-white f-2">
                      {t("video_call_history_note")}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="trans-table-sec">
        <Container>
          {props.callHistoryUser.loading ? (
            <BillingAccountLoader />
          ) : props.callHistoryUser.data.video_call_requests &&
            props.callHistoryUser.data.video_call_requests.length > 0 ? (
            <Row>
              <Col sm={12} md={12}>
                <VideoCallHistoryTable
                  videoCallRequest={props.callHistoryUser.data.video_call_requests}
                />
              </Col>
            </Row>
          ) : (
            <NoDataFound />
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  callHistoryUser: state.privateCall.callHistoryUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(VideoCallHistoryIndex));
