import React, { Component } from "react";
import { Button, Container, Row, Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchSinglePageStart } from "../../store/actions/PageAction";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { resetPasswordStart } from "../../store/actions/UserAction";
import { translate, t } from "react-multi-lang";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ResetPassword = (props) => {
  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("password_is_required"))
      .matches(
        /^(?=.*[a-zA-Z-0-9])/,
        t("space_values_not_valid")
      )
      .min(6
        ,
        t("must_contain_6_characters")
      ),
    password_confirmation: Yup.string()
      .required(t("confirmation_pawword_is_required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string()
          .oneOf([Yup.ref("password")], t("both_password_need_to_be_the_same"))
          .matches(
            /^(?=.*[a-zA-Z-0-9])/,
            t("space_values_not_valid")
          )
          .min(6
            ,
            t("must_contain_6_characters")
          ),

      }),
  });

  const passwordValidation = (value) => {
    const trimmedValue = value.replaceAll(" ", "");
    if (trimmedValue !== value)
      return t("should_not_contain_empty_space");
    return "";
  }

  const handleForgotPasswordSubmit = (values) => {
    props.dispatch(resetPasswordStart(values));
  };

  return (
    <>
      <div className="login-sec">
        <div className="coach-template-animation coach-template-layout34">
          <div className="coach-shape">
            <div className="coach-transformX-L-50 coach-transition-delay-1">
              <Image
                src={
                  window.location.origin + "/assets/images/fitness/shape1.png"
                }
              />
            </div>
          </div>
          <Container>
            <Row>
              <Col lg={7}>
                <div className="coach-column-wrap justify-content-between left-text-padding">
                  <div className="coach-animated-img">
                    <div className="coach-transformX-L-50 coach-transition-delay-10">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/fitness/rocket-img.png"
                        }
                      />
                    </div>
                  </div>
                  <div className="coach-transformX-L-50 coach-transition-delay-3">
                    <Link to="#" className="coach-logo">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/fitness/coach-logo-white.png"
                        }
                      />
                    </Link>
                  </div>
                  <div className="coach-transformX-L-50 coach-transition-delay-5">
                    <div className="coach-middle-content">
                      <h2 className="coach-main-title">
                        {t("reset_password")}
                      </h2>
                      <div className="coach-switcher-description1">
                        {t(
                          "enter_the_email_address_or_mobile_number_associate"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={5}>
                <div className="coach-column-wrap justify-content-center">
                  <div className="coach-form">
                    <h2 className="coach-sub-title">{t("reset_password")}</h2>

                    <Formik
                      initialValues={{
                        password: "",
                        password_confirmation: "",
                        reset_token: props.match.params.token,
                      }}
                      validationSchema={resetPasswordSchema}
                      onSubmit={(values) => handleForgotPasswordSubmit(values)}
                    >
                      {({
                        touched,
                        errors,
                        values,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <Form noValidate className="login-form">
                          <Field
                            type="password"
                            placeholder={t("new_password_placeholder")}
                            autoFocus
                            name="password"
                            className="form-control"
                            value={values.password}
                            validate={passwordValidation}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="text-danger text-right"
                          />

                          <Field
                            type="password"
                            placeholder={t("new_password_placeholder")}
                            name="password_confirmation"
                            className="form-control"
                            value={values.password_confirmation}
                            validate={passwordValidation}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="password_confirmation"
                            className="text-danger text-right"
                          />
                          <Button
                            id="forgotpassword"
                            type="submit"
                            className="btn btn-auth btn-lg mt-3"
                            disabled={props.inputData.buttonDisable}
                          >
                            {props.inputData.loadingButtonContent != null
                              ? props.inputData.loadingButtonContent
                              : t("reset_password")}
                          </Button>
                        </Form>
                      )}
                    </Formik>
                    <div className="login-form coach-checkbox-box">
                      <span className="coach-switcher-description1">
                        {t("already_have_an_account")}
                      </span>
                      <Link className="terms-link ms-2" to="/login">
                        {t("log_in")}
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  inputData: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ResetPassword));