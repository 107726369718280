import React, { useEffect, useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Media,
  Image,
  Nav,
  Tab,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchFollowingStart } from "../../../store/actions/FollowAction";
import { fetchUserDetailsStart } from "../../../store/actions/UserAction";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import AddWalletTokenModal from "../../helper/AddWalletTokenModal";
import PayAndSeePostModal from "../../PaymentModal/PayAndSeePostModal";
import VideoTrainingModal from "../../Fitness/VideoTrainingModal";
import { isMobile } from "react-device-detect";

const NewLatestHeader = (props) => {
  useEffect(() => {
    if (localStorage.getItem("userId")) {
      props.dispatch(fetchUserDetailsStart());
      props.dispatch(
        fetchFollowingStart({
          skip: 0,
          take: 12,
        })
      );
    }
  }, []);

  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };
  const [walletToken, setWalletToken] = useState(false);

  const closeWalletTokenModal = () => {
    setWalletToken(false);
  };
  const [paymentPost, setPaymentPost] = useState(false);

  const closePaymentPost = () => {
    setPaymentPost(false);
  };
  const [videoTraining, setVideoTraining] = useState(false);

  const closeVideoTrainingModal = () => {
    setVideoTraining(false);
  };
  return (
    <>
      <div className="new-single-wrapper">
        <Container>
          <nav className="navbar navbar-expand-lg navbar-light px-sm-0 navBar">
            <Link to={"/"} className="navbar-brand">
              <Image
                src={configuration.get("configData.site_logo")}
                width="150"
              />
            </Link>
            {
              localStorage.getItem("userId") ?
                <button onClick={handleToggle} className="navbar-toggle">
                  {/* {navbarOpen ? "Close" : "Open"} */}
                  <Image
                    className="hamburger-icon"
                    src={
                      window.location.origin +
                      "/assets/images/fitness/hamburger-icon.svg"
                    }
                  />
                </button>
                :
                null
            }
            < div
              className="collapse navbar-collapse navbar-ex1-collapse mobile-hide"
              id="navigationbar"
            >
              <ul className="navbar-nav ml-auto nav-pills">
                {localStorage.getItem("userId") ? (
                  <>
                    {!props.profile.loading &&
                      props.profile.data.is_content_creator === 2 ? (
                      <>
                        <Media as="li" className="nav-item">
                          <Link to={isMobile ? "/home" : "/w/home"} className="nav-link">
                            Home
                          </Link>
                        </Media>
                        <Media as="li" className="nav-item">
                          <Link to="/wallet" className="nav-link">
                            {t("wallet")}
                          </Link>
                        </Media>

                        <Media as="li" className="nav-item">
                          <Link to="/add-post" className="nav-link">
                            {t("add_post")}
                          </Link>
                        </Media>
                        <Media as="li" className="nav-item">
                          <Link to="/live-videos-history" className="nav-link">
                            {t("live_streaming")}
                          </Link>
                        </Media>
                        <Media
                          as="li"
                          // onClick={() => setVideoTraining(true)}
                          className="nav-item"
                        >
                          <Link className="nav-link" to="/video-calls-received">
                            {t("video_sessions")}
                          </Link>
                        </Media>
                        <Media as="li" className="nav-item">
                          <Link className="nav-link" to="/audio-calls-received">
                            {t("call_sessions")}
                          </Link>
                        </Media>
                        <Media as="li" className="nav-item">
                          <Link to="/product-list" className="nav-link">
                            {t("products")}
                          </Link>
                        </Media>
                        <Media as="li" className="nav-item">
                          <Link className="nav-link" to="/inbox">
                            {t("messages")}
                          </Link>
                        </Media>
                        <Media as="li" className="nav-item">
                          <Link to="/edit-profile" className="nav-link">
                            {t("settings")}
                          </Link>
                        </Media>
                      </>
                    ) : (
                      <>
                        {props.profile.data.registered_as === 2 ? (
                          <Media as="li" className="nav-item">
                            <Link to="/become-a-coach" className="nav-link">
                              {t("become_a_content_creator")}
                            </Link>
                          </Media>
                        ) : (
                          ""
                        )}
                        <Media as="li" className="nav-item">
                          <Link to={isMobile ? "/home" : "/w/home"} className="nav-link">
                            Home
                          </Link>
                        </Media>
                        <Media as="li" className="nav-item">
                          <Link to="/transaction" className="nav-link">
                            {t("token_transactions")}
                          </Link>
                        </Media>
                        <Media as="li" className="nav-item">
                          <Link to="/edit-profile" className="nav-link">
                            {t("settings")}
                          </Link>
                        </Media>
                        <Media as="li" className="nav-item">
                          <Link to="/video-calls-sent" className="nav-link">
                            {t("video_sessions")}
                          </Link>
                        </Media>
                        <Media as="li" className="nav-item">
                          <Link to="/audio-calls-sent" className="nav-link">
                            {t("call_sessions")}
                          </Link>
                        </Media>
                        <Media as="li" className="nav-item">
                          <Link
                            to={isMobile ? "/scheduled-live-videos" : "/w/scheduled-live-videos"}
                            className="nav-link"
                          >
                            {t("live_streams")}
                          </Link>
                        </Media>
                        <Media as="li" className="nav-item">
                          <Link to="/inbox" className="nav-link">
                            {t("message")}
                          </Link>
                        </Media>
                      </>
                    )}

                    {!props.profile.loading &&
                    Object.keys(props.profile.data).length >0 &&
                      props.profile.data.is_content_creator === 1 &&
                      !props.following.loading &&
                      Object.keys(props.following.data).length >0 &&
                      props.following.data.followers.length > 0 && (
                        <Media as="li" className="dropdown nav-item">
                          <Link
                            to="/"
                            className="dropdown-toggle nav-link"
                            data-toggle="dropdown"
                          >
                            {t("my_coaches")}
                            <b className="caret"></b>
                          </Link>
                          <ul className="dropdown-menu">
                            {props.following.data.followers
                              .slice(0, 6)
                              .map((follower, index) => (
                                <Media as="li" key={index}>
                                  <Link
                                    to={isMobile ? `/w/${follower.otherUser.user_unique_id}` : `/` + follower.otherUser.user_unique_id}
                                    eventKey={`/${follower.otherUser.user_unique_id}`}
                                  >
                                    {follower.otherUser.name}
                                  </Link>
                                </Media>
                              ))}
                            {props.following.data.followers.length > 6 && (
                              <Media as="li">
                                <Link to={isMobile ? "/home" : "/w/home"}>{t("show_more")}</Link>
                              </Media>
                            )}
                          </ul>
                        </Media>
                      )}
                    {localStorage.getItem("userId") ?
                      <Media as="li" className="nav-item">
                        <Link to="/logout" className="nav-link">
                          {t("logout")}
                        </Link>
                      </Media>
                      :
                      null
                    }
                  </>
                ) : (
                  null
                )}
              </ul>
            </div>
            <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
              {localStorage.getItem("userId") ?
                !props.profile.loading &&
                  Object.keys(props.profile.data).length > 0
                  ? (
                    <>
                      {props.profile.data.is_content_creator === 2 ? (
                        <>
                          <Media as="li" className="nav-item">
                            <Link to={isMobile ? "/home" : "/w/home"} className="nav-link">
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/home.svg"
                                  }
                                />
                              </span>
                              Home
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link
                              to="/live-videos-history"
                              onClick={handleToggle}
                              className="nav-link"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/live-streaming-icon.svg"
                                  }
                                />
                              </span>
                              {t("live_streaming")}
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link
                              to="/wallet"
                              onClick={handleToggle}
                              className="nav-link"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/wallet-icon.svg"
                                  }
                                />
                              </span>
                              {t("wallet")}
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link
                              to="/add-post"
                              onClick={handleToggle}
                              className="nav-link"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/post-workout-icon.svg"
                                  }
                                />
                              </span>
                              {t("add_post")}
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link
                              to="/edit-profile"
                              onClick={handleToggle}
                              className="nav-link"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/setting-icon.svg"
                                  }
                                />
                              </span>
                              {t("settings")}
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link
                              className="nav-link"
                              onClick={handleToggle}
                              to="/video-calls-received"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/video-session-icon.svg"
                                  }
                                />
                              </span>
                              {t("video_sessions")}
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link
                              className="nav-link"
                              onClick={handleToggle}
                              to="/audio-calls-received"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/audio-session-icon.svg"
                                  }
                                />
                              </span>
                              {t("call_sessions")}
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link
                              to="/product-list"
                              onClick={handleToggle}
                              className="nav-link"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/live-streaming-icon.svg"
                                  }
                                />
                              </span>
                              {t("products")}
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link className="nav-link" to="/inbox">
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/message-icon.svg"
                                  }
                                />
                              </span>
                              {t("messages")}
                            </Link>
                          </Media>
                        </>
                      ) : (
                        <>
                          {props.profile.data.registered_as === 2 ? (
                            <Media as="li" className="nav-item">
                              <Link
                                to="/become-a-coach"
                                onClick={handleToggle}
                                className="nav-link"
                              >
                                <span>
                                  <Image
                                    className="mobile-menu-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/fitness/menu/live-streaming-icon.svg"
                                    }
                                  />
                                </span>
                                {t("become_a_content_creator")}
                              </Link>
                            </Media>
                          ) : (
                            ""
                          )}

                          <Media as="li" className="nav-item">
                            <Link to={isMobile ? "/home" : "/w/home"} className="nav-link">
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/home.svg"
                                  }
                                />
                              </span>
                              Home
                            </Link>
                          </Media>

                          <Media as="li" className="nav-item">
                            <Link
                              to="/transaction"
                              onClick={handleToggle}
                              className="nav-link"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/token-transaction-icon.svg"
                                  }
                                />
                              </span>
                              {t("token_transactions")}
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link
                              to="/edit-profile"
                              onClick={handleToggle}
                              className="nav-link"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/setting-icon.svg"
                                  }
                                />
                              </span>
                              {t("settings")}
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link
                              to="/video-calls-sent"
                              onClick={handleToggle}
                              className="nav-link"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/video-session-icon.svg"
                                  }
                                />
                              </span>
                              {t("video_sessions")}
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link
                              to="/audio-calls-sent"
                              onClick={handleToggle}
                              className="nav-link"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/audio-session-icon.svg"
                                  }
                                />
                              </span>
                              {t("call_sessions")}
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link
                              to={isMobile ? "/scheduled-live-videos" : "/w/scheduled-live-videos"}
                              onClick={handleToggle}
                              className="nav-link"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/live-streaming-icon.svg"
                                  }
                                />
                              </span>
                              {t("live_streams")}
                            </Link>
                          </Media>
                          <Media as="li" className="nav-item">
                            <Link
                              to="/inbox"
                              onClick={handleToggle}
                              className="nav-link"
                            >
                              <span>
                                <Image
                                  className="mobile-menu-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/menu/message-icon.svg"
                                  }
                                />
                              </span>
                              {t("message")}
                            </Link>
                          </Media>
                        </>
                      )}
                    </>
                  ) :
                  null
                :
                null
              }


              {localStorage.getItem("is_content_creator") == 2 &&
              (
                  <Media as="li" className="dropdown nav-item">
                    <Link
                      to="/"
                      className="dropdown-toggle nav-link"
                      data-toggle="dropdown"
                    >
                      <span>
                        <Image
                          className="mobile-menu-icon"
                          src={
                            window.location.origin +
                            "/assets/images/fitness/menu/my-coach-icon.svg"
                          }
                        />
                      </span>
                      {t("my_coaches")}
                      <b className="caret"></b>
                    </Link>
                    <ul className="dropdown-menu">
                      {props.following.data.followers
                        .slice(0, 6)
                        .map((follower, index) => (
                          <Media as="li" key={index}>
                            <Link
                              to={isMobile ? `/w/${follower.otherUser.user_unique_id}` : `/` + follower.otherUser.user_unique_id}
                              onClick={handleToggle}
                            >
                              {follower.otherUser.name}
                            </Link>
                          </Media>
                        ))}
                      {props.following.data.followers.length > 6 && (
                        <Media as="li">
                          <Link to={isMobile ? "/home" : "/w/home"} onClick={handleToggle}>
                            {t("show_more")}
                          </Link>
                        </Media>
                      )}
                    </ul>
                  </Media>
                )}
              {localStorage.getItem("userId") ?
                <Media as="li" className="nav-item">
                  <Link to="/logout" className="nav-link">
                    <span>
                      <Image
                        className="mobile-menu-icon"
                        src={
                          window.location.origin +
                          "/assets/images/fitness/menu/logout-icon.svg"
                        }
                      />
                    </span>
                    {t("logout")}
                  </Link>
                </Media>
                :
                null
              }
            </ul>
          </nav>
        </Container>
      </div >

      {walletToken && (
        <AddWalletTokenModal
          walletToken={walletToken}
          closeWalletTokenModal={closeWalletTokenModal}
          setWalletToken={setWalletToken}
        />
      )
      }
      {
        paymentPost && (
          <PayAndSeePostModal
            paymentPost={paymentPost}
            closePaymentPost={closePaymentPost}
            setPaymentPost={setPaymentPost}
          />
        )
      }
      {
        videoTraining && (
          <VideoTrainingModal
            videoTraining={videoTraining}
            closeVideoTrainingModal={closeVideoTrainingModal}
          />
        )
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  following: state.follow.following,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(NewLatestHeader));
