
import React, { useEffect, useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Media,
  Image,
  Nav,
  Tab,
  Button,
} from "react-bootstrap";

import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";

import ModelVideoSec from "./ModelVideoSec";

import { useParams } from "react-router";
import { useHistory } from "react-router";
import { fetchPostsStart } from "../../store/actions/PostAction";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

const PostsIndex = (props) => {


  const [activeSec, setActiveSec] = useState("video");

  const params = useParams();

  const history = useHistory();


  console.log(props.posts);


  useEffect(() => {
    // fetchUserProfile();
    // if (props.posts.loading) props.dispatch(fetchPostsStart({ type: "video" }));
  }, []);



  const setActiveSection = (event, key) => {
    setActiveSec(key);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="coach-single-profile-sec edit-post-work-out-sec">

        <div className="coach-single-profile-tab-sec">
          <Container>
            <Row>
              <Col md={12}>
                <ul className="nav nav-tabs edit-profile-tabs" role="tablist">
                  <Media
                    as="li"
                    role="presentation"
                    className={props.activeSec === "settings-card" ? "active" : ""}
                  >
                    <Link
                      to={isMobile ? "/home" : "/w/home"}
                      className="bookmarkes-list"
                      onClick={() => props.history.goBack()}
                    >
                      <Image
                        src={window.location.origin + "/assets/images/icons/back.svg"}
                        className="svg-clone"
                      />
                      {t("posts_list")}
                    </Link>
                  </Media>
                </ul>
                <Tab.Container id="left-tabs-example" defaultActiveKey="programs">
                  <Row>
                    <Col sm={12} className="mt-3 mb-5">
                      {activeSec === "video" &&
                        <ModelVideoSec
                          activeSec={activeSec}
                          setActiveSec={setActiveSec}
                          userPosts={props.posts}
                          scrollToTop={scrollToTop}
                          otherUserUniquId={localStorage.getItem("user_unique_id")}
                          enableEditOption={1}
                        />
                      }
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  posts: state.post.posts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(PostsIndex));
