import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";
import {
  FETCH_WALLET_DETAILS_START,
  ADD_MONEY_VIA_BANK_START,
  ADD_MONEY_VIA_CARD_START,
  ADD_MONEY_VIA_PAYPAL_START,
  ADD_MONEY_VIA_PAYFAST_START,
  FETCH_TOKEN_TRANSACTION_HISTORY_START,
  FETCH_MORE_TOKEN_TRANSACTION_HISTORY_START,
  WALLET_HISTORY_EXPORT_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchWalletDetailsSuccess,
  fetchWalletDetailsFailure,
  addMoneyViaCardSuccess,
  addMoneyViaCardFailure,
  addMoneyViaBankSuccess,
  addMoneyViaBankFailure,
  addMoneyViaPaypalSuccess,
  addMoneyViaPaypalFailure,
  addMoneyViaPayfastSuccess,
  addMoneyViaPayfastFailure,
  fetchTokenTransactionHistorySuccess,
  fetchTokenTransactionHistoryFailure,
  walletHistoryExportSuccess,
  walletHistoryExportFailure,
  fetchWalletDetailsStart,
  fetchTokenTransactionHistoryStart,
} from "../actions/WalletAction";
import { checkLogoutStatus } from "../actions/ErrorAction";

function* fetchWalletDetailsAPI(action) {
  try {
    const response = yield api.postMethod("wallets_index", action.data);
    if (response.data.success) {
      yield put(fetchWalletDetailsSuccess(response.data.data));
      // Do nothing
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchWalletDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchWalletDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* addMoneyViaCardAPI() {
  try {
    const inputData = yield select((state) => state.wallet.addMoneyInput.data);
    const response = yield api.postMethod(
      "wallets_add_money_by_stripe",
      inputData
    );
    if (response.data.success) {
      yield put(addMoneyViaCardSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/wallet");
    } else {
      yield put(addMoneyViaCardFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addMoneyViaCardFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* addMoneyViaBankAPI() {
  try {
    const inputData = yield select((state) => state.wallet.addMoneyInput.data);
    const response = yield api.postMethod(
      "wallets_add_money_by_bank_account",
      inputData
    );
    if (response.data.success) {
      yield put(addMoneyViaBankSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/wallet");
    } else {
      yield put(addMoneyViaBankFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addMoneyViaBankFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* addMoneyViaPaypalAPI() {
  try {
    const inputData = yield select((state) => state.wallet.addMoneyInput.data);
    const response = yield api.postMethod(
      "wallets_add_money_by_paypal",
      inputData
    );
    if (response.data.success) {
      yield put(addMoneyViaPaypalSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchWalletDetailsStart({trainer_id:response.data.data.to_user_id}));
      yield put(fetchTokenTransactionHistoryStart({trainer_unique_code:response.data.data.trainer_unique_id}));

      // window.location.assign("/wallet");
    } else {
      yield put(addMoneyViaPaypalFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addMoneyViaPaypalFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* addMoneyViaPayfastAPI() {
  try {
    const inputData = yield select((state) => state.wallet.addMoneyInput.data);
    const response = yield api.postMethod(
      "wallets_add_money_by_payfast",
      inputData
    );
    if (response.data.success) {
      yield put(addMoneyViaPayfastSuccess(response.data.data));
      window.location.replace(response.data.data.redirect_url);
    } else {
      yield put(addMoneyViaPayfastFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addMoneyViaPayfastFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* fetchTokenTransactionHistoryAPI(action) {
  try {
    const response = yield api.postMethod("token_transaction_history", action.data);
    if (response.data.success) {
      yield put(fetchTokenTransactionHistorySuccess(response.data.data));
    } else {
      yield put(fetchTokenTransactionHistoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTokenTransactionHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* walletHistoryExportAPI(action) {
  try {
    const response = yield api.fileMethod("wallets_history_export", action.data);
    const outputFilename = `wallet_history_${Date.now()}.xlsx`;

    if (response.data) {
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      yield put(walletHistoryExportFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(walletHistoryExportFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_WALLET_DETAILS_START, fetchWalletDetailsAPI),
    yield takeLatest(ADD_MONEY_VIA_BANK_START, addMoneyViaBankAPI),
    yield takeLatest(ADD_MONEY_VIA_CARD_START, addMoneyViaCardAPI),
    yield takeLatest(ADD_MONEY_VIA_PAYPAL_START, addMoneyViaPaypalAPI),
    yield takeLatest(ADD_MONEY_VIA_PAYFAST_START, addMoneyViaPayfastAPI),
    yield takeLatest(FETCH_TOKEN_TRANSACTION_HISTORY_START, fetchTokenTransactionHistoryAPI),
    yield takeLatest(FETCH_MORE_TOKEN_TRANSACTION_HISTORY_START, fetchTokenTransactionHistoryAPI),
    yield takeLatest(WALLET_HISTORY_EXPORT_START, walletHistoryExportAPI),
  ]);
}
