import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_NOTIFICATIONS_START,
  SEND_PUSH_NOTIFICATION_START,
  SEND_EMAIL_NOTIFICATION_START,
  SEND_NOTIFICATION_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

import {
  fetchNotificationsFailure,
  fetchNotificationsSuccess,
  sendEmailNotificationFailure,
  sendEmailNotificationSuccess,
  sendPushNotificationSuccess,
  sendPushNotificationFailure,
  sendNotificationFailure,
  sendNotificationSuccess,
} from "../actions/NotificationAction";

function* fetchNotificationAPI() {
  try {
    const inputData = yield select(
      (state) => state.notification.notification.inputData
    );
    const response = yield api.postMethod(
      "bell_notifications_index",
      inputData
    );
    if (response.data.success) {
      yield put(fetchNotificationsSuccess(response.data.data));
    } else {
      yield put(fetchNotificationsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchNotificationsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* sendPushNotificationAPI(action) {
  try {
    const response = yield api.postMethod(
      "send_push_notification",
      action.data
    );
    if (response.data.success) {
      yield put(sendPushNotificationSuccess(response.data.data));
    } else {
      yield put(sendPushNotificationFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(sendPushNotificationFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* sendEmailNotificationAPI(action) {
  try {
    const response = yield api.postMethod(
      "send_email_notification",
      action.data
    );
    if (response.data.success) {
      yield put(sendEmailNotificationSuccess(response.data.data));
    } else {
      yield put(sendEmailNotificationFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(sendEmailNotificationFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* sendNotificationAPI(action) {
  try {
    const response = yield api.postMethod(
      "send_notification",
      action.data
    );
    if (response.data.success) {
      yield put(sendNotificationSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.reload();
    } else {
      yield put(sendNotificationFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(sendNotificationFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_NOTIFICATIONS_START, fetchNotificationAPI),
    yield takeLatest(SEND_PUSH_NOTIFICATION_START, sendPushNotificationAPI),
    yield takeLatest(SEND_EMAIL_NOTIFICATION_START, sendEmailNotificationAPI),
    yield takeLatest(SEND_NOTIFICATION_START, sendNotificationAPI),
  ]);
}
