import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Container, Row, Col, Image, Media } from "react-bootstrap";
import "../Accounts/Payments/AddBankIndex.css";
import { translate, t } from "react-multi-lang";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { Link } from "react-router-dom";
import {
    sendNotificationStart,
} from "../../store/actions/NotificationAction";
import { isMobile } from "react-device-detect";

const SendNotificationIndex = (props) => {
    const [inputData, setInputData] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(sendNotificationStart(inputData));
    };

    return (
        <div className="card-list-sec create-post notification-setting-sec">
            <Container>
                <ul className="nav" role="tablist">
                    <Media
                        as="li"
                        role="presentation"
                        className={props.activeSec === "settings-card" ? "active" : ""}
                    >
                        <Link
                            to={isMobile ? "/home" : "/w/home"}
                            className="bookmarkes-list"
                            onClick={() => props.history.goBack()}
                        >
                            <Image
                                src={window.location.origin + "/assets/images/icons/back.svg"}
                                className="svg-clone"
                            />
                        </Link>
                    </Media>
                </ul>
                <h4 className="head-title">{t("send_notification")}</h4>
                <Row>
                    <Col sm={12} md={12}>
                        <div className="add-bank-box bulk-message-padding">
                            <Form onSubmit={handleSubmit}>
                                <Col sm={12} md={12}>
                                    <div className="post-group-btn-sec">
                                        <Form.Group className="sent-tip-modal">
                                            <Form.Label>Notification Type:</Form.Label>
                                            {["radio"].map((type) => (
                                                <div key={`custom-inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Email Notification"
                                                        type={type}
                                                        id="email"
                                                        value="email"
                                                        name="notification_type"
                                                        onChange={(event) =>
                                                            setInputData({
                                                                ...inputData,
                                                                notification_type: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                    {/* <Form.Check
                                                        custom
                                                        inline
                                                        label="Push Notification"
                                                        type={type}
                                                        id="push"
                                                        value="push"
                                                        name="notification_type"
                                                        onChange={(event) =>
                                                            setInputData({
                                                                ...inputData,
                                                                notification_type: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Both"
                                                        type={type}
                                                        id="both"
                                                        value="both"
                                                        name="notification_type"
                                                        onChange={(event) =>
                                                            setInputData({
                                                                ...inputData,
                                                                notification_type: event.currentTarget.value,
                                                            })
                                                        }
                                                    /> */}
                                                </div>
                                            ))}
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="post-group-btn-sec">
                                        <Form.Group className="sent-tip-modal">
                                            <Form.Label>Clients:</Form.Label>
                                            {["radio"].map((type) => (
                                                <div key={`custom-inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="All Clients"
                                                        type={type}
                                                        id="all"
                                                        value="2"
                                                        name="subscription_type"
                                                        onChange={(event) =>
                                                            setInputData({
                                                                ...inputData,
                                                                subscription_type: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Active Clients"
                                                        type={type}
                                                        id="active"
                                                        value="1"
                                                        name="subscription_type"
                                                        onChange={(event) =>
                                                            setInputData({
                                                                ...inputData,
                                                                subscription_type: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Expired Clients"
                                                        type={type}
                                                        id="expired"
                                                        value="3"
                                                        name="subscription_type"
                                                        onChange={(event) =>
                                                            setInputData({
                                                                ...inputData,
                                                                subscription_type: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="formHorizontalNickname">
                                        <Form.Label>{t("title")}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Title"
                                            name="title"
                                            onChange={(event) => {
                                                setInputData({
                                                    ...inputData,
                                                    title: event.currentTarget.value,
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="formHorizontalNickname">
                                        <Form.Label>{t("content")}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Content"
                                            name="message"
                                            onChange={(event) => {
                                                setInputData({
                                                    ...inputData,
                                                    message: event.currentTarget.value,
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <div className="edit-save">
                                    <Button
                                        className="save-btn"
                                        type="submit"
                                    >
                                        {t("send")}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToPros = (state) => ({
    notification: state.notifications.notification,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(SendNotificationIndex));
