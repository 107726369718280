import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Container, Row, Col, Image, Media } from "react-bootstrap";
import "../Accounts/Payments/AddBankIndex.css";
import { translate, t } from "react-multi-lang";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { Link } from "react-router-dom";

import {
    fetchUserDetailsStart,
    notificationStatusUpdateStart,
} from "../../store/actions/UserAction";
import { isMobile } from "react-device-detect";

const NotificationSettingsIndex = (props) => {
    const [inputData, setInputData] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(notificationStatusUpdateStart(inputData));
    };

    useEffect(() => {
        if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
    }, []);


    return (
        <div className="card-list-sec create-post notification-setting-sec">
            <Container>
                <ul className="nav" role="tablist">
                    <Media
                        as="li"
                        role="presentation"
                        className={props.activeSec === "settings-card" ? "active" : ""}
                    >
                        <Link
                            to={isMobile ? "/home" : "/w/home"}
                            className="bookmarkes-list"
                            onClick={() => props.history.goBack()}
                        >
                            <Image
                                src={window.location.origin + "/assets/images/icons/back.svg"}
                                className="svg-clone"
                            />
                        </Link>
                    </Media>
                </ul>
                <h4 className="head-title">{t("notification_settings")}</h4>
                {props.profile.loading ? (
                    t("loading")
                ) : (
                    <Row>
                        <Col sm={12} md={12}>
                            <div className="add-bank-box bulk-message-padding">
                                <Form onSubmit={handleSubmit}>
                                    <Col sm={12} md={12}>
                                        <div className="post-group-btn-sec">
                                            <Form.Group className="sent-tip-modal">
                                                <Form.Label>Enable Email Notification:</Form.Label>
                                                {["radio"].map((type) => (
                                                    <div key={`custom-inline-${type}`} className="mb-3">
                                                        <Form.Check
                                                            custom
                                                            inline
                                                            label="Yes"
                                                            type={type}
                                                            id="yes"
                                                            value="1"
                                                            defaultChecked={
                                                                props.profile.data.is_email_notification == "1" ? true : false
                                                            }
                                                            name="is_email_notification"
                                                            onChange={(event) =>
                                                                setInputData({
                                                                    ...inputData,
                                                                    is_email_notification: event.currentTarget.value,
                                                                })
                                                            }
                                                        />
                                                        <Form.Check
                                                            custom
                                                            inline
                                                            label="No"
                                                            type={type}
                                                            defaultChecked={
                                                                props.profile.data.is_email_notification == "0" ? true : false
                                                            }
                                                            id="no"
                                                            value="0"
                                                            name="is_email_notification"
                                                            onChange={(event) =>
                                                                setInputData({
                                                                    ...inputData,
                                                                    is_email_notification: event.currentTarget.value,
                                                                })
                                                            }
                                                        />

                                                    </div>
                                                ))}
                                            </Form.Group>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="post-group-btn-sec">
                                            <Form.Group className="sent-tip-modal">
                                                <Form.Label>Enable Push Notification:</Form.Label>
                                                {["radio"].map((type) => (
                                                    <div key={`custom-inline-${type}`} className="mb-3">
                                                        <Form.Check
                                                            custom
                                                            inline
                                                            label="Yes"
                                                            type={type}
                                                            id="push-yes"
                                                            value="1"
                                                            defaultChecked={
                                                                props.profile.data.is_push_notification == "1" ? true : false
                                                            }
                                                            name="is_push_notification"
                                                            onChange={(event) =>
                                                                setInputData({
                                                                    ...inputData,
                                                                    is_push_notification: event.currentTarget.value,
                                                                })
                                                            }
                                                        />
                                                        <Form.Check
                                                            custom
                                                            inline
                                                            label="No"
                                                            type={type}
                                                            id="push-no"
                                                            value="0"
                                                            defaultChecked={
                                                                props.profile.data.is_push_notification == "0" ? true : false
                                                            }
                                                            name="is_push_notification"
                                                            onChange={(event) =>
                                                                setInputData({
                                                                    ...inputData,
                                                                    is_push_notification: event.currentTarget.value,
                                                                })
                                                            }
                                                        />

                                                    </div>
                                                ))}
                                            </Form.Group>
                                        </div>
                                    </Col>

                                    <div className="edit-save">
                                        <Button
                                            className="save-btn"
                                            type="submit"
                                        >
                                            {t("save")}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
};

const mapStateToPros = (state) => ({
    profile: state.users.profile,
    notification: state.notifications.notification,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(NotificationSettingsIndex));
