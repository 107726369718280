import React from "react";
import { Link } from "react-router-dom";
import { Image, Media } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { isMobile } from "react-device-detect";

const EditProfileTabSec = (props) => {
  return (
    <ul className="nav nav-tabs edit-profile-tabs" role="tablist">
      <Media
        as="li"
        role="presentation"
        className={props.activeSec === "settings-card" ? "active" : ""}
      >
        <Link
          to={isMobile ? "/home" : "/w/home"}
          className="bookmarkes-list"
          // aria-controls="profile"
          // role="tab"
          // data-toggle="tab"
          // onClick={() => props.setActiveSec("settings-card")}
        >
          <Image
            src={window.location.origin + "/assets/images/icons/back.svg"}
            className="svg-clone"
          />
          {t("settings")}
        </Link>
        <div className="tab-sec-head bt-style">Account Management</div>
      </Media>
      <Media
        as="li"
        role="presentation"
        className={props.activeSec === "profile-card" ? "active" : ""}
      >
        <Link
          to="#Section2"
          aria-controls="profile"
          role="tab"
          className="bookmarkes-list"
          data-toggle="tab"
          onClick={() => props.setActiveSec("profile-card")}
        >
          <Image src="assets/images/icons/profile.svg" className="svg-clone" />
          {t("profile")}
        </Link>
      </Media>
      {localStorage.getItem("is_content_creator") == 2 ? (
        <>
          <Media as="li" role="presentation">
            <Link to={"/dashboard"} className="bookmarkes-list">
              <Image
                src="assets/images/icons/analytics.svg"
                className="svg-clone"
              />
              {t("dashboard")}
            </Link>
          </Media>
          <Media as="li" role="presentation">
            <Link to={"/posts"} className="bookmarkes-list">
              {/* <Image src="assets/images/icons/settings.svg" className="svg-clone" /> */}
              <Image
                src={
                  window.location.origin +
                  "/assets/images/post/post-video-upload.svg"
                }
                className="svg-clone"
              />
              {t("posts_list")}
            </Link>
          </Media>
          {/* <Media
            as="li"
            role="presentation"
            className={props.activeSec === "trainer-service" ? "active" : ""}
          >
            <Link
              to="#Section7"
              aria-controls="profile"
              role="tab"
              className="bookmarkes-list"
              data-toggle="tab"
              onClick={() => props.setActiveSec("trainer-service")}
            >
              <Image src="assets/images/icons/lists.svg" className="svg-clone" />
              {t("trainer_services")}
            </Link>
          </Media> */}
          <Media
            as="li"
            role="presentation"
            className={props.activeSec === "send-notification" ? "active" : ""}
          >
            <Link to={"/send-notification"} className="bookmarkes-list">
              <Image
                src="assets/images/icons/notification1.svg"
                className="svg-clone"
              />
              {t("send_notification")}
            </Link>
          </Media>
          <Media as="li" role="presentation">
            <Link to={"/schedule-availability"} className="bookmarkes-list">
              <Image
                src="assets/images/icons/analytics.svg"
                className="svg-clone"
              />
              {t("schedule_availability")}
            </Link>
          </Media>
        </>
      ) : null}
      <Media
        as="li"
        role="presentation"
        className={props.activeSec === "notification-settings" ? "active" : ""}
      >
        <Link to={"/notification-settings"} className="bookmarkes-list">
          <Image
            src="assets/images/icons/notification1.svg"
            className="svg-clone"
          />
          {t("notification_settings")}
        </Link>
      </Media>
      <Media
        as="li"
        role="presentation"
        className={props.activeSec === "change-password-card" ? "active" : ""}
      >
        <Link
          to="#Section3"
          aria-controls="profile"
          role="tab"
          className="bookmarkes-list"
          data-toggle="tab"
          onClick={() => props.setActiveSec("change-password-card")}
        >
          <Image src="assets/images/icons/change-1.svg" className="svg-clone" />
          {t("change_password")}
        </Link>
      </Media>
      <Media
        as="li"
        role="presentation"
        className={
          props.activeSec === "session-management-card" ? "active" : ""
        }
      >
        <Link
          to="#session-management"
          aria-controls="profile"
          role="tab"
          className="bookmarkes-list d-flex"
          data-toggle="tab"
          onClick={() => props.setActiveSec("session-management-card")}
        >
          <Image
            src="assets/images/fitness/icons/session-management.svg"
            className="svg-clone-1"
          />
          {t("session_management")}
        </Link>
      </Media>
      {/* <Media
        as="li"
        role="presentation"
        className={props.activeSec === "delete-account-card" ? "active" : ""}
      >
        <Link
          to="#Section4"
          aria-controls="profile"
          role="tab"
          className="bookmarkes-list"
          data-toggle="tab"
          onClick={() => props.setActiveSec("delete-account-card")}
        >
          <Image src="assets/images/icons/delete.png" className="svg-clone" />
          {t("delete_account")}
        </Link>
      </Media> */}
      <div className="tab-sec-head bb-style">{t("monetization")}</div>

      {configuration.get("configData.is_one_to_one_call_enabled") == 1 ? (
        <>
          {localStorage.getItem("is_content_creator") != 2 ? (
            <Media
              as="li"
              role="presentation"
              className={props.activeSec === "video-calls-card" ? "active" : ""}
            >
              <Link
                to={"/video-calls-sent"}
                className="bookmarkes-list"
                onClick={() => props.setActiveSec("video-calls-card")}
              >
                <Image
                  src={
                    window.location.origin +
                    "/assets/images/icons/video-camera.png"
                  }
                  className="svg-clone"
                />
                {t("video_call_request_sent")}
              </Link>
            </Media>
          ) : (
            <Media
              as="li"
              role="presentation"
              className={
                props.activeSec === "video-calls-received-card" ? "active" : ""
              }
            >
              <Link
                to={"/video-calls-received"}
                className="bookmarkes-list"
                onClick={() => props.setActiveSec("video-calls-received-card")}
              >
                <Image
                  src={
                    window.location.origin +
                    "/assets/images/icons/video-camera.png"
                  }
                  className="svg-clone"
                />
                {t("video_call_request_received")}
              </Link>
            </Media>
          )}
          {configuration.get("configData.is_one_to_many_call_enabled") == 1 ? (
            localStorage.getItem("is_content_creator") == 2 ? (
              <Media
                as="li"
                role="presentation"
                className={props.activeSec === "live-videos" ? "active" : ""}
              >
                <Link to={"/live-videos-history"} className="bookmarkes-list">
                  <Image
                    src={
                      window.location.origin + "/assets/images/icons/live.svg"
                    }
                    className="svg-clone"
                  />
                  {t("live_history")}
                </Link>
              </Media>
            ) : (
              <Media
                as="li"
                role="presentation"
                className={props.activeSec === "live-videos" ? "active" : ""}
              >
                <Link 
                  to={isMobile ? "/live-videos" : "/w/live-videos"}
                  className="bookmarkes-list"
                >
                  <Image
                    src={
                      window.location.origin + "/assets/images/icons/live.svg"
                    }
                    className="svg-clone"
                  />
                  {t("live_videos")}
                </Link>
              </Media>
            )
          ) : (
            ""
          )}
          {localStorage.getItem("is_content_creator") != 2 ? (
            <>
              <Media
                as="li"
                role="presentation"
                className={
                  props.activeSec === "audio-calls-sent" ? "active" : ""
                }
              >
                <Link
                  to={"/audio-calls-sent"}
                  className="bookmarkes-list"
                  onClick={() => props.setActiveSec("audio-calls-sent")}
                >
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/icons/audio-call.svg"
                    }
                    className="svg-clone"
                  />
                  {t("audio_call_request_sent")}
                </Link>
              </Media>
              <Media
                as="li"
                role="presentation"
                className={
                  props.activeSec === "scheduled-calls" ? "active" : ""
                }
              >
                <Link
                  to={isMobile ? "/scheduled-live-videos" : "/w/scheduled-live-videos"}
                  className="bookmarkes-list"
                  onClick={() => props.setActiveSec("scheduled-calls")}
                >
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/icons/audio-call.svg"
                    }
                    className="svg-clone"
                  />
                  {t("scheduled_videos")}
                </Link>
              </Media>
            </>
          ) : (
            <Media
              as="li"
              role="presentation"
              className={
                props.activeSec === "audio-calls-received-card" ? "active" : ""
              }
            >
              <Link
                to={"/audio-calls-received"}
                className="bookmarkes-list"
                onClick={() => props.setActiveSec("audio-calls-received-card")}
              >
                <Image
                  src={
                    window.location.origin +
                    "/assets/images/icons/audio-call.svg"
                  }
                  className="svg-clone"
                />
                {t("audio_call_request_received")}
              </Link>
            </Media>
          )}
          {localStorage.getItem("is_content_creator") == 2 && (
            <Media
              as="li"
              role="presentation"
              className={props.activeSec === "ecom-card" ? "active" : ""}
            >
              <Link
                to={"/product-list"}
                className="bookmarkes-list"
                onClick={() => props.setActiveSec("ecom-card")}
              >
                <Image
                  src={
                    window.location.origin +
                    "/assets/images/ecom/shopping-bag-black.svg"
                  }
                  className="svg-clone"
                />
                {t("products")}
              </Link>
            </Media>
          )}
        </>
      ) : null}

      <div className="tab-sec-head bb-style">{t("security")}</div>

      <Media
        as="li"
        role="presentation"
        className={props.activeSec === "two_factor" ? "active" : ""}
      >
        <Link
          to="#two_factor"
          aria-controls="profile"
          role="tab"
          className="bookmarkes-list"
          data-toggle="tab"
          onClick={() => props.setActiveSec("two_factor")}
        >
          <i className="fas fa-lock svg-clone icon"></i>
          {t("two_step_authentication")}
        </Link>
      </Media>
      {localStorage.getItem("is_content_creator") == 2 ? (
        <Media
          as="li"
          role="presentation"
          className={props.activeSec === "availability_status" ? "active" : ""}
        >
          <Link
            to="#availabitiy"
            aria-controls="profile"
            role="tab"
            className="bookmarkes-list"
            data-toggle="tab"
            onClick={() => props.setActiveSec("availability_status")}
          >
            <Image
              src="assets/images/icons/availability.svg"
              className="svg-clone"
            />
            {t("availability_status")}
          </Link>
        </Media>
      ) : (
        ""
      )}
      {localStorage.getItem("is_content_creator") == 2 ? (
        <>
          <div className="tab-sec-head bb-style">{t("lookUps")}</div>

          {/* <Media
            as="li"
            role="presentation"
            className={props.activeSec === "account-card" ? "active" : ""}
          >
            <Link
              to={{
                pathname: "/add-bank",
                state: {
                  prevPath: props.location.pathname,
                },
              }}
              className="bookmarkes-list"
            >
              <Image src="assets/images/icons/account.svg" className="svg-clone" />
              {t("add_bank")}
            </Link>
          </Media> */}

          {/* <Media
            as="li"
            role="presentation"
            className={props.activeSec === "security-card" ? "active" : ""}
          >
            <Link to={`/document-upload`} className="bookmarkes-list">
              <Image
                src="assets/images/icons/documents.png"
                className="svg-clone"
              />
              {t("documents")}
            </Link>
          </Media> */}
          {/* <Media
            as="li"
            role="presentation"
            className={props.activeSec === "bank-accounts" ? "active" : ""}
          >
            <Link to={`/billing-accounts`} className="bookmarkes-list">
              <Image
                src="assets/images/icons/merchant-account.png"
                className="svg-clone"
              />
              {t("bank_accounts")}
            </Link>
          </Media> */}
        </>
      ) : (
        ""
      )}
      <Media
        as="li"
        role="presentation"
        className={props.activeSec === "help" ? "active" : ""}
      >
        <Link to={`/support`} className="bookmarkes-list">
          <i className="fa fa-info-circle svg-clone icon"></i>
          {t("help_and_support")}
        </Link>
      </Media>
    </ul>
  );
};

export default translate(EditProfileTabSec);
