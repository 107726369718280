import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "./Loader.css";

const CalenderLoader = () => {
  return (
    <>
      <div className="calender-loader">
        <Skeleton count={8} />
      </div>
    </>
  );
};

export default CalenderLoader;
