import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import {
  saveVideoCallRequestStart,
  clearVideoCallRequestStart,
} from "../../store/actions/VideoCallAction";
import DateTimePicker from "react-datetime-picker";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import dayjs from "dayjs";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";

import { fetchTrainersAvailabilityUserStart } from "../../store/actions/AvailabilityAction";

const VideoTrainingModal = (props) => {
  // const today = utils().getToday();

  const currentDate = new Date(new Date().toLocaleString('en', { timeZone: props.profile.data.timezone }));

  const today = { year: currentDate.getFullYear(), month: parseInt(currentDate.getMonth() + 1), day: currentDate.getDate() };

  const [selectedDay, setSelectedDay] = useState(today);

  const [errorMsg, setErrorMsg] = useState("");

  const [selectedTime, setSelectedTime] = useState();

  const [selectedStartEndTime, setSelectedStartEndTime] = useState({
    start_time: "",
    end_time: "",
  });

  const formatNumber = (num) => {
    if (num < 10) return "0" + num;
    else return num;
  };

  useEffect(() => {
    setSelectedTime();
    setSelectedStartEndTime({});
    let currentDate = dayjs(new Date())
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("YYYY-MM-DD HH:mm");

    props.dispatch(
      fetchTrainersAvailabilityUserStart({
        date:
          selectedDay.year + "-" + formatNumber(selectedDay.month) + "-" + formatNumber(selectedDay.day),
        trainer_id: props.userDetails.user_id,
        user_time_now: currentDate,
      })
    );
  }, [selectedDay]);

  const TimeButton = ({ time }) => {
    return (
      <Button
        disabled={time.is_slot_booked == 1}
        className={`time-btn 
        ${selectedTime === time.start_time + time.end_time ? "active" : ""}
        ${time.is_slot_booked == 1 ? "strike-off" : ""}
        `}
        onClick={() => {
          if (time.is_slot_booked == 0) {
            setSelectedStartEndTime({
              start_time: time.start_time,
              end_time: time.end_time,
            });
            setSelectedTime(time.start_time + time.end_time);
            setErrorMsg("");
          }
        }}
      >
        {time.start_time_for_user} - {time.end_time_for_user}
      </Button>
    );
  };

  let selectedDate = new Date(
    `${selectedDay.year}-${formatNumber(selectedDay.month)}-${formatNumber(selectedDay.day)}`
  );

  const [trainerServiceId, setTrainerServiceId] = useState("");
  const [videoTrainingProceed, setVideoTrainingProceed] = useState(false);

  useEffect(() => {
    if (
      !props.videocall.loading &&
      props.videocall.data &&
      props.videocall.data.success
    ) {
      props.closeVideoTrainingModal();
      props.dispatch(clearVideoCallRequestStart());
    }
  }, [props.videocall.data]);

  const handleSubmit = (event) => {
    if (selectedDay && selectedStartEndTime) {
      let scheduleDate =
        selectedDay.year +
        "-" +
        formatNumber(selectedDay.month) +
        "-" +
        formatNumber(selectedDay.day);
      props.dispatch(
        saveVideoCallRequestStart({
          date: scheduleDate,
          start_time: selectedStartEndTime.start_time,
          end_time: selectedStartEndTime.end_time,
          model_id: props.userDetails.user_id,
          trainer_service_id: trainerServiceId,
        })
      );
    } else {
      const notificationMessage = getErrorNotificationMessage(
        t("select_a_time_slot")
      );
      props.dispatch(createNotification(notificationMessage));
      setErrorMsg(t("select_a_time_slot"));
    }
  };

  return (
    <>
      <Modal
        className="modal-dialog-center video-training-modal video-training-modal-lg"
        size="md"
        centered
        show={props.videoTraining}
        onHide={props.closeVideoTrainingModal}
      >
        <Form>
          <Modal.Header>
            <Modal.Title>{t("video_training")}</Modal.Title>
            <button
              type="button"
              className="close"
              onClick={props.closeVideoTrainingModal}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("close")}</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            {videoTrainingProceed ? (
              <>
                <Form className="video-training-proceed-form">
                  <Form.Group className="mb-3">
                    {/* <Form.Label>Date and Time</Form.Label> */}
                    {/* <DateTimePicker
                      onChange={setStartTime}
                      className="floating-input"
                      name="start_time"
                      required={true}
                      value={startTime}
                      isClockOpen={false}
                      minDate={new Date()}
                      format={"y-MM-dd h:mm:ss a"}
                    /> */}
                    <div className="video-trainer-item">
                    <h4>
                      {selectedDate.toLocaleString("en-us", {
                        weekday: "long",
                      })}
                      ,{" "}
                      {selectedDate.toLocaleString("default", {
                        month: "long",
                      })}{" "}
                      {selectedDay.day}
                    </h4>
                    <h5 className="call_schedule_trainer_time">
                      Trainer's Time:
                      <span>
                        <b>{props.userDetails.time_formatted}</b>
                      </span>
                    </h5>
                    </div>
                    <div className="video-training-proceed-box">
                      <Calendar
                        value={selectedDay}
                        onChange={setSelectedDay}
                        shouldHighlightWeekends
                        minimumDate={utils().getToday()}
                      // minDate={new Date()}
                      // format={"y-MM-dd h:mm:ss a"}
                      // value={startTime}
                      />
                      {props.availability.loading ? (
                        "Loading..."
                      ) : (
                        <div className="time-picker">
                          <div className="time-picker-box">
                            <div className="time-picker-card">
                              {props.availability.data.trainer_availabilities
                                .length > 0
                                ? props.availability.data.trainer_availabilities.map(
                                  (trainer_slot, index) => (
                                    <TimeButton time={trainer_slot} />
                                  )
                                )
                                : null}
                            </div>
                            <div className="text-danger large">{errorMsg}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  {!props.trainerServices.loading &&
                    props.trainerServices.data.trainer_services &&
                    props.trainerServices.data.trainer_services.length > 0 ? (
                    <Form.Group className="mb-3 mt-5">
                      <select
                        aria-label="Default select example"
                        className="form-select form-control"
                        onChange={(e) => setTrainerServiceId(e.target.value)}
                      >
                        <option value="">
                          {t("select_training_services")}
                        </option>
                        {props.trainerServices.data.trainer_services.map(
                          (trainerService) => (
                            <option value={trainerService.trainer_service_id}>
                              {trainerService.service}
                            </option>
                          )
                        )}
                      </select>
                    </Form.Group>
                  ) : null}
                </Form>
              </>
            ) : (
              <>
                <h4>{t("session")}</h4>
                <p>{t("videotraining_note")}</p>
                <h2>{props.userDetails.video_call_amount_formatted}/Hour</h2>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="mb-5">
            <Button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              onClick={props.closeVideoTrainingModal}
            >
              {t("cancel")}
            </Button>
            <Button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
              onClick={
                videoTrainingProceed
                  ? handleSubmit
                  : () => setVideoTrainingProceed(true)
              }
              disabled={props.videocall.buttonDisable}
            >
              {videoTrainingProceed ? t("send_request") : t("proceed")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* {videoTrainingProceed && (
      <VideoTrainingProceedModal videoTrainingProceed={videoTrainingProceed} closeVideoTrainingProceedModal={closeVideoTrainingProceedModal} />
      )} */}
    </>
  );
};

const mapStateToPros = (state) => ({
  videocall: state.videocall.saveVideoCallRequest,
  availability: state.trainerAvailability.trainerAvailability,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(VideoTrainingModal));
