import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Table, Image } from "react-bootstrap";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import { fetchAllTransactionStart, fetchMoreTransactionStart } from "../../store/actions/TransactionAction";
import WithdrawModal from "../helper/WithdrawModal";
import AddWalletTokenModal from "../helper/AddWalletTokenModal";
import NoDataFound from "../NoDataFound/NoDataFound";
import WalletLoader from "../Loader/WalletLoader";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import CommonCenterLoader from "../Loader/CommonCenterLoader";
import {
	fetchTokenTransactionHistoryStart,
	fetchMoreTokenTransactionHistoryStart
} from "../../store/actions/WalletAction";
import InfiniteScroll from "react-infinite-scroll-component";

const SingleTrainerTransactions = (props) => {

	const [colVal, setColValue] = useState(4);

	const [take, setTake] = useState(12);

	useEffect(() => {
		props.dispatch(fetchWalletDetailsStart({
			trainer_unique_code: props.trainerId,
		}));
		props.dispatch(fetchTokenTransactionHistoryStart({
			trainer_unique_code: props.trainerId,
			skip: 0,
			take: take,
		}));
		setColValue(configuration.get("configData.is_referral_enabled") == 1 ? 3 : 4);
	}, []);

	const fetchMoreTokenTransaction = () => {
		props.dispatch(fetchMoreTokenTransactionHistoryStart({
			trainer_unique_code: props.trainerId,
			skip: props.tokenTransactions.data.history.length,
			take: take,
		}));
	}

	const [withdrawModal, setWithdrawModal] = useState(false);

	const [addWalletAmountModal, setAddWalletAmountModal] = useState(false);

	const closeWithdrawModal = () => {
		setWithdrawModal(false);
	};

	const closeAddWalletAmountModal = () => {
		setAddWalletAmountModal(false);
	};

	return (
		<>
			<div className="wallet-sec">
				{props.wallet.loading ? (
					<WalletLoader></WalletLoader>
				) : (
					<Container>
						<Row>
							<Col sm={12} md={12}>
								<div className="wallet-header-sec">
									<Row>
										{/* <Col sm={12} md={6} xl={3}>
											<div className="wallet-header-card">
												<Image
													src={
														window.location.origin +
														"/assets/images/icons/wallet-active.svg"
													}
													className="credit-img"
												/>
												<div className="wallet-header-details">
													<h5>{t("total_balance")}</h5>
													<h3>
														{props.wallet.data.user_wallet.total_formatted}
													</h3>
												</div>
											</div>
										</Col> */}
										<Col sm={12} md={6} xl={3}>
											<div className="wallet-header-card">
												<Image
													src={
														window.location.origin +
														"/assets/images/icons/wallet-dollar.svg"
													}
													className="credit-img"
												/>
												<div className="wallet-header-details">
													<h5>{t("wallet_balance")}</h5>
													<h3>
														{props.wallet.data.user_wallet.remaining_formatted}
														{/* <span className="amount-decimal">.76500293</span>
														<span className="amount-abb">BTC</span> */}
													</h3>
												</div>
											</div>
										</Col>
										{configuration.get("configData.is_referral_enabled") == 1 ?
											<Col sm={12} md={6} xl={3}>
												<div className="wallet-header-card">
													<Image
														src={
															window.location.origin +
															"/assets/images/icons/wallet-dollar.svg"
														}
														className="credit-img"
													/>
													<div className="wallet-header-details">
														<h5>{t("referral_balance")}</h5>
														<h3>
															{props.wallet.data.user_wallet.referral_amount_formatted}
															{/* <span className="amount-decimal">.76500293</span>
															<span className="amount-abb">BTC</span> */}
														</h3>
													</div>
												</div>
											</Col>
											: ''}

										<Col sm={12} md={6} xl={3}>
											<div className="edit-save">
												<Button
													className="send-btn-white mb-3"
													onClick={() => setAddWalletAmountModal(true)}
												>
													{configuration.get("configData.is_only_wallet_payment") == 1 ? t("buy_tokens") : t("add_wallet_amount")}
												</Button>
												{/* <Button
													className="send-btn-white"
													onClick={() => setWithdrawModal(true)}
												>
													{t("withDraw")}
												</Button> */}
											</div>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</Container>
				)}
			</div>
			<div className="trans-table-sec">
				<Container>
					<Row>
						<Col sm={12} md={12}>
							<h4>{t("transactions")}</h4>
							<p>{t("total_records")} - {props.tokenTransactions.data.total}</p>
							<div className="trans-table">
								{props.tokenTransactions.loading ? (
									<CommonCenterLoader></CommonCenterLoader>
								) : props.tokenTransactions.data.history.length > 0 ? (
									<InfiniteScroll
										dataLength={props.tokenTransactions.data.history.length}
										next={fetchMoreTokenTransaction}
										hasMore={props.tokenTransactions.data.history.length < props.tokenTransactions.data.total &&
											props.tokenTransactions.errorCount < 2}
										loader={<CommonCenterLoader />}
									>
										<Table borderedless responsive>
											<tbody>
												{props.tokenTransactions.data.history.map((data) => (
													<tr>
														<td>{data.status_formatted}</td>
														{configuration.get("configData.is_only_wallet_payment") == 0 ?
															<>
																<td className="amount">
																	{data.paid_amount_formatted}{" "}
																	{/* <span className="amout-abb">STRAT</span> */}
																</td>
																<td className="amount">
																	{t("service_fee")} :{" "}{data.admin_amount_formatted}{" "}
																	{/* <span className="amout-abb">STRAT</span> */}
																</td>
															</>
															:
															<>
																<td className="amount">
																	{data.token_formatted}{" "}({data.currency}{data.paid_amount})
																</td>
																<td className="amount">
																	{t("service_fee")} :{" "}{data.admin_token_formatted}{" "}
																</td>
															</>
														}

														{/* <td className="amount">
															<span className="text-capitalize">
																{data.payment_type}{" "}
															</span> */}
															{/* <span className="amout-abb text-muted">TYPE</span> */}
														{/* </td> */}
														<td>
															{t("from")} :{" "}
															{data.received_from_username
																? data.received_from_username
																: t("you")}
														</td>
														<td>{data.payment_id}</td>
														{/* <td>
															<Badge className="unconfirmed-badge">
																unconfirmed
															</Badge>
															<span>now</span>
														</td> */}
													</tr>
												))
												}
											</tbody>
										</Table>
									</InfiniteScroll>
								) : (
									<NoDataFound></NoDataFound>
								)}
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<WithdrawModal
				withdrawModal={withdrawModal}
				closeWithdrawModal={closeWithdrawModal}
				payments={props.wallet}
			/>
			<AddWalletTokenModal
				addWalletAmountModal={addWalletAmountModal}
				closeAddWalletAmountModal={closeAddWalletAmountModal}
				payments={props.wallet}
				trainerId={!props.wallet.loading && props.wallet.data.user_wallet ? props.wallet.data.user_wallet.trainer_id : 0}
			/>
		</>
	);
};

const mapStateToPros = (state) => ({
	wallet: state.wallet.walletData,
	tokenTransactions: state.wallet.tokenTransactions,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(SingleTrainerTransactions));
