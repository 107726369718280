import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Badge,
  Image,
} from "react-bootstrap";
import "../../Wallet/Wallet.css";
import NoDataFound from "../../NoDataFound/NoDataFound";
import BillingAccountLoader from "../../Loader/BillingAccountLoader";
import { translate, t } from "react-multi-lang";
import { Link } from "react-router-dom";

import {
  callRequestReceivedModelStart,
  moreCallRequestReceivedModelStart,
} from "../../../store/actions/PrivateCallAction";
import VideoCallHistoryTable from "./VideoCallHistoryTable";

const VideoCallRequestReceivedIndex = (props) => {
  const [take, setTake] = useState(12);

  useEffect(() => {
    props.dispatch(
      callRequestReceivedModelStart({
        skip: 0,
        take: take,
      })
    );
  }, []);

  const fetchMoreRequests = () => {
    props.dispatch(
      moreCallRequestReceivedModelStart({
        skip: props.callRequestReceivedModel.data.video_call_requests.length,
        take: take,
      })
    );
  };

  return (
    <>
      <div className="wallet-sec live-video-list-header-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div className="wallet-header-sec">
                <Row>
                  <Col sm={12} md={12} xl={8}>
                    <Link
                      className="bookmarkes-list notify-title back-button"
                      onClick={() => props.history.goBack()}
                    >
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/icons/back-white.svg"
                        }
                        className="svg-clone"
                      />
                      <h3 className="ml-2 mb-0">VIDEO SESSIONS</h3>
                    </Link>
                    <h3></h3>
                  </Col>

                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div class="live-session-title">
        <h4>
          <span>
            <Image
              className="live-session-title-icon"
              src={
                window.location.origin +
                "/assets/images/fitness/video-sessions-icon.svg"
              }
            />
          </span>
          VIDEO SESSIONS
        </h4>
      </div>
      <div className="trans-table-sec live-video-history-table-sec">
        <Container>
          {props.callRequestReceivedModel.loading ? (
            <BillingAccountLoader />
          ) : props.callRequestReceivedModel.data.video_call_requests &&
            props.callRequestReceivedModel.data.video_call_requests.length >
            0 ? (
            <Row>
              <Col sm={12} md={12}>
                <VideoCallHistoryTable
                  videoCallRequest={
                    props.callRequestReceivedModel.data.video_call_requests
                  }
                  errorCount={props.callRequestReceivedModel.errorCount}
                  total={props.callRequestReceivedModel.data.total}
                  fetchMore={fetchMoreRequests}
                />
              </Col>
            </Row>
          ) : (
            <NoDataFound />
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  callRequestReceivedModel: state.privateCall.callRequestReceivedModel,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(VideoCallRequestReceivedIndex));
