import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Image } from "react-bootstrap";
import "../Wallet/Wallet.css";
import {
  fetchScheduledLiveVideosStart,
  fetchUserScheduledLiveVideosStart,
} from "../../store/actions/LiveVideoAction";
import { Link } from "react-router-dom";
import NoDataFound from "../NoDataFound/NoDataFound";
import BillingAccountLoader from "../Loader/BillingAccountLoader";
import { translate, t } from "react-multi-lang";
import GoLiveModal from "../helper/GoLiveModal";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import ScheduleLiveDataCard from "./ScheduleLiveDataCard";
import UserLiveVideoLoader from "../Loader/UserLiveVideoLoader";

const ScheduledLiveVideosIndex = (props) => {
  const [goLive, setGoLive] = useState(false);

  useEffect(() => {
    if (props.userDetails.loading) props.dispatch(fetchUserDetailsStart());
    if (localStorage.getItem("is_content_creator") != 2) {
      props.dispatch(fetchUserScheduledLiveVideosStart());
    } else {
      props.dispatch(fetchScheduledLiveVideosStart());
    }
  }, []);

  const closeGoLiveModal = () => {
    setGoLive(false);
  };

  return (
    <>
      {/* <div className="wallet-sec live-video-list-header-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div className="wallet-header-sec">
                <Row className="align-items-center">
                  <Col sm={12} md={12} xl={4}>
                    <Link
                      className="bookmarkes-list notify-title back-button"
                      onClick={() => props.history.goBack()}
                    >
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/icons/back-white.svg"
                        }
                        className="svg-clone"
                      />
                      <h3 className="ml-2 mb-0">{t("scheduled_videos")}</h3>
                    </Link>
                  </Col>
                  <Col sm={12} md={12} xl={10} className="text-right">
                    <div className="resp-btn-align">
                      
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className="live-video-history-sec">
        <div className="live-video-history-header-bg-img-sec">
          <Image
            className="live-video-history-header-bg-img new-header-bg-img"
            src={
              window.location.origin + "/assets/images/fitness/background.jpg"
            }
          />
          <div className="live-video-history-on-live-btn-sec">
            {/* <Button className="on-live-btn" onClick={() => props.history.goBack()}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"/></svg> ON LIVE
                </Button> */}
            <Image
              className="onlive-svg"
              src={
                window.location.origin + "/assets/images/fitness/on-live.svg"
              }
              onClick={() => props.history.goBack()}
            />
          </div>
        </div>
      </div>
      <div className="trans-table-sec">
        <Container>
          {props.liveVideos.loading ? (
            <UserLiveVideoLoader />
          ) : props.liveVideos.data.live_videos.length > 0 ? (
            <div className="video-list-sec">
              {props.liveVideos.data.live_videos.map((video) => (
                <ScheduleLiveDataCard video={video} key={video.live_video_id} />
              ))}
            </div>
          ) : (
            <NoDataFound />
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideos: state.liveVideo.scheduledLiveVideos,
  userDetails: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ScheduledLiveVideosIndex));
