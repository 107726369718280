import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { fetchActiveFollowersStart } from '../../store/actions/FollowAction';
import { Multiselect } from 'multiselect-react-dropdown';
import {
    sendPushNotificationStart,
    sendEmailNotificationStart
} from '../../store/actions/NotificationAction';
import { translate, t } from "react-multi-lang";

const NewNotificationIndex = (props) => {

    const [options, setOptions] = useState([{ name: "hello" }]);

    const [notificationData, setNotificationData] = useState({
        notifier: "push",
        type: "all",
        selectedFollowers: [],
        message: "",
    });

    useEffect(() => {
        props.dispatch(fetchActiveFollowersStart());
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        console.log(notificationData);
        if (notificationData.notifier === "push") {
            props.dispatch(sendPushNotificationStart(notificationData));
        } else if (notificationData.notifier === "email") {
            props.dispatch(sendEmailNotificationStart(notificationData));
        }
    }

    return (
        <>
            <div className="new-notification-sec">
                <Container>
                    <h2 className="head-title">{t("notification")}</h2>
                    <Form className="new-notification-form" onSubmit={handleSubmit}>
                        <Row className="align-items-center">
                            <Col md={6}>
                                <Row>
                                    <Col md={6}>
                                        <div className="notify-checkbox">
                                            <div className="inputGroup">
                                                <input id="option1" name="option" type="radio"
                                                    checked={notificationData.notifier === "push"}
                                                    onChange={() => setNotificationData({
                                                        ...notificationData,
                                                        notifier: "push"
                                                    })} />
                                                <label for="option1">{t("push_notification")}</label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="notify-checkbox">
                                            <div className="inputGroup">
                                                <input id="option2" name="option" type="radio"
                                                    checked={notificationData.notifier === "email"}
                                                    onChange={() => setNotificationData({
                                                        ...notificationData,
                                                        notifier: "email"
                                                    })} />
                                                <label for="option2">{t("email_notification")}</label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="notify-checkbox margin-top-sm">
                                            <div className="inputGroup">
                                                <input id="option3" name="option3" type="checkbox"
                                                    checked={notificationData.type === "all"}
                                                    onClick={() => setNotificationData({
                                                        ...notificationData,
                                                        type: notificationData.type === "all" ? "multi" : "all"
                                                    })} />
                                                <label for="option3">{t("all")}</label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="notify-select">
                                            {!props.activeFollowers.loading ?
                                                <Multiselect
                                                    options={props.activeFollowers.data.followers}
                                                    // selectedValue={notificationData.selectedFollowers}
                                                    displayValue="name"
                                                    onSelect={(selectedList, selectedItem) => setNotificationData({
                                                        ...notificationData,
                                                        selectedFollowers: selectedList
                                                    })}
                                                    onRemove={(selectedList, selectedItem) => setNotificationData({
                                                        ...notificationData,
                                                        selectedFollowers: selectedList
                                                    })}
                                                    avoidHighlightFirstOption="true"
                                                    disable={notificationData.type === "all"}
                                                    className="multi-select"
                                                /> : null}
                                        </div>
                                    </Col>
                                    <Col md={12} className="margin-top-sm">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                placeholder="Message"
                                                value={notificationData.message}
                                                onChange={e => setNotificationData({
                                                    ...notificationData,
                                                    message: e.target.value,
                                                })} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <div className="notify-send-btn-sec">
                                            <Button type="submit" className="buy-token-btn">{t("send")}</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <div className="push-notify-img-sec">
                                    <Image
                                        className="push-notify-img"
                                        src={
                                            window.location.origin + "/assets/images/fitness/push-notification.svg"
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    activeFollowers: state.follow.activeFollowers,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(NewNotificationIndex));
