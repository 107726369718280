import React, { useState, useEffect } from "react";
import { Media, Container, Row, Col, Button, Image } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import "./Auth.css";
import {
  isAndroid,
  isIOS,
  isWindows,
  isMacOs,
  mobileModel,
  browserName,
  osName,
  mobileVendor,
  browserVersion,
} from "react-device-detect";
import configuration from "react-global-configuration";
import { connect } from "react-redux";
import {
  userRegisterStart,
  usernameValidationStart,
  referralValidationStart,
} from "../../store/actions/UserAction";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { translate, t } from "react-multi-lang";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
} from 'reactjs-social-login';

import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons';

const REDIRECT_URI = window.location.href;

const RegisterIndex = (props) => {
  const referral = "";
  if (configuration.get("configData.is_referral_enabled") == 1) {
    const query = new URLSearchParams(props.location.search);
    const referral = query.get("referral");
  }

  var device_type = "";
  var device_model = "";
  var browser_type = browserName;

  if (isAndroid == true) {
    device_type = "android";
    device_model = mobileModel;
  } else if (isIOS == true) {
    device_type = "ios";
    device_model = mobileModel;
  } else {
    device_type = "web";
    device_model = browserName + " " + browserVersion;
  }

  const [signupInputData, setSignupInputData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    referral_code: referral ? referral : "",
    device_type: device_type,
    device_model: device_model,
    browser_type: browser_type,
    registered_as: 1,
    agree: false,
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const registervalidation = Yup.object().shape({
    name: Yup.string().required("Name is required *"),
    username: Yup.string().required("User Name is required *"),

    agree: Yup.bool().oneOf([true], t("accept_terms_and_conditions")),

    email: Yup.string()
      .email("Invalid Email address format")
      .required("Email is required *"),
    password: Yup.string()
      .required(t("password_is_required"))
      .matches(/^(?=.*[a-zA-Z-0-9])/, t("space_values_not_valid"))
      .min(6, t("must_contain_6_characters")),
  });

  const passwordValidation = (value) => {
    const trimmedValue = value.replaceAll(" ", "");
    if (trimmedValue !== value) return t("should_not_contain_empty_space");
    return "";
  };

  const handleSignup = (values) => {
    props.dispatch(userRegisterStart(values));
    // console.log(values);
  };

  // const handleSignup = (event) => {
  //   event.preventDefault();
  //   props.dispatch(userRegisterStart(signupInputData));
  // };

  const usernameValidation = (value) => {
    const trimmedValue = value.replaceAll(" ", "");
    if (trimmedValue !== value) return t("should_not_contain_empty_space");
    return "";
  };

  const handleUsernameValidation = (event, username) => {
    setSignupInputData({
      ...signupInputData,
      username: username,
    });
    props.dispatch(usernameValidationStart({ username: username }));
  };

  const checkReferralCode = (event) => {
    event.preventDefault();

    if (signupInputData.referral_code) {
      props.dispatch(
        referralValidationStart({
          referral_code: signupInputData.referral_code,
        })
      );
    } else {
      const notificationMessage = getErrorNotificationMessage(
        t("please_enter_the_referral_code")
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const handleFacebookLogin = (response) => {
    console.log("handleFacebookLogin", response);

    const emailAddress = response.email === undefined || response.email === null ? response.id + "@facebook.com" : response.email;

    props.dispatch(
      userRegisterStart({
        name: response.name,
        first_name: response.first_name ? response.first_name : "",
        last_name: response.last_name ? response.last_name : "",
        email: emailAddress,
        social_unique_id: response.userID,
        picture: response.picture ? response.picture.data.url : '',
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (response) => {
    console.log("handleGoogleLogin", response);
    props.dispatch(
      userRegisterStart({
        name: response.name,
        email: response.email,
        first_name: response.given_name ? response.given_name : "",
        last_name: response.family_name ? response.family_name : "",
        social_unique_id: response.sub,
        picture: response.picture,
        login_by: "google",
      })
    );
  };


  return (
    <>
      <div className="login-sec">
        <div className="coach-template-animation coach-template-layout34">
          <div className="new-register-sec">
            <Container>
              <Row>
                <Col lg={7}>
                  <div className="coach-column-wrap justify-content-center left-text-padding">
                    {/* <div className="coach-animated-img">
                      <div className="">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/fitness/rocket-img.png"
                          }
                        />
                      </div>
                    </div> */}
                    <div className="coach-bg-card">
                      <div className="">
                        <Link to="#" className="coach-logo">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/fitness/coach-logo-white.png"
                            }
                          />
                        </Link>
                      </div>
                      <div className="">
                        <div className="coach-middle-content">
                          <h2 className="coach-main-title">
                            {t("sign_up_to_coach_me")}
                          </h2>
                          <div className="coach-switcher-description1 desktop-signin">
                            {t("if_you_have_an_account_you_can")}{" "}
                            <Link
                              to="/login"
                              className="coach-switcher-text ms-2"
                            >
                              {t("login")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="coach-column-wrap justify-content-center">
                    <div className="coach-form">
                      <h2 className="coach-sub-title">{t("sign_up")}</h2>

                      <Formik
                        initialValues={signupInputData}
                        validationSchema={registervalidation}
                        onSubmit={(values) => handleSignup(values)}
                      >
                        {({
                          touched,
                          errors,
                          values,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <Form noValidate className="login-form">
                            <Field
                              type="text"
                              placeholder={t("name")}
                              // autoFocus
                              name="name"
                              className="form-control"
                              value={values.name}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="name"
                              className="text-danger text-right"
                            />

                            <Field
                              type="text"
                              placeholder={t("username")}
                              name="username"
                              className="form-control"
                              value={values.username}
                              validate={usernameValidation}
                            />

                            <ErrorMessage
                              component={"div"}
                              name="username"
                              className="text-danger text-right"
                            />

                            <Field
                              type="email"
                              placeholder={t("email_address")}
                              name="email"
                              className="form-control"
                              value={values.email}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="email"
                              className="text-danger text-right"
                            />
                            <div className="icon-password-sec">
                              <Field
                                type={passwordShown ? "text" : "password"}
                                placeholder="********"
                                name="password"
                                className="form-control"
                                value={values.password}
                                validate={passwordValidation}
                              />
                              <i
                                className="register-toggle-password"
                                onClick={togglePasswordVisiblity}
                              >
                                {passwordShown ? <FaEyeSlash /> : <FaEye />}
                              </i>{" "}
                            </div>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="text-danger text-right"
                            />
                            {configuration.get(
                              "configData.is_referral_enabled"
                            ) == 1 && (
                                <>
                                  <Field
                                    type="text"
                                    placeholder="Referral Code(Optional)"
                                    name="referral_code"
                                    className="form-control"
                                    value={values.referral_code}
                                  />

                                  <div className="check-referral-link">
                                    <a
                                      className="text-primary"
                                      href="#"
                                      onClick={checkReferralCode}
                                    >
                                      {t("check_referral_code_valid")}
                                    </a>
                                  </div>

                                  <ErrorMessage
                                    component={"div"}
                                    name="referral_code"
                                    className="text-danger text-right"
                                  />
                                </>
                              )}

                            <div className="coach-checkbox-box">
                              <Field
                                type="checkbox"
                                id="agree"
                                name="agree"
                                required={true}
                                className="custom-control-input"
                              />

                              <label for="agree" className="ps-4">
                                {t("signing_up_confirmation")}{" "}
                                <Link className="terms-link" to={`/page/terms`}>
                                  {t("terms_of_service")}
                                </Link>{" "}
                                and
                                <Link
                                  className="terms-link"
                                  to={`/page/privacy`}
                                >
                                  {t("privacy_policy")}
                                </Link>
                                .
                              </label>
                              <ErrorMessage
                                component={"div"}
                                name="agree"
                                className="text-danger text-right"
                              />
                            </div>
                            <Button
                              type="submit"
                              className="coach-btn-fill"
                              disabled={
                                props.signup.buttonDisable ||
                                !values.name ||
                                !values.username ||
                                !values.email ||
                                !values.password ||
                                !values.agree
                              }
                            >
                              {props.signup.loadingButtonContent !== null
                                ? props.signup.loadingButtonContent
                                : t("sign_up")}
                            </Button>
                          </Form>
                        )}
                      </Formik>
                      <div className="coach-switcher-description1 mobile-signin">
                        {t("if_you_have_an_account_you_can")}{" "}
                        <Link to="/login" className="coach-switcher-text ms-2">
                          {t("login")}
                        </Link>
                      </div>
                    </div>
                    {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                      configuration.get("configData.FB_CLIENT_ID") ? (
                      <div className="coach-style-line">
                        <span>{t("or_continus_with")}</span>
                      </div>
                    ) : null}
                    <ul className="coach-socials list-unstyled">
                      {configuration.get("configData.GOOGLE_CLIENT_ID") && (
                        <LoginSocialGoogle
                          client_id={configuration.get("configData.social_logins.GOOGLE_CLIENT_ID")}
                          redirect_uri={REDIRECT_URI}
                          scope="openid profile email"
                          discoveryDocs="claims_supported"
                          access_type="offline"
                          onResolve={({ provider, data }: IResolveParams) => {
                            handleGoogleLogin(data);
                          }}
                          onReject={err => {
                            console.log(err);
                          }}
                        >
                          <Media as="li" className="coach-facebook">
                            <Link to="#" title="Google">
                              <i className="fab fa-google"></i>
                            </Link>
                          </Media>
                        </LoginSocialGoogle>
                      )}
                      {configuration.get("configData.FB_CLIENT_ID") && (
                        <LoginSocialFacebook
                          appId={configuration.get("configData.social_logins.FB_CLIENT_ID")}
                          fieldsProfile={
                            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                          }
                          redirect_uri={REDIRECT_URI}
                          onResolve={({ provider, data }: IResolveParams) => {
                            handleFacebookLogin(data);
                          }}
                          onReject={err => {
                            console.log(err);
                          }}
                        >
                          <Media as="li" className="coach-facebook">
                            <Link to="#" title="Facebook">
                              <i className="fab fa-facebook-f"></i>
                            </Link>
                          </Media>
                        </LoginSocialFacebook>
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  signup: state.users.registerInputData,
  validation: state.users.validationInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(RegisterIndex));
