import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const ProductLoader = () => {

    return (
        <div className="ecom-featured-box ecom-features-box-1 mt-5">
            {[...Array(6)].map(() =>
                <Skeleton height={300}
                />
            )}
        </div>
    );
}

export default ProductLoader;