import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import {
  fetchScheduledLiveVideosStart,
  fetchUserScheduledLiveVideosStart,
} from "../../store/actions/LiveVideoAction";
import NoDataFound from "../NoDataFound/NoDataFound";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import LiveDataCardNew from "./LiveDataCardNew";
import UserLiveVideoLoader from "../Loader/UserLiveVideoLoader";

const WebLiveStreamingIndex = (props) => {

  useEffect(() => {
    if (props.userDetails.loading) props.dispatch(fetchUserDetailsStart());
    if (localStorage.getItem("is_content_creator") != 2) {
      props.dispatch(fetchUserScheduledLiveVideosStart());
    } else {
      props.dispatch(fetchScheduledLiveVideosStart());
    }
  }, []);

  return (
    <>
      <div className="web-live-streaming-sec">
        <Container>
          {/* <h2>Live Stream</h2> */}
          {/* <div className="web-live-streaming-header-sec">
            <div className="web-live-streaming-header-card">
              <Image
                className="web-live-streaming-header-img"
                src={
                  window.location.origin + "/assets/images/new-changes/live-streaming-header-img.png"
                }
              />
              <div className="web-live-streaming-details-sec">
                <div className="web-live-streaming-details-info">
                  <h4>Live Stream</h4>
                  <p>8 Years Expereince</p>
                </div>
                <Button className="play-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    fill="#fff"
                    enableBackground="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                  >
                    <path d="M256 0C114.617 0 0 114.615 0 256s114.617 256 256 256 256-114.615 256-256S397.383 0 256 0zm88.48 269.57l-128 80a16.008 16.008 0 01-16.238.422A15.994 15.994 0 01192 336V176c0-5.82 3.156-11.172 8.242-13.992a15.957 15.957 0 0116.238.422l128 80c4.676 2.93 7.52 8.055 7.52 13.57s-2.844 10.641-7.52 13.57z"></path>
                  </svg>
                </Button>
              </div>
            </div>
          </div> */}
          {/* <h2 className="mt-5">Live Stream</h2>
          <Row>
            <Col md={12}>
              <div className="web-livestreaming-ongoing-box">
                <div className="web-livestreaming-ongoing-card">
                  <div className="web-livestreaming-ongoing-top-sec">
                    <div className="web-livestreaming-ongoing-img-sec">
                      <Image
                      className="web-livestreaming-ongoing-img"
                        src={
                          window.location.origin + "/assets/images/new-changes/live-streaming-user-1.png"
                        }
                      />
                    </div>
                    <div className="web-livestreaming-ongoing-user-count-img-sec">
                      <div class="category-image">
                        <div class="cat-image-1">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-1.png"
                            }
                          />
                        </div>
                        <div class="cat-image-2">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-2.png"
                            }
                          />
                        </div>
                        <div class="cat-image-3">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-3.png"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="web-livestreaming-ongoing-bottom-sec">
                    <h4>Trainee</h4>
                    <p>8 Years Expereince</p>
                  </div>
                </div>
                <div className="web-livestreaming-ongoing-card">
                  <div className="web-livestreaming-ongoing-top-sec">
                    <div className="web-livestreaming-ongoing-img-sec">
                      <Image
                      className="web-livestreaming-ongoing-img"
                        src={
                          window.location.origin + "/assets/images/new-changes/live-streaming-user-1.png"
                        }
                      />
                    </div>
                    <div className="web-livestreaming-ongoing-user-count-img-sec">
                      <div class="category-image">
                        <div class="cat-image-1">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-1.png"
                            }
                          />
                        </div>
                        <div class="cat-image-2">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-2.png"
                            }
                          />
                        </div>
                        <div class="cat-image-3">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-3.png"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="web-livestreaming-ongoing-bottom-sec">
                    <h4>Trainee</h4>
                    <p>8 Years Expereince</p>
                  </div>
                </div>
                <div className="web-livestreaming-ongoing-card">
                  <div className="web-livestreaming-ongoing-top-sec">
                    <div className="web-livestreaming-ongoing-img-sec">
                      <Image
                      className="web-livestreaming-ongoing-img"
                        src={
                          window.location.origin + "/assets/images/new-changes/live-streaming-user-1.png"
                        }
                      />
                    </div>
                    <div className="web-livestreaming-ongoing-user-count-img-sec">
                      <div class="category-image">
                        <div class="cat-image-1">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-1.png"
                            }
                          />
                        </div>
                        <div class="cat-image-2">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-2.png"
                            }
                          />
                        </div>
                        <div class="cat-image-3">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-3.png"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="web-livestreaming-ongoing-bottom-sec">
                    <h4>Trainee</h4>
                    <p>8 Years Expereince</p>
                  </div>
                </div>
                <div className="web-livestreaming-ongoing-card">
                  <div className="web-livestreaming-ongoing-top-sec">
                    <div className="web-livestreaming-ongoing-img-sec">
                      <Image
                      className="web-livestreaming-ongoing-img"
                        src={
                          window.location.origin + "/assets/images/new-changes/live-streaming-user-1.png"
                        }
                      />
                    </div>
                    <div className="web-livestreaming-ongoing-user-count-img-sec">
                      <div class="category-image">
                        <div class="cat-image-1">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-1.png"
                            }
                          />
                        </div>
                        <div class="cat-image-2">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-2.png"
                            }
                          />
                        </div>
                        <div class="cat-image-3">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-3.png"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="web-livestreaming-ongoing-bottom-sec">
                    <h4>Trainee</h4>
                    <p>8 Years Expereince</p>
                  </div>
                </div>
                <div className="web-livestreaming-ongoing-card">
                  <div className="web-livestreaming-ongoing-top-sec">
                    <div className="web-livestreaming-ongoing-img-sec">
                      <Image
                      className="web-livestreaming-ongoing-img"
                        src={
                          window.location.origin + "/assets/images/new-changes/live-streaming-user-1.png"
                        }
                      />
                    </div>
                    <div className="web-livestreaming-ongoing-user-count-img-sec">
                      <div class="category-image">
                        <div class="cat-image-1">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-1.png"
                            }
                          />
                        </div>
                        <div class="cat-image-2">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-2.png"
                            }
                          />
                        </div>
                        <div class="cat-image-3">
                          <Image
                            src={
                              window.location.origin + "/assets/images/new-changes/user-count-3.png"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="web-livestreaming-ongoing-bottom-sec">
                    <h4>Trainee</h4>
                    <p>8 Years Expereince</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row> */}
          <h2 className="mt-5">{t('live_streams')}</h2>
          <Row>
            <Col md={12}>

              {props.liveVideos.loading ? (
                <UserLiveVideoLoader />
              ) : props.liveVideos.data.live_videos.length > 0 ? (
                <div className="web-livestreaming-schedule-box">
                  {props.liveVideos.data.live_videos.map((video) => (
                    <LiveDataCardNew video={video} key={video.live_video_id} />
                  ))}
                  {/* <div className="web-livestreaming-schedule-card">
                    <div className="web-livestreaming-schedule-top-sec">
                      <Image
                        className="web-livestreaming-schedule-top-bg-img"
                        src={
                          window.location.origin + "/assets/images/new-changes/live-streaming-schedule-bg.png"
                        }
                      />
                      <div className="web-livestreaming-schedule-user-img-sec">
                        <Image
                          className="web-livestreaming-schedule-user-img"
                          src={
                            window.location.origin + "/assets/images/new-changes/live-streaming-user-1.png"
                          }
                        />
                      </div>
                      <div className="web-livestreaming-schedule-video-token">
                        <h3>1 Token</h3>
                      </div>
                      <div className="web-livestreaming-schedule-video-timezone">
                        <h3>01 Jan 2023 07:30 AM +13:00GMT</h3>
                      </div>
                    </div>
                    <div className="web-livestreaming-schedule-bottom-sec">
                      <div className="web-livestreaming-schesule-info-sec">
                        <h4>Trainee</h4>
                        <p>8 Years Expereince</p>
                      </div>
                      <div className="start-now-btn-sec">
                        <Button className="start-now-btn">
                          Start Now
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="web-livestreaming-schedule-card">
                    <div className="web-livestreaming-schedule-top-sec">
                      <Image
                        className="web-livestreaming-schedule-top-bg-img"
                        src={
                          window.location.origin + "/assets/images/new-changes/live-streaming-schedule-bg.png"
                        }
                      />
                      <div className="web-livestreaming-schedule-user-img-sec">
                        <Image
                          className="web-livestreaming-schedule-user-img"
                          src={
                            window.location.origin + "/assets/images/new-changes/live-streaming-user-1.png"
                          }
                        />
                      </div>
                      <div className="web-livestreaming-schedule-video-token">
                        <h3>1 Token</h3>
                      </div>
                      <div className="web-livestreaming-schedule-video-timezone">
                        <h3>01 Jan 2023 07:30 AM +13:00GMT</h3>
                      </div>
                    </div>
                    <div className="web-livestreaming-schedule-bottom-sec">
                      <div className="web-livestreaming-schesule-info-sec">
                        <h4>Trainee</h4>
                        <p>8 Years Expereince</p>
                      </div>
                      <div className="start-now-btn-sec">
                        <Button className="start-now-btn">
                          Start Now
                        </Button>
                      </div>
                    </div>
                  </div> */}
                </div>
              ) : (
                <NoDataFound />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideos: state.liveVideo.scheduledLiveVideos,
  userDetails: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(WebLiveStreamingIndex));