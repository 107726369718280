import React, { useState, useEffect } from 'react';
import CommonCenterLoader from '../../Loader/CommonCenterLoader';
import NoDataFound from '../../NoDataFound/NoDataFound';
import { connect } from 'react-redux';
import { translate, t } from "react-multi-lang";
import { Button, Container, Row, Col, Table, Image } from "react-bootstrap";
import {
    fetchtTrainerServiceListStart,
    deleteTrainerServiceStart,
} from '../../../store/actions/UserAction';
import AddServiceModal from '../../helper/AddServiceModal';

const TrainerServicesCard = (props) => {

    const [showServiceModal, setShowServiceModal] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [skip, setSkip] = useState(true);

    useEffect(() => {
        props.dispatch(fetchtTrainerServiceListStart());
    }, []);

    useEffect(() => {
        if (!skip) {
            if (props.deleteTrainerServices.loading && Object.keys(props.deleteTrainerServices.data).length > 0) {
                props.dispatch(fetchtTrainerServiceListStart());
            }
        }
        setSkip(false);
    }, [props.deleteTrainerServices]);

    const closeServiceModal = () => {
        setShowServiceModal(false);
        setSelectedService(null);
    }

    useEffect(() => {
        if (selectedService) {
            setShowServiceModal(true);
        }
    }, [selectedService]);

    const deleteService = trainerServiceId => {
        if (window.confirm("Do you want to delete this service?"))
            props.dispatch(deleteTrainerServiceStart({
                trainer_service_id: trainerServiceId,
            }));
    }

    return (
        <>
            <div
                role="tabpanel"
                className={
                    props.activeSec === "trainer-service"
                        ? "tab-pane fade in active"
                        : "tab-pane fade"
                }
                id="Section7"
            >
                <div className="trans-table-sec">
                    <Container>
                        <Row>
                            <Col sm={12} md={12}>
                                <div className="trainer-header-sec">
                                    <span className="h4">{t("trainer_services")}</span>
                                    <Button
                                        variant="primary"
                                        className="add-btn"
                                        onClick={() => setShowServiceModal(true)}
                                    >{t("add")}</Button>
                                </div>
                                <div className="trans-table new-trainer-service-table">
                                    {props.trainerServicesList.loading ? (
                                        <CommonCenterLoader></CommonCenterLoader>
                                    ) : props.trainerServicesList.data.trainer_services.length > 0 ? (
                                        <Table borderedless responsive>
                                            <tbody>
                                                {props.trainerServicesList.data.trainer_services.map((data, i) => (
                                                    <tr className="service-table-row">
                                                        <td>{i + 1}</td>
                                                        <td>{data.service}</td>
                                                        <td>
                                                            <Button
                                                                className="edit-btn mr-2"
                                                                onClick={() => setSelectedService(data)}>
                                                                <i className="fas fa-tag mr-2"></i>{t("edit")}
                                                            </Button>
                                                            <Button
                                                                className="delete-btn"
                                                                onClick={() => deleteService(data.trainer_service_id)}>
                                                                <i className="fas fa-video mr-2"></i>{t("delete")}
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <NoDataFound></NoDataFound>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <AddServiceModal
                showModal={showServiceModal}
                closeServiceModal={closeServiceModal}
                selectedService={selectedService}
            />
        </>
    );
}

const mapStateToPros = (state) => ({
    trainerServicesList: state.users.trainerServicesList,
    deleteTrainerServices: state.users.deleteTrainerServices,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TrainerServicesCard);