import React, { useState } from "react";
import {
  Media,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Auth.css";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { forgotPasswordStart } from "../../store/actions/UserAction";

const ForgotPasswordIndex = (props) => {
  const [forgotPasswordInputData, setForgotPasswordInputData] = useState({});

  const handleForgotPassword = (event) => {
    event.preventDefault();
    props.dispatch(forgotPasswordStart(forgotPasswordInputData));
  };

  return (
    <>
      <div className="login-sec">
        <div className="coach-template-animation coach-template-layout34">
          <div className="new-mobile-login-sec new-forgot-psd">
            <Container>
              <Row>
                <Col lg={7}>
                  <div className="new-coloumn-center left-text-padding">
                    {/* <div className="coach-animated-img">
                      <div className="">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/fitness/rocket-img.png"
                          }
                        />
                      </div>
                    </div> */}
                    <div className="coach-bg-card">
                      <div className="">
                        <Link to="#" className="coach-logo">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/fitness/coach-logo-white.png"
                            }
                          />
                        </Link>
                      </div>
                      <div className="">
                        <div className="coach-middle-content">
                          <h2 className="coach-main-title">
                            {t("reset_password")}
                          </h2>
                          <div className="coach-switcher-description1">
                            {t(
                              "enter_the_email_address_or_mobile_number_associate"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="coach-column-wrap justify-content-center">
                    <div className="coach-form">
                      <h2 className="coach-sub-title">
                        {t("forgot_passwords")}
                      </h2>
                      <Form onSubmit={handleForgotPassword}>
                        <Form.Group>
                          <Form.Control
                            type="email"
                            placeholder={t("email_address")}
                            value={forgotPasswordInputData.email}
                            name="email"
                            onChange={(event) =>
                              setForgotPasswordInputData({
                                ...forgotPasswordInputData,
                                email: event.currentTarget.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group>
                          <div className="coach-checkbox-box">
                            <span className="coach-switcher-description1">
                              {t("return_to")}
                            </span>
                            <Link className="terms-link ms-2" to="/login">
                              {t("log_in")}
                            </Link>
                          </div>
                        </Form.Group>
                        <Form.Group>
                          <Button
                            type="submit"
                            className="coach-btn-fill"
                            disabled={
                              props.forgotPassword.buttonDisable ||
                              !forgotPasswordInputData.email
                            }
                          >
                            {props.forgotPassword.loadingButtonContent !== null
                              ? props.forgotPassword.loadingButtonContent
                              : t("forgot_passwords")}
                          </Button>
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ForgotPasswordIndex));
