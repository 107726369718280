import React from 'react';
import { Row,Col, Container } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const SingleVideoLoader = () => {
  return (
  <>
   <div className="custome-main single-product-card">
          <Container>
            <Row className="justify-content-center">
              <Col xl={10} md={12}>
                <div className="video-main-wrapped">
                  <div className="custome-main-card mb-0">
                    <div className="custome-card-profile">
                      <div className="custome-card-profile-details">
                        <div className="custome-card-details-img">
                          <Skeleton count={1} circle={true} width={50} height={50} />
                        </div>
                        <div className="custome-card-details-info">
                          <Skeleton className="mb-3" count={2} width={120} height={20} />
                        </div>
                      </div>
                      <div className="custome-card-profile-time">
                        <span><Skeleton count={1} height={20} width={120} /></span>
                      </div>
                    </div>
                    <div className="single-card-img-sec">
                      <Skeleton count={1} width={"920px"} height={"60vh"} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
  </>
  )
}

export default SingleVideoLoader