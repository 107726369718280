import React, { useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Badge,
  Image,
} from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { Link } from "react-router-dom";
import {
  acceptAudioCallStart,
  rejectAudioCallStart,
  endAudioCallStart,
} from "../../../store/actions/PrivateCallAction";
import AudioCallMakePaymentModel from "../../helper/AudioCallMakePaymentModel";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonCenterLoader from "../../Loader/CommonCenterLoader";
import { isMobile } from "react-device-detect";

const AudioCallHistoryTable = (props) => {
  const [makePaymentInput, setMakePaymentInput] = useState({
    audio_call_request_id: "",
    model_displayname: "",
    model_picture: "",
    amount: "",
    amount_formatted: "",
    model_unique_id: "",
    model_id: "",
    modelname: "",
  });

  const [
    selectedCallDetailsToMakePayment,
    setSelectedCallDetailsToMakePayment,
  ] = useState(null);

  const [makePaymentModel, setMakePaymentModel] = useState(false);

  const closePaymentModal = () => {
    setMakePaymentModel(false);
  };

  const makePayment = (event, callDetails) => {
    event.preventDefault();
    setMakePaymentInput({
      audio_call_request_id: callDetails.audio_call_request_id,
      model_displayname: callDetails.model_displayname,
      model_picture: callDetails.model_picture,
      amount: callDetails.amount,
      amount_formatted: callDetails.amount_formatted,
      model_unique_id: callDetails.model_unique_id,
      model_id: callDetails.model_id,
      modelname: callDetails.modelname,
    });
    setSelectedCallDetailsToMakePayment(callDetails);
    setMakePaymentModel(true);
  };

  return (
    <>
      <div className="trans-table">
        <InfiniteScroll
          dataLength={props.audioCallRequest.length}
          next={props.fetchMore}
          hasMore={
            props.audioCallRequest.length < props.total && props.errorCount < 2
          }
          loader={<CommonCenterLoader />}
        >
          <Table borderedless responsive>
            <thead>
              <tr className="bg-white text-muted text-center text-uppercase">
                <th>{t("action")}</th>
                <th>{t("status")}</th>
                {localStorage.getItem("is_content_creator") == 2 ?
                  <th>{t("user")}</th>
                  : <th>{t("coach")}</th>}
                <th>{t("trainer_services")}</th>
                <th>{t("scheduled")}</th>
                {/* <th>{t("end_time")}</th> */}
                <th>{t("s_no")}</th>
              </tr>
            </thead>
            <tbody>
              {props.audioCallRequest.map((audioCall, index) => (
                <tr
                  key={audioCall.video_call_request_id}
                  className="text-center"
                >
                  <td className="new-audio-btn">
                    {audioCall.accept_btn_status == 1 ? (
                      <Button
                        className="btn btn-sm btn-success mr-3 mb-3"
                        onClick={() =>
                          props.dispatch(
                            acceptAudioCallStart({
                              audio_call_request_id:
                                audioCall.audio_call_request_id,
                            })
                          )
                        }
                      >
                        {t("accept")}
                      </Button>
                    ) : (
                      ""
                    )}
                    {audioCall.reject_btn_status == 1 ? (
                      <Button
                        className="btn btn-sm btn-danger  mr-3 mb-3"
                        onClick={() =>
                          props.dispatch(
                            rejectAudioCallStart({
                              audio_call_request_id:
                                audioCall.audio_call_request_id,
                            })
                          )
                        }
                      >
                        {t("reject")}
                      </Button>
                    ) : (
                      ""
                    )}
                    {audioCall.payment_btn_status == 1 ? (
                      <Button
                        className="btn btn-success mr-3"
                        onClick={(event) => makePayment(event, audioCall)}
                      >
                        {t("paynow")}
                      </Button>
                    ) : (
                      ""
                    )}

                    {audioCall.join_btn_status == 1 ? (
                      <Link
                        className="btn btn-success mr-3"
                        to={isMobile ? `/private-audio-call/${audioCall.audio_call_request_unique_id}` : `/audio-call/${audioCall.audio_call_request_unique_id}`}
                      >
                        {t("join_call")}
                      </Link>
                    ) : (
                      ""
                    )}

                    {audioCall.start_btn_status == 1 ? (
                      <Link
                        className="btn btn-sm btn-success mr-3 mb-3"
                        to={isMobile ? `/private-audio-call/${audioCall.audio_call_request_unique_id}` : `/audio-call/${audioCall.audio_call_request_unique_id}`}
                      >
                        {t("start_call")}
                      </Link>
                    ) : (
                      ""
                    )}

                    {audioCall.end_btn_status == 1 ? (
                      <Button
                        className="btn btn-danger mr-3"
                        onClick={() =>
                          props.dispatch(
                            endAudioCallStart({
                              audio_call_request_id:
                                audioCall.audio_call_request_id,
                            })
                          )
                        }
                      >
                        {t("end_call")}
                      </Button>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{audioCall.call_status_formatted}</td>
                  
                  {localStorage.getItem("is_content_creator") == 2 ?
                  <td>
                    <Link className="user-chat-msg" 
                      to={isMobile ? `/w/` + audioCall.user_unique_id : `/` + audioCall.user_unique_id}
                    >
                      {audioCall.user_displayname}
                    </Link>
                  </td>
                  :
                  <td>
                    <Link className="user-chat-msg" 
                    to={isMobile ? `/w/` + audioCall.user_unique_id : `/` + audioCall.user_unique_id}
                    >
                      {audioCall.model_displayname}
                    </Link>
                  </td>
                  }
                  <td>
                    {audioCall.trainer_service
                      ? audioCall.trainer_service.service
                      : "N/A"}
                  </td>
                  <td>{audioCall.start_time ? audioCall.start_time : "-"}</td>
                  {/* <td>{audioCall.end_time ? audioCall.end_time : "-"}</td> */}
                  
                  <td>{index + 1}</td>
                  
                </tr>
              ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      </div>
      <AudioCallMakePaymentModel
        audio_call_request_id={makePaymentInput.audio_call_request_id}
        audioCallPayment={makePaymentModel}
        closePaymentModal={closePaymentModal}
        userPicture={makePaymentInput.model_picture}
        name={makePaymentInput.model_displayname}
        user_unique_id={makePaymentInput.model_unique_id}
        model_id={makePaymentInput.model_id}
        callDetails={makePaymentInput}
        username={makePaymentInput.modelname}
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  null,
  mapDispatchToProps
)(translate(AudioCallHistoryTable));
