import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
} from "react-bootstrap";
import "./Availablity.css";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import {
  fetchTrainersAvailabilityStart,
  saveTrainersAvailabilityStart,
} from "../../../../store/actions/AvailabilityAction";
import dayjs from "dayjs";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { CalenderScheduler } from "../../../CustomComponents/CalenderScheduler/CalenderScheduler";
import { format } from "date-fns";
import CalenderLoader from "../../../SingleProfile/CalenderLoader";

var yesterday = moment().subtract(1, "day");

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const ScheduleAvailabilityIndex = (props) => {
  const [today, setToday] = useState(null);

  const formatNumber = (num) => {
    if (num < 10) return "0" + num;
    else return num;
  };

  const [skipRender, setSkipRender] = useState(true);
  const [startTime, setStartTime] = useState();
  const [startTimeFormatted, setStartTimeFormatted] = useState();
  const [selectedSlots, setSelectedSlots] = useState([]);

  useEffect(() => {
    if (!skipRender) {
      props.dispatch(fetchTrainersAvailabilityStart({ date: startTime }));
    }
  }, [startTime]);

  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
      setToday(new Date(new Date().toLocaleString('en', { timeZone: props.profile.data.timezone })));
    }
  }, [props.profile]);

  useEffect(() => {
    if (today) {
      setStartTime(today.getFullYear() +
        "-" +
        formatNumber(parseInt(today.getMonth() + 1)) +
        "-" +
        formatNumber(today.getDate()));
      setStartTimeFormatted(parseInt(today.getMonth() + 1) +
        "/" +
        today.getDate() +
        "/" +
        today.getFullYear());
    }
  }, [today]);

  const startTimeOnchange = (time) => {
    setSelectedSlots([]);
    console.log(time);
    // setStartTime(dayjs(time).format("YYYY-MM-DD"));
    setStartTime(time);
    setStartTimeFormatted(dayjs(time).format("MM/DD/YYYY"));
  };

  const onClickTimeslot = (timeslot) => {
    if (selectedSlots.includes(timeslot)) {
      setSelectedSlots(
        selectedSlots.filter((x) => x.slot_id !== timeslot.slot_id)
      );
    } else {
      setSelectedSlots([...selectedSlots, timeslot]);
    }
    // let index = selectedSlots.findIndex(x => x.slot_id === timeslot.slot_id);
    // if (index != -1) {
    //     selectedSlots.splice(index, 1);
    // } else {
    //     setSelectedSlots([
    //         ...selectedSlots,
    //         timeslot
    //     ]);
    // }
  };

  const selectAllTimeSlots = () => setSelectedSlots(props.availability.data.trainer_slot);
  const unselectAllTimeSlots = () => setSelectedSlots(
    props.availability.data.trainer_slot.filter((x) => x.is_slot_booked == 1)
  );;

  const handleSubmit = (event) => {
    console.log(JSON.stringify(selectedSlots));
    event.preventDefault();
    const removedSlots = props.availability.data.trainer_slot.filter(slot => slot.is_available == 1)
      .filter(oldSlot =>
        !selectedSlots.some(selectedSlot =>
          oldSlot.slot_id === selectedSlot.slot_id
        )
      );
    props.dispatch(
      saveTrainersAvailabilityStart({
        date: startTime,
        slots: JSON.stringify(selectedSlots),
        removed_slots: JSON.stringify(removedSlots),
      })
    );
  };

  useEffect(() => {
    if (!skipRender && !props.availability.loading && Object.keys(props.availability.data).length > 0) {
      // let slots_arr = [];
      // props.availability.data.trainer_availabilities.map((value, i) => {
      //     slots_arr.push(JSON.parse(value));
      // });
      // setSelectedSlots(slots_arr);
      setSelectedSlots(
        props.availability.data.trainer_slot.filter((x) => x.is_available == 1 || x.is_slot_booked == 1)
      );
    }
    setSkipRender(false);
  }, [props.availability]);

  return (
    <>
      {today ?
        <div className="schedule-availability-sec">
          <div className="wallet-sec live-video-list-header-sec">
            <Container>
              <Row>
                <Col sm={12} md={12}>
                  <div className="wallet-header-sec">
                    <Row>
                      <Col sm={12} md={12} xl={8}>
                        <Link
                          className="bookmarkes-list notify-title back-button"
                          onClick={() => props.history.goBack()}
                        >
                          <img
                            src={
                              window.location.origin +
                              "/assets/images/icons/back-white.svg"
                            }
                            className="svg-clone"
                          />
                          <h3 className="ml-2 mb-0">Set Availability</h3>
                        </Link>
                        <h3></h3>
                      </Col>

                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="schedule-availability-body-sec">
            <Container>
              <div className="schedule-availability-box">
                <Row>
                  <Col xl={8} md={12}>
                    <CalenderScheduler
                      date={today}
                      format="YYYY-MM-DD"
                      disablePastDate={true}
                      onChange={startTimeOnchange}
                    />
                  </Col>
                  <Col xl={4} md={12}>
                    <div className="schedule-availability-select-box">
                      <div className="schedule-availability-select-header-sec">
                        <h3 className="mb-2">{t("schedule_availability")}</h3>
                        {/* <p>December 26, Sunday</p> */}
                        <h5></h5>
                      </div>
                      <div className="schedule-availability-select-card">
                        <div className="schedule-availability-select-top-sec">
                          <h4>{startTimeFormatted}</h4>
                          <div className="schedule-availability-select-all-checkbox">
                            <input
                              type="checkbox"
                              id="todo"
                              name="todo"
                              value="todo"
                              checked={props.availability.data.trainer_slot?.length === selectedSlots.length}
                              onClick={() => {
                                if (props.availability.data.trainer_slot?.length === selectedSlots.length)
                                  unselectAllTimeSlots()
                                else
                                  selectAllTimeSlots()
                              }}
                            />
                            <label for="todo" data-content="Get out of bed" >
                              Mark All Available
                            </label>
                          </div>
                        </div>

                        <div
                          className="schedule-availability-select-middle-sec"
                          style={{
                            height: "calc(100vh - 350px)",
                            overflowY: "auto",
                            marginTop: "1em",
                          }}
                        >
                          {props.availability.loading ? (
                            <CalenderLoader />
                          ) : (
                            <ul className="list-unstyled schedule-availability-select-list">
                              {props.availability.data.trainer_slot.length > 0
                                ? props.availability.data.trainer_slot.map(
                                  (trainer_slot, index) => (
                                    <li
                                      className={
                                        trainer_slot.is_slot_booked
                                          ? "booked-status"
                                          : trainer_slot.is_available
                                            ? ""
                                            : "not-available-status"
                                      }
                                    >
                                      <label
                                        for={"radio-card-" + index}
                                        className="radio-card"
                                      >
                                        <input
                                          type="checkbox"
                                          name={"radio-" + index}
                                          id={"radio-card-" + index}
                                          onChange={(e) =>
                                            onClickTimeslot(trainer_slot)
                                          }
                                          disabled={trainer_slot.is_slot_booked}
                                          checked={selectedSlots.includes(
                                            trainer_slot
                                          )}
                                        // defaultChecked={trainer_slot.is_slot_booked ? true : (trainer_slot.is_available ? true : false)}
                                        />
                                        <div className="card-content-wrapper">
                                          <div className="schedule-availability-time">
                                            <span>
                                              {trainer_slot.start_time}
                                            </span>
                                            <span>-</span>
                                            <span>{trainer_slot.end_time}</span>
                                          </div>
                                          <div className="schedule-availability-status-tick">
                                            <span className="check-icon"></span>
                                            <h4>
                                              {
                                                trainer_slot.is_slot_booked
                                                  ? "Booked"
                                                  : props.availability.data
                                                    .is_new_slot
                                                    ? "Available"
                                                    : trainer_slot.is_available
                                                      ? "Available"
                                                      : "Not Available"}
                                            </h4>
                                          </div>
                                        </div>
                                      </label>
                                    </li>
                                  )
                                )
                                : null}
                            </ul>
                          )}
                        </div>
                        <div className="schedule-availability-select-bottom-sec">
                          {/* <div className="please-be-available-sec">
                          <h5>
                            <Image
                              className="warning-icon"
                              src={
                                window.location.origin +
                                "/assets/images/fitness/icons/warning.svg"
                              }
                            />
                            <span>Please be available</span>
                          </h5>
                        </div> */}
                          <div className="schedule-availability-select-btn-sec">
                            <Button
                              className="about-me-btn"
                              onClick={handleSubmit}
                              disabled={props.saveAvailability.buttonDisable}
                            >
                              {props.saveAvailability.loadingButtonContent !==
                                null
                                ? props.saveAvailability.loadingButtonContent
                                : t("submit")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  availability: state.trainerAvailability.availability,
  saveAvailability: state.trainerAvailability.saveAvailability,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ScheduleAvailabilityIndex));
