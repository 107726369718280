import React from "react";
import { Modal, Form, InputGroup, Image, Button } from "react-bootstrap";
import "./PaymentModal.css";

const PayAndSeePostModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center "
        size="md"
        centered
        show={props.paymentPost}
        onHide={props.closePaymentPost}
      >
        <Modal.Header closeButton className="wallet-modal-header">
          <Modal.Title>Pay And See The Post</Modal.Title>
        </Modal.Header>
        <Form className="payment-tokens">
          <Modal.Body className="wallet-token-body">
            <div className="wallet-balance-box">
              <div className="wallet-box-card mb-4">
                <h4>10 Tokens</h4>
              </div>
              <div className="wallet-box-card">
                <h4>Available</h4>
                <h3>558 Tokens</h3>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="wallet-modal-footer payment-post-wallet">
            <div className="wallet-modal-btn">
              <Button
                type="button"
                className="btn btn-cancel"
                data-dismiss="modal"
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="btn pay-btn"
                data-dismiss="modal"
              >
                Pay
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default PayAndSeePostModal;
