import React, { useState, useEffect } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";
import { videoCallBroadcastStart } from "../../store/actions/LiveVideoAction";
import dayjs from "dayjs";
import DatePicker from 'react-mobile-datepicker';


var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const GoLiveModal = (props) => {

  const dateConfig = {
    'year': {
      format: 'YYYY',
      caption: 'Year',
      step: 1,
    },
    'month': {
      format: 'MM',
      caption: 'Month',
      step: 1,
    },
    'date': {
      format: 'DD',
      caption: 'Day',
      step: 1,
    },
    'hour': {
      format: 'hh',
      caption: 'Hour',
      step: 1,
    },
    'minute': {
      format: 'mm',
      caption: 'Min',
      step: 1,
    },
  }

  const [show, setShow] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [dateWithInitialValue, setDateWithInitialValue] = useState(
    dayjs(new Date())
      .tz(props.timezone)
      .format("YYYY-MM-DD HH:mm:00")
  );
  const [paymentStatus, setPaymentStatus] = useState(1);
  const [streamingStatus, setStreamingStatus] = useState(2);
  const today = new Date(
    dayjs(new Date())
      .tz(props.timeZone)
      .format("YYYY-MM-DD HH:mm:00")
  );
  const [startTime, setStartTime] = useState(today);

  const [inputData, setInputData] = useState({
    schedule_time: dateWithInitialValue,
    streaming_type: 2,
    payment_status: 1,
    title: "",
    amount: "",
    no_of_minutes: 0,
  });

  useEffect(() => {
    setInputData({
      ...inputData,
      schedule_time: dateWithInitialValue,
    });
  }, [dateWithInitialValue]);

  const [previewImage, setPreviewImage] = useState("");

  const paymentStatusOnchange = (event) => {
    setInputData({
      ...inputData,
      payment_status: event,
    });
    setPaymentStatus(event);
  };

  const streamingStatusOnchange = (event) => {
    setInputData({
      ...inputData,
      streaming_type: event,
    });
    setStreamingStatus(event);
  };

  const startTimeOnchange = (time) => {
    console.log(time);
    setInputData({
      ...inputData,
      schedule_time: dayjs(time).format("YYYY-MM-DD HH:mm:00"),
    });
    setStartTime(time);
  };

  const closeGoLiveModal = (event) => {
    event.preventDefault();
    setInputData({
      streaming_type: 2,
      payment_status: 1,
      title: "",
      amount: 1,
    });
    props.closeGoLiveModal();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputData);
    props.dispatch(videoCallBroadcastStart(inputData));
  };

  useEffect(() => {
    if (!skipRender && !props.saveLiveVideo.loading && Object.keys(props.saveLiveVideo.data).length > 0)
      props.closeGoLiveModal();
    setSkipRender(false);
  }, [props.saveLiveVideo]);

  const handleChangeImage = (event) => {
    if (event.currentTarget.type === "file") {
      let reader = new FileReader();
      let file = event.currentTarget.files[0];

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file);

      setInputData({
        ...inputData,
        snapshot: event.currentTarget.files[0],
      });
    }
  };

  return (
    <>
      <Modal
        className="modal-dialog-center sent-tip-modal go-live-modal go-live-modal-new"
        size="lg"
        centered
        show={props.goLive}
        onHide={props.closeGoLiveModal}
      >
        {props.goLive === true ? (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>{t("go_live")} </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={12}>
                  <h4 className="mb-5">
                    <span className="">
                      Your Timezone is : <b>{props.timezone}</b>
                    </span>
                  </h4>
                </Col>
              </Row>
              <Row>
                {/* <Col md={6}>
                  <div className="go-live-img-sec">
                    <Image
                      className="go-live-img"
                      src={
                        window.location.origin + "/assets/images/go-live-img.svg"
                      }
                    />
                  </div>
                </Col> */}
                <Col md={6}>
                  <div className="floating-form go-live-form">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label className="choose-payment-label">
                        {t("title")}:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("title")}
                        value={inputData.title ? inputData.title : null}
                        onChange={(event) =>
                          setInputData({
                            ...inputData,
                            title: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group>
                      <label className="choose-payment-label">
                        {t("streaming_type")}:
                      </label>
                      {["radio"].map((type) => (
                        <div key={`custom-inline-${type}`} className="mb-3">
                          {/* <Form.Check
                            custom
                            inline
                            label="Now"
                            type={type}
                            id="now"
                            value="1"
                            name="streaming_type"
                            onChange={(event) => {
                              streamingStatusOnchange(event.currentTarget.value);
                            }}
                          /> */}
                          <Form.Check
                            custom
                            inline
                            label="Schedule"
                            type={type}
                            // id={`custom-inline-${type}-2`}
                            id="later"
                            value="2"
                            defaultChecked={true}
                            name="streaming_type"
                            onChange={(event) => {
                              streamingStatusOnchange(
                                event.currentTarget.value
                              );
                            }}
                          />
                        </div>
                      ))}
                    </Form.Group>
                    {streamingStatus == 2 ? (
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label className="choose-payment-label">
                          {t("start_time")}:
                        </Form.Label>
                        <Form.Control
                          className="date-input"
                          value={inputData.schedule_time}
                          onClick={() => setShow(!show)}
                          readOnly={true}
                        />
                        <DatePicker
                          value={startTime}
                          isOpen={show}
                          onSelect={time => { startTimeOnchange(time); setShow(false) }}
                          onCancel={() => setShow(false)}
                          confirmText={"Select"}
                          cancelText={"Cancel"}
                          headerFormat='DD/MM/YYYY'
                          theme={"dark"}
                          min={today}
                          showCaption={true}
                          dateConfig={dateConfig}
                        />
                      </Form.Group>
                    ) : (
                      ""
                    )}
                    {/* <div className="floating-label">
                    <input
                      className="floating-input"
                      type="text"
                      value={inputData.description ? inputData.description : null}
                      placeholder={t("go_live_description_placeholder")}
                      onChange={(event) =>
                        setInputData({
                          ...inputData,
                          description: event.currentTarget.value,
                        })
                      }
                    />
                    <span className="highlight"></span>
                    <label className="default-label">
                      {t("description")} ({t("optional")})
                    </label>
                  </div> */}
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label className="choose-payment-label">
                        {t("description")}:
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        className="height-auto"
                        value={
                          inputData.description ? inputData.description : null
                        }
                        placeholder={t("go_live_description_placeholder")}
                        onChange={(event) =>
                          setInputData({
                            ...inputData,
                            description: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="floating-form go-live-form">
                    <Form.Group>
                      <label className="choose-payment-label">
                        {t("payment_status")}
                      </label>
                      {["radio"].map((type) => (
                        <div key={`custom-inline-${type}`} className="mb-3">
                          {/* <Form.Check
                            custom
                            inline
                            label="Free"
                            type={type}
                            id="free"
                            value="0"
                            name="payment_status"
                            // defaultChecked={true}
                            onChange={(event) => {
                              paymentStatusOnchange(event.currentTarget.value);
                            }}
                          /> */}
                          <Form.Check
                            custom
                            inline
                            label="Paid"
                            type={type}
                            // id={`custom-inline-${type}-2`}
                            id="paid"
                            value="1"
                            defaultChecked={true}
                            name="payment_status"
                            onChange={(event) => {
                              paymentStatusOnchange(event.currentTarget.value);
                            }}
                          />
                        </div>
                      ))}
                    </Form.Group>
                    {paymentStatus == 1 ? (
                      <>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className="choose-payment-label">
                            Tokens (1 token =  {configuration.get("configData.currency")}{configuration.get("configData.token_amount")}):
                          </Form.Label>
                          <Form.Control
                            type="number"
                            min="0"
                            step="any"
                            value={inputData.amount ? inputData.amount : null}
                            onChange={(event) =>
                              setInputData({
                                ...inputData,
                                amount: event.currentTarget.value,
                              })
                            }
                          />
                        </Form.Group>
                        <div className="floating-label">
                          <label className="label-default-1">
                            {t("token_amount")} (
                            {configuration.get("configData.token_amount")} *{" "}
                            {inputData.amount ? inputData.amount : 0}) ={" "}
                            {configuration.get("configData.token_amount") *
                              inputData.amount}{" "}
                            {configuration.get("configData.currency")}
                          </label>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label className="choose-payment-label">
                        {t("duration_in_minutes")}:
                      </Form.Label>
                      <Form.Control
                        type="number"
                        min="0"
                        step="any"
                        value={
                          inputData.no_of_minutes
                            ? inputData.no_of_minutes
                            : null
                        }
                        onChange={(event) =>
                          setInputData({
                            ...inputData,
                            no_of_minutes: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          {t("upload_preview_image")}
                        </button>
                        <input
                          type="file"
                          name="preview_file"
                          accept="image/png, image/jpg, image/jpeg"
                          onChange={(event) => handleChangeImage(event)}
                        />
                      </div>
                    </Form.Group>
                    {previewImage !== "" ? (
                      <div className="upload-img-preview-sec">
                        <Image
                          className="upload-img-preview"
                          src={previewImage}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={closeGoLiveModal}
              >
                {t("cancel")}
              </Button>
              <Button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={handleSubmit}
                disabled={
                  inputData.title != ""
                    ? false
                    : true || props.videocall.buttonDisable
                }
              >
                {props.videocall.loadingButtonContent !== null
                  ? props.videocall.loadingButtonContent
                  : t("go_live")}
              </Button>
            </Modal.Footer>
          </Form>
        ) : null}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  videocall: state.videocall.saveVideoCallRequest,
  saveLiveVideo: state.liveVideo.saveLiveVideo,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(GoLiveModal));
