import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PostDisplayCard from "../helper/PostDisplayCard";
import { fetchSinglePostStart } from "../../store/actions/PostAction";
import { Col, Container } from "react-bootstrap";
import { Image, Media } from "react-bootstrap";
import { isMobile } from "react-device-detect";

const PostView = (props) => {
  useEffect(() => {
    props.dispatch(
      fetchSinglePostStart({
        post_unique_id: props.match.params.post_unique_id,
      })
    );
  }, [props.match.params.post_unique_id]);
  
  return (
    <div className="home-screen home-sec">
      <Container>
        <div className="padding-top-xl">
          <Col xl={9} offset={2} md={9} className="custom-padding">
            <ul className="nav" role="tablist">
              <Media
                as="li"
                role="presentation"
                className={props.activeSec === "settings-card" ? "active" : ""}
              >
                <Link
                  to={isMobile ? "/home" : "/w/home"}
                  className="bookmarkes-list"
                  onClick={() => props.history.goBack()}
                >
                  <Image
                    src={window.location.origin + "/assets/images/icons/back.svg"}
                    className="svg-clone"
                  />
                </Link>
              </Media>
            </ul>

            {props.singlePost.loading ? (
              ""
            ) : (
              <PostDisplayCard
                post={props.singlePost.data.post}
                key={props.singlePost.data.post.post_unique_id}
                isVideoAutoPlay={true}
              />
            )}
          </Col>
        </div>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  singlePost: state.post.singlePost,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PostView);
