import React, { useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

const CoachHomeProfileCard = (props) => {
    const { user } = props;

    return (
        <>
            {user.otherUser ?
                <Link to={isMobile ? `/w/${user.otherUser.user_unique_id}` : `/` + user.otherUser.user_unique_id}>
                    <div className="home-profile-card">
                        <div className="home-profile-card-bg-sec">
                            <Image
                                className="home-profile-card-bg"
                                src={user.otherUser.cover}
                            />
                        </div>
                        <div className="home-profile-card-content">
                            <div className="home-profile-user-img-sec">
                                <Image
                                    className="home-profile-user-img"
                                    src={user.otherUser.picture}
                                />
                            </div>
                            <h4>{user.otherUser.name} 
                                <span>
                                    {user.otherUser.is_verified_badge == 1 &&
                                    <Image
                                        className="coach-home-profile-verified-icon"
                                        src={
                                        window.location.origin + "/assets/images/fitness/user-verified-icon.png"
                                        }
                                    />
                                    }</span>
                            </h4>
                        </div>
                    </div>
                </Link>
                : ''}
        </>
    );
};

export default CoachHomeProfileCard;
