import React, { useEffect, Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./StaticPage.css";
import { connect } from "react-redux";
import { fetchSupportPageStart } from "../../store/actions/PageAction";
import { translate, t } from "react-multi-lang";
import CommonCenterLoader from "../Loader/CommonCenterLoader";
import { Link, useHistory } from "react-router-dom";

const StaticPageSupport = (props) => {
  const history = useHistory();

  useEffect(() => {
    props.dispatch(fetchSupportPageStart());
  }, []);

  console.log(props.supportData);
  return (
    <>
      <div className="static-page-sec">
        {props.supportData.loading ? (
          // t("loading")
          <CommonCenterLoader></CommonCenterLoader>
        ) : (
          <Container>
            <div className="static-back-btn">
              <Link to="#" onClick={() => history.goBack()}>
                <Image
                  src={window.location.origin + "/assets/images/icons/back.svg"}
                />
              </Link>
            </div>
            <h3 className="head-title">Help & Support</h3>
            <Row>
              <Col sm="12" md="12">
                <div className="static-box">
                  <h4 className="text-muted">
                    If you face any issues please drop a mail to
                  </h4>
                  <br></br>
                  <p className="text-muted">
                    <i className="fa fa-envelope svg-clone icon"></i>
                    <a href={`mailto:${props.supportData.data.support_email}`}>{props.supportData.data.support_email}</a>
                  </p>
                  {/* <h5 className="text-muted">
                    <i className="fa fa-mobile svg-clone icon"></i>
                    {props.supportData.data.support_mobile}
                  </h5> */}
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  supportData: state.page.supportData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(StaticPageSupport));
