import React, { useEffect, useState } from "react";
import { Notify } from "react-redux-notify";
import NewLatestHeader from "./Header/NewLatestHeader";

const CoachLayout = (props) => {

  const [themeState, setThemeState] = useState(false);

  const toggleClass = () => {
    setThemeState(!themeState);
  };

  return (
    <div className={`${themeState ? "dark-mode" : ""}`} >
      <Notify position="TopRight" />
      <NewLatestHeader toggleTheme={toggleClass}/>
        <div className="main-wrap-sec coach-layout">
          <div className="main-wrap-sec">
            {React.cloneElement(props.children)}
          </div>
        </div>
    </div>
  );
}

export default CoachLayout;


// import React, { Component,useState } from "react";
// import { Notify } from "react-redux-notify";

// const CoachLayout  = (props) => {

//   const [themeState, setThemeState] = useState(false);

//   const toggleClass = () => {
//     setThemeState(!themeState);
//   };

//   return (
//     <body>
//       <Notify position="TopRight" />
//       <NewLatestHeader toggleTheme={toggleClass}/>
//       <div className="main-wrap-sec coach-layout">
//         {React.cloneElement(this.props.children)}
//       </div>
//       <br />
//     </body>
//   );
// }