import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import AgoraRTC from "agora-rtc-sdk-ng";
import { Form, Button, Container, Row, Col, Table, Media, Image } from "react-bootstrap";
import {
    endVideoCallStart,
    fetchSingleVideoCallStart,
    joinVideoCallStart,
} from "../../store/actions/PrivateCallAction";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";

const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
const $ = window.$;
AgoraRTC.setLogLevel(4);

const AgoraOnetoOne = (props) => {

    var rtc = {
        // For the local client.
        client: null,
        // For the local audio and video tracks.
        localAudioTrack: null,
        localVideoTrack: null,
    };


    var localTracks = {
        videoTrack: null,
        audioTrack: null
    };

    var localTrackState = {
        videoTrackEnabled: true,
        audioTrackEnabled: true
    }

    var options = {
        // Pass your app ID here.
        appId: configuration.get("configData.agora_app_id"),
        // Set the channel name.
        channel: props.videoCallData.virtual_id,
        // Pass a token if your project enables the App Certificate.
        token: props.videoCallData.agora_token ? props.videoCallData.agora_token : null,
        uid: null,
    };

    var remoteUsers = {};

    async function startBasicCall() {

        rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

        rtc.client.on("user-published", handleUserPublished);
        rtc.client.on("user-unpublished", handleUserUnpublished);

        [options.uid, localTracks.audioTrack, localTracks.videoTrack] = await Promise.all([
            // join the channel
            await rtc.client.join(options.appId, options.channel, options.token, null),
            // create local tracks, using microphone and camera
            rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack(),
            rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack()
        ]);


        localTracks.videoTrack.play("local-player");

        await rtc.client.publish(Object.values(localTracks));

        // Publish the local audio and video tracks to the channel.
        // await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);

        console.log("publish success!");

        props.dispatch(
            joinVideoCallStart({
                video_call_request_id: props.videoCallData.video_call_request_id,
            })
        );


    }

    async function subscribe(user, mediaType) {
        const uid = user.uid;
        // subscribe to a remote user
        await rtc.client.subscribe(user, mediaType);
        console.log("subscribe success");
        if (mediaType === 'video') {

            const remoteVideoTrack = user.videoTrack;
            remoteVideoTrack.play("remote-playerlist");
        }
        if (mediaType === 'audio') {
            user.audioTrack.play();
        }
    }

    function handleUserPublished(user, mediaType) {
        const id = user.uid;
        remoteUsers[id] = user;
        subscribe(user, mediaType);
        $("#remote-playerlist").show();
        $("#remote-player-placeholder").hide();
    }

    function handleUserUnpublished(user) {
        $("#remote-playerlist").hide();
        $("#remote-player-placeholder").show();
        // const id = user.uid;
        // delete remoteUsers[id];
        // $(`#player-wrapper-${id}`).remove();
    }


    startBasicCall();

    async function leaveCall(is_owner) {
        if (is_owner) {
            // Destroy the local audio and video tracks.
            rtc.localAudioTrack.close();
            rtc.localVideoTrack.close();

            // Traverse all remote users.
            rtc.client.remoteUsers.forEach(user => {
                // Destroy the dynamically created DIV container.
                const playerContainer = document.getElementById(user.uid);
                playerContainer && playerContainer.remove();
            });
            props.dispatch(
                endVideoCallStart({
                    video_call_request_id: props.videoCallData.video_call_request_id,
                })
            );
            window.location.assign("/video-calls-received");
        } else {
            // Leave the channel.
            await rtc.client.leave();
            window.location.assign("/video-calls-sent");
        }


    }

    async function muteAudio() {
        if (!rtc.localAudioTrack) return;
        if (localTrackState.audioTrackEnabled == true) {
            await rtc.localAudioTrack.setEnabled(false);
            localTrackState.audioTrackEnabled = false;
            $("#mute-audio").hide();
            $("#unmute-audio").show();
        } else {
            await rtc.localAudioTrack.setEnabled(true);
            localTrackState.audioTrackEnabled = true;
            $("#mute-audio").show();
            $("#unmute-audio").hide();
        }
    }

    async function muteVideo() {
        if (!rtc.localVideoTrack) return;
        if (localTrackState.videoTrackEnabled == true) {
            await rtc.localVideoTrack.setEnabled(false);
            localTrackState.videoTrackEnabled = false;
            $("#mute-video").hide();
            $("#unmute-video").show();
            $("#local-player").hide();
            $("#local-player-placeholder").show();
        } else {
            await rtc.localVideoTrack.setEnabled(true);
            localTrackState.videoTrackEnabled = true;
            $("#mute-video").show();
            $("#unmute-video").hide();
            $("#local-player").show();
            $("#local-player-placeholder").hide();
        }
    }
    console.log(props.isOwner);
    return (
        <>
            <div className="new-video-call-box">
                <div className="new-video-call-card">

                    <div id="remote-playerlist" className="one-to-one-video-bg" />
                    <div id="remote-player-placeholder" className="reciver-video-placeholder" style={{ display: "none" }}>
                        <Image
                            className="new-video-call-img"
                            src={props.videoCallData ? props.videoCallData.is_model ?
                                props.videoCallData.user_picture :
                                props.videoCallData.model_picture :
                                window.location.origin + "/assets/images/fitness/videocall_bg.jpg"
                            }
                        />
                    </div>

                    <div className="new-video-call-user-card">
                        <div id="local-player" className="one-to-one-video-call" />
                        <div id="local-player-placeholder" className="local-player-placeholder" style={{ display: "none" }}>
                            <Image
                                className="new-video-call-user-img"
                                src={
                                    props.videoCallData ? props.videoCallData.is_model ?
                                        props.videoCallData.model_picture :
                                        props.videoCallData.user_picture :
                                        window.location.origin + "/assets/images/fitness/videocall-1.jpg"
                                }
                            />
                        </div>
                        {/* <div className="new-video-call-user-mute-option-bg">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 9 10"
                            >
                                <path
                                    fill="#fff"
                                    d="M5.999 5.517a1.42 1.42 0 00.149-.621V3.122a1.42 1.42 0 00-2.81-.266l2.66 2.66zm1.213-.621a.355.355 0 10-.71 0c0 .31-.085.614-.244.88l.521.539c.286-.418.437-.913.433-1.42zM4.728 6.315h.057L3.31 4.835v.06a1.42 1.42 0 001.42 1.42zm3.09 1.522L2.143 2.16a.356.356 0 10-.504.504L7.315 8.34a.355.355 0 00.581-.116.355.355 0 00-.077-.388z"
                                ></path>
                                <path
                                    fill="#fff"
                                    d="M5.792 8.09h-.71V7.35c.202-.028.398-.08.586-.156l-.567-.568a1.621 1.621 0 01-.373.043 1.774 1.774 0 01-1.774-1.774.355.355 0 10-.71 0 2.484 2.484 0 002.13 2.455v.738h-.71a.355.355 0 000 .71h2.128a.355.355 0 000-.71z"
                                ></path>
                            </svg>
                        </div> */}
                    </div>

                    {/* <div className="live-streaming-video-append-sec">

                        <div id="local-player" className="one-to-one-own-video" />
                        <div id="local-player-placeholder" className="one-to-one-own-video local-player-placeholder" style={{ display: "none" }}>
                            <Image
                                className="live-streaming-video-append-img"
                                src={
                                    props.videoCallData ? props.videoCallData.is_model ?
                                        props.videoCallData.model_picture :
                                        props.videoCallData.user_picture :
                                        window.location.origin + "/assets/images/fitness/videocall-1.jpg"
                                }
                            />
                        </div>

                        
                    </div> */}
                    <div className="new-video-call-action-btn-sec">
                        <ul className="list-unstyled new-video-call-action-btn-list">
                            {/* {props.isOwner ? ( */}
                            <>

                                <Media as="li"
                                    onClick={() =>
                                        muteAudio()
                                    }
                                >
                                    <a to="#">
                                        <i id="mute-audio" className="fa fa-microphone"></i>
                                        <i id="unmute-audio" className="fa fa-microphone-slash" style={{ display: "none" }}></i>
                                    </a>
                                </Media>
                                <Media as="li" className="call-animation-li"
                                    onClick={() => {
                                        if (window.confirm(t("leave_call_confirmation"))) {
                                            leaveCall(props.isOwner)
                                        };
                                    }
                                    }
                                >
                                    <Link to="#" className="end-call-bg">
                                        <svg
                                            xlns="http://www.w3.org/2000/svg"
                                            width="40"
                                            height="40"
                                            fill="fff"
                                            viewBox="0 0 40 40"
                                        >
                                            <g clipPath="url(#clip0_8_390)">
                                                <path
                                                    fill="#fff"
                                                    d="M32.085 27.433l-.287 1.316c-.268 1.234-1.449 2.036-2.757 1.875l-2.607-.32c-1.134-.141-2.083-.975-2.345-2.062l-.726-3.028c-1.18-.52-2.49-.778-3.927-.774a10.344 10.344 0 00-4.032.797l-.572 2.803c-.217 1.06-1.12 1.834-2.236 1.916l-2.604.194c-1.3.096-2.52-.761-2.856-2.005l-.36-1.333c-.359-1.328.028-2.705 1.015-3.615 2.332-2.15 6.15-3.157 11.456-3.026 5.314.132 9.195 1.336 11.637 3.61 1.028.957 1.485 2.346 1.2 3.652z"
                                                ></path>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_8_390">
                                                    <path
                                                        fill="#fff"
                                                        d="M0 0H28.61V28.61H0z"
                                                        transform="rotate(120 15.358 18.704)"
                                                    ></path>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </Link>
                                </Media>
                                <Media as="li"
                                    onClick={() =>
                                        muteVideo()
                                    }
                                >
                                    <Link to="#">
                                        <i id="mute-video" className="fa fa-video"></i>
                                        <i id="unmute-video" className="fa fa-video-slash" style={{ display: "none" }}></i>
                                    </Link>
                                </Media>
                            </>

                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};


const mapStateToPros = (state) => ({

});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(AgoraOnetoOne));