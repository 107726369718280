import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, Button, Form, Image, InputGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Auth.css";
import {
	isAndroid,
	isIOS,
	isWindows,
	isMacOs,
	mobileModel,
	browserName,
	osName,
	mobileVendor,
	browserVersion
} from "react-device-detect";
import configuration from "react-global-configuration";
import {
	userLoginStart,
	userRegisterStart,
} from "../../store/actions/UserAction";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import SocialButton from "../helper/SocialButton";
import { gapi } from "gapi-script";

const TrainerLoginIndex = (props) => {

	const [loginInputData, setLoginInputData] = useState({
		role: "trainer"
	});

	useEffect(() => {

		var device_type = "";
		var device_model = "";
		var browser_type = browserName;

		if (isAndroid == true) {
			device_type = "android";
			device_model = mobileModel;
		} else if (isIOS == true) {
			device_type = "ios";
			device_model = mobileModel;
		} else {
			device_type = "web";
			device_model = browserName + ' ' + browserVersion;
		}

		setLoginInputData({
			...loginInputData,
			email: configuration.get("configData.demo_user_email"),
			password: configuration.get("configData.demo_user_password"),
			device_type: device_type,
			device_model: device_model,
			browser_type: browser_type,
		})
	}, []);

	const handleLogin = (event) => {
		event.preventDefault();
		props.dispatch(userLoginStart(loginInputData));
	};

	// const handleFacebookLogin = (user) => {
	// 	console.log("handleFacebookLogin", user._profile);
	// 	props.dispatch(
	// 		userRegisterStart({
	// 			name: user._profile.name,
	// 			first_name: user._profile.firstName ? user._profile.firstName : "",
	// 			last_name: user._profile.lastName ? user._profile.lastName : "",
	// 			email: user._profile.email ? user._profile.email : "",
	// 			social_unique_id: user._profile.id,
	// 			picture: user._profile.profilePicURL,
	// 			login_by: "facebook",
	// 		})
	// 	);
	// };

	// useEffect(() => {
	// 	gapi.load("client:auth2", () => {
	// 	  gapi.client.init({
	// 		clientId: configuration.get("configData.GOOGLE_CLIENT_ID"),
	// 		plugin_name: "chat",
	// 	  });
	// 	});
	// }, []);

	// const handleGoogleLogin = (user) => {
	// 	console.log("handleGoogleLogin", user._profile);
	// 	props.dispatch(
	// 		userRegisterStart({
	// 			name: user._profile.name,
	// 			email: user._profile.email,
	// 			first_name: user._profile.firstName ? user._profile.firstName : "",
	// 			last_name: user._profile.lastName ? user._profile.lastName : "",
	// 			social_unique_id: user._profile.id,
	// 			picture: user._profile.profilePicURL,
	// 			login_by: "google",
	// 		})
	// 	);
	// };

	// const handleSocialLoginFailure = (err) => {
	// 	console.error(err);
	// };

	return (
		<>
			<div className="login-43">
				<Container>
					<Row>
						<Col lg={5} md={12} className="bg-img none-992">
							
						</Col>
						<Col lg={7} md={12} className="bg-color-8 align-self-center">
						<div className="info">
								<div className="logo clearfix">
									<Link to="#" className="coach-logo">
										<Image
											src={
												window.location.origin + "/assets/images/fitness/coach-logo-white.png"
											}
										/>
									</Link>
								</div>
								<div className="social-list">
									{/* {configuration.get("configData.FB_CLIENT_ID") &&
										<SocialButton
											provider="facebook"
											appId={configuration.get("configData.FB_CLIENT_ID")}
											onLoginSuccess={handleFacebookLogin}
											onLoginFailure={handleSocialLoginFailure}
											className="social-button"
											id="facebook-connect"
										>
											<i className="fab fa-facebook-f"></i>
										</SocialButton>	
									}								
									{configuration.get("configData.GOOGLE_CLIENT_ID") &&
										<SocialButton
											provider="google"
											key={"google"}
											appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
											onLoginSuccess={handleGoogleLogin}
											onLoginFailure={handleSocialLoginFailure}
											className="social-button"
											id="google-connect"
										>
											<i className="fab fa-google-plus-g"></i>
										</SocialButton>
									} */}
								</div>
							</div>
							<div className="form-section">
								<h3 className="trainer-head-title">Login</h3>
								<div className="login-inner-form">
									<div className="trainer-form">
										<Form onSubmit={handleLogin}>
											<InputGroup className="mb-3">
												<FormControl
													placeholder="Email Id"
													aria-label="Email Id"
													aria-describedby="basic-addon2"
													value={loginInputData.email}
													onChange={e => setLoginInputData({
														...loginInputData,
														email: e.target.value,
													})}
												/>
												<InputGroup.Text id="basic-addon2"><i className="fas fa-envelope"></i></InputGroup.Text>
											</InputGroup>
											<InputGroup className="mb-3">
												<FormControl
													type="password"
													placeholder="Password"
													aria-label="Password"
													aria-describedby="basic-addon2"
													value={loginInputData.password}
													onChange={e => setLoginInputData({
														...loginInputData,
														password: e.target.value,
													})}
												/>
												<InputGroup.Text id="basic-addon2"><i className="fas fa-lock"></i></InputGroup.Text>
											</InputGroup>
											{/* <Form.Group>
                                                <div className="checkbox form-box">
                                                    <div className="form-check checkbox-theme">
                                                        <input className="form-check-input" type="checkbox" value="" id="rememberMe" />
                                                        <label className="form-check-label" for="rememberMe">
                                                            Remember Me
                                                        </label>
                                                    </div>
                                                </div>
                                            </Form.Group> */}
											<Form.Group>
												<p className="text mb-5"><Link to="/trainer-forgot-password">Forgot password</Link></p>
											</Form.Group>
											<Form.Group>
												<Button type="submit" className="btn-md btn-theme w-100" disabled={props.login.buttonDisable}>
													{props.login.loadingButtonContent !== null
														? props.login.loadingButtonContent
														: "Login"}
												</Button>
											</Form.Group>
											<p className="text">If you don’t have an account You can<Link to="/trainer-register" className="ml-2"> Signup here</Link></p>
										</Form>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

const mapStateToPros = (state) => ({
	login: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(
	mapStateToPros,
	mapDispatchToProps
)(translate(TrainerLoginIndex));
