import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Media,
} from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import "./Auth.css";
import {
  isAndroid,
  isIOS,
  isWindows,
  isMacOs,
  mobileModel,
  browserName,
  osName,
  mobileVendor,
  browserVersion,
} from "react-device-detect";
import configuration from "react-global-configuration";
import {
  userLoginStart,
  userRegisterStart,
} from "../../store/actions/UserAction";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
// import SocialButton from "../helper/SocialButton";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
} from 'reactjs-social-login';
import { useLocation } from 'react-router';

import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons';

const REDIRECT_URI = window.location.href;

const LoginIndex = (props) => {

  var device_type = "";
  var device_model = "";
  var browser_type = browserName;

  if (isAndroid == true) {
    device_type = "android";
    device_model = mobileModel;
  } else if (isIOS == true) {
    device_type = "ios";
    device_model = mobileModel;
  } else {
    device_type = "web";
    device_model = browserName + " " + browserVersion;
  }

  const [loginInputData, setLoginInputData] = useState({
    email: configuration.get("configData.demo_user_email"),
    password: configuration.get("configData.demo_user_password"),
    role: "user",
    device_type: device_type,
    device_model: device_model,
    browser_type: browser_type,
  });

  const history = useHistory();
  const [skipRender, setSkipRender] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("invalid_email_note"))
      .required(t("email_is_required")),
    password: Yup.string()
      .required(t("password_is_required"))
      .matches(
        /^(?=.*[a-zA-Z-0-9])/,
        t("space_values_not_valid")
      )
      .min(6
        ,
        t("must_contain_6_characters")
      ),
  });

  const passwordValidation = (value) => {
    const trimmedValue = value.replaceAll(" ", "");
    if (trimmedValue !== value)
      return t("should_not_contain_empty_space");
    return "";
  }

  const handleLogin = (values) => {
    props.dispatch(userLoginStart(values));
    // console.log(values);
  };
  const { pathname } = useLocation();

  const handleFacebookLogin = (response) => {
    console.log("handleFacebookLogin", response);

    const emailAddress = response.email === undefined || response.email === null ? response.id + "@facebook.com" : response.email;

    props.dispatch(
      userRegisterStart({
        name: response.name,
        first_name: response.first_name ? response.first_name : "",
        last_name: response.last_name ? response.last_name : "",
        email: emailAddress,
        social_unique_id: response.userID,
        picture: response.picture ? response.picture.data.url : '',
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (response) => {
    console.log("handleGoogleLogin", response);
    props.dispatch(
      userRegisterStart({
        name: response.name,
        email: response.email,
        first_name: response.given_name ? response.given_name : "",
        last_name: response.family_name ? response.family_name : "",
        social_unique_id: response.sub,
        picture: response.picture,
        login_by: "google",
      })
    );
  };

  useEffect(() => {
    if (localStorage.getItem("redirect")) {
      if(localStorage.getItem("is_client") == 1 || localStorage.getItem("is_client") == 0) {
        history.push(`/login-redirect?is_client=${localStorage.getItem("is_client")}`);
      } else if (!localStorage.getItem("userId") || pathname == "/become-a-coach") {
        history.push(`/login-redirect?is_client=0`);
        localStorage.setItem("is_client", 2);
      }
    }
  }, []);

  return (
    <>
      <div className="login-sec">
        <div className="coach-template-animation coach-template-layout34">
          {/* <div className="coach-shape">
            <div className="">
              <Image
                src={
                  window.location.origin + "/assets/images/fitness/shape1.png"
                }
              />
            </div>
          </div> */}
          <div className="new-mobile-login-sec">
            <Container>
              <Row>
                <Col lg={7}>
                  <div className="coach-column-wrap justify-content-center left-text-padding">
                    {/* <div className="coach-animated-img">
                      <div className="">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/fitness/rocket-img.png"
                          }
                        />
                      </div>
                    </div> */}
                    <div className="coach-bg-card">
                      <div className="">
                        <Link to="#" className="coach-logo">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/fitness/coach-logo-white.png"
                            }
                          />
                        </Link>
                      </div>
                      <div className="">
                        <div className="coach-middle-content">
                          <h2 className="coach-main-title">
                            {t("sign_in_to_coach_me")}
                          </h2>
                        </div>
                        <div className="coach-switcher-description1 desktop-signin">
                          {t("if_you_dont_have_an_account_you_can")}{" "}
                          <Link
                            to="/register"
                            className="coach-switcher-text ms-2"
                          >
                            {t("sign_up")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="coach-column-wrap justify-content-center">
                    <div className="coach-form">
                      <h2 className="coach-sub-title">{t("login")}</h2>
                      <Formik
                        initialValues={loginInputData}
                        validationSchema={loginSchema}
                        onSubmit={(values) => handleLogin(values)}
                      >
                        {({
                          touched,
                          errors,
                          values,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <Form noValidate className="login-form">
                            <Field
                              type="email"
                              placeholder={t("email_address")}
                              autoFocus
                              name="email"
                              className="form-control"
                              value={values.email}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="email"
                              className="text-danger text-right"
                            />
                            <div className="icon-password-sec">
                              <Field
                                type={passwordShown ? "text" : "password"}
                                placeholder="********"
                                name="password"
                                className="form-control"
                                value={values.password}
                                validate={passwordValidation}
                              />
                              <i
                                className="toggle-password"
                                onClick={togglePasswordVisiblity}
                              >
                                {passwordShown ? <FaEyeSlash /> : <FaEye />}
                              </i>{" "}
                            </div>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="text-danger text-right"
                            />


                            <div className="coach-forgot-password-sec">
                              <div className="coach-checkbox-box">
                                <Link className="terms-link" to="/forgot-password">
                                  Forgot Password
                                </Link>
                              </div>
                            </div>
                            <Button
                              type="submit"
                              className="coach-btn-fill"
                              disabled={props.login.buttonDisable}
                            >
                              {props.login.loadingButtonContent !== null
                                ? props.login.loadingButtonContent
                                : t("signin")}
                            </Button>
                            <div className="auth-note-sec">
                                <span>Do you want register as user?</span>  <Link to="/register" className="auth-note">Signup  </Link>
                            </div>
                            <div className="coach-switcher-description1 mobile-signin">
                              {t("if_you_dont_have_an_account_you_can")}{" "}
                              <Link
                                to="/register"
                                className="coach-switcher-text ms-2"
                              >
                                {t("sign_up")}
                              </Link>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                    {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                      configuration.get("configData.FB_CLIENT_ID") ? (
                      <div className="coach-style-line">
                        <span>Or Continue with</span>
                      </div>
                    ) : null}
                    <ul className="coach-socials list-unstyled">
                      {configuration.get("configData.GOOGLE_CLIENT_ID") && (
                        <LoginSocialGoogle
                          client_id={configuration.get("configData.social_logins.GOOGLE_CLIENT_ID")}
                          redirect_uri={REDIRECT_URI}
                          scope="openid profile email"
                          discoveryDocs="claims_supported"
                          access_type="offline"
                          onResolve={({ provider, data }: IResolveParams) => {
                            handleGoogleLogin(data);
                          }}
                          onReject={err => {
                            console.log(err);
                          }}
                        >
                          <Media as="li" className="coach-facebook">
                            <Link to="#" title="Google">
                              <i className="fab fa-google"></i>
                            </Link>
                          </Media>
                        </LoginSocialGoogle>
                      )}
                      {configuration.get("configData.FB_CLIENT_ID") && (
                        <LoginSocialFacebook
                          appId={configuration.get("configData.social_logins.FB_CLIENT_ID")}
                          fieldsProfile={
                            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                          }
                          redirect_uri={REDIRECT_URI}
                          onResolve={({ provider, data }: IResolveParams) => {
                            handleFacebookLogin(data);
                          }}
                          onReject={err => {
                            console.log(err);
                          }}
                        >
                          <Media as="li" className="coach-facebook">
                            <Link to="#" title="Facebook">
                              <i className="fab fa-facebook-f"></i>
                            </Link>
                          </Media>
                        </LoginSocialFacebook>
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LoginIndex));
