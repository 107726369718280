import React, { useEffect, useState, useRef } from "react";
import LoginModal from '../Model/LoginModal';
import SignupModal from "../Model/SignupModal";
import ForgotPasswordModal from "../Model/ForgotPasswordModal";
import { Link, useHistory } from "react-router-dom";

const LoginModalContainer = () => {
    const history = useHistory();

    const [loginModal, setLoginModal] = useState(true);
    const [signupModal, setSignupModal] = useState(false);
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);

    const openSignupModal = () => {
        setLoginModal(false);
        setSignupModal(true);
    };

    const closeLoginModal = () => {
        setLoginModal(false);
        localStorage.removeItem("redirect");
        localStorage.removeItem("is_client");
        history.push("/");
    };

    const closeSignupModal = () => {
        setSignupModal(false);
        localStorage.removeItem("redirect");
        localStorage.removeItem("is_client");
        history.push("/");
    };

    const openForgotPasswordModal = () => {
        setLoginModal(false);
        setSignupModal(false);
        setForgotPasswordModal(true);
    }

    const closeForgotPasswordModal = () => {
        setForgotPasswordModal(false);
        setSignupModal(false);
        localStorage.removeItem("redirect");
        history.push("/");
    };

    return (
        <>
            <LoginModal
                loginModal={loginModal}
                closeLoginModal={closeLoginModal}
                openSignupModal={openSignupModal}
                openForgotPasswordModal={openForgotPasswordModal}
            />

            <SignupModal
                signupModal={signupModal}
                closeSignupModal={closeSignupModal}
            />

            <ForgotPasswordModal
                forgotPasswordModal={forgotPasswordModal}
                closeForgotPasswordModal={closeForgotPasswordModal}
            />
        </>
    );
}

export default LoginModalContainer;