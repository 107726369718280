import React from "react";
import {Form, Button, Image, Modal} from "react-bootstrap";

const NewSingleProfileDetailsModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center new-single-profile-modal"
        size="md"
        centered
        show={props.newSingleProfileDetails}
        onHide={props.closeNewSingleProfileDetailsModal}
      >
          <Modal.Header closeButton>
            <Modal.Title>Profile Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="new-single-profile-modal-card">
              <div className="new-single-profile-modal-bg-img-sec">
                <Image
                  className="new-single-profile-modal-bg-img"
                  src={props.user.cover}
                  alt={props.user.name}
                />
              </div>
              <div className="new-single-profile-modal-info-sec">
                <Image
                  className="new-single-profile-modal-user-img"
                  src={props.user.picture}
                  alt={props.user.name}
                />
                <h2>{props.user.name}</h2>
                <p>{props.user.about_formatted}</p>
              </div>
            </div>
          </Modal.Body>
      </Modal>
    </>
  );
};

export default NewSingleProfileDetailsModal;