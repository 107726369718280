import React, { Component } from "react";
import { Notify } from "react-redux-notify";
import EmptyHeader from "./Header/EmptyHeader";

class EmptyLayoutNew extends Component {
  state = {};
  render() {
    return (
      <body className="line-height-zero">
        <Notify position="TopRight" />
        <div className="main-wrap-sec empty-layout-wrap">
          {React.cloneElement(this.props.children)}
        </div>
        <br />
      </body>
    );
  }
}

export default EmptyLayoutNew;
