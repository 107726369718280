import React, { useState } from "react";
import { Dropdown, Image, Media, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import PaymentModal from "./PaymentModal";
import configuration from "react-global-configuration";
import { liveVideoStartCallStart } from "../../store/actions/LiveVideoAction";

const ScheduleLiveDataCard = (props) => {
  const { video } = props;
  const [paymentModal, setPaymentModal] = useState(false);
  const openPaymentModal = (event) => {
    event.preventDefault();
    setPaymentModal(true);
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const handleLiveStart = (event, live_video_id) => {
    event.preventDefault();
    props.dispatch(
      liveVideoStartCallStart({
        live_video_id: live_video_id,
      })
    );
  };

  return (
    <>
      <div className="video-list-card">
        <Link
          to={`/live-video/${video.live_video_unique_id}`}
          className="btn"
          style={{ marginBottom: "1rem" }}
        >
          <div className="video-list-img-sec">
            <Image
              // src={configuration.get("configData.live_streaming_placeholder_img")}
              src={video.snapshot}
              className="video-list-img"
            />
            <div className="video-top-header-sec-1">
              <h3>{video.formatted_schedule_time}</h3>
            </div>
            {/* <div className="video-bottom-sec">
                            <h3>
                                {video.viewer_cnt} {t("views")}
                            </h3>
                        </div> */}
            {video.amount > 0 ? (
              <div className="video-bottom-right-sec">
                <h3>{video.amount_formatted}</h3>
              </div>
            ) : (
              ""
            )}
          </div>
        </Link>
        <div className="video-list-user-details">
          <div className="user-img-sec">
            <Image src={video.user_picture} className="user-img" />
          </div>
          <div className="video-list-user-info">
            <div className="video-list-user-card">
              <div className="video-list-data-type">
                <h4 className="title">{video.title}</h4>
                <h4 className="username">@{video.user_displayname}</h4>
                <h4 className="description">{video.description}</h4>
                <h4 className="date">{video.created_at_formatted}</h4>
              </div>
              <div className="resp-align-right">
                {video.streaming_type == 2 &&
                video.user_id == localStorage.getItem("userId") ? (
                  <Button
                    className="live-btn-blue"
                    onClick={(event) =>
                      handleLiveStart(event, video.live_video_id)
                    }
                  >
                    {t("start_now")}
                  </Button>
                ) : video.is_streaming ? (
                  <Link
                    className="live-btn-blue"
                    to={`/join/${video.live_video_unique_id}`}
                  >
                    {t("join_now")}
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ScheduleLiveDataCard));
