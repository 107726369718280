import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import AgoraRTC from "agora-rtc-sdk-ng";
import {
    Form,
    Button,
    Container,
    Row,
    Col,
    Table,
    Media,
    Image,
} from "react-bootstrap";
import {
    endAudioCallStart,
    joinAudioCallStart,
} from "../../store/actions/PrivateCallAction";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
AgoraRTC.setLogLevel(4);

const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
const $ = window.$;

const AgoraAudioCall = (props) => {
    var rtc = {
        // For the local client.
        client: null,
        // For the local audio and video tracks.
        localAudioTrack: null,
        // localVideoTrack: null,
    };

    var localTracks = {
        audioTrack: null,
    };

    var localTrackState = {
        audioTrackEnabled: true,
    };

    var options = {
        // Pass your app ID here.
        appId: configuration.get("configData.agora_app_id"),
        // Set the channel name.
        channel: props.audioCallData.virtual_id,
        // Pass a token if your project enables the App Certificate.
        token: props.audioCallData.agora_token
            ? props.audioCallData.agora_token
            : null,
        uid: null,
    };

    var remoteUsers = {};

    async function startBasicCall() {
        // Create an AgoraRTCClient object.
        rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

        rtc.client.on("user-published", handleUserPublished);
        rtc.client.on("user-unpublished", handleUserUnpublished);

        [options.uid, localTracks.audioTrack] = await Promise.all([
            // join the channel
            await rtc.client.join(
                options.appId,
                options.channel,
                options.token,
                null
            ),
            // create local tracks, using microphone and camera
            (rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()),
        ]);

        await rtc.client.publish([rtc.localAudioTrack]);

        console.log("publish success!");

        props.dispatch(
            joinAudioCallStart({
                audio_call_request_id: props.audioCallData.audio_call_request_id,
            })
        );
    }

    async function subscribe(user, mediaType) {
        const uid = user.uid;
        // subscribe to a remote user
        await rtc.client.subscribe(user, mediaType);
        console.log("subscribe success");
        if (mediaType === "audio") {
            user.audioTrack.play();
        }
    }

    function handleUserPublished(user, mediaType) {
        const id = user.uid;
        remoteUsers[id] = user;
        subscribe(user, mediaType);
    }

    function handleUserUnpublished(user) {
        // const id = user.uid;
        // delete remoteUsers[id];
        // $(`#player-wrapper-${id}`).remove();
    }

    startBasicCall();

    async function leaveCall(is_owner) {
        if (is_owner) {
            // Destroy the local audio track.
            rtc.localAudioTrack.close();

            // Leave the channel.
            await rtc.client.leave();
            if (window.confirm(t("are_you_sure_to_audio_leave")))
                props.dispatch(
                    endAudioCallStart({
                        audio_call_request_id: props.audioCallData.audio_call_request_id,
                    })
                );

            window.location.assign("/audio-calls-received");
        } else {
            // Leave the channel.
            await rtc.client.leave();
            if (window.confirm(t("are_you_sure_to_audio_leave")))
                window.location.assign("/audio-calls-sent");
        }
    }

    async function muteAudio() {
        if (!rtc.localAudioTrack) return;
        if (localTrackState.audioTrackEnabled == true) {
            await rtc.localAudioTrack.setEnabled(false);
            localTrackState.audioTrackEnabled = false;
            $("#mute-audio").hide();
            $("#unmute-audio").show();
        } else {
            await rtc.localAudioTrack.setEnabled(true);
            localTrackState.audioTrackEnabled = true;
            $("#mute-audio").show();
            $("#unmute-audio").hide();
        }
    }

    console.log(props.isOwner);
    return (
        <div className="new-video-call-box">
            <div className="new-video-call-card">
                <div className="new-audio-call-img" id="local-player">
                    <img
                        src={props.audioCallData.model_picture}
                        className="new-aduio-call-user-name-bg"
                    />
                </div>
                <div className="new-video-call-user-card" id="remote-playerlist">

                    <img
                        className="new-video-call-user-img"
                        src={props.audioCallData.user_picture}
                    />
                </div>
                <div className="new-video-call-action-btn-sec">
                    {props.isOwner ? (

                        <ul className="list-unstyled new-video-call-action-btn-list">
                            <Media as="li">
                                <Link to="#" onClick={() => leaveCall(props.isOwner)} className="end-call-bg">
                                    <svg
                                        xlns="http://www.w3.org/2000/svg"
                                        width="40"
                                        height="40"
                                        fill="fff"
                                        viewBox="0 0 40 40"
                                    >
                                        <g clipPath="url(#clip0_8_390)">
                                            <path
                                                fill="#fff"
                                                d="M32.085 27.433l-.287 1.316c-.268 1.234-1.449 2.036-2.757 1.875l-2.607-.32c-1.134-.141-2.083-.975-2.345-2.062l-.726-3.028c-1.18-.52-2.49-.778-3.927-.774a10.344 10.344 0 00-4.032.797l-.572 2.803c-.217 1.06-1.12 1.834-2.236 1.916l-2.604.194c-1.3.096-2.52-.761-2.856-2.005l-.36-1.333c-.359-1.328.028-2.705 1.015-3.615 2.332-2.15 6.15-3.157 11.456-3.026 5.314.132 9.195 1.336 11.637 3.61 1.028.957 1.485 2.346 1.2 3.652z"
                                            ></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_8_390">
                                                <path
                                                    fill="#fff"
                                                    d="M0 0H28.61V28.61H0z"
                                                    transform="rotate(120 15.358 18.704)"
                                                ></path>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </Link>
                            </Media>
                            <Media as="li"
                                onClick={() =>
                                    muteAudio()
                                }
                            >
                                <a to="#">
                                    <i id="mute-audio" className="fa fa-microphone"></i>
                                    <i id="unmute-audio" className="fa fa-microphone-slash" style={{ display: "none" }}></i>
                                </a>
                            </Media>
                        </ul>
                    ) : (
                        <ul className="list-unstyled new-video-call-action-btn-list">
                            <Media as="li">
                                <Link to="#" onClick={() => leaveCall(props.isOwner)} className="end-call-bg">
                                    <svg
                                        xlns="http://www.w3.org/2000/svg"
                                        width="40"
                                        height="40"
                                        fill="fff"
                                        viewBox="0 0 40 40"
                                    >
                                        <g clipPath="url(#clip0_8_390)">
                                            <path
                                                fill="#fff"
                                                d="M32.085 27.433l-.287 1.316c-.268 1.234-1.449 2.036-2.757 1.875l-2.607-.32c-1.134-.141-2.083-.975-2.345-2.062l-.726-3.028c-1.18-.52-2.49-.778-3.927-.774a10.344 10.344 0 00-4.032.797l-.572 2.803c-.217 1.06-1.12 1.834-2.236 1.916l-2.604.194c-1.3.096-2.52-.761-2.856-2.005l-.36-1.333c-.359-1.328.028-2.705 1.015-3.615 2.332-2.15 6.15-3.157 11.456-3.026 5.314.132 9.195 1.336 11.637 3.61 1.028.957 1.485 2.346 1.2 3.652z"
                                            ></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_8_390">
                                                <path
                                                    fill="#fff"
                                                    d="M0 0H28.61V28.61H0z"
                                                    transform="rotate(120 15.358 18.704)"
                                                ></path>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </Link>
                            </Media>
                            <Media as="li"
                                onClick={() =>
                                    muteAudio()
                                }
                            >
                                <a to="#">
                                    <i id="mute-audio" className="fa fa-microphone"></i>
                                    <i id="unmute-audio" className="fa fa-microphone-slash" style={{ display: "none" }}></i>
                                </a>
                            </Media>


                        </ul>
                    )}
                </div>
            </div>

        </div>
    );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(AgoraAudioCall));
