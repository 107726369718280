import React, { useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import VideoTrainingProceedModal from "./VideoTrainingProceedModal";
import { translate, t } from "react-multi-lang";

const VideoTrainingModal = (props) => {
  const [videoTrainingProceed, setVideoTrainingProceed] = useState(false);

  const closeVideoTrainingProceedModal = () => {
    setVideoTrainingProceed(false);
  };

  return (
    <>
      <Modal
        className="modal-dialog-center video-training-modal"
        size="md"
        centered
        show={props.videoTraining}
        onHide={props.closeVideoTrainingModal}
      >
        <Form>
          <Modal.Header>
            <Modal.Title>{t("video_training")}</Modal.Title>
            <button
              type="button"
              className="close"
              onClick={props.closeVideoTrainingModal}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("close")}</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <h4>{t("session")}</h4>
            <p>{t("demo_text")}</p>
            <h2>
              $ 99 <sub>/{t("month")}</sub>
            </h2>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              onClick={props.closeVideoTrainingModal}
            >
              {t("cancel")}
            </Button>
            <Button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
              onClick={() => setVideoTrainingProceed(true)}
            >
              {t("proceed")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {videoTrainingProceed && (
        <VideoTrainingProceedModal
          videoTrainingProceed={videoTrainingProceed}
          closeVideoTrainingProceedModal={closeVideoTrainingProceedModal}
        />
      )}
    </>
  );
};

export default translate(VideoTrainingModal);
