import React, { useState, useRef } from "react";
import { Modal, Container, Row, Col, Button, Media, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Draggable from "react-draggable";

const LiveStreamingOnetoOne = (props) => {

    const nodeRef = useRef(null);

    const [position2, setPosition2] = useState({ x: 50, y: 50 });
  
    const [Opacity2, setOpacity2] = useState(false);

    const trackPos2 = (data) => {
        setPosition2({ x: data.x, y: data.y });
    };

    const handleStart2 = () => {
        setOpacity2(true);
      };
      const handleEnd2 = () => {
        setOpacity2(false);
      };
  

    return (
        <>
            <div className="live-streaming-one-to-one-sec">
                {/* <Container>
                    <Row>
                        <Col md={12}> */}
                            <div className="live-streaming-video-sec">
                                <div className="live-streaming-video-bg-sec">
                                    <Image
                                        className="live-streaming-video-bg"
                                        src={
                                            window.location.origin + "/assets/images/fitness/videocall_bg.jpg"
                                        }
                                    />
                                </div>
                                <div className="live-streaming-video-append-sec">
                                <Draggable
                                    nodeRef={nodeRef}
                                    onDrag={(e, data) => trackPos2(data)}
                                    onStart={handleStart2}
                                    onStop={handleEnd2}
                                    scale={2}
                                >
                                    <Image
                                        className="live-streaming-video-append-img"
                                        ref={nodeRef}
                                        style={{ opacity: Opacity2 ? "0.6" : "1" }}
                                        src={
                                            window.location.origin + "/assets/images/fitness/videocall-1.jpg"
                                        }
                                    />
                                </Draggable>  
                                </div>
                                <div className="live-streaming-video-action-btn-sec">
                                    <ul className="list-unstyled live-streaming-video-action-btn">
                                        <Media as="li">
                                            <Link to="#">
                                                <i className="fa fa-pause"></i>
                                            </Link>
                                        </Media>
                                        <Media as="li" className="call-animation-li">
                                            <Link to="/coach-single-profile" className="call-animation">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                            </Link>
                                        </Media>
                                        <Media as="li">
                                            <Link to="#">
                                                <i className="fa fa-microphone"></i>
                                            </Link>
                                        </Media>
                                    </ul>
                                </div>
                            </div>
                        {/* </Col>
                    </Row>
                </Container> */}
            </div>
        </>
    );
};

export default LiveStreamingOnetoOne;
