import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./CreatePost.css";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  postFileUploadStart,
  savePostStart,
  postFileRemoveStart,
  fetchPostCategoriesStart,
} from "../../../store/actions/PostAction";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { getErrorNotificationMessage } from "../../helper/NotificationMessage";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import configuration from "react-global-configuration";

const CreatePostNewIndex = (props) => {
  const [inputData, setInputData] = useState({});
  const [postFileData, setPostFileData] = useState([]);
  const [previewImage, setPreviewImage] = useState(false);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(false);

  const [paidPost, setPaidPost] = useState(false);
  const [videoTitle, setVideoTitle] = useState("");

  const [fileUploadStatus, setFileUploadStatus] = useState(false);

  const [videoThumbnailStatus, setVideoThumbnailStatus] = useState(false);

  const mentionsRef = useRef();

  const [editorContentState, setEditorContentstate] = useState("");

  const [editorHtmlContent, setEditorHtmlContent] = useState("");

  const [videoThumbnail, setVideoThumbnail] = useState({
    videoPreviewImage: "",
  });

  const [videoPreview, setVideoPreview] = useState({ previewVideo: "" });

  const [selectedValue, setSelectedValue] = useState("1");

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    // props.dispatch(fetchPostCategoriesStart());
  }, []);

  useEffect(() => {
    if (
      props.fileUpload.loading === false &&
      props.fileUpload.data.post_file.length > 0
    ) {
      let files = [];
      props.fileUpload.data.post_file.map((value, i) => {
        files.push(value.post_file);
      });
      setPostFileData(files);
      setPaidPost(true);
      setFileUploadStatus(true);
      setVideoThumbnailStatus(true);
      setVideoPreviewUrl(true);
      setInputData({
        ...inputData,
        post_file_id: props.fileUpload.data.post_file_id,
      });
    }
    console.log("Entered");
    console.log("post_file_id", props.fileUpload.data.post_file_id);
  }, [props.fileUpload]);

  useEffect(() => {
    if (props.fileRemove.loading === false) {
      let files = [];
      if (props.fileRemove.data.post_file.length > 0) {
        props.fileRemove.data.post_file.map((value, i) => {
          files.push(value);
        });
      } else {
        setFileUploadStatus(false);
        setPaidPost(false);
        setPreviewImage(false);
        setVideoPreviewUrl(false);
        setVideoThumbnailStatus(false);
        setVideoTitle("");
      }
      setInputData({
        ...inputData,
        post_file_id: props.fileRemove.data.post_file_id,
      });
      setPostFileData(files);
    }
  }, [!props.fileRemove.loading]);

  const handleChangeImage = (event, fileType) => {
    let data_array = [];

    [...event.target.files].forEach((file, key) => {
      let name = "file[" + key + "]";

      data_array[name] = file;
    });
    data_array["file_type"] = fileType;

    setPreviewImage(true);
    setFileUploadStatus(true);
    setPaidPost(true);
    props.dispatch(postFileUploadStart(data_array));
  };

  // const handleChangeVideo = (event, fileType) => {

  // };

  const handleClose = (event, post_file) => {
    event.preventDefault();
    if (props.fileUpload.loadingButtonContent !== null) {
      const notificationMessage = getErrorNotificationMessage(
        "File is being uploaded.. Please wait"
      );
      props.dispatch(createNotification(notificationMessage));
    } else {
      setPostFileData([]);
      props.dispatch(
        postFileRemoveStart({
          file: post_file,
          // file_type: props.fileUpload.data.post_file.file_type,
          // blur_file: props.fileUpload.data.post_file.blur_file,
          post_file_id: inputData.post_file_id,
        })
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (fileUploadStatus) {
      props.dispatch(
        savePostStart({
          title: inputData.title,
          content: inputData.content,
          length: inputData.length,
          is_home_post: selectedValue,
          amount: inputData.amount ? inputData.amount : "",
          post_file_id: inputData.post_file_id ? inputData.post_file_id : "",
          preview_file: inputData.preview_file ? inputData.preview_file : "",
          post_category_ids: inputData.post_category_ids
            ? inputData.post_category_ids
            : [],
          video_preview_file: inputData.video_preview_file
            ? inputData.video_preview_file
            : "",
        })
      );
    } else {
      props.dispatch(
        savePostStart({
          title: inputData.title,
          content: inputData.content,
          length: inputData.length,
          amount: inputData.amount ? inputData.amount : "",
          post_category_ids: inputData.post_category_ids
            ? inputData.post_category_ids
            : [],
        })
      );
    }
  };

  // const setValues = (inputValue) => {
  //   let user_id_arr = [];
  //   inputValue.map((value, i) => {
  //     user_id_arr.push(value.post_category_id);
  //   });
  //   setInputData({
  //     ...inputData,
  //     post_category_ids: user_id_arr,
  //   });
  // };

  // const handleVideopreviewImage = (event) => {

  // };

  const handleVideoPreview = (event) => {
    if (event.currentTarget.type === "file") {
      let reader = new FileReader();
      let file = event.currentTarget.files[0];
      reader.onloadend = () => {
        setVideoPreview({ ...videoPreview, previewVideo: reader.result });
      };

      if (file) {
        reader.readAsDataURL(file);
      }

      setInputData({
        ...inputData,
        video_preview_file: file,
      });
    }
  };

  const onVideoDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    let data_array = [];
    let name = "file[0]";
    data_array[name] = acceptedFiles[0];
    data_array["file_type"] = "video";
    setVideoTitle(acceptedFiles[0].name);
    props.dispatch(postFileUploadStart(data_array));
  }, []);

  useEffect(() => {
    console.log("Changed");
    console.log("InputData", inputData);
  }, [inputData]);

  const onVideoPreviewImageDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);
      let reader = new FileReader();
      let file = acceptedFiles[0];
      reader.onloadend = () => {
        setVideoThumbnail({
          ...videoThumbnail,
          videoPreviewImage: reader.result,
        });
      };
      if (file) {
        reader.readAsDataURL(file);
      }
      setInputData({
        ...inputData,
        preview_file: file,
      });
      setFileUploadStatus(true);
    },
    [inputData]
  );


  return (
    <>
      <div className="create-post-new-sec">
        <Container>
          <h3 className="head-title">POST WORKOUT</h3>
          {localStorage.getItem("is_content_creator") == 2 ? (
            <div className="create-post-new-box">
              <Form className="create-post-new-form" onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <div className="create-post-new-left-sec">
                      <InputGroup className="mb-5">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Workout Name:
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="(Maximum 4 Words)"
                          value={inputData.title}
                          className="placeholder-text"
                          onChange={(e) =>
                            setInputData({
                              ...inputData,
                              title: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                      <InputGroup className="mb-5 display-home">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon">
                            {t("add_post_to_public")}:
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Group className="form-posts">
                          <label className="radio-label">
                            <input
                            className="form-check-inputs"
                              type="radio"
                              value="1" 
                              checked={selectedValue === '1'}
                              onChange={handleRadioChange}
                            /> 
                            {t("yes")}
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="0"
                              className="form-check-inputs"
                              checked={selectedValue === '0'}
                              onChange={handleRadioChange}
                            />
                            {t("no")}
                          </label>
                        </Form.Group>
                        <p className="create-home-post">{t("when_you_add_your_workout_post_para")}</p>
                      </InputGroup>
                      <InputGroup className="mb-5 textarea-input">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Workout Description:
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="(Workout Description, Including What Equipment is Needed and Exercise Level)"
                          as="textarea"
                          aria-label="With textarea"
                          value={inputData.content}
                          onChange={(e) =>
                            setInputData({
                              ...inputData,
                              content: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                      <InputGroup className="mb-5">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Workout Length:
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          type="number"
                          step="any"
                          min="1"
                          placeholder="(In Minutes example '60')"
                          className="placeholder-text"
                          value={inputData.length}
                          onChange={(e) =>
                            setInputData({
                              ...inputData,
                              length: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                      <InputGroup className="mb-0">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Token (1 token =  {configuration.get("configData.currency")}{configuration.get("configData.token_amount")}):
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="number"
                          placeholder="1"
                          className="placeholder-text"
                          name="amount"
                          pattern="[0-9]*"
                          min="1"
                          inputmode="numeric"
                          value={inputData.amount}
                          onChange={(event) =>
                            setInputData({
                              ...inputData,
                              amount: event.currentTarget.value,
                            })
                          }
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="create-post-new-right-sec">
                      {videoPreviewUrl &&
                        postFileData &&
                        props.fileUpload.buttonDisable ? (
                        <Row>
                          {postFileData.map((video, index) => (
                            <>
                              <h4 className="mt-4">
                                Note: Vimeo video upload in progress. It will
                                take few minutes to load.
                              </h4>
                              <Col sm={12} md={12}>
                                <div
                                  key={index}
                                  className="post-img-preview-sec my-3 my-lg-4"
                                >
                                  <iframe
                                    src={video}
                                    width="400"
                                    height="200"
                                    frameborder="0"
                                    allow="autoplay; fullscreen"
                                    allowfullscreen
                                    controlsList="nodownload"
                                  ></iframe>

                                  <Link
                                    to="#"
                                    onClick={(event) =>
                                      handleClose(event, video)
                                    }
                                    className="close-video"
                                  >
                                    <i className="far fa-window-close"></i>
                                  </Link>
                                </div>
                              </Col>
                            </>
                          ))}
                        </Row>
                      ) : (
                        <Dropzone
                          onDrop={onVideoDrop}
                          accept={{ "video/*": [".mp4", ".webm"] }}
                          multiple={false}
                          disabled={props.fileUpload.buttonDisable}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="create-post-new-uploader-sec">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="create-post-new-uploader-card">
                                  <Image
                                    className="create-post-new-uploader-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/fitness/icons/upload-video-icon.svg"
                                    }
                                  />
                                  {props.fileUpload.loadingButtonContent ? (
                                    <h4>
                                      {props.fileUpload.loadingButtonContent}
                                    </h4>
                                  ) : (
                                    <>
                                      <h4>Maximum Video size 1 GIG</h4>
                                      <p>
                                        Please Compress If its Larger than 1 GIG
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      )}

                      {videoThumbnailStatus === true ? (
                        <Dropzone
                          onDrop={onVideoPreviewImageDrop}
                          accept={{
                            "image/*": [
                              ".gif",
                              ".jpg",
                              ".jpeg",
                              ".gif",
                              ".png",
                              ".jpg",
                              ".jpeg",
                              ".png",
                            ],
                          }}
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="create-post-new-uploader-thumbnail-sec">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="create-post-new-uploader-thumbnail-card">
                                  <Image
                                    className="create-post-new-uploader-thumbnail-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/fitness/icons/upload-video-thumbnails.svg"
                                    }
                                  />
                                  <p>Upload Thumbnail Image</p>
                                </div>
                                <div className="image-format-sec">
                                  <h4>
                                    Supported Format -{" "}
                                    <span>jpg, jpeg, png, gif - 1500px * 1000px</span>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      ) : null}
                      {videoThumbnail.videoPreviewImage !== "" ? (
                        <Row>
                          <Col sm={12} md={6} className="mb-3 mb-lg-4">
                            <div className="post-img-preview-sec m-0">
                              <Image
                                alt="#"
                                src={videoThumbnail.videoPreviewImage}
                                className="post-video-preview"
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}

                      <div className="create-post-new-btn-sec">
                        <Button
                          type="submit"
                          className="btn gradient-btn postBtn gradientcolor text-uppercase mt-0 mt-md-3"
                          disabled={
                            props.fileUpload.buttonDisable ||
                            props.savePost.buttonDisable ||
                            !inputData.post_file_id
                          }
                        >
                          {props.fileUpload.loadingButtonContent !== null
                            ? props.fileUpload.loadingButtonContent
                            : props.savePost.loadingButtonContent !== null
                              ? props.savePost.loadingButtonContent
                              : t("post_workout")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          ) : (
            ""
          )}{" "}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  savePost: state.post.savePost,
  fileUpload: state.post.fileUpload,
  fileRemove: state.post.fileRemove,
  searchUser: state.home.searchUser,
  postCategories: state.post.postCategories,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(CreatePostNewIndex));
