import React, { useState, useEffect } from "react";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import PaymentModal from "../LiveVideos/PaymentModal";
import { Link } from "react-router-dom";
import { Image, Media } from "react-bootstrap";
import { useHistory } from "react-router";
import { deletePostStart } from "../../store/actions/PostAction";
import AddWalletTokenModal from "../helper/AddWalletTokenModal";
import PPVPaymentModal from "../helper/PPVPaymentModal";

const ModelVideoCard = (props) => {
    const { post } = props;

    const history = useHistory();
    const [addWalletAmountModal, setAddWalletAmountModal] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const [PPVPayment, setPPVPayment] = useState(false);

    const [showContents, setShowContents] = React.useState(false);

    const showPaymentModal = (post) => {
        if (post.amount > props.userDetails.data.user.wallet_balance) {
            setAddWalletAmountModal(true);
        } else {
            setSelectedPost(post);
        }
    };

    const handleDeletePost = (event, post) => {
        event.preventDefault();
        if (window.confirm(t("delete_post_confirmation")))
            props.dispatch(deletePostStart({ post_id: post.post_id }));
    };
    const redirectToLogin = () => {
        if (!localStorage.getItem("userId")) {
            history.push(`/login-redirect?is_client=${localStorage.getItem("is_client")}`);
        }
    };

    const closeAddWalletAmountModal = () => {
        setAddWalletAmountModal(false);
    };

    const closePPVPaymentModal = () => {
        setSelectedPost(null);
        setPPVPayment(false);
    };

    useEffect(() => {
        if (selectedPost) {
            setPPVPayment(true);
        }
    }, [selectedPost]);

    return (
        <>
            <div className="coach-single-profile-subscribers-sessions">
                <div className="coach-single-profile-program-card">
                    {post.is_paid_post === 1 &&
                        post.payment_info.is_user_needs_pay === 1 ? (
                        <Link
                            to="#"
                            onClick={() => {
                                showPaymentModal(post);
                                redirectToLogin();
                            }}
                        >
                            <div className="new-coach-single-header">
                                <h3>{post.title}</h3>
                            </div>
                            <div className="coach-single-profile-program-video-sec">
                                <Image
                                    className="coach-single-profile-program-video"
                                    // src={p_file.preview_file}
                                    src={
                                        props.p_file.preview_file
                                            ? props.p_file.preview_file
                                            : window.location.origin +
                                            "/assets/images/fitness/video-1.jpg"
                                    }
                                />
                            </div>
                            <div className="coach-single-profile-on-demand">
                                {t("buy_now")}
                            </div>
                            {/* <div className="coach-single-profile-fav-icon">
                                                <i className="far fa-bookmark"></i>
                                            </div> */}
                            <div className="coach-single-profile-program-info">
                                <div className="coach-single-profile-program-text">
                                    {/* <div dangerouslySetInnerHTML={{ __html: post.content }} /> */}
                                    <div className="new-video-time">
                                        <Image
                                            src={
                                                window.location.origin +
                                                "/assets/images/icons/new/video-camera.svg"
                                            }
                                            className="new-video-icon"
                                        />
                                        <span>{post.length_formatted}</span>{" "}
                                    </div>
                                    {/* <h4>1500px * 1000px</h4> */}
                                    {/* <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> */}
                                </div>
                                <div className="coach-single-profile-program-time-count">
                                    <p>
                                        <Image
                                            className="token-icon"
                                            src={
                                                window.location.origin +
                                                "/assets/images/fitness/token/token4.svg"
                                            }
                                        />
                                        {post.amount_formatted}{" "}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    ) : (
                        <Link to={`/post/${post.post_unique_id}`}>
                            <div className="new-coach-single-header">
                                <h3>{post.title}</h3>
                            </div>
                            <div className="coach-single-profile-program-video-sec">
                                <Image
                                    className="coach-single-profile-program-video"
                                    src={
                                        props.p_file.preview_file
                                            ? props.p_file.preview_file
                                            : window.location.origin +
                                            "/assets/images/fitness/video-4.jpg"
                                    }
                                />
                            </div>

                            {/* <div className="coach-single-profile-fav-icon">
                                                <i className="far fa-bookmark"></i>
                                            </div> */}
                            <div className="coach-single-profile-program-info">
                                <div className="coach-single-profile-program-text">
                                    <div className="new-video-time">
                                        <Image
                                            src={
                                                window.location.origin +
                                                "/assets/images/icons/new/video-camera.svg"
                                            }
                                            className="new-video-icon"
                                        />
                                        <span>{post.length_formatted}</span>{" "}
                                    </div>

                                    {/* <div dangerouslySetInnerHTML={{ __html: post.content }} /> */}
                                    {/* <h4>1500px * 1000px</h4> */}
                                    {/* <h6><span>Chris Pearson</span> | <span>Core Crusher with Chris</span></h6> */}
                                </div>
                            </div>
                        </Link>
                    )}
                    {post.content_formatted ? (
                        <div className="coach-single-profile-program-toggle">
                            <Link to="#" onClick={() => setShowContents(!showContents)}>
                                {/* <i className="fas fa-chevron-down"></i> */}
                                <Image
                                    src={
                                        window.location.origin +
                                        "/assets/images/icons/new/arrow-down.svg"
                                    }
                                    className="card-down-arrow"
                                />
                            </Link>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                {showContents && (
                    <div className="coach-single-profile-program-desc">
                        <div className="coach-html-desc">
                            <p>{post.content_formatted}</p>
                        </div>
                    </div>
                )}
                {props.enableEditOption && props.enableEditOption == 1 ? (
                    <>
                        <div className="model-video-action-btn-sec">
                            <button
                                className="save-btn btn btn-primary mt-2"
                                onClick={(event) => handleDeletePost(event, post)}
                            >
                                {/* {t("delete")} */}
                                <Image
                                    className="delete-icon"
                                    src={
                                        window.location.origin +
                                        "/assets/images/fitness/menu/delete-icon.svg"
                                    }
                                />
                            </button>
                            <Link
                                to={`/edit-post/${post.post_unique_id}`}
                                className="save-btn btn btn-primary mt-2"
                            >
                                {/* {t("edit")} */}
                                <Image
                                    className="delete-icon"
                                    src={
                                        window.location.origin +
                                        "/assets/images/fitness/menu/edit-icon.svg"
                                    }
                                />
                            </Link>
                        </div>
                    </>
                ) : (
                    ""
                )}
                {localStorage.getItem("userId") ? (
                    <>
                        {!props.userDetails.loading && (
                            <AddWalletTokenModal
                                addWalletAmountModal={addWalletAmountModal}
                                closeAddWalletAmountModal={closeAddWalletAmountModal}
                                trainerId={props.userDetails.data.user.user_id}
                                wallet={props.wallet}
                                payments={props.wallet}
                            />
                        )}
                        {PPVPayment && (
                            <PPVPaymentModal
                                PPVPayment={PPVPayment}
                                closePPVPaymentModal={closePPVPaymentModal}
                                post={selectedPost}
                                username={selectedPost.username}
                                userPicture={selectedPost.user_picture}
                                name={selectedPost.user_displayname}
                                post_id={selectedPost.post_id}
                                user_id={selectedPost.user_id}
                                amount={selectedPost.amount}
                            />
                        )}
                    </>
                ) : null}
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(ModelVideoCard));
