import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, Button, Form, Image, InputGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Auth.css";
import {
	isAndroid,
	isIOS,
	isWindows,
	isMacOs,
	mobileModel,
	browserName,
	osName,
	mobileVendor,
	browserVersion
} from "react-device-detect";
import configuration from "react-global-configuration";
import { connect } from "react-redux";
import {
	userRegisterStart,
	usernameValidationStart,
	referralValidationStart,
} from "../../store/actions/UserAction";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { translate, t } from "react-multi-lang";
import SocialButton from "../helper/SocialButton";
import { gapi } from "gapi-script";

const TrainerRegisterIndex = (props) => {

	const [signupInputData, setSignupInputData] = useState({
		referral_code: "",
		device_type: "",
		device_model: "",
		browser_type: "",
		agree: false,
		registered_as:2,
	});

	useEffect(() => {
		const referral = '';
		if (configuration.get("configData.is_referral_enabled") == 1) {
			const query = new URLSearchParams(props.location.search);
			const referral = query.get("referral");
		}

		var device_type = "";
		var device_model = "";
		var browser_type = browserName;

		if (isAndroid == true) {
			device_type = "android";
			device_model = mobileModel;
		} else if (isIOS == true) {
			device_type = "ios";
			device_model = mobileModel;
		} else {
			device_type = "web";
			device_model = browserName + ' ' + browserVersion;
		}

		setSignupInputData({
			...signupInputData,
			referral_code: referral ? referral : '',
			device_type: device_type,
			device_model: device_model,
			browser_type: browser_type,
			registered_as:2,
		});

	}, []);

	const handleSignup = (event) => {
		event.preventDefault();
		props.dispatch(userRegisterStart(signupInputData));
	};

	const handleUsernameValidation = (event, username) => {
		setSignupInputData({
			...signupInputData,
			username: username,
		});
		props.dispatch(usernameValidationStart({ username: username }));
	};

	const checkReferralCode = (event) => {
		event.preventDefault();

		if (signupInputData.referral_code) {
			props.dispatch(referralValidationStart({ referral_code: signupInputData.referral_code }));
		} else {
			const notificationMessage = getErrorNotificationMessage('Please enter the Referral code');
			props.dispatch(createNotification(notificationMessage));
		}
	};

	// useEffect(() => {
	// 	gapi.load("client:auth2", () => {
	// 	  gapi.client.init({
	// 		clientId: configuration.get("configData.GOOGLE_CLIENT_ID"),
	// 		plugin_name: "chat",
	// 	  });
	// 	});
	// }, []);

	// const handleFacebookLogin = (user) => {
	// 	console.log("handleFacebookLogin", user._profile);
	// 	props.dispatch(
	// 		userRegisterStart({
	// 			name: user._profile.name,
	// 			first_name: user._profile.firstName ? user._profile.firstName : "",
	// 			last_name: user._profile.lastName ? user._profile.lastName : "",
	// 			email: user._profile.email ? user._profile.email : "",
	// 			social_unique_id: user._profile.id,
	// 			picture: user._profile.profilePicURL,
	// 			login_by: "facebook",
	// 			registered_as:2,
	// 		})
	// 	);
	// };

	// const handleGoogleLogin = (user) => {
	// 	console.log("handleGoogleLogin", user._profile);
	// 	props.dispatch(
	// 		userRegisterStart({
	// 			name: user._profile.name,
	// 			email: user._profile.email,
	// 			first_name: user._profile.firstName ? user._profile.firstName : "",
	// 			last_name: user._profile.lastName ? user._profile.lastName : "",
	// 			social_unique_id: user._profile.id,
	// 			picture: user._profile.profilePicURL,
	// 			login_by: "google",
	// 			registered_as:2,
	// 		})
	// 	);
	// };

	// const handleSocialLoginFailure = (err) => {
	// 	console.error(err);
	// };

	return (
		<>
			<div className="login-43">
				<Container>
					<Row>
						<Col lg={5} md={12} className="bg-img none-992">
						</Col>
						<Col lg={7} md={12} className="bg-color-8 align-self-center">
						<div className="info">
								<div className="logo clearfix">
									<Link to="#" className="coach-logo">
										<Image
											src={
												window.location.origin + "/assets/images/fitness/coach-logo-white.png"
											}
										/>
									</Link>
								</div>
								{/* <div className="social-list">
									{configuration.get("configData.FB_CLIENT_ID") &&
										<SocialButton
											provider="facebook"
											appId={configuration.get("configData.FB_CLIENT_ID")}
											onLoginSuccess={handleFacebookLogin}
											onLoginFailure={handleSocialLoginFailure}
											className="social-button"
											id="facebook-connect"
										>
											<Link to="#"><i className="fab fa-facebook-f"></i></Link>
										</SocialButton>
									}
									{configuration.get("configData.GOOGLE_CLIENT_ID") &&
										<SocialButton
											provider="google"
											key={"google"}
											appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
											onLoginSuccess={handleGoogleLogin}
											onLoginFailure={handleSocialLoginFailure}
											className="social-button"
											id="google-connect"
										>
											<Link to="#"><i className="fab fa-google-plus-g"></i></Link>
										</SocialButton>
									}
								</div> */}
							</div>
							<div className="form-section">
								<h3 className="trainer-head-title">Register</h3>
								<div className="login-inner-form">
									<div className="trainer-form">
										<Form onSubmit={handleSignup}>
											<InputGroup className="mb-3">
												<FormControl
													placeholder="First Name"
													aria-label="First Name"
													aria-describedby="basic-addon2"
													value={signupInputData.name}
													onChange={e => setSignupInputData({
														...signupInputData,
														name: e.target.value,
													})}
												/>
												<InputGroup.Text id="basic-addon2"><i className="fas fa-user"></i></InputGroup.Text>
											</InputGroup>
											<InputGroup className="mb-3">
												<FormControl
													aria-label="Last Name"
													aria-describedby="basic-addon2"
													placeholder="Username"
													value={signupInputData.user_name}
													name="username"
													onChange={(event) =>
														event.currentTarget.value &&
															event.currentTarget.value.length > 3
															? handleUsernameValidation(
																event,
																event.currentTarget.value
															)
															: ""
													}
													isValid={props.validation.isValid}
													isInvalid={props.validation.isInValid}
												/>
												<InputGroup.Text id="basic-addon2"><i className="fas fa-user"></i></InputGroup.Text>
											</InputGroup>
											{props.validation.isInValid ? (
												<Form.Control.Feedback type="invalid">
													{t("username_already_taken")}
												</Form.Control.Feedback>
											) : (
												""
											)}
											{props.validation.isValid ? (
												<Form.Control.Feedback>
													{t("looks_good")}
												</Form.Control.Feedback>
											) : (
												""
											)}
											<InputGroup className="mb-3">
												<FormControl
													placeholder="Email Id"
													aria-label="Email Id"
													aria-describedby="basic-addon2"
													value={signupInputData.email}
													name="email"
													onChange={(event) =>
														setSignupInputData({
															...signupInputData,
															email: event.currentTarget.value,
														})
													}
												/>
												<InputGroup.Text id="basic-addon2"><i className="fas fa-envelope"></i></InputGroup.Text>
											</InputGroup>
											<InputGroup className="mb-3">
												<FormControl
													type="password"
													placeholder="Password"
													aria-label="Password"
													aria-describedby="basic-addon2"
													value={signupInputData.password}
													name="password"
													onChange={(event) =>
														setSignupInputData({
															...signupInputData,
															password: event.currentTarget.value,
														})
													}
												/>
												<InputGroup.Text id="basic-addon2"><i className="fas fa-lock"></i></InputGroup.Text>
											</InputGroup>
											{configuration.get("configData.is_referral_enabled") == 1 && (
												<Form.Group>
													<Form.Control
														type="text"
														placeholder="Referral Code(Optional)"
														autoComplete="off"
														value={signupInputData.referral_code}
														name="referral_code"
														onChange={(event) =>
															setSignupInputData({
																...signupInputData,
																referral_code: event.currentTarget.value,
															})
														}
													/>

													<div className="check-referral-link">
														<a
															className="text-primary"
															href="#"
															onClick={checkReferralCode}
														>
															{t("check_referral_code_valid")}
														</a>
													</div>
												</Form.Group>
											)}
											<Form.Group>
												<div className="checkbox form-box">
													<div className="form-check checkbox-theme">
														<input
															className="form-check-input"
															type="checkbox"
															value=""
															id="rememberMe"
															checked={signupInputData.agree}
															onChange={e => setSignupInputData({
																...signupInputData,
																agree: !signupInputData.agree,
															})} />
														<label className="form-check-label" for="rememberMe">
															{t("signing_up_confirmation")}{" "}
															<Link to={`/page/trainer-terms`} target="_blank">{t("terms_of_service")}</Link> and
															<Link to={`/page/privacy`} target="_blank">{t("privacy_policy")}</Link>.
														</label>
													</div>
												</div>
											</Form.Group>
											<Form.Group>
												<Button
													type="submit"
													className="btn-md btn-theme w-100"
													disabled={props.signup.buttonDisable || !signupInputData.agree}
												>{props.signup.loadingButtonContent !== null
													? props.signup.loadingButtonContent
													: "SIGN UP"}</Button>
											</Form.Group>
											<p className="text">Already a member?<Link to="/trainer-login" className="ml-2"> Login here</Link></p>
										</Form>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

const mapStateToPros = (state) => ({
	signup: state.users.registerInputData,
	validation: state.users.validationInputData,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(
	mapStateToPros,
	mapDispatchToProps
)(translate(TrainerRegisterIndex));
