import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Image } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { fetchLiveVideosStart } from "../../store/actions/LiveVideoAction";
import { Link } from "react-router-dom";
import NoDataFound from "../NoDataFound/NoDataFound";
import BillingAccountLoader from "../Loader/BillingAccountLoader";
import { translate, t } from "react-multi-lang";
import GoLiveModal from "../helper/GoLiveModal";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import LiveDataCard from "./LiveDataCard";
import UserLiveVideoLoader from "../Loader/UserLiveVideoLoader";

const UserLiveVideosIndex = (props) => {
  const [goLive, setGoLive] = useState(false);

  useEffect(() => {
    if (props.userDetails.loading) props.dispatch(fetchUserDetailsStart());
    props.dispatch(fetchLiveVideosStart());
  }, []);

  const closeGoLiveModal = () => {
    setGoLive(false);
  };

  return (
    <>
      <div className="live-video-history-sec">
        <div className="live-video-history-header-bg-img-sec">
            <Image
                className="live-video-history-header-bg-img"
                src={
                    window.location.origin + "/assets/images/live-history/fitness-bg.jpg"
                }
            />
            <div className="live-video-history-on-live-btn-sec">
                {/* <Button className="on-live-btn" onClick={() => props.history.goBack()}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"/></svg> ON LIVE
                </Button> */}
                <Image
                    className="onlive-svg"
                    src={
                        window.location.origin + "/assets/images/fitness/on-live.svg"
                    }
                    onClick={() => props.history.goBack()}
                />
            </div>
        </div>
      </div>
      <div className="trans-table-sec">
        <Container>
          {props.liveVideos.loading ? (
            <UserLiveVideoLoader />
          ) : props.liveVideos.data.live_videos.filter(liveVideo => liveVideo.user_id != localStorage.getItem("userId")).length > 0 ? (
            <div className="video-list-sec">
              {props.liveVideos.data.live_videos.filter(liveVideo => localStorage.getItem("userId") != String(liveVideo.user_id)).map((video) => (
                <LiveDataCard video={video} key={video.live_video_id} />
              ))}
            </div>
          ) : (
            <NoDataFound />
          )}
        </Container>
       
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideos: state.liveVideo.liveVideos,
  userDetails: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(UserLiveVideosIndex));
