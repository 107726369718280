import React, { useState, useEffect } from "react";
import { Media, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Background from './user-list-bg.png';
import { translate, t } from "react-multi-lang";
import { useHistory } from "react-router";
import CommonCenterLoader from "../Loader/CommonCenterLoader";
import {
  searchUserStart,
} from "../../store/actions/HomeAction";
import { connect } from "react-redux";
import VerifiedBadgeNoShadow from "../Handlers/VerifiedBadgeNoShadow";
import HomeLoader from "../Loader/HomeLoader";
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  fetchMoreFollowingStart,
} from "../../store/actions/FollowAction";
import NoDataFound from "../NoDataFound/NoDataFound";
import HomeProfileCard from "./HomeProfileCard";
import { isMobile } from "react-device-detect";

const HomeProfileListIndex = (props) => {

  const history = useHistory();

  const [take, settake] = useState(12);

  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0 && props.profile.data.is_content_creator === 2) {
      history.push('/dashboard');
    }
  }, [props.profile]);

  const [show, toggleShow] = useState(false);

  const fetchMoreFollowings = () => {
    props.dispatch(fetchMoreFollowingStart({
      skip: props.following.data.followers.length,
      take: 12,
    }));
  }

  const handleSearch = (event) => {
    if (event.currentTarget.value === "") {
      toggleShow(false);
    } else {
      toggleShow(true);
      props.dispatch(searchUserStart({ key: event.currentTarget.value }));
    }
  };

  return (
    <>
      <div className="home-user-list-sec">
        <Container>
          <div className="search-row">
            <Link to="#" className="search-button">
              {t("home")}
            </Link>
            <div className="search-container">
              <Form className="search-box">
                <input
                  className="search-text"
                  type="text"
                  autoFocus={true}
                  placeholder={t("search_trainer")}
                  onChange={handleSearch}
                />
                <Link to="#" className="search-btn">
                  <i className="fas fa-search"></i>
                </Link>
              </Form>
            </div>
            {show && (
              <div className="search-dropdown-sec">
                <ul className="list-unstyled search-dropdown-list-sec">
                  {props.searchUser.loading
                    ? <CommonCenterLoader />
                    : props.searchUser.data.users.length > 0
                      ? props.searchUser.data.users.map((user) => (
                        <Media as="li" key={user.user_unique_id}>
                          <Link to={isMobile ? `/w/${user.user_unique_id}` : `/` + user.user_unique_id}>
                            <div className="search-body">
                              <div className="user-img-sec">
                                <Image
                                  alt="#"
                                  src={user.picture}
                                  className="user-img"
                                />
                              </div>
                              <div className="search-content">
                                <h5>
                                  {user.name}{" "}
                                  {user.is_verified_badge == 1 ? (
                                    <div className="pl-2">
                                      <VerifiedBadgeNoShadow />
                                    </div>
                                  ) : null}
                                </h5>
                                <p className="text-muted f-12">
                                  @{user.username}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </Media>
                      ))
                      : t("no_trainer_found")}
                </ul>
              </div>
            )}
          </div>
          <div className="home-user-list-box">
            {props.following.loading ? (
              <div className="home-profile-grid">
                <HomeLoader />
              </div>
            ) : props.following.data.followers.length > 0 ? (
              <InfiniteScroll
                dataLength={props.following.data.followers.length}
                next={fetchMoreFollowings}
                hasMore={props.following.data.followers.length < props.following.data.total &&
                  props.following.errorCount < 2}
                loader={<div className="home-profile-grid mt-5">
                  <HomeLoader />
                </div>}
              >
                <Row>
                  {props.following.data.followers.map((follower, index) => (
                    <HomeProfileCard user={follower} key={index} index={index} />
                  ))}
                </Row>
              </InfiniteScroll>
            ) : (
              <NoDataFound />
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  following: state.follow.following,
  searchUser: state.home.searchUser,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(HomeProfileListIndex));
