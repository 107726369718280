import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Tab,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import {
  forgotPasswordStart,
} from "../../store/actions/UserAction";

const ForgotPasswordModal = (props) => {

  const [forgotPasswordInputData, setForgotPasswordInputData] = useState({});

  const handleForgotPassword = (event) => {
    event.preventDefault();
    props.dispatch(forgotPasswordStart(forgotPasswordInputData));
  };

  return (
    <>
      <Modal
        centered
        size="md"
        className="modal-dialog-center sent-tip-modal"
        show={props.forgotPasswordModal}
        onHide={props.closeForgotPasswordModal}
      >
        {props.forgotPasswordModal === true ?
          <>
            <Modal.Header closeButton>
              <Modal.Title>{t("forgot_password")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="signin-modal form-section">
                <Link to="#" className="sign-in-logo">
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/fitness/coach-logo.png"
                    }
                    width="237"
                    className="modal-logo"
                  />
                </Link>
                <Form onSubmit={handleForgotPassword} className="modal-form" method="post">
                  <Form.Group controlId="formBasicEmail" className="mb-4">
                    <Form.Control
                      type="text"
                      controlId="loginemail"
                      className="form-control"
                      placeholder="E-mail"
                      value={forgotPasswordInputData.email}
                      required
                      name="email"
                      onChange={(event) =>
                        setForgotPasswordInputData({
                          ...forgotPasswordInputData,
                          email: event.currentTarget.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Button
                      id="forgotpassword"
                      type="submit"
                      onClick={handleForgotPassword}
                      disabled={props.forgotPassword.buttonDisable}
                      className="btn btn-auth btn-lg"
                    >
                      {props.forgotPassword.loadingButtonContent !==
                        null
                        ? props.forgotPassword.loadingButtonContent
                        : t("forgot_password")}
                    </Button>
                  </Form.Group>

                </Form>
              </div>
            </Modal.Body>
          </>
          : null}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ForgotPasswordModal));
