import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Row, Col } from 'react-bootstrap';

const MarcIndexLoader = () => {
  return (
    <>
      <div className="custome-card-sec">
        <Row>
          {[...Array(6)].map(() =>
            <Col xl={4} md={12}>
              <div className="custome-main-card">
                <div className="custome-card-profile" >
                  <div className="custome-card-profile-details">
                    <div className="custome-card-details-img">
                      <Skeleton count={1} width={350} height={285}
                      />
                    </div>
                    <div className="custome-card-details-info">
                      <Skeleton className="mb-3" count={2} width={80} height={18} />
                    </div>
                  </div>
                  <div className="custome-card-profile-time">
                    <span><Skeleton count={1} height={20} width={80} /></span>
                  </div>
                </div>
                <div className="card-img-main">
                  <Skeleton style={{ borderRadius: "20px" }} count={1} width={350} height={220} />
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </>
  )
}

export default MarcIndexLoader