import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Table, Badge } from "react-bootstrap";
import "../../Wallet/Wallet.css";
import NoDataFound from "../../NoDataFound/NoDataFound";
import BillingAccountLoader from "../../Loader/BillingAccountLoader";
import { translate, t } from "react-multi-lang";
import {
  acceptAudioCallStart,
  audioCallHistoryUserStart,
  rejectAudioCallStart,
} from "../../../store/actions/PrivateCallAction";
import { Link } from "react-router-dom";
import AudioCallMakePaymentModel from "../../helper/AudioCallMakePaymentModel";
import AudioCallHistoryTable from "./AudioCallHistoryTable";

const AudioCallHistoryIndex = (props) => {
  useEffect(() => {
    props.dispatch(audioCallHistoryUserStart());
  }, []);
  // const [makePaymentInput, setMakePaymentInput] = useState({
  //   audio_call_request_id: "",
  //   model_displayname: "",
  //   model_picture: "",
  //   amount: "",
  //   amount_formatted: "",
  //   model_unique_id: "",
  //   modelname: "",
  // });

  // const [
  //   selectedCallDetailsToMakePayment,
  //   setSelectedCallDetailsToMakePayment,
  // ] = useState(null);

  // const [makePaymentModel, setMakePaymentModel] = useState(false);

  // const closePaymentModal = () => {
  //   setMakePaymentModel(false);
  // };

  // const makePayment = (event, callDetails) => {
  //   event.preventDefault();
  //   setMakePaymentInput({
  //     audio_call_request_id: callDetails.audio_call_request_id,
  //     model_displayname: callDetails.model_displayname,
  //     model_picture: callDetails.model_picture,
  //     amount: callDetails.amount,
  //     amount_formatted: callDetails.amount_formatted,
  //     model_unique_id: callDetails.model_unique_id,
  //     modelname: callDetails.modelname,
  //   });
  //   setSelectedCallDetailsToMakePayment(callDetails);
  //   setMakePaymentModel(true);
  // };

  return (
    <>
      <div className="wallet-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div className="wallet-header-sec">
                <Row>
                  <Col sm={12} md={12} xl={9}>
                    <Link
                      className="bookmarkes-list notify-title back-button"
                      onClick={() => props.history.goBack()}
                    >
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/icons/back.svg"
                        }
                        className="svg-clone"
                      />
                      <h3 className="ml-2 mb-0">{t("audio_call_history")}</h3>
                    </Link>
                    <p className="text-muted f-2">
                      {t("audio_call_history_note")}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="trans-table-sec">
        <Container>
          {props.callHistoryUser.loading ? (
            <BillingAccountLoader />
          ) : props.callHistoryUser.data.audio_call_requests &&
            props.callHistoryUser.data.audio_call_requests.length > 0 ? (
            <Row>
              <Col sm={12} md={12}>
                <AudioCallHistoryTable
                  audioCallRequest={props.callHistoryUser.data.audio_call_request}
                />
              </Col>
              {/* <AudioCallMakePaymentModel
                audio_call_request_id={makePaymentInput.audio_call_request_id}
                audioCallPayment={makePaymentModel}
                closePaymentModal={closePaymentModal}
                userPicture={makePaymentInput.model_picture}
                name={makePaymentInput.model_displayname}
                user_unique_id={makePaymentInput.model_unique_id}
                callDetails={makePaymentInput}
                username={makePaymentInput.modelname}
              /> */}
            </Row>
          ) : (
            <NoDataFound />
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  callHistoryUser: state.privateCall.audioCallHistoryUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(AudioCallHistoryIndex));
