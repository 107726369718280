import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  videoCallBroadcastFailure,
  videoCallBroadcastSuccess,
  fetchLiveVideosSuccess,
  fetchLiveVideosFailure,
  fetchLiveVideosHistorySuccess,
  fetchLiveVideosHistoryFailure,
  fetchSingleLiveVideoSuccess,
  fetchSingleLiveVideoFailure,
  joinLiveVideosSuccess,
  joinLiveVideosFailure,
  livePaymentPaypalSuccess,
  livePaymentPaypalFailure,
  livePaymentStripeSuccess,
  livePaymentStripeFailure,
  liveViewerUpdateSuccess,
  liveViewerUpdateFailure,
  liveVideoEndSuccess,
  liveVideoEndFailure,
  livePaymentWalletSuccess,
  livePaymentWalletFailure,
  fetchScheduledLiveVideosFailure,
  fetchScheduledLiveVideosSuccess,
  liveVideoStartCallSuccess,
  liveVideoStartCallFailure,
  fetchUserScheduledLiveVideosFailure,
  fetchUserScheduledLiveVideosSuccess,
  deleteLiveVideosSuccess,
  deleteLiveVideosFailure,
  fetchLiveVideosHistoryStart,
  liveVideosSaveSuccess,
  liveVideosSaveFailure,
} from "../actions/LiveVideoAction";
import {
  VIDEO_CALL_BROADCAST_START,
  FETCH_LIVE_VIDEOS_START,
  FETCH_LIVE_VIDEOS_HISTORY_START,
  JOIN_LIVE_VIDEOS_START,
  FETCH_SINGLE_LIVE_VIDEOS_START,
  LIVE_VIDEOS_PAYMENT_BY_STRIPE_START,
  LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START,
  LIVE_VIDEOS_VIEWER_UPDATE_START,
  LIVE_VIDEOS_END_START,
  LIVE_VIDEOS_PAYMENT_BY_WALLET_START,
  FETCH_MORE_LIVE_VIDEOS_HISTORY_START,
  FETCH_SCHEDULED_LIVE_VIDEOS_START,
  LIVE_VIDEOS_START_CALL_START,
  FETCH_USER_SCHEDULED_LIVE_VIDEOS_START,
  DELETE_LIVE_VIDEOS_START,
  LIVE_VIDEOS_SAVE_START,
} from "../actions/ActionConstant";

import {
  checkLogoutStatus,
} from "../actions/ErrorAction";
import { isMobile } from "react-device-detect";

function* liveVideoSaveAPI(action) {
  try {
    const inputData = yield select((state) => state.liveVideo.saveLiveVideo.inputData);
    const response = yield api.postMethod("live_videos_broadcast_start", action.data);
    if (response.data.success) {
      yield put(videoCallBroadcastSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      if (action.data.streaming_type == 1) {
        window.location.assign(
          window.location.origin +
            "/join/" +
            response.data.data.live_video_unique_id
        );
      } else {
        window.location.assign(window.location.origin + isMobile ? "/scheduled-live-videos" : "/w/scheduled-live-videos");
      }

    } else {
      yield put(videoCallBroadcastFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(videoCallBroadcastFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveVideosAPI(action) {
  try {
    const skipCount = yield select((state) => state.liveVideo.liveVideos.skip);
    const response = yield api.postMethod("live_videos", action.data);
    if (response.data.success) {
      yield put(fetchLiveVideosSuccess(response.data.data));
    } else {
      yield put(fetchLiveVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchLiveVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* scheduledLiveVideosAPI(action) {
  try {
    const skipCount = yield select((state) => state.liveVideo.scheduledLiveVideos.skip);
    const response = yield api.postMethod("scheduled_live_videos", action.data);
    if (response.data.success) {
      yield put(fetchScheduledLiveVideosSuccess(response.data.data));
    } else {
      yield put(fetchScheduledLiveVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchScheduledLiveVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* userScheduledVideosAPI(action) {
  try {
    const skipCount = yield select((state) => state.liveVideo.scheduledLiveVideos.skip);
    const response = yield api.postMethod("user_scheduled_live_videos", action.data);
    if (response.data.success) {
      yield put(fetchUserScheduledLiveVideosSuccess(response.data.data));
    } else {
      yield put(fetchUserScheduledLiveVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchUserScheduledLiveVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveVideosHistoryAPI(action) {
  try {
    const response = yield api.postMethod("live_videos_owner_list", action.data);
    if (response.data.success) {
      yield put(fetchLiveVideosHistorySuccess(response.data.data));
    } else {
      yield put(fetchLiveVideosHistoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchLiveVideosHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleLiveVideoAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.singleLiveVideo.inputData
    );
    const response = yield api.postMethod("live_videos_view", inputData);
    if (response.data.success) {
      yield put(fetchSingleLiveVideoSuccess(response.data.data));
    } else {
      yield put(fetchSingleLiveVideoFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleLiveVideoFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveStripeAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveStripe.inputData
    );
    const response = yield api.postMethod(
      "live_videos_payment_by_card",
      inputData
    );
    if (response.data.success) {
      yield put(livePaymentStripeSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign(window.location.origin + "/live-video/" +
        response.data.data.live_video_unique_id);
    } else {
      yield put(livePaymentStripeFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(livePaymentStripeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* livePaypalAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.livePaypal.inputData
    );
    const response = yield api.postMethod(
      "live_videos_payment_by_paypal",
      inputData
    );
    if (response.data.success) {
      yield put(livePaymentPaypalSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));

      window.location.assign(window.location.origin + "/live-video/" +
        response.data.data.live_video_unique_id);

    } else {
      yield put(livePaymentPaypalFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(livePaymentPaypalFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveViewerUpdateAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveViewerUpdate.inputData
    );
    const response = yield api.postMethod(
      "live_videos_viewer_update",
      inputData
    );
    if (response.data.success) {
      yield put(liveViewerUpdateSuccess(response.data.data));
      // const notificationMessage = getSuccessNotificationMessage(
      //   response.data.message
      // );
      // yield put(createNotification(notificationMessage));

    } else {
      yield put(liveViewerUpdateFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(liveViewerUpdateFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveEndAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveEnd.inputData
    );
    const response = yield api.postMethod(
      "live_videos_broadcast_stop",
      inputData
    );
    if (response.data.success) {
      yield put(liveVideoEndSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      console.log(response.data.data);
      if(response.data.data.is_owner == 1) {
        window.location.assign(window.location.origin + "/live-videos-history");
      } else {
        window.location.assign(window.location.origin + isMobile ? "/live-videos" : "/w/live-videos");
      }

    } else {
      yield put(liveVideoEndFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(liveVideoEndFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveWalletAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveWallet.inputData
    );
    const response = yield api.postMethod(
      "live_videos_payment_by_wallet",
      inputData
    );
    if (response.data.success) {
      yield put(livePaymentWalletSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign(window.location.origin + "/live-video/" + response.data.data.live_video_unique_id);

    } else {
      yield put(livePaymentWalletFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(livePaymentWalletFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveStartAPI(action) {
  try {
    const response = yield api.postMethod(
      "live_videos_start",
      action.data
    );
    if (response.data.success) {
      yield put(liveVideoStartCallSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign(
        window.location.origin +
          "/join/" +
          response.data.data.live_video_unique_id
      );
    } else {
      yield put(liveVideoStartCallFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(liveVideoStartCallFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteLiveVideoAPI(action) {
  try {

    const response = yield api.postMethod("live_videos_delete", action.data);
    if (response.data.success) {
      yield put(deleteLiveVideosSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchLiveVideosHistoryStart());

    } else {
      yield put(deleteLiveVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteLiveVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveVideoEditSaveAPI(action) {
  try {
    const response = yield api.postMethod("live_videos_save", action.data);
    if (response.data.success) {
      yield put(liveVideosSaveSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      if (action.data.streaming_type == 1) {
        window.location.assign(
          window.location.origin +
            "/join/" +
            response.data.data.live_video_unique_id
        );
      } else {
        window.location.assign(window.location.origin + "/scheduled-live-videos");
      }

    } else {
      yield put(liveVideosSaveFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(liveVideosSaveFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(VIDEO_CALL_BROADCAST_START, liveVideoSaveAPI)]);
  yield all([yield takeLatest(FETCH_LIVE_VIDEOS_START, liveVideosAPI)]);
  yield all([yield takeLatest(FETCH_SCHEDULED_LIVE_VIDEOS_START, scheduledLiveVideosAPI)]);
  yield all([yield takeLatest(FETCH_LIVE_VIDEOS_HISTORY_START, liveVideosHistoryAPI)]);
  yield all([yield takeLatest(FETCH_MORE_LIVE_VIDEOS_HISTORY_START, liveVideosHistoryAPI)]);
  // yield all([yield takeLatest(JOIN_LIVE_VIDEOS_START, joinLiveVideosAPI)]);
  yield all([yield takeLatest(FETCH_SINGLE_LIVE_VIDEOS_START, fetchSingleLiveVideoAPI)]);
  yield all([yield takeLatest(LIVE_VIDEOS_PAYMENT_BY_STRIPE_START, liveStripeAPI)]);
  yield all([yield takeLatest(LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START, livePaypalAPI)]);
  yield all([yield takeLatest(LIVE_VIDEOS_VIEWER_UPDATE_START, liveViewerUpdateAPI)]);
  yield all([yield takeLatest(LIVE_VIDEOS_END_START, liveEndAPI)]);
  yield all([yield takeLatest(LIVE_VIDEOS_PAYMENT_BY_WALLET_START, liveWalletAPI)]);
  yield all([yield takeLatest(LIVE_VIDEOS_START_CALL_START, liveStartAPI)]);
  yield all([yield takeLatest(FETCH_USER_SCHEDULED_LIVE_VIDEOS_START, userScheduledVideosAPI)]);
  yield all([yield takeLatest(DELETE_LIVE_VIDEOS_START, deleteLiveVideoAPI)]);
  yield all([yield takeLatest(LIVE_VIDEOS_SAVE_START, liveVideoEditSaveAPI)]);
}
