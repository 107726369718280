import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Image, Media } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../NoDataFound/NoDataFound";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchLiveVideosStart } from "../../store/actions/LiveVideoAction";
import UserLiveVideoLoader from "../Loader/UserLiveVideoLoader";
import ModelLiveStreamingCard from "./ModelLiveStreamingCard";

const ModelLiveStreamingSec = (props) => {

	const [showDesc, setShowDesc] = React.useState(false);


	useEffect(() => {
		props.dispatch(
			fetchLiveVideosStart({
				trainer_id: props.userDetails.user_id,
			})
		);
	}, []);

	return (
		<>
			<div className="coach-single-profile-subscribers-box">
				{props.liveVideos.loading ? (
					<UserLiveVideoLoader />
				) : props.liveVideos.data.live_videos.length > 0 ? (
					props.liveVideos.data.live_videos.map((video) => (
						<ModelLiveStreamingCard video={video} key={video.live_video_id}/>
					))) :
					<NoDataFound />
				}
			</div>
		</>
	);
};

const mapStateToPros = (state) => ({
	liveVideos: state.liveVideo.liveVideos,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(
	mapStateToPros,
	mapDispatchToProps
)(translate(ModelLiveStreamingSec));

