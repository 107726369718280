import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Form, Button, Container, Row, Col, Table, Image } from "react-bootstrap";
import "../Accounts/Payments/AddBankIndex.css";

import {
    fetchSingleLiveVideoStart,
    liveVideosSaveStart,
} from "../../store/actions/LiveVideoAction";
import { translate, t } from "react-multi-lang";
import dayjs from "dayjs";
import moment from 'moment';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import DatePicker from 'react-mobile-datepicker';


var yesterday = moment().subtract(1, 'day');
var valid = function (current) {
    return current.isAfter(yesterday);
};

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)


const EditLiveVideos = (props) => {
    const descriptionLength = 1100;

    const dateConfig = {
        'year': {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
        },
        'month': {
            format: 'MM',
            caption: 'Month',
            step: 1,
        },
        'date': {
            format: 'DD',
            caption: 'Day',
            step: 1,
        },
        'hour': {
            format: 'hh',
            caption: 'Hour',
            step: 1,
        },
        'minute': {
            format: 'mm',
            caption: 'Min',
            step: 1,
        },
    }

    const [today, setToday] = useState();
    const [show, setShow] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [startTime, setStartTime] = useState();
    const [clamped, setClamped] = useState(true);

    useEffect(() => {
        if (!props.userDetails.loading && Object.keys(props.userDetails.data).length > 0) {
            props.dispatch(
                fetchSingleLiveVideoStart({
                    live_video_unique_id: props.match.params.live_video_unique_id,
                })
            );
            setToday(new Date(dayjs(new Date())
                .tz(props.userDetails.data.timezone)
                .format("YYYY-MM-DD HH:mm:00")
            ));
        }
    }, [props.userDetails]);

    const [inputData, setInputData] = useState({
        schedule_time: startTime,
        streaming_type: 2,
        payment_status: 1,
        title: '',
        amount: 1,
        no_of_minutes: 0,
    });

    console.log(startTime);

    useEffect(() => {
        if (!props.liveVideo.loading) {
            setStartTime(new Date(dayjs(props.liveVideo.data.schedule_time)
                .tz(props.userDetails.data.timezone)
                .format("YYYY-MM-DD HH:mm:00")
            ));
            setInputData({
                ...inputData,
                streaming_type: 2,
                payment_status: 1,
                live_video_id: props.liveVideo.data.live_video_id,
                schedule_time: dayjs(props.liveVideo.data.schedule_time).format("YYYY-MM-DD HH:mm:00"),
                title: props.liveVideo.data.title,
                amount: props.liveVideo.data.token,
                no_of_minutes: props.liveVideo.data.no_of_minutes,
                description: props.liveVideo.data.description,
            });

        }
    }, [!props.liveVideo.loading]);

    const [paymentStatus, setPaymentStatus] = useState(1);
    const [streamingStatus, setStreamingStatus] = useState(2);


    const [previewImage, setPreviewImage] = useState("");

    const paymentStatusOnchange = (event) => {
        setInputData({
            ...inputData,
            payment_status: event,
        });
        setPaymentStatus(event);
    };

    const streamingStatusOnchange = (event) => {
        setInputData({
            ...inputData,
            streaming_type: event,
        });
        setStreamingStatus(event);
    };

    const startTimeOnchange = (time) => {
        setInputData({
            ...inputData,
            schedule_time: dayjs(time).format("YYYY-MM-DD HH:mm:00"),
        });
        setStartTime(time);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(liveVideosSaveStart(inputData));
    };

    const handleChangeImage = (event) => {
        if (event.currentTarget.type === "file") {
            let reader = new FileReader();
            let file = event.currentTarget.files[0];

            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };

            reader.readAsDataURL(file);

            setInputData({
                ...inputData,
                snapshot: event.currentTarget.files[0],
            });
        }
    };

    return (
        <div className="card-list-sec">
            {props.liveVideo.loading ? '' :
                <Container>
                    <Link
                        className="bookmarkes-list notify-title back-button head-title"
                        onClick={() =>
                            props.location.state && props.location.state.prevPath
                                ? props.history.goBack()
                                : props.history.push("/")
                        }
                    >
                        <img
                            src={window.location.origin + "/assets/images/icons/back.svg"}
                            className="svg-clone"
                        />
                        {t("edit_live_video")}
                    </Link>
                    {/* <h4 className="head-title">{t("add_bank")}</h4> */}
                    <Row>
                        <Col sm={12} md={12}>
                            <div className="add-bank-box">
                                <Form onSubmit={handleSubmit}>
                                    <Col md={6}>

                                        <Form.Group className="sent-tip-modal">
                                            <Form.Label>{t("title")}</Form.Label>
                                            <Form.Control type="text" placeholder={t("title")}
                                                value={inputData.title ? inputData.title : null}
                                                onChange={(event) =>
                                                    setInputData({
                                                        ...inputData,
                                                        title: event.currentTarget.value,
                                                    })
                                                } />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="exampleForm.ControlInput1">
                                            <Form.Label className="choose-payment-label">{t("duration_in_minutes")}</Form.Label>
                                            <Form.Control type="number"
                                                min="0"
                                                step="any"
                                                value={inputData.no_of_minutes ? inputData.no_of_minutes : null}
                                                onChange={(event) =>
                                                    setInputData({
                                                        ...inputData,
                                                        no_of_minutes: event.currentTarget.value,
                                                    })
                                                } />
                                        </Form.Group>
                                    </Col>


                                    <Col md={6}>
                                        <Form.Group className="sent-tip-modal">
                                            <Form.Label>{t("streaming_type")}</Form.Label>
                                            {["radio"].map((type) => (
                                                <div key={`custom-inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Schedule"
                                                        type={type}
                                                        // id={`custom-inline-${type}-2`}
                                                        id="later"
                                                        value="2"
                                                        defaultChecked={true}
                                                        name="streaming_type"
                                                        onChange={(event) => {
                                                            streamingStatusOnchange(event.currentTarget.value);
                                                        }}
                                                    />

                                                </div>
                                            ))}
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="sent-tip-modal">
                                            <Form.Label>{t("payment_status")}</Form.Label>
                                            {["radio"].map((type) => (
                                                <div key={`custom-inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Paid"
                                                        type={type}
                                                        // id={`custom-inline-${type}-2`}
                                                        id="paid"
                                                        value="1"
                                                        defaultChecked={true}
                                                        name="payment_status"
                                                        onChange={(event) => {
                                                            paymentStatusOnchange(event.currentTarget.value);
                                                        }}
                                                    />

                                                </div>
                                            ))}
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group controlId="exampleForm.ControlInput1">
                                            <Form.Label className="choose-payment-label">{t("start_time")}</Form.Label>
                                            <Form.Control
                                                className="date-input"
                                                value={inputData.schedule_time}
                                                onClick={() => setShow(!show)}
                                                readOnly={true}
                                            />
                                            <DatePicker
                                                value={startTime}
                                                isOpen={show}
                                                onSelect={time => { startTimeOnchange(time); setShow(false) }}
                                                onCancel={() => setShow(false)}
                                                confirmText={"Select"}
                                                cancelText={"Cancel"}
                                                headerFormat='DD/MM/YYYY'
                                                theme={"dark"}
                                                min={today}
                                                showCaption={true}
                                                dateConfig={dateConfig}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <>
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Label className="choose-payment-label">{configuration.get("configData.is_only_wallet_payment") == 1 ? t("token") : t("amount")}</Form.Label>
                                                <Form.Control type="number"
                                                    min="0"
                                                    step="any"
                                                    value={inputData.amount ? inputData.amount : null}
                                                    onChange={(event) =>
                                                        setInputData({
                                                            ...inputData,
                                                            amount: event.currentTarget.value,
                                                        })
                                                    } />
                                            </Form.Group>
                                            <div className="floating-label">
                                                <label className="label-default-1">{t("token_amount")} ({configuration.get("configData.token_amount")} * {inputData.amount ? inputData.amount : 0}) = {configuration.get("configData.token_amount") * inputData.amount} {" "}{configuration.get("configData.currency")}
                                                </label>
                                            </div>
                                        </>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group controlId="exampleForm.ControlInput1">
                                            <Form.Label className="choose-payment-label">{t("description")} ({t("optional")})</Form.Label>
                                            <Form.Control as="textarea" rows={2}
                                                className="height-auto"
                                                value={inputData.description ? inputData.description : null}
                                                placeholder={t("go_live_description_placeholder")}
                                                onChange={(event) =>
                                                    setInputData({
                                                        ...inputData,
                                                        description: event.currentTarget.value,
                                                    })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <div className="upload-btn-wrapper">
                                                <button className="btn">
                                                    {t("upload_preview_image")}
                                                </button>
                                                <input
                                                    type="file"
                                                    name="preview_file"
                                                    accept="image/png, image/jpg, image/jpeg"
                                                    onChange={(event) => handleChangeImage(event)}
                                                />
                                            </div>
                                        </Form.Group>
                                        {previewImage !== "" ? (
                                            <div className="upload-img-preview-sec">
                                                <Image
                                                    className="upload-img-preview"
                                                    src={previewImage}
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </Col>
                                    <Col md={6}></Col>
                                    <div className="edit-save">
                                        <Button
                                            className="btn gradient-btn gradientcolor addBank"
                                            type="submit"
                                        >
                                            {t("save")}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            }
        </div>
    );
};

const mapStateToPros = (state) => ({
    userDetails: state.users.profile,
    liveVideo: state.liveVideo.singleLiveVideo,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(EditLiveVideos));
