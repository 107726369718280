import React, { useState } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Badge,
  Image,
} from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { Link } from 'react-router-dom';
import {
  acceptCallStart,
  callRequestReceivedModelStart,
  callHistoryUserStart,
  rejectCallStart,
  endVideoCallStart,
} from "../../../store/actions/PrivateCallAction";
import VideoCallMakePaymentModel from "../../helper/VideoCallMakePaymentModel";
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import CommonCenterLoader from '../../Loader/CommonCenterLoader';
import { isMobile } from "react-device-detect";

const VideoCallHistoryTable = (props) => {

  const [makePaymentInput, setMakePaymentInput] = useState({
    video_call_request_id: "",
    model_displayname: "",
    model_picture: "",
    amount: "",
    amount_formatted: "",
    model_unique_id: "",
    model_id: "",
    modelname: "",
  });

  const [
    selectedCallDetailsToMakePayment,
    setSelectedCallDetailsToMakePayment,
  ] = useState(null);

  const [makePaymentModel, setMakePaymentModel] = useState(false);

  const closePaymentModal = () => {
    setMakePaymentModel(false);
  };

  const makePayment = (event, callDetails) => {
    event.preventDefault();
    setMakePaymentInput({
      video_call_request_id: callDetails.video_call_request_id,
      model_displayname: callDetails.model_displayname,
      model_picture: callDetails.model_picture,
      amount: callDetails.amount,
      amount_formatted: callDetails.amount_formatted,
      model_unique_id: callDetails.model_unique_id,
      model_id: callDetails.model_id,
      modelname: callDetails.modelname,
    });
    setSelectedCallDetailsToMakePayment(callDetails);
    setMakePaymentModel(true);
  };

  return (
    <>
      <div className="trans-table">
        <InfiniteScroll
          dataLength={props.videoCallRequest.length}
          next={props.fetchMore}
          hasMore={props.videoCallRequest.length < props.total &&
            props.errorCount < 2}
          loader={<CommonCenterLoader />}
        >
          <Table borderedless responsive>
            <thead>
              <tr className="bg-white text-muted text-center text-uppercase">
                <th>{t("action")}</th>
                <th>{t("status")}</th>
                {localStorage.getItem("is_content_creator") == 2 ?
                  <th>{t("user")}</th>
                  : <th>{t("coach")}</th>}
                <th>{t("trainer_services")}</th>
                <th>{t("scheduled")}</th>
                {/* <th>{t("end_time")}</th> */}
                <th>{t("s_no")}</th>
              </tr>
            </thead>
            <tbody>
              {props.videoCallRequest.map(
                (videoCall, index) => (
                  <tr
                    key={videoCall.video_call_request_id}
                    className="text-center"
                  >
                    <td>
                      {videoCall.accept_btn_status == 1 ? (
                        <Button
                          className="btn btn-sm btn-success mr-3 mb-3"
                          onClick={() =>
                            props.dispatch(
                              acceptCallStart({
                                video_call_request_id:
                                  videoCall.video_call_request_id,
                              })
                            )
                          }
                        >
                          {t("accept")}
                        </Button>
                      ) : (
                        ""
                      )}
                      {videoCall.reject_btn_status == 1 ? (
                        <Button
                          className="btn btn-sm btn-danger mr-3 mb-3"
                          onClick={() =>
                            props.dispatch(
                              rejectCallStart({
                                video_call_request_id:
                                  videoCall.video_call_request_id,
                              })
                            )
                          }
                        >
                          {t("reject")}
                        </Button>
                      ) : (
                        ""
                      )}
                      {videoCall.payment_btn_status == 1 ? (
                        <Button
                          className="btn btn-success mr-3 mb-3"
                          onClick={(event) =>
                            makePayment(event, videoCall)
                          }
                        >
                          {videoCall.amount > 0
                            ? t("paynow")
                            : t("confirm_request")}
                        </Button>
                      ) : (
                        ""
                      )}

                      {videoCall.join_btn_status == 1 ? (
                        <Link
                          className="btn btn-success mr-3 mb-3"
                          to={isMobile ? `/private-call/${videoCall.video_call_request_unique_id}` : `/video-call/${videoCall.video_call_request_unique_id}`}
                        >
                          {t("join_call")}
                        </Link>
                      ) : (
                        ""
                      )}

                      {videoCall.start_btn_status == 1 ? (
                        <Link
                          className="btn btn-success mr-3 mb-3"
                          to={isMobile ? `/private-call/${videoCall.video_call_request_unique_id}` : `/video-call/${videoCall.video_call_request_unique_id}`}
                        >
                          {t("start_call")}
                        </Link>
                      ) : (
                        ""
                      )}

                      {videoCall.end_btn_status == 1 ? (
                        <Button
                          className="btn btn-danger mr-3 mb-3"
                          onClick={() =>
                            props.dispatch(
                              endVideoCallStart({
                                video_call_request_id:
                                  videoCall.video_call_request_id,
                              })
                            )
                          }
                        >
                          {t("end_call")}
                        </Button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{videoCall.call_status_formatted}</td>
                    {localStorage.getItem("is_content_creator") == 2 ?
                      <td>
                        {/* <Link to={`/` + videoCall.user_unique_code}> */}
                        {videoCall.user_displayname}
                        {/* </Link> */}
                      </td>
                      :
                      <td>
                        {videoCall.is_model ?
                          videoCall.model_displayname
                          :
                          <Link to={isMobile ? `/w/` + videoCall.model_unique_id : `/` + videoCall.model_unique_id}>
                            {videoCall.model_displayname}
                          </Link>}

                      </td>
                    }
                    <td>{videoCall.trainer_service ? videoCall.trainer_service.service : 'N/A'}</td>
                    <td>
                      {videoCall.start_time
                        ? videoCall.start_time
                        : "-"}
                    </td>
                    {/* <td>
                      {videoCall.end_time ? videoCall.end_time : "-"}
                    </td> */}

                    <td>{index + 1}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </InfiniteScroll>
      </div>
      <VideoCallMakePaymentModel
        video_call_request_id={makePaymentInput.video_call_request_id}
        videoCallPayment={makePaymentModel}
        closePaymentModal={closePaymentModal}
        userPicture={makePaymentInput.model_picture}
        name={makePaymentInput.model_displayname}
        user_unique_id={makePaymentInput.model_unique_id}
        model_id={makePaymentInput.model_id}
        callDetails={makePaymentInput}
        username={makePaymentInput.modelname}
      />
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  null,
  mapDispatchToProps
)(translate(VideoCallHistoryTable));