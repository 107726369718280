import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Media,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./NewBecomeAContentCreator.css";
import Step1 from "../Step1";
import Step2 from "../Step2";
import Step3 from "../Step3";
import Step4 from "../Step4";
import Step6 from "../Step6";
import { isMobile } from "react-device-detect";
import { fetchUserDetailsStart } from "../../../store/actions/UserAction";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { Link } from "react-router-dom";

const BecomeAContentCreatorMobile = (props) => {
  const [initalStep, setInitalStep] = useState("");

  useEffect(() => {
    if (!isMobile) {
      window.location.assign("/become-a-coach");
    }
    props.dispatch(fetchUserDetailsStart());
  }, []);

  useEffect(() => {
    if (props.user.data.content_creator_step === 0) {
      setInitalStep(0);
    } else if (props.user.data.content_creator_step == 1) {
      setInitalStep(1);
    } else if (props.user.data.content_creator_step == 2) {
      setInitalStep(2);
    } else if (props.user.data.content_creator_step == 3) {
      setInitalStep(3);
    } else if (props.user.data.content_creator_step == 4) {
      setInitalStep(3);
    } else {
      setInitalStep("");
    }

    console.log("Hello" + props.user.data.content_creator_step, initalStep);
  }, [props.user.data]);

  const changeInitialStep = (value) => {
    setInitalStep(value);
  };

  useEffect(() => {
    !props.user.loading && props.dispatch(fetchUserDetailsStart());
  }, [props.addKycDocInput.data]);

  return (
    <div className="new-become-content-creator-sec">
      <Container>
        {initalStep === "" ? null : (
          <div className="new-become-content-creator-box">
            <ul className="new-become-content-creator-list list-unstyled">
              <Media
                as="li"
                onClick={() => setInitalStep(1)}
                className={initalStep === 0 ? "active" : ""}
              >
                <div className="new-become-content-creator-icon-sec">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM288.1 344.1C284.3 349.7 278.2 352 272 352s-12.28-2.344-16.97-7.031L216 305.9V408c0 13.25-10.75 24-24 24s-24-10.75-24-24V305.9l-39.03 39.03c-9.375 9.375-24.56 9.375-33.94 0s-9.375-24.56 0-33.94l80-80c9.375-9.375 24.56-9.375 33.94 0l80 80C298.3 320.4 298.3 335.6 288.1 344.1z" />
                  </svg>
                </div>
                <h4>{t("upload_documents")}</h4>
              </Media>
              {initalStep === 0 && (
                <div className="new-steps">
                  <Step1 user={props.user} />
                </div>
              )}
              <Media
                as="li"
                onClick={() => setInitalStep(1)}
                className={initalStep === 1 ? "active" : ""}
              >
                <div className="new-become-content-creator-icon-sec">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" />
                  </svg>
                </div>
                <h4>{t("admin_approve_the_document")}</h4>
              </Media>
              {initalStep == 1 && (
                <div className="new-steps">
                  <Step2 user={props.user} jumpToStep={changeInitialStep} />
                </div>
              )}
              {/* <Media as="li" onClick={() => setInitalStep(3)} className={initalStep === 3 ? "active" : ""}>
                            <div className="new-become-content-creator-icon-sec">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z" /></svg>
                            </div>
                            <h4>{t("pending_validation")}</h4>
                        </Media>
                        {initalStep == 3 && (
                            <div className="new-steps">
                                <Step3 user={props.user} />
                            </div>
                        )} */}

              <Media
                as="li"
                onClick={() => setInitalStep(2)}
                className={initalStep === 2 ? "active" : ""}
              >
                <div className="new-become-content-creator-icon-sec">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z" />
                  </svg>
                </div>
                <h4>{t("update_billing_details")}</h4>
              </Media>
              {initalStep == 2 && (
                <div className="new-steps">
                  <Step4 user={props.user} jumpToStep={changeInitialStep} />
                </div>
              )}

              <Media
                as="li"
                onClick={() => setInitalStep(3)}
                className={initalStep === 3 ? "active" : ""}
              >
                <div className="new-become-content-creator-icon-sec">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M243.4 2.587C251.4-.8625 260.6-.8625 268.6 2.587L492.6 98.59C506.6 104.6 514.4 119.6 511.3 134.4C508.3 149.3 495.2 159.1 479.1 160V168C479.1 181.3 469.3 192 455.1 192H55.1C42.74 192 31.1 181.3 31.1 168V160C16.81 159.1 3.708 149.3 .6528 134.4C-2.402 119.6 5.429 104.6 19.39 98.59L243.4 2.587zM256 128C273.7 128 288 113.7 288 96C288 78.33 273.7 64 256 64C238.3 64 224 78.33 224 96C224 113.7 238.3 128 256 128zM127.1 416H167.1V224H231.1V416H280V224H344V416H384V224H448V420.3C448.6 420.6 449.2 420.1 449.8 421.4L497.8 453.4C509.5 461.2 514.7 475.8 510.6 489.3C506.5 502.8 494.1 512 480 512H31.1C17.9 512 5.458 502.8 1.372 489.3C-2.715 475.8 2.515 461.2 14.25 453.4L62.25 421.4C62.82 420.1 63.41 420.6 63.1 420.3V224H127.1V416z" />
                  </svg>
                </div>
                <h4>{t("admin_makes_you_creator")}</h4>
              </Media>
              {initalStep == 3 && (
                <div className="new-steps">
                  <Step6 user={props.user} jumpToStep={changeInitialStep} />
                </div>
              )}
              {/* <Media as="li" onClick={() => setStep(5)} className={step===5?"active":""}>
                            <div className="new-become-content-creator-icon-sec">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M160 0C177.7 0 192 14.33 192 32V67.68C193.6 67.89 195.1 68.12 196.7 68.35C207.3 69.93 238.9 75.02 251.9 78.31C268.1 82.65 279.4 100.1 275 117.2C270.7 134.3 253.3 144.7 236.1 140.4C226.8 137.1 198.5 133.3 187.3 131.7C155.2 126.9 127.7 129.3 108.8 136.5C90.52 143.5 82.93 153.4 80.92 164.5C78.98 175.2 80.45 181.3 82.21 185.1C84.1 189.1 87.79 193.6 95.14 198.5C111.4 209.2 136.2 216.4 168.4 225.1L171.2 225.9C199.6 233.6 234.4 243.1 260.2 260.2C274.3 269.6 287.6 282.3 295.8 299.9C304.1 317.7 305.9 337.7 302.1 358.1C295.1 397 268.1 422.4 236.4 435.6C222.8 441.2 207.8 444.8 192 446.6V480C192 497.7 177.7 512 160 512C142.3 512 128 497.7 128 480V445.1C127.6 445.1 127.1 444.1 126.7 444.9L126.5 444.9C102.2 441.1 62.07 430.6 35 418.6C18.85 411.4 11.58 392.5 18.76 376.3C25.94 360.2 44.85 352.9 60.1 360.1C81.9 369.4 116.3 378.5 136.2 381.6C168.2 386.4 194.5 383.6 212.3 376.4C229.2 369.5 236.9 359.5 239.1 347.5C241 336.8 239.6 330.7 237.8 326.9C235.9 322.9 232.2 318.4 224.9 313.5C208.6 302.8 183.8 295.6 151.6 286.9L148.8 286.1C120.4 278.4 85.58 268.9 59.76 251.8C45.65 242.4 32.43 229.7 24.22 212.1C15.89 194.3 14.08 174.3 17.95 153C25.03 114.1 53.05 89.29 85.96 76.73C98.98 71.76 113.1 68.49 128 66.73V32C128 14.33 142.3 0 160 0V0z" /></svg>
                            </div>
                            <h4>Update Plans</h4>
                        </Media>
                        {step == 5 && (
                            <div className="new-steps">
                                <Step1 />
                            </div>
                        )}
                        <Media as="li" onClick={() => setStep(6)} className={step===6?"active":""}>
                            <div className="new-become-content-creator-icon-sec">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M274.7 304H173.3C77.61 304 0 381.6 0 477.3C0 496.5 15.52 512 34.66 512H413.3C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM632.3 134.4c-9.703-9-24.91-8.453-33.92 1.266l-87.05 93.75l-38.39-38.39c-9.375-9.375-24.56-9.375-33.94 0s-9.375 24.56 0 33.94l56 56C499.5 285.5 505.6 288 512 288h.4375c6.531-.125 12.72-2.891 17.16-7.672l104-112C642.6 158.6 642 143.4 632.3 134.4z" /></svg>
                            </div>
                            <h4>Finish</h4>
                        </Media>
                        {step == 6 && (
                            <div className="new-steps">
                                <Step1 />
                            </div>
                        )} */}
            </ul>
          </div>
        )}
      </Container>
      {props.user.loading ? (
        ""
      ) : props.user.data.is_content_creator == 2 ? (
        <div className="text-center">
         <h2 className="text-center text-success be-coach">
                {t("congratulations_you are_now_a_coach")}
              </h2>
          <Link
            to="/edit-profile"
            className="btn gradient-btn gradientcolor addBank mt-3"
          >
            {t("edit_your_profile")}
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToPros = (state) => ({
  user: state.users.profile,
  addKycDocInput: state.kycDocument.addKycDocInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(BecomeAContentCreatorMobile));
