import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Badge,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import BillingAccountLoader from "../../Loader/BillingAccountLoader";
import NoDataFound from "../../NoDataFound/NoDataFound";
import {
  audioCallRequestSentStart,
  moreAudioCallRequestSentStart
} from "../../../store/actions/PrivateCallAction";
import { connect } from "react-redux";
import AudioCallHistoryTable from "./AudioCallHistoryTable";

const AudioCallRequestSentIndex = (props) => {
  const [take, setTake] = useState(12);

  useEffect(() => {
    props.dispatch(audioCallRequestSentStart({
      skip: 0,
      take: take,
    }));
  }, []);

  const fetchMoreRequest = () => {
    props.dispatch(moreAudioCallRequestSentStart({
      skip: props.audioCallRequestSent.data.audio_call_requests.length,
      take: take,
    }));
  }

  return (
    <>
      <div className="wallet-sec live-video-list-header-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div className="wallet-header-sec">
                <Row>
                  <Col sm={12} md={12} xl={8}>
                    <Link
                      className="bookmarkes-list notify-title back-button"
                      onClick={() => props.history.goBack()}
                    >
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/icons/back-white.svg"
                        }
                        className="svg-clone"
                      />
                      <h3 className="ml-2 mb-0">AUDIO SESSIONS</h3>
                    </Link>
                    <h3></h3>
                  </Col>

                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div class="live-session-title">
        <h4>
          <span>
            <Image
              className="live-session-title-icon"
              src={
                window.location.origin +
                "/assets/images/fitness/video-sessions-icon.svg"
              }
            />
          </span>
          AUDIO SESSIONS
        </h4>
      </div>
      <div className="trans-table-sec live-video-history-table-sec">
        <Container>
          {props.audioCallRequestSent.loading ? (
            <BillingAccountLoader />
          ) : props.audioCallRequestSent.data.audio_call_requests &&
            props.audioCallRequestSent.data.audio_call_requests.length > 0 ? (
            <Row>
              <Col sm={12} md={12}>
                <AudioCallHistoryTable
                  audioCallRequest={props.audioCallRequestSent.data.audio_call_requests}
                  fetchMore={fetchMoreRequest}
                  total={props.audioCallRequestSent.data.total}
                  errorCount={props.audioCallRequestSent.errorCount}
                />
              </Col>
            </Row>
          ) : (
            <NoDataFound />
          )}
        </Container>
      </div>
    </>
  );
}

const mapStateToPros = (state) => ({
  audioCallRequestSent: state.privateCall.audioCallRequestSent,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(AudioCallRequestSentIndex));