import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Image,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./Wallet.css";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import {
  fetchAllTransactionStart,
  fetchMoreTransactionStart,
} from "../../store/actions/TransactionAction";
import WithdrawModal from "../helper/WithdrawModal";
import AddWalletTokenModal from "../helper/AddWalletTokenModal";
import NoDataFound from "../NoDataFound/NoDataFound";
import WalletLoader from "../Loader/WalletLoader";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import CommonCenterLoader from "../Loader/CommonCenterLoader";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { walletHistoryExportStart } from "../../store/actions/WalletAction";
import InfiniteScroll from "react-infinite-scroll-component";

import moment from "moment";

const Wallet = (props) => {
  const [colVal, setColValue] = useState(4);

  useEffect(() => {
    props.dispatch(fetchWalletDetailsStart());
    setColValue(
      configuration.get("configData.is_referral_enabled") == 1 ? 3 : 4
    );
  }, []);

  const [withdrawModal, setWithdrawModal] = useState(false);

  const [addWalletAmountModal, setAddWalletAmountModal] = useState(false);

  const closeWithdrawModal = () => {
    setWithdrawModal(false);
  };

  const closeAddWalletAmountModal = () => {
    setAddWalletAmountModal(false);
  };

  const dateRef = useRef(null);

  const [take, setTake] = useState(12);

  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
    type: "",
  });

  useEffect(() => {
    props.dispatch(
      fetchAllTransactionStart({
        start_date: filter.startDate
          ? filter.startDate.format("YYYY-MM-DD")
          : "",
        end_date: filter.endDate ? filter.endDate.format("YYYY-MM-DD") : "",
        usage_type: filter.type,
        skip: 0,
        take: take,
      })
    );
  }, [filter]);

  const fetchMoreWalletTransActions = () => {
    props.dispatch(
      fetchMoreTransactionStart({
        start_date: filter.startDate
          ? filter.startDate.format("YYYY-MM-DD")
          : "",
        end_date: filter.endDate ? filter.endDate.format("YYYY-MM-DD") : "",
        usage_type: filter.type,
        skip: props.transaction.data.history.length,
        take: take,
      })
    );
  };

  const changeType = (e) => {
    setFilter({
      ...filter,
      type: e.target.value,
    });
  };

  const exportWalletTransaction = () => {
    props.dispatch(
      walletHistoryExportStart({
        start_date: filter.startDate
          ? filter.startDate.format("YYYY-MM-DD")
          : "",
        end_date: filter.endDate ? filter.endDate.format("YYYY-MM-DD") : "",
        usage_type: filter.type,
      })
    );
  };

  const handleApply = (event, picker) => {
    setFilter({
      ...filter,
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  };

  const resetDate = () => {
    dateRef.current.ref.value = "";
    setFilter({
      ...filter,
      startDate: null,
      endDate: null,
    });
  };

  console.log("Current Count", props.transaction.data.history.length);
  console.log("Total Count", props.transaction.data.total);
  console.log("Error Count", props.transaction.errorCount);

  return (
    <>
      <div className="wallet-sec">
        {props.wallet.loading ? (
          <WalletLoader></WalletLoader>
        ) : (
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <div className="wallet-header-sec">
                  <Row className="align-items-center">
                    {/* <Col sm={12} md={6} xl={3}>
											<div className="wallet-header-card">
												<Image
													src={
														window.location.origin +
														"/assets/images/icons/wallet-active.svg"
													}
													className="credit-img"
												/>
												<div className="wallet-header-details">
													<h5>{t("total_balance")}</h5>
													<h3>
														{props.wallet.data.user_wallet.total_formatted}
														<span className="amount-decimal">.76500293</span>
														<span className="amount-abb">..</span>
													</h3>
												</div>
											</div>
										</Col> */}
                    <Col sm={12} md={6} xl={6}>
                      {/* <div className="wallet-header-card">
												<Image
													src={
														window.location.origin +
														"/assets/images/icons/wallet-dollar.svg"
													}
													className="credit-img"
												/>
												<div className="wallet-header-details">
													<h5>{t("wallet_balance")}</h5>
													<h3>
														{props.wallet.data.user_wallet.remaining_formatted}
														<span className="amount-decimal">.76500293</span>
														<span className="amount-abb">BTC</span>
													</h3>
												</div>
											</div> */}
                      <Row>
                        <Col md={7}>
                          <div className="new-dashboard-card">
                            <div className="outile-border">
                              <div className="new-dashboard-img">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/images/fitness/wallet-icon.svg"
                                  }
                                />
                              </div>
                            </div>

                            <div className="wallet-header-details">
                              <h5>{t("wallet_balance")}</h5>
                              <h3>
                                {
                                  props.wallet.data.total_wallet_balance_formatted
                                }
                                {/* <span className="amount-decimal">.76500293</span>
									<span className="amount-abb">BTC</span> */}
                              </h3>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    {configuration.get("configData.is_referral_enabled") ==
                    1 ? (
                      <Col sm={12} md={6} xl={6}>
                        <div className="wallet-header-card">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/icons/wallet-dollar.svg"
                            }
                            className="credit-img"
                          />
                          <div className="wallet-header-details">
                            <h5>{t("referral_balance")}</h5>
                            <h3>
                              {
                                props.wallet.data.user_wallet
                                  .referral_amount_formatted
                              }
                              {/* <span className="amount-decimal">.76500293</span>
															<span className="amount-abb">BTC</span> */}
                            </h3>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    <Col sm={12} md={6} xl={6}>
                      <div className="edit-save">
                        {/* <Button
													className="send-btn-white"
													onClick={() => setAddWalletAmountModal(true)}
												>
													<Image
														src={
														window.location.origin +
														"/assets/images/fitness/icons/add-wallet-icon.svg"
														}
														className="add-wallet-icon"
													/>
													{configuration.get("configData.is_only_wallet_payment") == 1 ? t("add_wallet_token") : t("add_wallet_amount")}
												</Button> */}
                        <Button
                          className="send-btn-white btn-success"
                          onClick={() => setWithdrawModal(true)}
                        >
                          {t("withDraw")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
      <div className="trans-table-sec wallet-new-table-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <h4>{t("wallet_transactions")}</h4>
              {/* <div className="form-group col-sm-12 col-md-4">
									<select className="form-select" onChange={changeType} value={filter.type}>
										<option value="">All Types</option>
										<option value="wallet-add">wallet Add</option>
										<option value="video-call">Video Call</option>
										<option value="audio-call">Audio Call</option>
										<option value="ppv">PPV</option>
									</select>
								</div> */}

              <p>
                {t("total_records")} - {props.transaction.data.total}
              </p>

              <Form className="wallet-new-form-box">
                <Form.Control
                  as="select"
                  onChange={changeType}
                  value={filter.type}
                >
                  <option value="">{t("all_types")}</option>
                  <option value="wallet-add">{t("wallet")}</option>
                  <option value="video-call">{t("video_call")}</option>
                  <option value="audio-call">{t("audio_call")}</option>
                  <option value="ppv">{t("ppv")}</option>
                  <option value="tip">{t("tip")}</option>
                  <option value="live-video">{t("live_video")}</option>
                  <option value="chat">{t("chat")}</option>
                  <option value="withdraw">{t("withdraw")}</option>
                </Form.Control>

                <InputGroup className="mb-3">
                  <DateRangePicker
                    ref={dateRef}
                    initialSettings={{
                      autoUpdateInput: false,
                      locale: {
                        cancelLabel: "Clear",
                      },
                      maxDate: new Date(),
                    }}
                    onApply={handleApply}
                  >
                    <FormControl
                      placeholder="Start Date - End Date"
                      aria-label="Start Date - End Date"
                      aria-describedby="basic-addon2"
                      value={
                        filter.startDate
                          ? filter.startDate.format("MM/DD/YYYY") +
                            " - " +
                            filter.endDate.format("MM/DD/YYYY")
                          : ""
                      }
                      // readOnly
                    />
                  </DateRangePicker>
                  {filter.startDate && (
                    <InputGroup.Append>
                      <InputGroup.Text
                        className="mr-3"
                        onClick={() => resetDate()}
                      >
                        {t("clear")}
                      </InputGroup.Text>
                    </InputGroup.Append>
                  )}
                  <InputGroup.Append>
                    <InputGroup.Text
                      className={`${
                        props.transaction.data.history.length > 0
                          ? ""
                          : "bg-secondary"
                      }`}
                      id="basic-addon2"
                      onClick={(e) =>
                        props.transaction.data.history.length > 0
                          ? exportWalletTransaction()
                          : e.preventDefault()
                      }
                    >
                      Export
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form>

              {/* <div class="input-group mb-3 col-sm-12 col-md-4">
									<DateRangePicker
										ref={dateRef}
										initialSettings={{
											autoUpdateInput: false,
											locale: {
												cancelLabel: 'Clear',
											},
											maxDate: new Date(),
										}}
										onApply={handleApply}
									>
										<input type="text"
											class="form-control"
											placeholder="Start Date - End Date"
											value={
												filter.startDate ?
													filter.startDate.format('MM/DD/YYYY') + ' - ' + filter.endDate.format('MM/DD/YYYY') : ""
											}
											readOnly
										/>
									</DateRangePicker>
									{filter.startDate &&
										<div class="input-group-append">
											<button
												onClick={() => resetDate()}>clear</button>
										</div>
									}
								</div> */}
              {/* <button
									className="mb-3 btn btn-primary"
									onClick={() => exportWalletTransaction()}>Export</button> */}
              <div className="trans-table">
                {props.transaction.loading ? (
                  <CommonCenterLoader></CommonCenterLoader>
                ) : props.transaction.data.history.length > 0 ? (
                  <InfiniteScroll
                    dataLength={props.transaction.data.history.length}
                    next={fetchMoreWalletTransActions}
                    hasMore={
                      props.transaction.data.history.length <
                        props.transaction.data.total &&
                      props.transaction.errorCount < 2
                    }
                    loader={<CommonCenterLoader />}
                  >
                    <Table borderedless responsive>
                      <tbody>
                        {props.transaction.data.history.map((data) => (
                          <tr>
                            <td>{data.status_formatted}</td>
                            {configuration.get(
                              "configData.is_only_wallet_payment"
                            ) == 0 ? (
                              <>
                                <td className="amount">
                                  {data.paid_amount_formatted}{" "}
                                  {/* <span className="amout-abb">STRAT</span> */}
                                </td>
                                <td className="amount">
                                  {t("service_fee")} :{" "}
                                  {data.admin_amount_formatted}{" "}
                                  {/* <span className="amout-abb">STRAT</span> */}
                                </td>
                              </>
                            ) : (
                              <>
                                <td className="amount">
                                  {data.token_formatted} ({data.currency}
                                  {data.paid_amount})
                                </td>
                                <td className="amount">
                                  {t("service_fee")} :{" "}
                                  {data.admin_token_formatted}{" "}
                                </td>
                              </>
                            )}

                            <td className="amount">
                              <span className="text-capitalize">
                                {data.payment_type}{" "}
                              </span>
                              {/* <span className="amout-abb text-muted">TYPE</span> */}
                            </td>
                            <td>
                              {t("from")} :{" "}
                              {data.received_from_username
                                ? data.received_from_username
                                : "-"}
                            </td>
                            <td>{data.payment_id}</td>
                            {/* <td>
															<Badge className="unconfirmed-badge">
																unconfirmed
															</Badge>
															<span>now</span>
														</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                ) : (
                  <NoDataFound></NoDataFound>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <WithdrawModal
        withdrawModal={withdrawModal}
        closeWithdrawModal={closeWithdrawModal}
        payments={props.wallet}
      />

      <AddWalletTokenModal
        addWalletAmountModal={addWalletAmountModal}
        closeAddWalletAmountModal={closeAddWalletAmountModal}
        payments={props.wallet}
        trainerId={
          !props.wallet.loading && props.wallet.data.user_wallet
            ? props.wallet.data.user_wallet.trainer_id
            : 0
        }
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet.walletData,
  transaction: state.transaction.allTransaction,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Wallet));
