import {
    FETCH_TRAINERS_AVAILABILITY_START,
    FETCH_TRAINERS_AVAILABILITY_SUCCESS,
    FETCH_TRAINERS_AVAILABILITY_FAILURE,
    FETCH_TRAINERS_AVAILABILITY_USER_START,
    FETCH_TRAINERS_AVAILABILITY_USER_SUCCESS,
    FETCH_TRAINERS_AVAILABILITY_USER_FAILURE,
    DELETE_TRAINERS_AVAILABILITY_START,
    DELETE_TRAINERS_AVAILABILITY_SUCCESS,
    DELETE_TRAINERS_AVAILABILITY_FAILURE,
    SAVE_TRAINERS_AVAILABILITY_START,
    SAVE_TRAINERS_AVAILABILITY_SUCCESS,
    SAVE_TRAINERS_AVAILABILITY_FAILURE,
  } from "./ActionConstant";
  
  export function fetchTrainersAvailabilityStart(data) {
    return {
      type: FETCH_TRAINERS_AVAILABILITY_START,
      data,
    };
  }
  
  export function fetchTrainersAvailabilitySuccess(data) {
    return {
      type: FETCH_TRAINERS_AVAILABILITY_SUCCESS,
      data,
    };
  }
  
  export function fetchTrainersAvailabilityFailure(error) {
    return {
      type: FETCH_TRAINERS_AVAILABILITY_FAILURE,
      error,
    };
  }
  
  
  export function fetchTrainersAvailabilityUserStart(data) {
    return {
      type: FETCH_TRAINERS_AVAILABILITY_USER_START,
      data,
    };
  }
  
  export function fetchTrainersAvailabilityUserSuccess(data) {
    return {
      type: FETCH_TRAINERS_AVAILABILITY_USER_SUCCESS,
      data,
    };
  }
  
  export function fetchTrainersAvailabilityUserFailure(error) {
    return {
      type: FETCH_TRAINERS_AVAILABILITY_USER_FAILURE,
      error,
    };
  }
  
  export function deleteTrainersAvailabilityStart(data) {
    return {
      type: DELETE_TRAINERS_AVAILABILITY_START,
      data,
    };
  }
  
  export function deleteTrainersAvailabilitySuccess(data) {
    return {
      type: DELETE_TRAINERS_AVAILABILITY_SUCCESS,
      data,
    };
  }
  
  export function deleteTrainersAvailabilityFailure(error) {
    return {
      type: DELETE_TRAINERS_AVAILABILITY_FAILURE,
      error,
    };
  }

  export function saveTrainersAvailabilityStart(data) {
    return {
      type: SAVE_TRAINERS_AVAILABILITY_START,
      data,
    };
  }
  
  export function saveTrainersAvailabilitySuccess(data) {
    return {
      type: SAVE_TRAINERS_AVAILABILITY_SUCCESS,
      data,
    };
  }
  
  export function saveTrainersAvailabilityFailure(error) {
    return {
      type: SAVE_TRAINERS_AVAILABILITY_FAILURE,
      error,
    };
  }
  